<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row">
          <!-- <div class="card card-body">    -->
            <p class="h2">{{curr_instruction.text}}</p> 
            <img :src="audioImg" class="mx-auto d-block" style="width:60px;cursor: pointer;" @click="listen_count=listen_count+1;bv_tts.say(curr_instruction.text)">
          <!-- </div> -->
        </div>
        <div class="card card-body" style="background-color: lightgray;margin-top: 3mm;"> 
          <div class="row">
            <span style="fontSize:20px;white-space:pre-wrap" :style="result_message_style">{{result_message}}</span>
          </div>
          <div class="row" v-if="show_which_attributes.shape">
            <span v-if="attribute_correct.shape" style="color:green;font-size: large;">
              Correct shape - {{attribute_target.shape}}
              <button v-if="attribute_target.shape == 'Square'" class="button-small-eg" style="border-color:green"></button>
              <button v-else-if="attribute_target.shape == 'Circle'" class="btn border-5 rounded-circle" style="border-color:green; height: 45px; width: 45px" circle></button>
            </span> 
            <span v-else>
              <b style="color:red;font-size: large;">Wrong shape - </b>it should be {{attribute_target.shape}}
              <button v-if="attribute_target.shape == 'Square'" class="button-small-eg" style="border-color:green"></button>
              <button v-else-if="attribute_target.shape == 'Circle'" class="btn border-5 rounded-circle" style="border-color:green; height: 45px; width: 45px"></button>
            </span> 
          </div>
          <div class="row" v-if="show_which_attributes.color">
            <span v-if="attribute_correct.color" style="color:green; font-size: large;">
              Correct colour - <b :style="{'color':color_hex}">{{attribute_target.color}}</b>
            </span> 
            <span v-else>
              <b style="color:red; font-size: large;">Wrong colour - </b>it should be <b :style="{'color':color_hex}">{{attribute_target.color}}</b>
            </span> 
          </div>
          <div class="row" v-if="show_which_attributes.size">
            <span v-if="attribute_correct.size" style="color:green; font-size: large;">
              Correct size - {{attribute_target.size}}
            </span> 
            <span v-else>
              <b style="color:red; font-size: large;">Wrong size - </b>it should be {{attribute_target.size}}
            </span> 
          </div> 
          <div class="row mt-4 justify-content-center" v-for="row in (allsize.length*allshape.length)" :key="row">
            <button v-for="col in (allcolor.length)" :key="col" :class="[alltoken[(row-1)*allcolor.length+col-1].size, {'rounded-circle': alltoken[(row-1)*allcolor.length+col-1].shape}, 'm-1 btn border-5']" :style="alltoken[(row-1)*allcolor.length+col-1].color+alltoken[(row-1)*allcolor.length+col-1].border_color" @click="checkresult((row-1)*allcolor.length+col-1)">{{alltoken[(row-1)*allcolor.length+col-1].text}}</button>
          </div>
          
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
  				@next="nextExercise()"
          >
          
        </Sidebar>
      </template>
    </Layout>
  </div>  
</template>
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import audioImg from '@/assets/images/audio_logo.png'
import crossImg from '@/assets/images/wrong_cross.png'
import tickImg from '@/assets/images/Tick.png'
import { useTTS } from '@/stores/tts'
import { BV_Points } from '@/utils/bv_points'

export default {
  name: 'token_test',
  /*


  {
   "allcolor":[
      "background:#000000",
      "background:#0677d7",
      "background:#FF69B4",
      "background:#ffffff",
      "background:#ffd966"
   ],
   "allshape":[
      true,
      false
   ],
   "allsize":[
      "button-large",
      "button-small"
   ],
   "color_info":[
      {
         "color_code":"#000000",
         "color_name":"black"
      },
      {
         "color_code":"#0677d7",
         "color_name":"blue"
      },
      {
         "color_code":"#FF69B4",
         "color_name":"pink"
      },
      {
         "color_code":"#ffffff",
         "color_name":"white"
      },
      {
         "color_code":"#ffd966",
         "color_name":"yellow"
      }
   ],
   "exercise_type":"token_test",
   "exercise_type":"token_test",
   "full_token":true,
   "instruct":"Token test",
   "mod_name":"Token 1",
   "objects":[
      {
         "color":"background:#0677d7",
         "color_name":"blue"
      }
   ],
   "reshuffle":true,
   "shape_info":[
      {
         "shape_code":true,
         "shape_name":"circle"
      },
      {
         "shape_code":false,
         "shape_name":"square"
      }
   ],
   "size_info":[
      {
         "size_code":"button-large",
         "size_name":"large"
      },
      {
         "size_code":"button-small",
         "size_name":"small"
      }
   ],
   "text":"Click on one of the blue tokens",
   "plan_name":"Token assessment",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Token assessment/0",
   "ex_path":"Token assessment/0",
   "today":"2023-07-11 17:23",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"Click on one of the blue tokens",
   "Ex_Description":"Token test",
   "module_name":"Token assessment",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
  }

  */
  props: {
    exForm: {
      type: Object,
    },
  },
  components: {Layout, Sidebar},
  data(){
    return {
      listen_count:1,
      num_correct:0,
      show_which_attributes:{color:false,shape:false,size:false},
      attribute_target:{color:'',shape:'',size:''},
      attribute_correct: {color:false,shape:false,size:false},
      userCategory:'',
      tickImg:tickImg,
      crossImg:crossImg,
      audioImg: audioImg,
      curr_instruction: {text:''},
      allcolor:[],
      allsize:[],
      allshape:[],
      alltoken:[],
      shape_size_color_randomiser:[],
      task_attributes_for_results:{color:{contains:false,error:false},shape:{contains:false,error:false},size:{contains:false,error:false},multi:{contains:false,error:false}},
      answer_obj:null,
      color_info:[],
      shape_info:[],
      size_info:[],
      previously_selected:[],
      repeat_count:0,
      instruction_steps: [],
      organised_instructions: [],
      current_exercise_index: 0,
      answer_array: [],
      icw:0,
      number_errors:0,
      result_message:'',
      result_message_style:'',
      total_exercises: 0,
      correct_counter: 0,
      incorrect_counter:0,
      color_hex:'',
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points()
    }
  },
  created() {
    const _this = this

    console.debug('created')
    _this.userCategory = localStorage.getItem('userCategory');
    // _this.loadTokenInfo();

    logActivity( 'accessed', 'token_assessment')

    _this.loadExercise()  
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()

  },
  methods: {
    loadExercise()
    {
      const _this = this

      _this.curr_instruction = _this.exForm

      _this.current_exercise_index = _this.exForm.current_exercise_index+1

      _this.total_exercises = _this.exForm.total_all_type_exercises

      _this.allcolor = _this.curr_instruction.allcolor
      _this.allsize = _this.curr_instruction.allsize
      _this.allshape = _this.curr_instruction.allshape

      console.debug('_this.allcolor = ' + _this.allcolor)
      console.debug('_this.allsize = ' + _this.allsize)
      console.debug('_this.allshape = ' + _this.allshape)


      console.debug('rand token')
      _this.randToken(_this.curr_instruction.full_token)



      _this.result_message = ' \n '
      _this.result_message_style = ''

      _this.answer_array = []

      _this.icw = 0

      for(let n in _this.curr_instruction.objects)
      {
        console.debug('_this.curr_instruction.objects[n] = ' + _this.curr_instruction.objects[n])

        for(let k in _this.curr_instruction.objects[n])
        {
          if(k=='color_code' || k=='shape_name' || k=='size_name')
          {
            _this.icw = _this.icw+1
          }
          // console.debug(k + ' = ' + _this.curr_instruction.objects[n][k])
        }

          _this.answer_array.push(0)
          // 0 mean unanswered, 1 means incorrect, 2 means correct
      }

      let n_objects = _this.curr_instruction.objects.length

      _this.icw = _this.icw+n_objects-1

      console.debug('_this.icw = ' + _this.icw)

      _this.previously_selected = []

      setTimeout(() => {
        _this.bv_tts.say(this.curr_instruction.text)
      }, 500)

    },
    randToken(full_token)
    {
      if(!full_token)
      {
          this.allsize = ['button-large'];
      }
      else
      {
          this.allsize = ['button-small','button-large'];
      }
      

      this.shape_size_color_randomiser = []

      for(let i in this.allcolor)
      {
        for(let n in this.allshape)
        {
          for(let k in this.allsize)
          {
              let curr_object = {color:this.allcolor[i],shape:this.allshape[n],size:this.allsize[k]}

              this.shape_size_color_randomiser.push(curr_object)
          }
        }
      }

      // console.debug('this.size_randomiser = ' + this.size_randomiser)

      let token_size = this.allcolor.length*this.allsize.length*this.allshape.length;
      this.token_color = [];
      this.alltoken = []
      
      for(let i=0;i<token_size;i++)
      {
        let shape_temp = '';
        let size_temp = '';
        let color_temp='';

        let curr_obj_id = Math.floor(Math.random()*this.shape_size_color_randomiser.length)
        let curr_obj = this.shape_size_color_randomiser[curr_obj_id]

        shape_temp = curr_obj.shape
        size_temp = curr_obj.size
        color_temp = curr_obj.color

        this.shape_size_color_randomiser.splice(curr_obj_id,1)

        let token_temp = {shape:shape_temp,color:color_temp,size:size_temp,border_color:'',text:''};
        this.alltoken.push(token_temp);
      }
        
    },
    checkresult(obj_id)
    {
      const _this = this

      let clicked_token = _this.alltoken[obj_id]

      let target_keys = ['shape','size','color']

      let curr_answer = this.curr_instruction.objects


      let correct_results = []
      let how_many_correct = []
      let whats_correct = []
      let whats_incorrect = []

  

      _this.show_which_attributes = {color:false,shape:false,size:false}

      _this.previously_selected.push(obj_id)

      if(curr_answer.length>1)
      {
        _this.task_attributes_for_results['multi']['contains'] = true
      }

      for(let n in curr_answer)
      {
        correct_results.push(true)
        how_many_correct.push(0)
        whats_correct.push({})
        whats_incorrect.push({})


        for(let i in target_keys)
        {
          let key = target_keys[i]


          console.debug('curr_answer['+n+']['+key+'] = ' + curr_answer[n][key])
          if(curr_answer[n][key]!=undefined)
          {
            _this.task_attributes_for_results[key]['contains'] = true 

          }

          if(key in curr_answer[n])
          {
            
            if(curr_answer[n][key]!=clicked_token[key])
            {
              
              whats_incorrect[n][key] = curr_answer[n][key]
              correct_results[n] = false
            }
            else
            {
              whats_correct[n][key] = curr_answer[n][key]

              how_many_correct[n] = how_many_correct[n]+1
            }
          }

        }
      }
      //trying to remove from the hints analysis objects that have been picked before
      console.debug('_this.answer_array = ' + _this.answer_array)
      if(_this.answer_array.length>1)
      {
        for(let n in how_many_correct)
        {
          if(_this.answer_array[n]==2)
          {
            how_many_correct[n] = -1
          }
        }

      }

      let correct_result = correct_results.some(function(e)
      {
        return e
      })

      let result_message = ''

      if(correct_result)
      {

        _this.num_correct = _this.num_correct + 1
        for(let n in _this.answer_array)
        {
          if(_this.answer_array[n]==0)
          {
            if(correct_results[n]==true)
            {
              _this.answer_array[n]=2

            }

          }
        }

        let all_answered = true
        let all_correct = true

        for(let n in _this.answer_array)
        {

          if(_this.answer_array[n]==0)
          {
            all_answered = false
          }
          
          if(_this.answer_array[n]!=2)
          {
            all_correct = false
          }


        }


        if(all_correct)
        {

          _this.result_message_style = 'color:green'
          if(_this.number_errors==0)
          {
            _this.correct_counter = 1

          }
          result_message = "Good job! \n Click Next to go to the next exercise."
        }
        else
        {
          _this.result_message_style = 'color:blue'

          result_message = "You got it right, but you need to find one more token here"
        }

      }
      else
      {
        _this.result_message_style = 'color:red'
        _this.incorrect_counter = 1

        const max = Math.max(...how_many_correct);

        const index = how_many_correct.indexOf(max);

        result_message = ''

        _this.show_which_attributes = {color:false,shape:false,size:false}
        _this.attribute_target =  {color:'',shape:'',size:''}
        _this.attribute_correct =  {color:false,shape:false,size:false}

        if(_this.task_attributes_for_results['multi']['contains'])
        {
          _this.task_attributes_for_results['multi']['error'] = true
        }



        for(let n in whats_correct[index])
        {

          _this.show_which_attributes[n] = true

          let curr_attribute_target = whats_correct[index][n]
          
          if(n=='color')
          {
            console.debug('curr_attribute_target = ' + curr_attribute_target)
            if(curr_attribute_target.includes('0677d7'))
            {
              curr_attribute_target = '\t Blue';
              _this.color_hex = '#0677d7';

            }
            else if(curr_attribute_target.includes('69B4'))
            {
              curr_attribute_target = '\t Pink'
              _this.color_hex = '#FF69B4';

            }
            else if(curr_attribute_target.includes('00000'))
            {
              curr_attribute_target = '\t Black'
              _this.color_hex = '#000000';

            }
            else if(curr_attribute_target.includes('fffff'))
            {
              curr_attribute_target = '\t White'
              _this.color_hex = '#FFFFFF';
            }
            else
            {
              curr_attribute_target = '\t Yellow'
              _this.color_hex = '#ffd966';
            }

          }
          else if(n=='shape')
          {
            if(curr_attribute_target==true)
            {
              curr_attribute_target = 'Circle'
            }
            else
            {
              curr_attribute_target = 'Square'

            }

          }
          else
          {
            if(curr_attribute_target.includes('small'))
            {
              curr_attribute_target = 'Small'
            }
            else
            {
              curr_attribute_target = 'Large'
            }
          }

          _this.attribute_target[n] = curr_attribute_target

          _this.attribute_correct[n] = true
          

        }

        for(let n in whats_incorrect[index])
        {
          console.debug('n = ' + n)
          _this.show_which_attributes[n] = true

          let curr_attribute_target = whats_incorrect[index][n]
          
          if(n=='color')
          {
            console.debug('curr_attribute_target = ' + curr_attribute_target)

            if(curr_attribute_target.includes('0677d7'))
            {
              curr_attribute_target = '\t Blue'
              _this.color_hex = '#0677d7';
            }
            else if(curr_attribute_target.includes('69B4'))
            {
              curr_attribute_target = '\t Pink'
              _this.color_hex = '#FF69B4';
            }
            else if(curr_attribute_target.includes('00000'))
            {
              curr_attribute_target = '\t Black'
              _this.color_hex = '#000000';
            }
            else if(curr_attribute_target.includes('fffff'))
            {
              curr_attribute_target = '\t White'
              _this.color_hex = '#FFFFFF';
            }
            else
            {
              curr_attribute_target = '\t Yellow'
              _this.color_hex = '#ffd966';
            }

          }
          else if(n=='shape')
          {
            if(curr_attribute_target==true)
            {
              curr_attribute_target = 'Circle'
            }
            else
            {
              curr_attribute_target = 'Square'

            }

          }
          else
          {
            if(curr_attribute_target.includes('small'))
            {
              curr_attribute_target = 'Small'
            }
            else
            {
              curr_attribute_target = 'Large'
            }
          }

          _this.attribute_target[n] = curr_attribute_target


          _this.attribute_correct[n] = false
          

        }

        _this.result_message_style = 'color:red'

        
        result_message = "Not all correct. Because you picked: "

        _this.number_errors = _this.number_errors+1
      }

      _this.result_message = result_message

      if(correct_result)
      {
        console.debug('check')
        _this.alltoken[obj_id].border_color = ';border-color:lime';
        // _this.alltoken[obj_id].text = 'X';

      }
      else
      {
        _this.alltoken[obj_id].border_color = ';border-color:red';
        // _this.alltoken[obj_id].text = 'X';

      }

      let tts_results_message = _this.result_message
      
      if(this.show_which_attributes.shape)
      {
        let add_on_text = ''
        if(this.attribute_correct.shape)
        {
          add_on_text = ' correct shape ' + _this.attribute_target.shape + '.'

        }
        else
        {

          _this.task_attributes_for_results['shape']['error'] = true
          add_on_text = ' wrong shape. It should be ' + _this.attribute_target.shape + '.'

        }

        tts_results_message = tts_results_message + add_on_text

      }

      if(this.show_which_attributes.color)
      {

        let add_on_text = ''

        let curr_attribute_target = _this.attribute_target.color

        if(this.attribute_correct.color)
        {
          add_on_text = ' correct colour ' + _this.attribute_target.color + '.'

        }
        else
        {
          _this.task_attributes_for_results['color']['error'] = true

          add_on_text = ' wrong colour. It should be ' + curr_attribute_target + '.'
        }


        tts_results_message = tts_results_message + add_on_text

      }

      if(this.show_which_attributes.size)
      {

        let add_on_text = ''

        let curr_attribute_target = _this.attribute_target.size

        if(this.attribute_correct.size)
        {
          add_on_text = ' correct size ' + _this.attribute_target.size + '.'

        }
        else
        {
          _this.task_attributes_for_results['size']['error'] = true

          add_on_text = ' wrong size. It should be ' + curr_attribute_target + '.'
        }


        tts_results_message = tts_results_message + add_on_text

      }


      _this.bv_tts.system_say(tts_results_message)
    },
    endExercise()
    {
      const _this = this

      _this.saveResults()
      let start_object = {

        module_path:'', 
        assessment_type: "USER_TRAINING", 
        module_name: 'Token Assessment', 
        current_exercise_index: 0
      }

      let results_table = {'back':[],'pre':[]}

      let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter, Total:1, ListenCount:_this.listen_count,task_attributes_for_results:_this.task_attributes_for_results,ICW:_this.icw}

      let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:1}
      
      let results = {all_results:all_results, plot_results:plot_results}

      let resultsTablePre = {exercise_type: 'Token Assessment', exercise: 'Token Assessment', results: results}

      results_table['pre'].push(resultsTablePre)

      start_object.results_table = results_table

      //_this.$router.push({ name: 'results', params: {start_object: start_object, results:results_table}});

    },
    saveResults()
    {
      const _this = this


      if(this.correct_counter!=0 || this.incorrect_counter!=0)
      {
        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter, Total:1,ListenCount:_this.listen_count,task_attributes_for_results:_this.task_attributes_for_results,ICW:_this.icw}

        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:1}
        
        let results = {all_results:all_results, plot_results:plot_results}

        var token_data = {resultsTablePre: {exercise_type: 'Token Assessment', exercise: 'Token Assessment', results: results, audio_address:''}}

        save_pre_computed_results(_this.exForm, token_data)


        // _this.bv_points.add(30)
      }
    },
    nextExercise()
    {
        const _this = this;

        let total = 1

        if(_this.incorrect_counter==0 && _this.correct_counter==0)
        {
          total = 0
        }

        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter, Total:total,task_attributes_for_results:_this.task_attributes_for_results,ListenCount:_this.listen_count,ICW:_this.icw}

        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:total}
         
        let results = {all_results:all_results, plot_results:plot_results}

        var token_data = {resultsTablePre: {exercise_type: 'Token Assessment', exercise: 'Token Assessment', results: results, audio_address:''}}

        _this.saveResults(token_data)

        _this.$emit('logExerciseResults', token_data);
        
        _this.$emit('nextExercise');
    },
    
  },
}
</script>
<style scoped>

.custom-text{
    font-size: 20px;
    font-family: Arial;
}
.custom-button-sq
{
    font-size: 20px;
    color: red;
    width: 30px;
    height: 30px;
    white-space: pre-line;
}
.button-large{
    width:80px;
    height:80px;
    border-width: 5px;
    border-color: #bcbcbc;
}
.button-small{
    width:50px;
    height:50px;
    border-width: 5px;
    border-color: #bcbcbc;
}
.button-small-eg{
    width:50px;
    height:50px;
    background-color: lightgrey;
    border-width: 5px;
    border: solid;
    border-color: #bcbcbc;
}

.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}

 .text_item {
    /* margin-bottom: 18px; */
    font-size: 30px;
    /* vertical-align: middle; */
    /* padding: 40px 200px; */
    /* height:40%; */
  }

.menu__item {
  display: block;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 24px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}
</style>

<!-- Results Description
{
  "Token Assessment": {
    "<key>": { 
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>",
        "exercise": "Token Assessment",
        "exercise_type": "Token Assessment",
        "results": {
          "all_results": {
            "Correct": "<int>",
            "ICW": "<int>",
            "Incorrect": "<int>",
            "ListenCount": "<int>",
            "Total": "<int>",
            "task_attributes_for_results": {
              "color": {
                "contains": "<bool>",
                "error": "<bool>"
              },
              "multi": {
                "contains": "<bool>",
                "error": "<bool>"
              },
              "shape": {
                "contains": "<bool>",
                "error": "<bool>"
              },
              "size": {
                "contains": "<bool>",
                "error": "<bool>"
              }
            }
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>",
            "Total": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Token Assessment"
  }
} -->