import {getIssueFlag} from '@/utils/utils'
import constants from '@/utils/constants'
import {BV_Points} from '@/utils/bv_points'

import {logActivity, getTodayString} from '@/media_input/utils'
import {Create_Exercise_From_Token_Test_Directories, Create_News_Exercise, Create_Exercise_From_Base_Plans, shuffle, Create_Exercise_From_Single_Exercise_Address, Create_Exercise_From_Exercise_Directories, randomiseExerciseSets, Create_Phoneme_Exercise_List} from '@/utils/session/functions/exercise_creation'
import {find_n_random_words_containing_phonemes} from '@/media_input/words'
import {updateData, getData, getUserDataDir} from '@/firebase/index'

import {count_db_requested_exercises} from '@/utils/session/functions/exercise_count'



export class Module {
  constructor(meta) {

    this.meta = meta

    this.isAssignment = false
    this.moduleHandle = null

    this.exForm = {}
    
    //this should be global per module/package/session
    this.assessment_type = '' //assessment_type can be SLP_ASSESSMENT(Clinic assessment), USER_TRAINING (daily training),  SLP_TRAINING (Clinic_training) ASSIGNMENT_ASSESSMENT (home assessment (modules/packages)), USER_ASSESSMENT (self-check assessment) ASSIGNMENT_TRAINING (Homework training (modules/packages))
    this.finished_n_requested_exercises = false
    this.has_results = false

    this.current_exercise_index = 0

    this.total_available_all_type_exercises = null
    this.total_requested_input_task_exercises = null

    this.total_input_task_exercises = 0
    this.total_instructions_exercises = 0

    this.exercise_xForm_list = []

    this.demo_video_pool = []

    this.avg_loudness_array = [],

    this.results_table = {back:[], pre:[]}

    this.exercise_types = [ 'common_sayings',
                            'copy_word',
                            'copy_word_speak',
                            'hangman',
                            'cats_image_selection',
                            'count_in_images',
                            'chat_scenario',
                            'yes_no',
                            'reading_images',
                            'letter_word_match',
                            'read_menu',
                            'write_words',
                            'orla',
                            'give_directions',
                            'selection_questionnaire',
                            'swal_qol',
                            'fda',
                            'money_recognition', 
                            'clock_game', 
                            'reverse_direction',
                            'reversible_passive',
                            'stepping_stone',
                            'simon_says',
                            'picture_description',
                            'word_search',
                            'number_guessing',
                            'word_guessing',
                            'maths_table',
                            'quiz',
                            'write_numbers',
                            'assessment_questionnaire',
                            'single_word',
                            'minimal_pairs',
                            'listen_minimal_pairs',
                            'ddk_rate',
                            'karaoke',
                            'scene_description',
                            'scene_click',
                            'spoken_pca',
                            'pca',
                            'number_sequence',
                            'antonym_exercise',
                            'reading_comprehension',
                            'aphasia_position',
                            'aphasia_story',
                            'speak_background_noise',
                            'word_category',
                            'token_test',
                            'matching_shapes',
                            'post_video_module',
                            'post_video_module_spectrogram',
                            'name_a_picture_easy',
                            'Name_a_picture_easy',
                            'word_naming_easy',
                            'word_finding',
                            'memory_image',
                            'pace_training',
                            'avqi',
                            'speak_numbers',
                            'cookie_theft',
                            'basic_module'],
    this.current_exercise_type = null
    this.userCategory = localStorage.getItem('userCategory');
    this.module_name = ''
    this.showResults = false
    this.today = getTodayString()

    this.bv_points = new BV_Points()
  }

  async initialise(request_type, blueprint_id = null, blueprint_path = null) {
    let _this = this
    _this.request_type = request_type
    
    console.debug('request_type = ' + request_type)

    if (_this.request_type === 'stroke_smile') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/smile";
      _this.meta.start_object.module_name = "Stroke smile";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'pd_smile') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsPD/smile";
      _this.meta.start_object.module_name = "PD smile";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_frown') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/frown";
      _this.meta.start_object.module_name = "Stroke frown";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'pd_angry') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsPD/angry";
      _this.meta.start_object.module_name = "PD angry";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_surprised') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/surprised";
      _this.meta.start_object.module_name = "Stroke surprised";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'pd_surprised') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsPD/surprised";
      _this.meta.start_object.module_name = "PD surprised";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_disgust') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/disgust";
      _this.meta.start_object.module_name = "Stroke disgust";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'pd_disgust') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsPD/disgust";
      _this.meta.start_object.module_name = "PD disgust";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_excited') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/excited";
      _this.meta.start_object.module_name = "PD excited";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'pd_excited') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsPD/excited";
      _this.meta.start_object.module_name = "PD excited";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_facial_say_p') {
      _this.module_name = "Facial muscles";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/facial_say_p";
      _this.meta.start_object.module_name = "Stroke say P";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_smile_sides') {
      _this.module_name = "Facial muscles";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/smile_sides";
      _this.meta.start_object.module_name = "Stroke smile sides";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_eyebrows') {
      _this.module_name = "Facial muscles";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/eyebrows";
      _this.meta.start_object.module_name = "Stroke raise eyebrows";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_pout_lips') {
      _this.module_name = "Facial muscles";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/pout_lips";
      _this.meta.start_object.module_name = "Stroke pout lips";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_blow') {
      _this.module_name = "Facial muscles";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/blow";
      _this.meta.start_object.module_name = "Stroke blow";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_puff_cheeks_side') {
      _this.module_name = "Facial muscles";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/puff_cheeks_side";
      _this.meta.start_object.module_name = "Stroke puff cheeks side";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'stroke_puff_cheeks_both') {
      _this.module_name = "Facial muscles";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsStroke/puff_cheeks_both";
      _this.meta.start_object.module_name = "Stroke puff both cheeks";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === 'pd_mixed') {
      _this.module_name = "Facial expressions";

      _this.meta.start_object.module_path = "/Exercises/Articulation/FacialExpressionsPD/mixed";
      _this.meta.start_object.module_name = "PD excited";
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      _this.meta.start_object.total_requested_input_task_exercises = 10

      await _this.prepare();
    } else if (_this.request_type === "AVQI") {
      _this.module_name = "AVQI";
      _this.meta.start_object.module_path = '/BaseUserPlan/Training_modules/AVQI';
      _this.meta.start_object.module_name = 'AVQI';
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare()

    } else if (_this.request_type === "chat_scenario") {

      _this.module_name = "Chat scenario";

      let temp_scenario = localStorage.getItem('chatbot_topic');

      if(temp_scenario==undefined && temp_scenario==null)
      {
        temp_scenario = 'Barista'

      }

      _this.meta.start_object.module_path = '/Exercises/General/Chat_scenario/' + temp_scenario;

      // _this.meta.start_object.module_path = '/BaseUserPlan/Training_modules/Chat_scenario_local_storage/';
      _this.meta.start_object.module_name = 'Chat scenario';
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();

    } else if (_this.request_type === "yes_no") {
      _this.module_name = "Yes No Assessment";

      _this.meta.start_object.module_path = '/BaseUserPlan/Assessment_modules/Yes_No';
      _this.meta.start_object.module_name = 'Yes No Assessment';
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === "functional_sentences") {
      _this.module_name = "Functional Phrases";

      const promiseArray = []

      let functional_sentences_dir = ['/Exercises/General/PD_Intensive_EN/FunctionalSentences']
      promiseArray.push(Create_Exercise_From_Exercise_Directories(functional_sentences_dir))

      const all_results = await Promise.all(promiseArray)

      let functional_list = randomiseExerciseSets(all_results,[10])
      let exercise_data_list = []

      let issue_flag = getIssueFlag()
      let loudness_patient = issue_flag[2]

      if(loudness_patient)
      {
        //adding practise videos for loudness patients
        const promiseArray2 = []
        
        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Phrases/Phrase_instruction_how are you",
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_loud_clear"
          ]
        
        for(let n in list_loudness_exercises)
        {
          promiseArray2.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
        const all_results2 = await Promise.all(promiseArray2)


        for(let n in all_results2)
        {
          console.debug('exercise_type = ' + all_results2[n].exercise_type)
          this.demo_video_pool.push(all_results2[n])
        }
      }

      for(let i in functional_list)
      {
        exercise_data_list.push(functional_list[i])
      }

      _this.meta.start_object.module_name = 'Functional Phrases'
      _this.meta.start_object.pre_loaded_exercises = exercise_data_list
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      
      await _this.prepare();
    } else if (_this.request_type === "word_finding") {

      _this.module_name = "Word Finding";

      _this.meta.start_object.module_path = 'Exercises/General/WordFinding/Easy/';
      _this.meta.start_object.module_name = 'Word Finding';
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === "mouth_movement") {

      _this.module_name = "Practise speech sounds";

      var randomProperty = function (object) {
          var keys = Object.keys(object);
          return keys[Math.floor(keys.length * Math.random())];
      };

        
      var phoneme_list = []

      const n_phonemes = 5
      
      for(let n=0;n<n_phonemes;++n)
      {
        let chosen_phoneme = randomProperty(constants.phonemeDisplayDictionary)
        phoneme_list.push(chosen_phoneme)
      }

      const words_per_phoneme = 5 //up to 5 words words

      
      await new Promise(function(resolve)
      {
        let preload = Create_Phoneme_Exercise_List(phoneme_list, words_per_phoneme).then(async function(exercise_data_list) {
          console.debug('exercise_data_list = ' + exercise_data_list.length)
          
          _this.meta.start_object.module_name = 'Phoneme_' + phoneme_list;
          _this.meta.start_object.module_path = '';
          _this.meta.start_object.pre_loaded_exercises = exercise_data_list;
          _this.meta.start_object.assessment_type = "USER_TRAINING";

          await _this.prepare();
          resolve()
        });
      })
      
    } else if (_this.request_type === "voice_warm_up") {
      _this.module_name = "Voice warm up";

      const promiseArray = []

      let list_warmup_exercises = []

      list_warmup_exercises.push('/Exercises/General/Video_Instructions/BreathingInstruction')


      list_warmup_exercises.push('/Exercises/General/Video_Instructions/BreathingExerciseX3')

      let loudness_exercise = "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/MayMeMyMoeMum"
      list_warmup_exercises.push(loudness_exercise)

      for(let n in list_warmup_exercises)
      {
        promiseArray.push(Create_Exercise_From_Single_Exercise_Address(list_warmup_exercises[n]))
      }
        
      const all_results = await Promise.all(promiseArray);

      console.debug('all_results.length = ' + all_results.length)

      let exercise_data_list = []

      for(let n in all_results)
      {
        exercise_data_list.push(all_results[n])
      }

      _this.meta.start_object.module_name = 'Warm-up'
      _this.meta.start_object.pre_loaded_exercises = exercise_data_list
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === "fun_facts")
    {
      _this.module_name = "Fun facts";

      let form = 
      {
        n_item:50,
        value:'',
        phoneme: null,
        day: null,
        changes_confirm: false,
        pre_loaded_exercises: null
      }

      _this.pre_loaded_exercises = []

      let facts = localStorage.getItem('list_fun_facts')



      facts = facts.split(',,')

      facts[facts.length-1] = facts[facts.length-1].substring(0,facts[facts.length-1].length-1)

      function localStoreNewSentencePlan()
      {
        
        let mod_name = '';

        for(let i in facts)
        {
          mod_name = mod_name  + facts[i] + '/'
        }

        // console.debug('form text all= ',_this.form.text[1]);
        
        for(let n=0; n<facts.length; n++)
        {

          let form_temp = {
            name: 'fun_fact_' + n,
            instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',
            text: facts[n],
            assist:['spm_live'],
            voicetype: ['spm'],
            speechtype:['sentencegibility'],
            module_name: 'Fun facts',
            curr_exercise_path: 'text_list/',
            assessment_type: 'USER_TRAINING',
            start_btn_text: 'Record',
          };

          _this.pre_loaded_exercises.push(form_temp)  
          console.debug("pre_loaded_exercises[n].text = " + _this.pre_loaded_exercises[n].text)   
        }
      }
  
      localStoreNewSentencePlan();

      let exercise_data_list = []

      let issue_flag = getIssueFlag()

      let loudness_patient = issue_flag[2]


      if(loudness_patient)
      {
        const promiseArray = []

        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Word_instruction_hello",
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Great"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Help",
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Goodbye",
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
          ]

        for(let n in list_loudness_exercises)
        {
            promiseArray.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
          
        const all_results = await Promise.all(promiseArray)

        for(let n in all_results)
        {
            this.demo_video_pool.push(all_results[n])
        }
      }



      for(let i in _this.pre_loaded_exercises)
      {
        if(loudness_patient)
        {
          // _this.pre_loaded_exercises[i].assist = ['spm_live']
        }
        exercise_data_list.push(_this.pre_loaded_exercises[i])
      }

      form.pre_loaded_exercises = exercise_data_list


      form.changes_confirm = true

      if(form.pre_loaded_exercises != undefined && form.pre_loaded_exercises!=null && form.pre_loaded_exercises.length>0)
      {
        _this.meta.start_object.module_name = 'Fun Facts'
        _this.meta.start_object.pre_loaded_exercises = form.pre_loaded_exercises

        _this.meta.start_object.entry = 'fun_facts';
      }
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare();
    } else if (_this.request_type === "single_words") {

      _this.module_name = "Practise single words";
      
      let searchKey = 'any'
      _this.pre_loaded_exercises = []


      let issue_flag = getIssueFlag()

      let form = 
      {
        n_item:10,
        value:'',
        phoneme: null,
        day: null,
        changes_confirm: false,
        pre_loaded_exercises: null
      }

      let word_list = find_n_random_words_containing_phonemes(form.n_item, searchKey)

      console.debug('word_list')
      let autoGenWords = [];

      if(word_list.length<1)
      {
        alert('No words were found containing these letters');
      } else {
        autoGenWords = word_list;
      }

      if(autoGenWords.length > 0)
      {
        function localStoreNewWordsPlan()
        {
          
          let mod_name = '';

          for(let i in autoGenWords)
          {
            mod_name = mod_name  + autoGenWords[i] + '/'
          }

          // console.debug('form text all= ',_this.form.text[1]);
          
          for(let n=0; n<form.n_item; n++)
          {

            let form_temp = {
              text: mod_name,
              instruct: 'Press Start. Read the following word loudly and clearly.',
              exercise_type: 'single_word',
              name: mod_name,
              // assist:['spm_live'],
              voicetype: ['spm'],
              speechtype:['intelligibility'],
              module_name: 'Word list',
              curr_exercise_path: 'word_list/',
              assessment_type: 'USER_TRAINING',
              start_btn_text: 'Record',
              search_img: true
            };

            if(issue_flag[3])
            {
              form_temp.sound_autoplay = true
            }
          
            let text_temp = autoGenWords[n];

            form_temp.text = text_temp;
            form_temp.name = mod_name + '-' + n;

            _this.pre_loaded_exercises.push(form_temp)  
            console.debug("pre_loaded_exercises[n].text = " + _this.pre_loaded_exercises[n].text) 
              
          }
        }
    
        localStoreNewWordsPlan();

        let exercise_data_list = []

        let loudness_patient = issue_flag[2]

        
        if(loudness_patient)
        {

          const promiseArray = []

          let list_loudness_exercises = [
            "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Word_instruction_hello",
            "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Great",
            // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Help",
            "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Thank_you",
            // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Words/Goodbye",
            // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
            ]

          for(let n in list_loudness_exercises)
          {
            promiseArray.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
          }
            
          const all_results = await Promise.all(promiseArray)

          for(let n in all_results)
          {
            this.demo_video_pool.push(all_results[n])
          }
        }

        for(let i in _this.pre_loaded_exercises)
        {
          if(loudness_patient)
          {
            // _this.pre_loaded_exercises[i].assist = ['spm_live']
          }
          exercise_data_list.push(_this.pre_loaded_exercises[i])
        }

        form.pre_loaded_exercises = exercise_data_list

        form.changes_confirm = true

        if(form.pre_loaded_exercises != undefined && form.pre_loaded_exercises!=null && form.pre_loaded_exercises.length>0)
        {
          _this.meta.start_object.module_name = 'Word List'
          _this.meta.start_object.pre_loaded_exercises = form.pre_loaded_exercises
    
          _this.meta.start_object.entry = 'word';
        }
        _this.meta.start_object.assessment_type = "USER_TRAINING";
        await _this.prepare();
      }
    } else if (_this.request_type === "sound_loudness_loudness_patient") {
      
      _this.module_name = "Sustaining /Ah/";

      const promiseArray = []

      let base_plan = ['/BaseUserPlan/Max_Phonation_EN/']
      console.debug('base_plan = ' + base_plan)
      promiseArray.push(Create_Exercise_From_Base_Plans(base_plan))

      const all_results = await Promise.all(promiseArray)


      let issue_flag = getIssueFlag()
      let loudness_patient = issue_flag[2]
      let exercise_data_list = []

      /////////////////////////////////////////////////////////////////
      if(loudness_patient) //video instructions
      {
        const promiseArray2 = []
        
        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Sounds/Ah_full",
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
        ]
        
        for(let n in list_loudness_exercises)
        {
          promiseArray2.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
        const all_results2 = await Promise.all(promiseArray2)

        for(let n in all_results2)
        {
          this.demo_video_pool.push(all_results2[n])
        }
      }

      /////////////////////////////////////////////////////////////////

      for(let i in all_results)
      {
        for(let n in all_results[i])
        {
          exercise_data_list.push(all_results[i][n])
        }
      }

      _this.meta.start_object.pre_loaded_exercises = exercise_data_list

      _this.meta.start_object.module_name = 'Maximum Phonation'

      _this.meta.start_object.entry = 'phonation';
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === "pitch_variation") {

      _this.module_name = "Pitch variation";

      const promiseArray = []

      let pitch_exercise_dir = ['/Exercises/General/PD_Intensive_EN/DailyVariables/']
      promiseArray.push(Create_Exercise_From_Exercise_Directories(pitch_exercise_dir))

      const all_results = await Promise.all(promiseArray)
      let exForm_list_temp = []

      for(let i in all_results)
      {
        for(let n in all_results[i])
        {
          exForm_list_temp.push(all_results[i][n])
        }
      }

      let exercise_data_list = []

      let issue_flag = getIssueFlag()
      let loudness_patient = issue_flag[2]

      if(loudness_patient)
      {
        const promiseArray2 = []
        
        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Sounds/Pitch_instructions"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_spectrogram_image_page"
        ]
        
        for(let n in list_loudness_exercises)
        {
          promiseArray2.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
        const all_results2 = await Promise.all(promiseArray2)


        for(let n in all_results2)
        {
          this.demo_video_pool.push(all_results2[n])
        }
      }

      for(let i in exForm_list_temp)
      {
        exercise_data_list.push(exForm_list_temp[i])
      }

      _this.meta.start_object.module_name = 'Pitch exercise'
      _this.meta.start_object.pre_loaded_exercises = exercise_data_list
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare();
    } else if (_this.request_type === "Pace Training Interest")
    {
      _this.module_name = "Pace training"
      let form = 
      {
        n_item:50,
        value:'',
        pre_loaded_exercises: null
      }

      _this.pre_loaded_exercises = []

      let reading_data = localStorage.getItem('list_pace_interest')

      if(reading_data==undefined || reading_data==null || reading_data.length<1)
      {
        this.$router.push({ name: 'pace_interest_setup' } );
      }

      reading_data = JSON.parse(reading_data)



      let mod_name = reading_data['topic']

      // console.debug('form text all= ',_this.form.text[1]);
      
      for(let n=0; n<reading_data.length; n++)
      {
        console.debug('reading_data.length = ' + reading_data.length)
        console.debug('reading_data[n] = ' + reading_data[n])

        for(let k in reading_data[n])
        {
          console.debug('reading_data[n]['+k+']! = ' + reading_data[n][k])
        }

        let form_temp = {
          name: 'pace_interest_' + n,
          instruct: 'Press Start. Read the paragraph below aloud. Try to keep your pace with the highlighted word. No need to match exactly. Press Next when finished.',

          text:reading_data[n].all_questions[0].paragraph,
          speechtype: ["sentencegibility","speech_rate"],
          // all_questions: reading_data[n].all_questions,
          module_name: 'Pace training',
          exercise_type:'pace_training',
          curr_exercise_path: 'text_list/',
          assessment_type: 'USER_TRAINING',

        };

        _this.pre_loaded_exercises.push(form_temp)  
          
      }
      
  

      let exercise_data_list = []

      for(let i in _this.pre_loaded_exercises)
      {
      
        exercise_data_list.push(_this.pre_loaded_exercises[i])
      }

      form.pre_loaded_exercises = exercise_data_list

      form.changes_confirm = true

      if(form.pre_loaded_exercises != undefined && form.pre_loaded_exercises!=null && form.pre_loaded_exercises.length>0)
      {
        _this.meta.start_object.module_name = 'Pace training'
        _this.meta.start_object.pre_loaded_exercises = form.pre_loaded_exercises

        _this.meta.start_object.entry = 'pace_training';
      }
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare();
    } else if (_this.request_type === "Reading Comprehension Interest")
    {
      _this.module_name = "Reading Comprehension";

      let form = 
      {
        n_item:50,
        value:'',
        pre_loaded_exercises: null
      }

      _this.pre_loaded_exercises = []

      let reading_data = localStorage.getItem('list_reading_comprehension')

      if(reading_data==undefined || reading_data==null || reading_data.length<1)
      {
        this.$router.push({ name: 'reading_comprehension_setup' } );
      }

      reading_data = JSON.parse(reading_data)



      let mod_name = reading_data['topic']

      // console.debug('form text all= ',_this.form.text[1]);
      
      for(let n=0; n<reading_data.length; n++)
      {
        console.debug('reading_data.length = ' + reading_data.length)
        console.debug('reading_data[n] = ' + reading_data[n])

        for(let k in reading_data[n])
        {
          console.debug('reading_data[n]['+k+']! = ' + reading_data[n][k])
        }

        let form_temp = {
          name: 'reading_comprehension_' + n,
          instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',

          all_questions: reading_data[n].all_questions,
          module_name: 'Reading comprehension',
          exercise_type:'reading_comprehension',
          curr_exercise_path: 'text_list/',
          assessment_type: 'USER_TRAINING',

        };

        _this.pre_loaded_exercises.push(form_temp)  
          
      }
      
  

      let exercise_data_list = []

      for(let i in _this.pre_loaded_exercises)
      {
      
        exercise_data_list.push(_this.pre_loaded_exercises[i])
      }

      form.pre_loaded_exercises = exercise_data_list

      form.changes_confirm = true

      if(form.pre_loaded_exercises != undefined && form.pre_loaded_exercises!=null && form.pre_loaded_exercises.length>0)
      {
        _this.meta.start_object.module_name = 'Reading Comprehension'
        _this.meta.start_object.pre_loaded_exercises = form.pre_loaded_exercises

        _this.meta.start_object.entry = 'reading_comprehension';
      }
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare();
    } else if (_this.request_type === "Pub Quiz") { 

      _this.module_name = "Pub Quiz";

      let form = 
      {
        n_item:50,
        value:'',
        pre_loaded_exercises: null
      }

      _this.pre_loaded_exercises = []

      let quiz_data = localStorage.getItem('list_quiz')

      if(quiz_data==undefined || quiz_data==null || quiz_data.length<1)
      {
        this.$router.push({ name: 'pub_quiz_setup' } );
      }

      quiz_data = JSON.parse(quiz_data)



      let mod_name = quiz_data['topic']

      // console.debug('form text all= ',_this.form.text[1]);
      
      for(let n=0; n<quiz_data.length; n++)
      {
        console.debug('quiz_data.length = ' + quiz_data.length)
        console.debug('quiz_data[n] = ' + quiz_data[n])


        let form_temp = {
          name: 'pub_quiz_' + n,
          instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',
          question:quiz_data[n]['question'],
          answer:quiz_data[n]['answer'],
          options:quiz_data[n]['options'],
          module_name: 'Pub Quiz',
          exercise_type:'quiz',
          curr_exercise_path: 'text_list/',
          assessment_type: 'USER_TRAINING',

        };

        _this.pre_loaded_exercises.push(form_temp)  
          
      }
      
      //_this.meta.start_object.module_path = '/BaseUserPlan/Training_modules/PubQuiz/'
      //_this.meta.start_object.module_name = 'Pub Quiz'

      let exercise_data_list = []

      for(let i in _this.pre_loaded_exercises)
      {
      
        exercise_data_list.push(_this.pre_loaded_exercises[i])
      }

      form.pre_loaded_exercises = exercise_data_list

      form.changes_confirm = true

      if(form.pre_loaded_exercises != undefined && form.pre_loaded_exercises!=null && form.pre_loaded_exercises.length>0)
      {
        _this.meta.start_object.module_name = 'Pub Quiz'
        _this.meta.start_object.pre_loaded_exercises = form.pre_loaded_exercises

        _this.meta.start_object.entry = 'pub_quiz';
      }
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare();
    
    } else if (_this.request_type === "reading_comprehension_news_mid") {
      _this.module_name = "Reading Comprehension";


      let exercise_data_list = []

      let interests_array = localStorage.getItem('interests_array');

      if(interests_array!=undefined && interests_array!=null && interests_array!='null' && interests_array.length>0)
      {

        console.debug('interests_array = ' + interests_array)
        interests_array = JSON.parse(interests_array)

        if(interests_array.length <= 0) {
          interests_array = ['Latest News']
        }
      }
      else
      {
        interests_array = ['Latest News']
      }
      let topics = interests_array

      let region = localStorage.getItem("region");
      let type = 'NewsQuiz'
      let news_size = 'Mid'

      let n_exercises = 5

      let config = {topics:topics,region:region,type:type,news_size:news_size, n_exercises:n_exercises}

      await new Promise(function(resolve) {
        Create_News_Exercise(config).then(async curr_list=>{

          for(let n in curr_list)
          {
            exercise_data_list.push(curr_list[n])
          }

          _this.meta.start_object.module_name = 'Reading Comprehension'
          _this.meta.start_object.pre_loaded_exercises = exercise_data_list
    
          _this.meta.start_object.total_requested_input_task_exercises = 5

          _this.meta.start_object.assessment_type = "USER_TRAINING";

          await _this.prepare();
          resolve()
        })
      })

    } else if (_this.request_type === "reading_comprehension_news_long") {
      _this.module_name = "Reading Comprehension";


      let exercise_data_list = []

      let interests_array = localStorage.getItem('interests_array');

      if(interests_array!=undefined && interests_array!=null && interests_array!='null' && interests_array.length>0)
      {

        console.debug('interests_array = ' + interests_array)
        interests_array = JSON.parse(interests_array)

        if(interests_array.length <= 0) {
          interests_array = ['Latest News']
        }
      }
      else
      {
        interests_array = ['Latest News']
      }
      let topics = interests_array

      let region = localStorage.getItem("region");
      let type = 'NewsQuiz'
      let news_size = 'Long'

      let n_exercises = 5

      let config = {topics:topics,region:region,type:type,news_size:news_size, n_exercises:n_exercises}

      await new Promise(function(resolve)
      {
        Create_News_Exercise(config).then(async curr_list=>{

          for(let n in curr_list)
          {
            exercise_data_list.push(curr_list[n])
          }

          _this.meta.start_object.module_name = 'Reading Comprehension'
          _this.meta.start_object.pre_loaded_exercises = exercise_data_list
    
          _this.meta.start_object.total_requested_input_task_exercises = 5

          _this.meta.start_object.assessment_type = "USER_TRAINING";

          await _this.prepare();
          resolve()
        })
      })

    } else if (_this.request_type === "latest_news") {
      _this.module_name = "Latest news";

      let issue_flag = getIssueFlag()

      let loudness_patient = issue_flag[2]

      let exercise_data_list = []

      if(loudness_patient)
      {
        const promiseArray = []
        
        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Phrases/Phrase_instruction_how are you",
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Phrases/Cup_of_tea"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
        ]
        
        for(let n in list_loudness_exercises)
        {
            promiseArray.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
        const all_results = await Promise.all(promiseArray)

        console.debug('all_results.length = ' + all_results.length)

        for(let n in all_results)
        {
          this.demo_video_pool.push(all_results[n])
        }
      }

      let interests_array = localStorage.getItem('interests_array');

      if(interests_array!=undefined && interests_array!=null && interests_array!='null' && interests_array.length>0)
      {

        console.debug('interests_array = ' + interests_array)
        interests_array = JSON.parse(interests_array)

        if(interests_array.length <= 0) {
          interests_array = ['Latest News']
        }
      }
      else
      {
        interests_array = ['Latest News']
      }
      let topics = interests_array

      let region = localStorage.getItem("region");
      let type = 'Standard'
      let news_size = 'Title'

      let n_exercises = 12

      let config = {topics:topics,region:region,type:type,news_size:news_size, n_exercises:n_exercises}

      await new Promise(function(resolve)
      {
        Create_News_Exercise(config).then(async curr_list=>{

          for(let n in curr_list)
          {
            exercise_data_list.push(curr_list[n])
          }

          _this.meta.start_object.module_name = 'Daily News'
          _this.meta.start_object.pre_loaded_exercises = exercise_data_list
    
          _this.meta.start_object.total_requested_input_task_exercises = 5

          console.debug('exercise_data_list = ' + exercise_data_list )

          console.debug('exercise_data_list.length = ' + exercise_data_list.length )
          _this.meta.start_object.assessment_type = "USER_TRAINING";

          await _this.prepare();
          resolve()
        })
      })

    } else if (_this.request_type === "jokes") {
      _this.module_name = "Jokes";

      const promiseArray = []

      let jokes_exercises_dir = ['/Exercises/General/Jokes/All']
      promiseArray.push(Create_Exercise_From_Exercise_Directories(jokes_exercises_dir))

      const all_results = await Promise.all(promiseArray)

      let jokes_list = randomiseExerciseSets(all_results,[5])
      let exercise_data_list = []

      let issue_flag = getIssueFlag()
      let loudness_patient = issue_flag[2]

      if(loudness_patient)
      {
        const promiseArray2 = []
        
        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Phrases/Phrase_instruction_how are you",
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Sentences/Joke"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_loud_clear"
          ]
        
        for(let n in list_loudness_exercises)
        {
          promiseArray2.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
        const all_results2 = await Promise.all(promiseArray2)

        for(let n in all_results2)
        {
          console.debug('exercise_type = ' + all_results2[n].exercise_type)
          this.demo_video_pool.push(all_results2[n])
        }
      }

      for(let i in jokes_list)
      {
        exercise_data_list.push(jokes_list[i])
      }

      _this.meta.start_object.module_name = 'Jokes'
      _this.meta.start_object.pre_loaded_exercises = exercise_data_list
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare();
    } else if (_this.request_type === "tongue_twisters") {
      _this.module_name = "Tongue twisters";

      const promiseArray = []

      let tongue_twisters_exercises_dir = ['/Exercises/General/TongueTwist/All']
      promiseArray.push(Create_Exercise_From_Exercise_Directories(tongue_twisters_exercises_dir))

      const all_results = await Promise.all(promiseArray)

      let tongue_twister_list = randomiseExerciseSets(all_results,[5])
      let exercise_data_list = []

      let issue_flag = getIssueFlag()
      let loudness_patient = issue_flag[2]

      if(loudness_patient)
      {
        const promiseArray2 = []
        
        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Phrases/Phrase_instruction_how are you",
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Sentences/Tongue_twister"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
        ]
        
        for(let n in list_loudness_exercises)
        {
          promiseArray2.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
        const all_results2 = await Promise.all(promiseArray2)


        for(let n in all_results2)
        {
          this.demo_video_pool.push(all_results2[n])
        }
      }

      for(let i in tongue_twister_list)
      {
        exercise_data_list.push(tongue_twister_list[i])
      }

      _this.meta.start_object.module_name = 'Tongue twisters'
      _this.meta.start_object.pre_loaded_exercises = exercise_data_list

      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare();
    } else if (_this.request_type === "mix_match") {

      _this.module_name = "Mix match";

      const promiseArray = []

      let base_plan = ['/BaseUserPlan/General/News/UK/Standard/Short/Latest News/']
      console.debug('base_plan = ' + base_plan)
      promiseArray.push(Create_Exercise_From_Base_Plans(base_plan))

      let jokes_exercises_dir = ['/Exercises/General/Jokes/All']
      promiseArray.push(Create_Exercise_From_Exercise_Directories(jokes_exercises_dir))

      let tongue_exercises_dir = ['/Exercises/General/TongueTwist/All']
      promiseArray.push(Create_Exercise_From_Exercise_Directories(tongue_exercises_dir))

      const all_results = await Promise.all(promiseArray)


      let exForm_list_temp = randomiseExerciseSets(all_results,[4,3,3])

      exForm_list_temp = shuffle(exForm_list_temp)

      let exercise_data_list = []

      let issue_flag = getIssueFlag()
      let loudness_patient = issue_flag[2]

      if(loudness_patient)
      {
        const promiseArray2 = []
        
        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Phrases/Phrase_instruction_how are you",
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Phrases/Whats_for_dinner"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
        ]
        
        for(let n in list_loudness_exercises)
        {
            promiseArray2.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
        const all_results2 = await Promise.all(promiseArray2)

        for(let n in all_results2)
        {
          this.demo_video_pool.push(all_results2[n])
        }
      }

      for(let i in exForm_list_temp)
      {
        exercise_data_list.push(exForm_list_temp[i])
      }

      _this.meta.start_object.module_name = 'Mixed Sentence'
      _this.meta.start_object.pre_loaded_exercises = exercise_data_list
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare()
    } else if (_this.request_type === "long_news") {
      
      _this.module_name = "Daily News (longer text)";

      let exercise_data_list = []

      let issue_flag = getIssueFlag()
      let loudness_patient = issue_flag[2]

      if(loudness_patient)
      {
        const promiseArray = []
        
        let list_loudness_exercises = [
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Sentences/Sentence_instruction_quote",
          "/Exercises/General/PD_Intensive_EN/Video_Instructions/Phrases/What_time_is_it"
          // "/Exercises/General/PD_Intensive_EN/Video_Instructions/Try_by_self_image_page"
        ]
        
        for(let n in list_loudness_exercises)
        {
            promiseArray.push(Create_Exercise_From_Single_Exercise_Address(list_loudness_exercises[n]))
        }
        const all_results = await Promise.all(promiseArray)

        for(let n in all_results)
        {
          this.demo_video_pool.push(all_results[n])
        }
      }

      let interests_array = localStorage.getItem('interests_array');

      if(interests_array!=undefined && interests_array!=null && interests_array!='null' && interests_array.length>0)
      {
        interests_array = JSON.parse(interests_array)

        if(interests_array.length<=0)
        {
          interests_array = ['Latest News']
        }
      }
      else
      {
        interests_array = ['Latest News']
      }

      let topics = interests_array


      let region = localStorage.getItem("region")
      let type = 'Standard'
      let news_size = 'Short'
      let n_exercises = 12

      let config = {topics:topics,region:region,type:type,news_size:news_size, n_exercises:n_exercises}

      await new Promise(function(resolve)
      {
        Create_News_Exercise(config).then(async exForm_list_temp=>{

          for(let i in exForm_list_temp)
          {
            exercise_data_list.push(exForm_list_temp[i])
          }
          
          _this.meta.start_object.module_name = 'Daily News'
          _this.meta.start_object.pre_loaded_exercises = exercise_data_list
    
          _this.meta.start_object.total_requested_input_task_exercises = 5

          console.debug('exercise_data_list = ' + exercise_data_list )

          console.debug('exercise_data_list.length = ' + exercise_data_list.length )
          _this.meta.start_object.assessment_type = "USER_TRAINING";
          await _this.prepare();
          resolve()
        })
      })
    } else if (_this.request_type === "word_memory") {
      _this.module_name = "Word memory" 
      
      _this.meta.start_object.module_path = '/Exercises/General/Aphasia/Memory_Image/'
      _this.meta.start_object.module_name = 'Memory Image'

      _this.meta.start_object.total_requested_input_task_exercises = 6 
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare();
    } else if (_this.request_type === "clock_game") {
      _this.module_name = "Clock game"
      _this.meta.start_object.module_path = '/Exercises/General/Aphasia/Clock'
      _this.meta.start_object.module_name = 'Clock Game'

      _this.meta.start_object.total_requested_input_task_exercises = 5 
      _this.meta.start_object.assessment_type = "USER_TRAINING";
      await _this.prepare()
    } else if (_this.request_type === "pace_training") {
      _this.module_name = "Pace training"
      let exercise_data_list = []

      let interests_array = localStorage.getItem('interests_array');

      if(interests_array!=undefined && interests_array!='null' && interests_array!=null && interests_array.length>0)
      {
        interests_array = JSON.parse(interests_array)

        if(interests_array.length<=0)
        {
          interests_array = ['Latest News']
        }
      }
      else
      {
        interests_array = ['Latest News']
      }

      let topics = interests_array

      let region = localStorage.getItem("region");
      let type = 'Pace'
      let news_size = 'Mid'
      let n_exercises = 10

      let config = {topics:topics,region:region,type:type,news_size:news_size, n_exercises:n_exercises}

      await new Promise(function(resolve)
      {
        Create_News_Exercise(config).then(async exForm_list_temp=>{
          for(let i in exForm_list_temp)
          {
            console.debug('exForm_list_temp[i].exercise_type = ' + exForm_list_temp[i].exercise_type)
            exercise_data_list.push(exForm_list_temp[i])
          }
          _this.meta.start_object.module_name = 'Pace Training'
          _this.meta.start_object.pre_loaded_exercises = exercise_data_list
          _this.meta.start_object.total_requested_input_task_exercises = 5
          console.debug('exercise_data_list = ' + exercise_data_list )
          console.debug('exercise_data_list.length = ' + exercise_data_list.length )
          _this.meta.start_object.assessment_type = "USER_TRAINING";
          await _this.prepare()
          resolve()
        })
      })
    } else if (_this.request_type === "language_assessment") {
      _this.module_name = "Picking an object"

      let token_assesment_dir = "Exercises/General/Aphasia/Token_assess"

      await new Promise(function(resolve)
      {
        Create_Exercise_From_Token_Test_Directories([token_assesment_dir]).then(async pre_loaded_exercises => {          
          _this.meta.start_object.module_path = ''
          _this.meta.start_object.module_loadExerciseData = 'Token Test'

          _this.meta.start_object.pre_loaded_exercises = pre_loaded_exercises

          _this.meta.start_object.assessment_type = "USER_ASSESSMENT";
          
          await _this.prepare();

          resolve()
        })
      })

    } else if (_this.request_type === "loudness_assessment") {
      _this.module_name = "Loudness assessment"

      let exercises_dir = ['/Exercises/General/PD_Intensive_EN/Evaluation/']

      await new Promise(function(resolve)
      {
        Create_Exercise_From_Exercise_Directories(exercises_dir).then(async exercise_data_list=>{
          _this.meta.start_object.module_path = ''
          _this.meta.start_object.pre_loaded_exercises = exercise_data_list
          _this.meta.start_object.module_name = 'Loudness Assessment'
          _this.meta.start_object.assessment_type = "USER_ASSESSMENT"

          await _this.prepare();
          resolve()
        })
      })

    } else if (_this.request_type === "speech_assessment") { 
      _this.module_name = "Speech assessment";
      _this.meta.start_object.module_path = '/BaseUserPlan/Tikofsky10-wordIntelligibilityTest/'
      _this.meta.start_object.module_name = 'Tikofsky10-wordIntelligibilityTest'
      _this.meta.start_object.total_requested_input_task_exercises = 10 
      _this.meta.start_object.assessment_type = "USER_ASSESSMENT"

      await _this.prepare();
    } else if (_this.request_type === "assignment") {
      this.module_name = "Assignment from therapist"
      
      this.isAssignment = true

      let index = this.meta.index
      let isAssessment = this.meta.isAssessment

      await this.loadAssignmentModule(index, isAssessment)
    } else if (_this.request_type === "user_training" && blueprint_id != null) {
      _this.meta.start_object.module_path = '/BaseUserPlan/Training_modules/' + blueprint_id + '/';
      _this.meta.start_object.assessment_type = "USER_TRAINING";

      await _this.prepare()  
    } else if (_this.request_type === "clinic_training" && blueprint_path != null) {
      _this.meta.start_object.module_path = blueprint_path
      _this.meta.start_object.assessment_type = "SLP_TRAINING";

      await _this.prepare()
    } else if (_this.request_type === "clinic_assessment" && blueprint_path != null) {
      _this.meta.start_object.module_path = blueprint_path
      _this.meta.start_object.assessment_type = "SLP_ASSESSMENT";

      await _this.prepare()
    } else {
      //all the rest go here - preloaded modules
      _this.module_name = _this.meta.start_object.module_name
      await _this.prepare();    
    }    
    
    return false
  }

  async prepare() {
    let _this = this;

    sessionStorage.setItem("in_noise_background_noise", 'true')


    ////////////////////////////////////////////////

    let module_path = _this.meta.start_object.module_path

    console.debug('module_path = '+ module_path)

    console.debug('_this.meta.start_object = ' + Object.keys(_this.meta.start_object))

    _this.assessment_type = _this.meta.start_object.assessment_type //this needs to be initialised ealier
    _this.module_name = _this.meta.start_object.module_name  // this should be loaded from mod_name from database

    /// if module is loaded from database blueprint it needs to read plan_type
    let randomise = false

    let plan_type = _this.meta.start_object.plan_type

    if (plan_type != undefined && 
        plan_type != null &&
       (plan_type ==='random' || plan_type === 'random_assortment')) {
      randomise = true
    }
    console.debug('randomise = ' + randomise)

    ////////////////////

    //this can be NaN if not set (like in preloaded modules)
    _this.total_requested_input_task_exercises = Number(_this.meta.start_object.total_requested_input_task_exercises)

    let pre_loaded_exercises = _this.meta.start_object.pre_loaded_exercises;

    if(pre_loaded_exercises != undefined && pre_loaded_exercises != null && pre_loaded_exercises.length>0) {
      //pre_loaded exercises are exercises with saved exercises 
      await _this.run_pre_loaded_exercise(pre_loaded_exercises)
    } else {
      await _this.loadModuleFolder(module_path, randomise)
    }

    if(_this.module_name!=undefined && _this.module_name!=null) {
      logActivity( 'accessed', _this.module_name)
    } else {
      logActivity( 'accessed', 'no title')
    }
    
  }

  async getFirebaseHandleByChildKey(handle, target_key, dest_array)
  { 
    const _this = this;
    await new Promise( (resolve) => {
      handle.forEach(function (childSnapshot) {

        let c_snapshot = childSnapshot;
  
        if(childSnapshot.hasChildren()) {
          _this.getFirebaseHandleByChildKey(c_snapshot, target_key, dest_array)
        }
        else if(childSnapshot.key == target_key) {
          dest_array.push(handle)
        }
      })
      resolve()
    })
  }

  async run_pre_loaded_exercise(pre_loaded_exercises)
  {
    const _this = this;

    _this.exercise_xForm_list = pre_loaded_exercises

    for(let i in _this.exercise_xForm_list) {
      _this.exercise_xForm_list[i].today = getTodayString()
    }

    ///exercises_xForm.length is total requested exercises since it is preloaded
    _this.total_requested_input_task_exercises = _this.exercise_xForm_list.length
    _this.total_available_all_type_exercises = _this.exercise_xForm_list.length
    await _this.next()
  }

  async loadModuleFolder(module_path, randomise=false)
  {
    const _this = this;
    
    console.debug('module_path = ' + module_path)
    
    await new Promise((resolve) => {

      getData(module_path , async function (err, handle) {
        await _this.loadSingleModuleFolder(handle, randomise)
        resolve()
      });

    })
  }

  async loadSingleModuleFolder(root_handle, randomise=false) //sets exercise_address_list and total_available_all_type_exercises
  {
    const _this = this

    let root_val = root_handle.val()


    if('plan_type' in root_val && root_val.plan_type==='random_assortment')
    {
      randomise = true
    }

    console.debug(randomise)

    //load meta data here

    if (this.meta.start_object.module_name == "") {
      this.meta.start_object.module_name = root_handle.val()['mod_name']
      this.module_name = root_handle.val()['mod_name']
    }

    let exercise_address_list = [] //stores addresses pointing in /Exercise folder

    exercise_address_list = await _this.load_module_exercise_addresses_list(root_handle)


    //alert(this.total_requested_input_task_exercises)
    if (_this.total_requested_input_task_exercises == null || isNaN(_this.total_requested_input_task_exercises)) {
      let n_db_input_task_requested_exercises = count_db_requested_exercises(root_handle.val())
      _this.total_requested_input_task_exercises = n_db_input_task_requested_exercises//Math.min(n_db_input_task_requested_exercises, 10) //should minus the instructions
    }
    ////////////////////////////////////////
    //load exForm list

    let all_indexes = []

    for(let k in exercise_address_list) {
      all_indexes.push(k)
    }

    if(randomise) {
      all_indexes = shuffle(all_indexes)
    }

    for (let i = 0; i<exercise_address_list.length; i++) {
      if (_this.total_input_task_exercises >= _this.total_requested_input_task_exercises && _this.total_requested_input_task_exercises != 0) {
        break 
      }
      let curr_exercise_address_handle = exercise_address_list[all_indexes[i]]
      await _this.loadExercises(curr_exercise_address_handle)
    }

    /////
    //now we know the real total_input_task_exercises
    ///check when total input tasks exercises is lower than the total requested input_tasks available
    //alert(_this.total_input_task_exercises)
    //alert(_this.total_requested_input_task_exercises)
    if (_this.total_input_task_exercises < _this.total_requested_input_task_exercises) {
      await _this.fillDiffRequestedExercises(exercise_address_list) //kk: this was added to fix minimal pairs - if it causes errors elsewhere disable.
    }
    _this.total_available_all_type_exercises = _this.exercise_xForm_list.length
    ////////////////////////////////////////

    if (_this.meta['current_exercise_index']) { //this is for assignments to resume from where they left
      _this.current_exercise_index = _this.meta['current_exercise_index']
      if (_this.current_exercise_index >= _this.total_available_all_type_exercises) {
        _this.current_exercise_index = 0        
      }
    } else {
      _this.current_exercise_index = 0
    }

    await _this.next()
  }
  
  async fillDiffRequestedExercises(exercise_address_list){
    let diff = this.total_requested_input_task_exercises - this.total_input_task_exercises
    let pool = []
    for (let i=0; i<exercise_address_list.length; i++) {
      if (!exercise_address_list[i].val()['type']) { //only randomise exercises not instructions
        pool.push(exercise_address_list[i])
      }
    }

    if(pool.length>0)
    {
      //randomise and fill
      for (let i=0; i<diff; i++) {
        let x = Math.floor(Math.random() * (pool.length))
        let curr_exercise_address_handle = pool[x]
        await this.loadExercises(curr_exercise_address_handle)
      }

    }
    
  }

  async load_module_exercise_addresses_list(handle) { //fill exercise_address_list with firebase handles where address is located (pointers)
    let _this = this

    let temp_form = []
    await _this.getFirebaseHandleByChildKey(handle, 'address', temp_form)

    if(temp_form.length == 0) {
      console.debug('running backup key crawler')
      await _this.getFirebaseHandleByChildKey(handle, 'instruct', temp_form)
    }

    //check if multiexercises addresses exist
    let x_temp_addresses = temp_form.slice(0) // use it as temporary

    for (let i=0; i<temp_form.length; i++) {

      let i_exercise_address_handle = temp_form[i]
      let exercise_range = i_exercise_address_handle.child('exercise_range').val()


      if (exercise_range == 'multi_exercises') {

        let plan_type = i_exercise_address_handle.child('plan_type').val()

        let requested_exercises = i_exercise_address_handle.child('number_exercises').val()
        
        let m_temp_form = await _this.getMultiExercisesAddress(i_exercise_address_handle.child('address').val(), requested_exercises, plan_type)

        if (m_temp_form.length > 0) {
          x_temp_addresses[i] = m_temp_form[0]
          for (let x=1; x<m_temp_form.length; x++) {
            x_temp_addresses.push(m_temp_form[x])
          }
        }
      }
    }
    
    return x_temp_addresses //get list into exercise address list
  }

  async loadExercises(curr_exercise_address_handle) //populate exercise_xForm_list using exercise_address_list
  {
    const _this = this
    
    let ex_address_path = curr_exercise_address_handle.child('address').val()

    console.debug('ex_address_path = ' + ex_address_path)

    if (ex_address_path==undefined || ex_address_path==null) { //no exercise path/address 
      console.debug('no ex path')
      
      let exercise_data = _this.loadExerciseData(curr_exercise_address_handle)

      let demo_video = false

      console.debug('exercise_data.type = ' + exercise_data.type)

      if (exercise_data.type === 'input_task') {
        _this.total_input_task_exercises = _this.total_input_task_exercises + 1
      }
      else if (exercise_data.type === 'demo_video') {
        demo_video = true
        _this.demo_video_pool.push(exercise_data)
      } else {
        _this.total_instructions_exercises = _this.total_instructions_exercises + 1
      }

      if (!demo_video) {
        _this.exercise_xForm_list.push(exercise_data);          
      }

    } else {
      let read_path;
      if(ex_address_path.includes('Exercises')) {
        read_path = ex_address_path
      } else {
        read_path = '/Exercises/' + ex_address_path
      }      
      console.debug('read path = ' + read_path)

      await new Promise ((resolve) => {

        getData(read_path, async function (err, curr_exercise_handle) {

          let exercise_data = _this.loadExerciseData(curr_exercise_handle)

          let demo_video = false

          if (exercise_data.type === 'input_task') {
            _this.total_input_task_exercises = _this.total_input_task_exercises + 1
          } else if (exercise_data.type === 'demo_video') {
            demo_video = true
            _this.demo_video_pool.push(exercise_data)
          } else {
            _this.total_instructions_exercises = _this.total_instructions_exercises + 1
          }

          if (!demo_video) {
            _this.exercise_xForm_list.push(exercise_data);          
          }

          resolve()
        });

      })
    }

    //console.log(_this.total_input_task_exercises)
  }
  
  loadExerciseData(curr_module_handle)
  {
    console.debug('loading exercise data')
    const _this = this

    let exercise_data = {}

    exercise_data = curr_module_handle.val()

    if(!this.module_name) {
      this.module_name = exercise_data['name'] //this is wrong should be removed - name is always stored in mod_name
    }

    exercise_data.module_name = _this.module_name
    
    exercise_data.assessment_type = _this.assessment_type

    exercise_data.curr_exercise_path = _this.module_name + '/'

    exercise_data.audioIns1 = curr_module_handle.child('audioIns').val();
    exercise_data.imageAddress = curr_module_handle.child('Address').val();
    exercise_data.videoAddress = curr_module_handle.child('Video_Address').val();
    exercise_data.audioAddress = curr_module_handle.child('Audio_Address').val();

    exercise_data.audioIns2 = curr_module_handle.child('Audio_Instructions').val();
    exercise_data.videoIns = curr_module_handle.child('Video_Instructions').val();

    exercise_data.exercise_type = curr_module_handle.child('exercise_type').val();
    exercise_data.url = curr_module_handle.child('Url').val()

    exercise_data.url2image = curr_module_handle.child('Urltoimage').val()

    if (curr_module_handle.child('type').val()) {
      exercise_data.type = curr_module_handle.child('type').val()
    } else {
      exercise_data.type = 'input_task'
    }

    exercise_data.audioIns = null;

    if(exercise_data.audioIns1 != null) {
      exercise_data.audioIns = exercise_data.audioIns1
    }

    if(exercise_data.audioIns2 != null) {
      exercise_data.audioIns = exercise_data.audioIns2
    }

    if(exercise_data.text != null) {
      exercise_data.Ex_Text = exercise_data.text 
    }

    if(exercise_data.instruct != null) {
      exercise_data.Ex_Description = exercise_data.instruct;
    } else if(exercise_data.question != null) {
      exercise_data.Ex_Description = exercise_data.question;
    }
    return exercise_data;
  }

  async getMultiExercisesAddress(read_path, requested_exercises, plan_type)
  {
    const _this = this;

    let temp_handle_list = []

    await new Promise ((resolve) => {      
      getData(read_path, async function (err, curr_module_handle) {
        let exercises_address_array = []

        await _this.getFirebaseHandleByChildKey(curr_module_handle, 'instruct', exercises_address_array) //retrieves exercises as each exercises has 'instruct'
        console.debug('multiexercises exercise_address_list = ' + exercises_address_array.length)

        let total_available_all_type_exercises = exercises_address_array.length
        
        for(let i=0;i<requested_exercises;++i) {
          let handle

          if(plan_type == 'random' || plan_type=='random_assortment') {
            let r = Math.floor(Math.random() * total_available_all_type_exercises);
            handle = exercises_address_array[r]
          } else {            
            if (i > total_available_all_type_exercises-1) {
              //this is when the requested exercises are more than available (eg. word pairs)
              handle = exercises_address_array[Math.floor(Math.random() * (total_available_all_type_exercises - 1))]
            } else {
              handle = exercises_address_array[i]
            }
          }

          temp_handle_list.push(handle);
        }
        resolve()
      })
    })
    
    return temp_handle_list
  }

  async loadAssignmentModule(index, isAssessment)
  {
    const _this = this
    
    _this.isAssessment = isAssessment

    _this.isAssignment = true //always true here

    let assignment_type = 'homework'

    if (isAssessment) {
      assignment_type = 'assessment'
    }
    
    let assignments_address = '/Assignments/' + assignment_type + '/general'

    let do_once = true

    let foundSnapshot = false
    
    await new Promise((resolve) => {

      getUserDataDir(assignments_address, async function (err, handle) {
        if (do_once) {
          do_once = false
          
          if(handle.hasChildren()) //should always be true
          {
            let counter = 0        
            handle.forEach(function (childSnapshot) {
                            
              if (counter == index) { //get requested module
                
                _this.moduleHandle = childSnapshot

                foundSnapshot = true

                resolve()
              }
              counter++;
            })
          }
          
        }
      });
    })


    if (foundSnapshot) {

      _this.module_name = _this.moduleHandle.val()['mod_name']
      _this.assessment_type = "ASSIGNMENT_TRAINING"
      
      if (isAssessment) {
        _this.assessment_type = "ASSIGNMENT_ASSESSMENT"
      }

      await this.loadSingleModuleFolder(_this.moduleHandle)

      updateData(_this.moduleHandle.ref, {"last_accessed": new Date()});

    }

  }

  async next() {
    const _this = this;
    
    console.debug("curr_exercise index " + _this.current_exercise_index)
    
    if(_this.current_exercise_index >= _this.total_available_all_type_exercises) //end of set of exercises
    {//end of exercises
      if (_this.isAssignment) {
        updateData(_this.moduleHandle.ref, {"current_exercise_index": this.current_exercise_index});
      }

      _this.current_exercise_index = 0
      _this.finished_n_requested_exercises = true;
      _this.showResultPage()
      return true
    } else if((_this.total_available_all_type_exercises - _this.current_exercise_index) > 5 && 
              _this.current_exercise_index % 10 == 0 && 
              _this.exForm.assessment_type && 
              !_this.exForm.assessment_type.includes('ASSESSMENT') && 
              this.showResults != true)
    {
      //break at 10th exercise if it is not an assessment
      _this.showResultPage()
    } else {
      //continue
      _this.bv_points.add(10)
      
      _this.exForm = _this.exercise_xForm_list[_this.current_exercise_index]

      ///some of the following might not coming from loadSingleExercise() - like preloaded so we need to make sure all required data is stored in exForm
      _this.exForm.today = _this.today

      _this.exForm.assessment_type = _this.assessment_type
      
      _this.exForm.module_name = _this.module_name
      
      _this.exForm.current_exercise_index = _this.current_exercise_index

      _this.exForm.total_all_type_exercises = _this.total_available_all_type_exercises

      _this.exForm.total_input_task_exercises = _this.total_input_task_exercises
    
      _this.exForm.total_instructions_exercises = _this.total_instructions_exercises

      _this.exForm.demo_video_pool = _this.demo_video_pool

      _this.exForm.has_results = _this.has_results

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


      let exercise_type = _this.exForm.exercise_type
     
      if(exercise_type == undefined || exercise_type== null) {
        exercise_type = 'basic_module'
      }

      if(_this.exercise_types.includes(exercise_type)) {
        _this.current_exercise_type = exercise_type
      } else {
        _this.current_exercise_type = 'basic_module'
      }
  
      //Increase Iterator//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if(_this.isAssignment) { //this is required to update current_exercise_index
        updateData(_this.moduleHandle.ref, {"current_exercise_index": _this.current_exercise_index});
      }
      
      _this.current_exercise_index = _this.current_exercise_index + 1

      _this.showResults = false;
    }

    return false
  }

  goToResults(curr_ex_done)
  {
    const _this = this
    if(!curr_ex_done) {
      _this.current_exercise_index = _this.current_exercise_index - 1
    }

    if(_this.current_exercise_index >= _this.exercise_xForm_list.length) //end of set of exercises
    {
      //this.current_exercise_index = 0
    }
    
    _this.showResultPage()
  }

  showResultPage() {
    const _this = this

    if(_this.finished_n_requested_exercises==false && _this.current_exercise_index < _this.total_available_all_type_exercises) {
      _this.meta.start_object.pre_loaded_exercises = _this.exercise_xForm_list
      _this.more_exercises_available = true
    } else {
      _this.meta.start_object.pre_loaded_exercises = null
      _this.more_exercises_available = false
    }

    /* IPA and _this.meta.start_object.current_exercise_index is not used anymore - so I think this is not required
    if ( _this.meta.start_object.pre_loaded_exercises != undefined && 
         _this.meta.start_object.pre_loaded_exercises != null && 
        (_this.meta.start_object.pre_loaded_exercises.length-1) >= _this.meta.start_object.current_exercise_index) {
    
        _this.more_exercises_available = true
    
    } else if ( _this.meta.start_object.module_path == '') {
                _this.more_exercises_available = false
    } else if ( _this.meta.start_object.entry != undefined &&
                _this.meta.start_object.entry != null && _this.meta.start_object.entry == 'IPA') {
      _this.more_exercises_available = false
    }*/

    _this.showResults = true;
  }

  logExerciseResults(data)
  {
    const _this = this
   
    let resultsTablePre = data['resultsTablePre']

    if(resultsTablePre != null) {
      _this.results_table['pre'].push(resultsTablePre)
    }

    if(data.avg_loudness_array && data.avg_loudness_array.length>0) {
      _this.avg_loudness_array.push(data.avg_loudness_array)

      if(!('pre' in _this.results_table)) {
        _this.results_table['pre'] = []
      }

      _this.results_table['pre'].push(
        {
          'results':  { 'avg_loudness_array': _this.avg_loudness_array }, 
          'exercise_type': 'Loudness', 
          'exercise': ''
        }
      )
    }

    let resultsTableBack = data['resultsTableBack']
    
    if(resultsTableBack != null) {
      for(let i in resultsTableBack) {
        _this.results_table['back'].push(resultsTableBack[i])
      }
    }


    _this.exForm.has_results = (_this.results_table['back'].length > 0 || _this.results_table['pre'].length > 0)

    if (_this.exForm.has_results) {
      logActivity( 'completed', _this.module_name, _this.isAssignment)

      this.has_results = true
    }

  }

  getTotalExercisesNumber() {
    return this.total_available_all_type_exercises
  }

  getTotalInputTaskExercisesNumber() {
    return this.total_input_task_exercises
  }

  getTotalInstructionsExercisesNumber() {
    return this.total_instructions_exercises
  }

}