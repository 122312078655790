<template>
  <div>
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body"  style="width:100%;border-color: blueviolet;">
          <div class="accordion" id="accordionDDK">
            <div class="accordion-item mb-3">

              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ddk_collapse" aria-expanded="false" aria-controls="ddk_collapse"><p class="text-start" >
                  <b>DDK rate</b></p></button>
              </div>
              <div :class="['accordion-collapse', 'collapse', show_collapse ? 'show': '']" id="ddk_collapse" data-bs-parent="#accordionDDK">

                <div class="row mt-4 mb-2 m-2"  v-if="ddk_interp.single!== 'NA'">
                  <div class="col-6 text-start">
                    <p>
                      Single syllable (Pa/Ta/Ka):
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{ddk_interp.single}} repetitions in {{ ddk_interp.duration }}s &emsp; &emsp; ({{ ddk_interp.single_rating }})</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row mb-2 m-2"  v-if="ddk_interp.double!== 'NA'">
                  <div class="col-6 text-start">
                    <p>
                      Double syllables (/Ka La/):
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p>
                      {{ddk_interp.double}} 
                      <span>
                        repetitions in {{ ddk_interp.duration }}s &emsp; &emsp; ({{ ddk_interp.double_rating }})
                      </span>
                    </p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row mb-2 m-2"  v-if="ddk_interp.triple!== 'NA'">
                  <div class="col-6 text-start">
                    <p>
                      Triple syllables (/Pa Ta Ka/):
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p>
                      {{ddk_interp.triple}} repetitions in {{ ddk_interp.duration }}s &emsp; &emsp; ({{ ddk_interp.triple_rating }})
                    </p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr class="text-center">
                          <th>Name</th>
                          <th>Loudness (dB)</th>

                          <th>Number repetitions</th>
                          <th>Test duration (s)</th>
                          <th>Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center" v-for="(item, index) in table" :key="index" >
                          <td>{{ item.name }}</td>
                          <td>{{ item.loudness }}</td>

                          <td>{{ item.number_repetitions }}</td>
                          <td>{{ item.test_duration }}</td>
                          <td>{{ item.interp }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'Ddk',
  props: ['ddk_interp', 'table', 'show_collapse'],
  setup(props, { emit }) {
    
    return {
      
    }

  }
}

</script>
<style scoped>


</style>





