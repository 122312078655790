<template>
  <div class="mt-4">

    <div class="row justify-content-center" v-if="edit_meta_module_pointer == null">
      
      <div class="col-12">
        <div class="row">
          <p class="lead">Please choose an exercise template below</p>
        </div>
        <div class="row justify-content-center">
          <div class="col-4">
            <select class="form-select text-center" v-model="selected_module_template">
              <option value="voice_n_speech" selected>Voice and speech</option>
              <option value="picture_naming" selected>Picture naming</option>

              <option value="real_life_activities">Real life activities</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">

      <picture_naming 
        v-if="selected_module_template === 'picture_naming'"
        :edit_meta_module_pointer = "edit_meta_module_pointer"
        :module_folder_locations = "module_folder_locations"

        @add_to_assignment_list = "add_to_assignment_list"
        @module_saved="module_saved"
        @cancel_submit="cancel_submit"
      >
      </picture_naming>


      <voice_speech 
        v-if="selected_module_template === 'voice_n_speech'"
        :edit_meta_module_pointer = "edit_meta_module_pointer"
        :module_folder_locations = "module_folder_locations"

        @add_to_assignment_list = "add_to_assignment_list"
        @module_saved="module_saved"
        @cancel_submit="cancel_submit"
      >
      </voice_speech>

      <activity 
        v-if="selected_module_template === 'real_life_activities'"
        :edit_meta_module_pointer = "edit_meta_module_pointer"
        :module_folder_locations = "module_folder_locations"

        @add_to_assignment_list = "add_to_assignment_list"
        @module_saved="module_saved"
        @cancel_submit="cancel_submit"
      >
      </activity>

    </div>



  </div>
</template>

<script>

import picture_naming from './picture_naming/picture_naming.vue';

import voice_speech from './voice_speech/voice_speech.vue';
import activity from './activity/activity.vue';
import { ref } from 'vue'

export default {
  name: 'create_module',
  components: {
    voice_speech,
    activity,
    picture_naming
  },
  props: {
    edit_meta_module_pointer: null,
    module_folder_locations: []
  },
  setup( props, {emit}) {

    const selected_module_template = ref(null)
    const edit_meta_module_pointer = props.edit_meta_module_pointer

    if (edit_meta_module_pointer) {

      console.debug('edit_meta_module_pointer.type = ' + edit_meta_module_pointer.type)

      if (edit_meta_module_pointer.type === 'SpeechModules') {
        selected_module_template.value = 'voice_n_speech'
      } else if (edit_meta_module_pointer.type === 'PictureNaming') {
        selected_module_template.value = 'picture_naming'
      }       
      else if (edit_meta_module_pointer.type === 'ActivityModules') {
        selected_module_template.value = 'real_life_activities'
      } else {
        //error
        edit_meta_module_pointer.value = null
        selected_module_template.value = 'voice_n_speech'        
      }

    } else {
      selected_module_template.value = 'voice_n_speech'
    }

    const show_my_modules = ( ) => {
      emit('show_my_modules')
    }

    const add_to_assignment_list = ( custom_module) => {
      emit('add_to_assignment_list', custom_module)
      show_my_modules()
    }

    const module_saved = (module_location) => {
      console.log(module_location)
      emit('module_saved', module_location)
    }
    const cancel_submit = () => {
      emit('cancel_submit')
    }

    return {
      selected_module_template,
      add_to_assignment_list,
      module_saved,
      cancel_submit
    }

  }
}
</script>