<template>
  <div>
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
        <div class="row mt-2">
      <div class="card card-body"  style="border-color: blueviolet;">
        <div class="accordion" id="accordionQuestionnaires">
          <div class="accordion-item mb-3">
            <div class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#questionnaires_collapse" aria-expanded="false" aria-controls="questionnaires_collapse"><p class="text-start" >
                <b>Questionnaire</b></p></button>
            </div>
            <div :class="['accordion-collapse', 'collapse', show_collapse ? 'show': '']" id="questionnaires_collapse" data-bs-parent="#accordionQuestionnaires">

          <div class="row mb-2">
            <div v-for="questionnaire, i of table">
            <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

              <p class="text-start" v-if="'Questionnaire_Name' in table[i]" ><b>Questionnaire {{ i +1}} - {{ table[i]['Questionnaire_Name'] }}</b></p>
              <p class="text-start" v-else ><b>Questionnaire {{ i +1}} </b></p>

              <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

              <table class="table mt-4" >
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th v-if="'questions' in table[i]" scope="col">Question</th>
                  <th v-if="'rating_scores' in table[i]" scope="col">Score</th>

                  <th v-if="'answers' in table[i]" scope="col">Answer</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="row, index in table[i]['questions']" v-bind:key="index">
                <td >{{ index +1}}</td>
                <td v-if="'questions' in table[i]" class="text-start">{{ table[i]['questions'][index] }}</td>
                <td v-if="'rating_scores' in table[i]">{{ table[i]['rating_scores'][index]}}</td>

                <td v-if="'answers' in table[i]">{{ table[i]['answers'][index]}}</td>

              </tr>
              </tbody>
            </table> 


            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </section>
  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'Ddk',
  props: ['table', 'show_collapse'],
  setup(props, { emit }) {
    
    return {
      
    }

  }
}

</script>
<style scoped>


</style>





