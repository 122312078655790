<template>
  <div>

    <h1 class="display-3 mt-4"><strong>Select Personal Instructor</strong></h1>
    <p>This will be the voice that will read all the instructions. Choose the one you like.</p>
    <br/>
    
    <div class="row mt-4 justify-content-center" v-if="voices.length > 0">
      <div class="col-md-5">
        <div class="card card-body">
          <div class="row">
            <div class="col-2" @click="select_tts(voices[0].name)">
              <i v-if="selected_tts == voices[0].name" class="bi bi-check-circle-fill text-primary" style="font-size: 20px; cursor:pointer"></i>
              <i v-else class="bi bi-circle text-primary" style="font-size: 20px; cursor:pointer"></i>
            </div>
            <div class="col">
              {{ voices[0].name }}
            </div>
            <div class="col-3">
              <button class="btn btn-sm btn-outline-primary rounded-pill px-3 py-2" @click="play_tts(voices[0].name)">Play</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5" v-if="voices.length >= 2">
        <div class="card card-body">
          <div class="row">
            <div class="col-2" @click="select_tts(voices[1].name)">
              <i v-if="selected_tts == voices[1].name" class="bi bi-check-circle-fill text-primary" style="font-size: 20px; cursor:pointer"></i>
              <i v-else class="bi bi-circle text-primary" style="font-size: 20px; cursor:pointer"></i>
            </div>
            <div class="col">
              {{ voices[1].name }}
            </div>
            <div class="col-3">
              <button class="btn btn-sm btn-outline-primary rounded-pill px-3 py-2" @click="play_tts(voices[1].name)">Play</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <div class="row mt-4 justify-content-center" v-if="voices.length >= 3">
      <div class="col-md-5">
        <div class="card card-body" >
          <div class="row">
            <div class="col-2" @click="select_tts(voices[2].name)">
              <i v-if="selected_tts == voices[2].name" class="bi bi-check-circle-fill text-primary" style="font-size: 20px; cursor:pointer"></i>
              <i v-else class="bi bi-circle text-primary" style="font-size: 20px; cursor:pointer"></i>
            </div>
            <div class="col">
              {{ voices[2].name }}
            </div>
            <div class="col-3">
              <button class="btn btn-sm btn-outline-primary rounded-pill px-3 py-2" @click="play_tts(voices[2].name)">Play</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5" v-if="voices.length >= 4">
        <div class="card card-body">
          <div class="row">
            <div class="col-2" @click="select_tts(voices[3].name)">
              <i v-if="selected_tts == voices[3].name" class="bi bi-check-circle-fill text-primary" style="font-size: 20px; cursor:pointer"></i>
              <i v-else class="bi bi-circle text-primary" style="font-size: 20px; cursor:pointer"></i>
            </div>
            <div class="col">
              {{ voices[3].name }}
            </div>
            <div class="col-3">
              <button class="btn btn-sm btn-outline-primary rounded-pill px-3 py-2" @click="play_tts(voices[3].name)">Play</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <br/>
    <div class="row mt-4 justify-content-center">
      <div class="col-12 text-center">
        <button class="btn btn-primary rounded-pill px-4" @click="next()">Continue</button>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useTTS } from '@/stores/tts'
import { getUserDataDir, updateDataDirectory, updateUserData } from '@/firebase/index'


export default {
  name: 'Select_TTS',
  setup(props, { emit }) {
    const bv_tts = useTTS().tts_engine
    const voices = ref([])
    const language = ref('UK')
  
    const selected_tts = ref('')

    const getVoices = async () => {
      
      await bv_tts.preferred_voice_promise
      
      getUserDataDir('Settings/language', function (err, result) {
        language.value = result.val()
        if (language.value == 'UK') {
          language.value = 'GB'
        }
        //the following depends on the above promise()

        let available_preferred_voices = bv_tts.get_available_preferred_system_voices(language.value)
        let all_voices = bv_tts.voices.filter(voice => voice.lang.includes(language.value))

        const combined = {};

        available_preferred_voices.forEach(obj => {
          combined[obj.name] = obj;
        });

        all_voices.forEach(obj => {
          if (!combined.hasOwnProperty(obj.name)) {
            combined[obj.name] = obj;
          }
        });
        voices.value = Object.values(combined);
        let session_preferred_voice = sessionStorage.getItem('preferred_voice')
        if (session_preferred_voice) {
          selected_tts.value = session_preferred_voice
        }
      })     
    }

    getVoices()


    let ref_path = 'Users/' + localStorage.getItem('uid') + '/Settings/'

    const select_tts = (voice_name) => {
      if (selected_tts.value !== voice_name) {
        selected_tts.value = voice_name
        updateDataDirectory(ref_path, {'preferred_voice': voice_name})
      } else {
        selected_tts.value = ''
      }
    }

    const play_tts = async (voice_name) => {
      
      updateDataDirectory(ref_path, {'preferred_voice': voice_name})
      await bv_tts.set_voice()
      bv_tts.say("hello", updateDataDirectory(ref_path, {'preferred_voice': ''}))
    }

    const next = () => {

      sessionStorage.setItem('preferred_voice', selected_tts.value)

      updateDataDirectory(ref_path, {'preferred_voice': selected_tts.value})
      updateUserData('Data/onboarding', {'current_stage_index': 5})
      emit('next')
    }

    return {
      selected_tts,
      voices,
      language,

      next,
      select_tts,
      play_tts
      
      
    }
  }
}
</script>

<style scoped>

</style>
