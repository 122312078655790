


export async function make_request_open_ai(_this,request)
{

    console.debug('make request')

    let apiKey = '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'
    const client = _this.axios.create({
        headers: { 'Authorization': 'Bearer ' + apiKey }
    });
    
    const params = {
      "prompt": request, 
      "max_tokens": 600,
      "temperature":0.6,
      "top_p":0.6
        }

    const response = await client.post('https://api.openai.com/v1/engines/gpt-3.5-turbo-instruct/completions', params)


    for(let i in response.data.choices[0])
    {
        console.debug('response.data.choices[0]['+i+'] = ' + response.data.choices[0][i])
    }



    let output = response.data.choices[0].text;

    return output
}
    

export async function make_request_o1_mini(_this,request)
{

    console.debug('make request')

    let apiKey = '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'
    const client = _this.axios.create({
        headers: { 'Authorization': 'Bearer ' + apiKey }
    });

    const params = {
        model: "gpt-4-turbo", 
        messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: request }
        ],
        max_tokens: 600,
        temperature: 0.6,
        top_p: 0.6
    };

    let output = 'fail'
    
    try {
        const response = await client.post('https://api.openai.com/v1/chat/completions', params);
        console.debug(response.data);

        for(let i in response.data.choices[0])
        {
            console.debug('response.data.choices[0]['+i+'] = ' + response.data.choices[0][i])
        }
    
        
        
        output = response.data.choices[0].message.content;
        
    } catch (error) {
        console.debug(error.response)
        console.debug(error.response.data)

        console.debug(error.message)

        console.error('Error:', error.response ? error.response.data : error.message);
    }


   
    return output
}


export async function fun_facts(_this, topic, number)
{

    let request = 'Tell me ' + number + ' short fun facts about ' + topic + ". Use british english. Use common simple words. Use vocabulary of a 16 year old. Don't use words with more than 6 letters or non-english words"

    let raw_answer = await make_request_open_ai(_this,request)

    console.debug('raw_answer = ' + raw_answer)

    let list_facts = raw_answer.split('\n')

    let out_facts = []

    for(let n in list_facts)
    {

        if(list_facts[n].length>3)
        {
            let start = list_facts[n].indexOf(' ')

            out_facts.push(list_facts[n].substring(start))    

        }
    }

    return out_facts

}

export async function short_paragraph(_this, topic, number)
{

    let request = 'Tell me ' + number + ' short paragraphs about ' + topic + ". Use british english. Use common simple words. Use vocabulary of a 16 year old. Don't use words with more than 6 letters or non-english words"

    console.debug('request = ' + request)

    let raw_answer = await make_request_open_ai(_this,request)


    console.debug('raw_answer = ' + raw_answer)

    let list_paragraph = raw_answer.split('\n')

    let regex = []
    
    let regex_1 = new RegExp(/\b(Paragraph\s?|paragraph\s?)(\d+\.?\d*)\s*/,'g') //removing instances of Paragraph 1...
    let regex_2 =  new RegExp(/^:/,'g')
    let regex_3 = new RegExp(/^([0-9]*)(\)|\.|:)\s*/,'g') //removing 1., 1), 1:
    
    regex.push(regex_1)
    regex.push(regex_2)
    regex.push(regex_3)



    let out_facts = []

    for(let n in list_paragraph)
    {

        if(list_paragraph[n].length>15)
        {

            for(let k in regex)
            {
                list_paragraph[n] = list_paragraph[n].replaceAll( regex[k],'');

            }


      

            out_facts.push(list_paragraph[n])    

        }
    }

    return out_facts

}


export async function search_synonyms(_this, curr_words)
{

    let request = "Find as many synonyms as possible for the following noun: " + curr_words + ". provide the answer as a semicolon separated string of synonyms with no other information"

    let raw_answer = await make_request_o1_mini(_this,request)

    console.debug(raw_answer)

    return raw_answer



}

export async function short_paragraph_phoneme(_this, topic, number,phoneme)
{

    let request = 'Tell me ' + number + ' short paragraphs about ' + topic + " containing as many words as possible with the phoneme " + phoneme + ". Use british english. Use common simple words. Use vocabulary of a 16 year old. Don't use words with more than 6 letters or non-english words"

    console.debug('request = ' + request)

    let raw_answer = await make_request_o1_mini(_this,request)


    console.debug('raw_answer = ' + raw_answer)

    let list_paragraph = raw_answer.split('\n')

    let regex = []
    
    let regex_1 = new RegExp(/\b(Paragraph\s?|paragraph\s?)(\d+\.?\d*)\s*/,'g') //removing instances of Paragraph 1...
    let regex_2 =  new RegExp(/^:/,'g')
    let regex_3 = new RegExp(/^([0-9]*)(\)|\.|:)\s*/,'g') //removing 1., 1), 1:
    
    regex.push(regex_1)
    regex.push(regex_2)
    regex.push(regex_3)



    let out_facts = []

    for(let n in list_paragraph)
    {

        if(list_paragraph[n].length>15)
        {

            for(let k in regex)
            {
                list_paragraph[n] = list_paragraph[n].replaceAll( regex[k],'');

            }


      

            out_facts.push(list_paragraph[n])    

        }
    }

    return out_facts

}




export async function pub_quiz(_this, topic, number)
{

    let request = 'Quiz with 3 multiple choices and ' + number + ' questions on ' + topic + ". Can the questions be preceded by Q#. Options by A., B., C.. Answers by:answer:. Don't add any header info. Use british english."

    let raw_answer = await make_request_open_ai(_this,request)

    console.debug('raw_answer = ' + raw_answer)

    let list_quiz = raw_answer.split('\n')

    let out_quiz = []

    let iter_counter = 0
    let curr_quiz_question = {}

    for(let n in list_quiz)
    {

        if(list_quiz[n].length>3)
        {
            let start = list_quiz[n].indexOf(' ')

            if(iter_counter==0)
            {
                curr_quiz_question = {question:'',options:[],answer:''}

                curr_quiz_question.question = list_quiz[n].substring(start)

                iter_counter = iter_counter + 1
            }
            else if(iter_counter<4)
            {

                curr_quiz_question.options.push(list_quiz[n].substring(start))

                iter_counter = iter_counter + 1

            }
            else
            {
                let start = list_quiz[n].indexOf('. ')

                curr_quiz_question.answer = list_quiz[n].substring(start+1)

                out_quiz.push(curr_quiz_question)
                iter_counter = 0

            }


        }
    }

    return out_quiz

}


export async function reading_comprehension(_this, topic, age)
{



    let request = 'Create a 3 to 5 sentence paragraph about ' + topic + ' with vocabulary for a ' + age + " year old and 3 multiple choice reading comprehension questions about it. Use british english. Can the paragraph be preceded by P). Can the questions be preceded by new line and Q#. Options by A., B., C.. Answers by:answer:. Don't add any header info."
    
    console.debug('v3')
    let raw_answer = await make_request_open_ai(_this,request)

    console.debug('raw_answer = ' + raw_answer)

    let list_quiz = raw_answer.split('\n')

    console.debug(list_quiz)

    if(list_quiz.length<6)
    {

        
        let separators = ['A.','B.','C.','Q1.','Q2.','Q3.','answer:']
        for(let n in separators)
        {
            let temp_list = []

            for(let k in list_quiz)
            {
                let curr_sentence = list_quiz[k]

                let new_curr_sentence = curr_sentence.split(separators[n])

                for(let m in new_curr_sentence)
                {
                    temp_list.push(new_curr_sentence[m])
                }

            }

            list_quiz = [...temp_list]
        }
        
    }

    let found_paragraph = false

    let paragraph = ''

    for(let k in list_quiz)
    {

        if(list_quiz[k].includes('P)'))
        {
            paragraph = list_quiz[k]
            found_paragraph = true

        }
    }


    let out_quiz = []

    let all_questions = []

    let iter_counter = 0
    let curr_quiz_question = {}

    

    for(let n in list_quiz)
    {

        if(list_quiz[n].length>3)
        {
            let start = list_quiz[n].indexOf(' ')

            if(!found_paragraph)
            {
                paragraph = list_quiz[n]

                found_paragraph = true
            }
            else if(list_quiz[n] != paragraph)
            {
                if(iter_counter==0)
                {

                    let paragraph_2 = paragraph.replace('P)','')


                    curr_quiz_question = {paragraph:paragraph_2,question:'',options:[],answer:'',reading_comprehension_age:age}
    
                    curr_quiz_question.question = list_quiz[n]
    
                    iter_counter = iter_counter + 1
                }
                else if(iter_counter<4)
                {
    
                    curr_quiz_question.options.push(list_quiz[n].substring(start))
    
                    iter_counter = iter_counter + 1
    
                }
                else
                {
                    let start = list_quiz[n].indexOf('. ')
    
                    curr_quiz_question.answer = list_quiz[n].substring(start+1)

                    console.debug('curr_quiz_question = ' + curr_quiz_question.question)
                    console.debug('curr_quiz_question.options = ' + curr_quiz_question.options)
                    console.debug('curr_quiz_question.answer = ' + curr_quiz_question.answer)

    
                    all_questions.push(curr_quiz_question)
                    iter_counter = 0
    
                }
    
    

            }


        }
    }

    out_quiz.push({'all_questions':all_questions})

    return out_quiz

}