<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row">
          <div class="card card-body">
            <h2>
              {{ instructions_text }}
              <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="TTSInstructions()">
            </h2>
            <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->
          </div>
        </div>
        <div class="row" style="height: 15mm;">
          <span :style="interaction_message_color"> {{  interaction_message}}</span> 
        </div>

        <div class="d-flex justify-content-center flex-wrap">
          <button v-for="i in full_text_array.length" :key="i" :class="[btn_class[i-1], 'm-2']" style="margin-left: 2mm;font-size: xx-large;" @click="clickWord(i-1)">
            {{ full_text_array[i-1] }}
          </button>

        </div>
        <div v-if="task_name === 'fill_in'" class="row mt-4 justify-content-center">
          <div class="col-4">
              <button v-if="half_word_url!=null || phoneme_single_target_word.length>1" class="btn btn-secondary btn-lg mt-2" @click="listenBeginningSound()" >Hear its first sound  <i class="bi bi-ear" style="margin-left: 3mm;"></i></button>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"

          :has_show_results="has_results"
          @show_results="goToResults()"   
          >
          <template v-slot:top>
            <hr/>
            <p class="lead" font-size="medium">Task {{Math.min(task,total_task_num)}} of {{total_task_num}}</p>

            <!-- <button v-show="if_recorder && !is_recording" class="btn btn-primary btn-lg w-100 mt-2"  @click="playPause()">Record</button> -->

            <button v-show="is_recording" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
            </button>

            <button v-show="!start_btn_disabled && !is_recording" class="btn btn-primary btn-lg w-100 mt-2"  @click="start()"><strong>{{ start_btn_text }} 
              <i v-bind:class="[start_btn_text==='Start' ? 'bi bi-play-circle' : 'bi bi-arrow-clockwise']" style="margin-left: 8mm;"></i></strong>
            </button>
            <button v-show="start_btn_disabled && !is_recording" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Reading...
            </button>

            <button v-if="task_name !== 'fill_in'" class="btn-info btn btn-lg w-100 mt-4" @click="nextTask()">Next task <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i></button>
            <button v-else class="btn-info btn btn-lg w-100 mt-4" @click="nextTask()">Next exercise <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i></button>

            <!-- <button class="btn btn-secondary btn-lg w-100 mt-2" @click="skipExercise()">Skip level</button> -->
             
            <!-- show results was here-->
          </template>
        </Sidebar>
      </template>
    </Layout>

    <div class="modal fade" id="level_up_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Level up</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="m-3">
              <div class="row">
                <h4 >{{ level_up_message }}</h4>
              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Let's go</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="finished_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Congratulations</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body m-2">
            <div class="row">
              <div class="row">
                <h4 >{{ finished_text }}</h4>

              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100"   data-bs-dismiss="modal" @click="nextExercise()" >Next </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="nothing_heard_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Sorry, we couldn't hear you well</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body m-3">
            <div class="row">
              <div class="row">
                <h4 >Click on Repeat to try again or Next to skip this exercise</h4>

              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100"   data-bs-dismiss="modal" >Back to the exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import { Modal } from 'bootstrap'

import { getUserDataDir, getData, updateDataDirectory} from '@/firebase/index'
import audioImg from '@/assets/images/audio_logo.png'
import { save_pre_computed_results } from '@/utils/results/data_save_utils'
import { logActivity, removePunctuationFromString} from '@/media_input/utils'
import { startConfetti} from '@/common_js/confetti'
import { LevenshteinDistance, LevenshteinDistanceWord_Phonemes, get_phonetic_transcription, LevenshteinDistanceNoLower } from '@/utils/text_distance_metrics'
import SpeechRec from '@/media_input/SpeechRecognition'
import { useTTS } from '@/stores/tts'

  // SLT\Exercises\Reading comprehension\Reading task
  export default {
    name: 'orla',
    props: 
    {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data(){
      return {
        blank_symbol:'_______',
        instructions_text:'',
        finished_text:'Congratulations, you finished the exercise. Click Next',
        has_results:false,
        userCategory:'',
        sentence_time_gap:300,
        levelling_up:false,
        start_btn_text:'Start',
        region:'UK',
        current_exercise_index: 0,
        base_level_address: '/Levels/Common_sayings',
        level_address:'',
        max_level:4,
        total_exercises: 0,
        level_up_message: 'You have been doing very well. Let’s try something harder.',
        total_task_num:2,
        no_hear_message_timeout:null,
        audioImg:audioImg,
        readAndHighlightTimeout: [],
        currentSentence: '',
        speech_rec:null,
        continue_read_and_highlight:false,
        target_words:null,
        is_recording:false,
        results:{correct:0,incorrect:0,total:0},
        first_attempt:true,
        current_level:1,
        current_setup:{},
        current_question_id:0,
        total_question_num:1,
        answer_type:'',
        total_q_a:[],
        if_recorder:true,
        level_2_question_number:0,
        btn_class: [],
        interaction_message_color:'',
        interaction_message:'',
        level_up_counter:0,
        level_up_target:3,
        task:1,
        random_index:0,
        start_btn_disabled:false,
        bv_tts: useTTS().tts_engine,
        full_sentence: 'I am well, thanks',
        full_text_array:[],
        used_rand_id:[], //needs to be remembered/passed from an outer layer
        answer:'',
        half_word_url: null,
        phoneme_single_target_word: '',
        task_name:'',
        sound_hints:false,
        audio_player: null,
      }
    },
    created() {
  
      const _this = this
  
      this.userCategory = localStorage.getItem('userCategory');
      let region = localStorage.getItem('region');





        
      if(region!=undefined && region!=null)
      {
        _this.region = region
      }

      _this.bv_tts.say('')
  

      _this.has_results = _this.exForm.has_results

      logActivity( 'accessed', 'orla')
      
      _this.current_exercise_index = _this.exForm.current_exercise_index + 1
      _this.total_exercises = _this.exForm.total_all_type_exercises

      // this.full_sentence = this.exForm.name
  
      // _this.full_text_array = this.full_sentence.split(' ')
    //   this.setupTask()
        this.loadDifficulty()

    },
    beforeUnmount()
    {
      this.clearAll()


    },
    mounted()
    {

    },
    computed: {
      currentSentence_found: function () {
        const _this = this

        console.debug('this.currentSentence = ' + this.currentSentence)
        if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.is_recording)
        {

            let curr_sentence = this.currentSentence.toString().toLowerCase()

            var regex = /[.,]/g;

            curr_sentence = curr_sentence.replace(regex, '');

            let words = curr_sentence.split(' ')

            console.debug('words = ' + words)

            let copy_target_words = [..._this.target_words]; //reactivity

            let found_something = false


            let convert_words = {'1st':'first','2ns':'second','3rd':'third','qing':'chin','ting':'chin','p':'pea','b':'bee','10':'ten','2':'two','3':'three','4':'four','6':'six','9':'nine'}



            for(let i in words)
            {

                let minimum_distance = 100

                let word = words[i]

                
                if(convert_words[word]!=undefined)
                {
                  word = convert_words[word]
                }

                console.debug('word = ' + word)

                for(let k in copy_target_words)
                {
                  
                  let distance = LevenshteinDistance(copy_target_words[k],word)

                  distance = distance/Math.max(copy_target_words[k].length,1)


                  if(distance<minimum_distance && distance<0.45)
                  {
                      // best_answer = this.target_words[i]
                      // this.stop_recording()
                      // found_something = true

                      const index = _this.target_words.indexOf(copy_target_words[k]);
                      if (true) { // only cut when it is the first word

                        // const display_index = this.full_text_array.indexOf(copy_target_words[k])


                        // this.btn_class[index] = 'correct-button'


                        found_something = true

                        if(_this.target_words.length>0)
                        {

                          _this.target_words.splice(index, 1); // 2nd parameter means remove one item only


                        }



                      }   
                  }
                }
            }

            if(_this.target_words.length==0)
            {
              this.speech_rec.stop()

              this.is_recording = false

              setTimeout(() => {
                this.foundAllWords()
              }, 400);


            }
            return curr_sentence
        }
        return ''
      },
    },
    methods: {
        listenBeginningSound()
        {
            const _this = this
            _this.sound_hints = true

            this.bv_tts.cancel()


            if(_this.half_word_url!=null)
            {
            _this.playSound(_this.half_word_url)
            }
            else
            {
            _this.listenPhoneme()
            }
        },
        listenPhoneme()
        {
            const _this = this
            let phoneme = _this.phoneme_single_target_word[1]

            let simple_representation_phoneme = ''
            for(let i in constants.phonemeDisplayDictionaryIPA)
            {
            if(constants.phonemeDisplayDictionaryIPA[i]==phoneme)
            {
                simple_representation_phoneme = i
                break
            }
            }
            if(simple_representation_phoneme!='')
            {
            let audio_file_location = '/BV/audio_resources/audio_hints/phonemes/'+simple_representation_phoneme+'.mp3'
            console.debug(audio_file_location)
            this.playAudio(audio_file_location)
            }
        },
        get_half_word_url(word)
        {
            let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'

            const _this = this;
            const storage = _this.$firebase.storage();

            storage.ref(audio_file_location).getDownloadURL()
            .then((url) => {
            _this.half_word_url = url
            console.debug('Found half word url!!')
            })
            .catch((error) => {
            console.debug('error ' + error )
            });
        },
        playAudio(audio_file_location)
        {
            const _this = this;
            const storage = _this.$firebase.storage();

            storage.ref(audio_file_location).getDownloadURL()
            .then((url) => {
            _this.playSound(url);
            })
            .catch((error) => {
            console.debug('error ' + error )
            });      
        },
        playSound(url) {
            const _this = this
            if(_this.audio_player==null)
            {
            _this.audio_player = new Audio(url);
            _this.audio_player.play();
            }
            else
            {
            if(!_this.audio_player.paused)
            {
                _this.audio_player.pause();
            }
            _this.audio_player = new Audio(url);
            _this.audio_player.play();
            }
        },
        clearAll()
        {
          this.bv_tts.cancel()

          this.is_recording = false
          this.start_btn_disabled = false

          
          if (this.no_hear_message_timeout) {
              window.clearTimeout(this.no_hear_message_timeout); // will do nothing if no timeout with id is present
          }

          this.continue_read_and_highlight = false

          for(let n in this.readAndHighlightTimeout)
          {
            if (this.readAndHighlightTimeout[n]) {
              window.clearTimeout(this.readAndHighlightTimeout[n]); // will do nothing if no timeout with id is present
            }


          }

        },
        skipExercise()
        {

          this.clearAll()
          this.$emit('nextExercise');
        },
        foundAllWords()
        {
          this.interaction_message = 'Well done, you did it!'
          this.interaction_message_color = 'font-size:xx-large;color:green;white-space: pre-wrap'
          startConfetti(this)
          this.bv_tts.say_sentences(this.interaction_message)
        
        //   console.debug('last word = '+this.target_words)
          this.full_text_array = this.full_sentence.split(' ')
          this.if_recorder = false

          this.results['correct'] += 1
          this.results['total'] += 1

          this.updateLevel(true)


          if(this.levelling_up==false)
          {
            
          setTimeout(() => {
              this.nextTask()
          }, 2400);

          }



        },
        saveResults()
        {
          const _this = this;

          if(_this.results['total']>0)
          {
            let all_results = {Correct:_this.results['correct'],'Not Recognised':_this.results['incorrect'],Total:_this.results['total']}
            
            let plot_results = {Correct:_this.results['correct'],'Not Recognised':_this.results['incorrect']}
            let results = {all_results: all_results, plot_results:plot_results}
            
            var commonSayingsData = {resultsTablePre: {exercise_type: 'Common Sayings', task: _this.task, results: results, audio_address:''}}

            
            save_pre_computed_results(_this.exForm, commonSayingsData)
            _this.$emit('logExerciseResults', commonSayingsData);




          }
          

        },
        nextTask()
        {

          if(this.task_name == 'fill_in' && this.is_recording && this.results['total']==0)
          {
            this.results['total']=1
            this.results['incorrect']=1
            this.updateLevel(false)

          }
          this.removeHighlightedWords()

          this.task = this.task+1

          this.saveResults()

          this.clearAll()
          this.task_execution()
        //   this.setupTask()

        },
        nextExercise()
        {
          const _this = this;
       
          
          this.clearAll()

          _this.$emit('nextExercise');
            
        },
        goToResults()
        {
          const _this = this
          _this.$emit('goToResults', false);
        },
        readAndHighlight(n)
        {
            const _this = this

            _this.readAndHighlightTimeout = []


            if(n<this.full_text_array.length)
            {

                let curr_word = this.full_text_array[n]

                
                _this.btn_class[n] = 'correct-button'
                this.$forceUpdate()


                if(curr_word!=this.blank_symbol)
                {
                  _this.bv_tts.say(curr_word)

                }




                _this.readAndHighlightTimeout.push(setTimeout(function() { 

                  if(_this.continue_read_and_highlight)
                  {
                    _this.readAndHighlight(n+1)

                  }

                },1000) )  
            }
            else
            {
              this.start_btn_disabled = false
            }

        },
      playPause()
      {

        this.speech_rec = new SpeechRec(function(captions) {
            this.currentSentence = captions.original
        }.bind(this))

        this.is_recording = true

        this.speech_rec.start(this.target_words,false,this.region)

        this.no_hear_message_timeout = setTimeout(() => {
          if(this.is_recording)
          {
            this.speech_rec.stop()
            this.is_recording = false

            let nothing_heard_pop = new Modal(document.getElementById('nothing_heard_pop'));
            nothing_heard_pop.show()

          }
        }, 30000);


      },
      updateLevel(correct)
      {

        if(correct)
        {
          this.level_up_counter++
          if(this.level_up_counter == this.level_up_target)
          {
              this.level_up_counter = 0
              this.current_level++

              console.debug('this.current_level = ' + this.current_level)

              if(this.current_level>this.max_level)
              {

                this.current_level = this.max_level
              }
              else
              {
                let level_up_pop = new Modal(document.getElementById('level_up_pop'));
                level_up_pop.show()
                this.levelling_up = true

              }

          }
        }
        else
        {
          this.level_up_counter--

          if(this.level_up_counter<=-3)
          {
            this.current_level=this.current_level-1

            if(this.current_level<1)
            {
              this.current_level = 1

            }
            else
            {
              this.level_up_counter = 0

            }



          }
        }

        updateDataDirectory(this.level_address,{current_level:this.current_level,level_up_counter:this.level_up_counter,used_ex_id:this.used_rand_id})

      },
      start()
      {



        if(this.task_name == 'read_together')
        {

          this.runListenAndRead()
        }
        else if(this.task_name == 'fill_in')
        {
          this.runChoralReading()
        }
      },
      loadDifficulty()
        {

            let _this = this

            let userType = localStorage.getItem('userCategory');


            let uid = localStorage.getItem('uid');

            this.level_address = '/Users/' + uid + this.base_level_address


            if(userType!=undefined || userType==='slp')
            {
                let client_number = localStorage.getItem('client_uid');

                if(client_number!='undefined' && client_number!=null && client_number!='')
                {

                    this.level_address = '/Users/'+ client_number + this.base_level_address

                }
            }

            let address = this.level_address

            let do_once = true

            getData(address, function (err, result) {

                if(do_once)
                {
                    do_once = false
                    
                    let current_level = result.child('current_level').val()
                    let lvl_up_counter = result.child('level_up_counter').val()
                    let used_exercise_id = result.child('used_ex_id').val()

                    if(used_exercise_id==undefined || used_exercise_id==null || used_exercise_id.length<1)
                    {
                        used_exercise_id = [-1]
                    }


                    console.debug('current_level = ' + current_level)

                    console.debug('lvl_up_counter = ' + current_level)
                    console.debug('used_exercise_id = ' + current_level)

                    if(current_level==undefined || lvl_up_counter==undefined || used_exercise_id == undefined)
                    {
                        console.debug('level reload')
                        current_level = 1
                        lvl_up_counter = 0
                        used_exercise_id = []

                        updateDataDirectory(address,{current_level:current_level,level_up_counter:lvl_up_counter,used_ex_id:[-1]})
                    }


                    _this.current_level = current_level
                
                    _this.level_up_counter = lvl_up_counter
                    _this.used_rand_id = used_exercise_id

                    _this.loadSettings()
                }

                
            })
        },
        loadSettings()
        {
            let _this = this
            this.current_question_id = 0;
            let diff_ref = _this.$firebase.database().ref('Exercises').child('Settings').child('Common_sayings')
            var do_once = true;
            getData(diff_ref , function (err, result) {

                if (do_once) 
                {

                    let settings = result.val()
                    // console.debug('start')
                    
                    // console.debug('current level= '+_this.current_level)



                    if(_this.current_level>_this.max_level)
                    {
                        _this.current_level = 1
                    }

                    


                    let pick_level = _this.current_level


                    if(_this.current_level==_this.max_level)
                    {
                      pick_level = Math.floor(Math.random()*_this.max_level)+1
                    }
                    
                    let level_key = 'level'+pick_level

                    console.debug('level loaded= '+level_key)
                    _this.current_setup = settings[level_key]
                    _this.source_from = _this.current_setup.source

                    // console.debug('keys = ' + Object.keys(_this.current_setup))


                    let full_tasks = _this.current_setup['tasks']
                    _this.total_task_num = Object.keys(full_tasks).length
                    
                    do_once = false;
                    
                    _this.initialiser()
                }

            });
        },
        initialiser()
      {
        let _this = this

        _this.if_recorder = false
     
        let data_ref = _this.$firebase.database().ref('Exercises').child('Resources').child('Common_sayings').child(_this.source_from)
        var do_once = true;
        getData(data_ref , function (err, result) {

        if (do_once) 
        {
            // let total_num = result.numChildren()
            let all_data = result.val()
            let all_keys = Object.keys(all_data)
            let total_num = all_keys.length
            let rand_ids = Math.floor(Math.random()*total_num)

            // _this.used_rand_id = -1

            if(!Array.isArray(_this.used_rand_id))
            {
              _this.used_rand_id = [_this.used_rand_id]
            }


            console.debug('_this.used_rand_id = ' + _this.used_rand_id)


            while(_this.used_rand_id.includes(rand_ids))
            {
                rand_ids = Math.floor(Math.random()*total_num)
            }

            if(_this.used_rand_id.length<4)
            {
              _this.used_rand_id.push(rand_ids)
            }
            else
            {
              let temp = []

              for(let k = 0;k<3;++k)
              {
                temp.push(_this.used_rand_id[_this.used_rand_id.length-3+k])

              }

              temp.push(rand_ids)
              _this.used_rand_id = temp

            }

            _this.full_sentence = all_data[all_keys[rand_ids]].sentence

            _this.full_text_array = _this.full_sentence.split(' ')

            console.debug('set up task = '+_this.current_setup.tasks)
            // for(let cnt in _this.current_setup.tasks)
            // {
            //     let task_key = cnt

            //     let task_name = _this.current_setup.tasks[task_key]

            //     _this.task_execution(task)
                
            // }
            

            _this.task_execution()
            
            do_once = false;
            
        }

        });
      },
      task_execution()
      {
        let task_key = 't'+this.task
        this.task_name = this.current_setup.tasks[task_key]

        if(this.task_name == 'read_together')
        {
            this.setupChoralReading()
        }
        else if(this.task_name == 'fill_in')
        {
            this.setupFinalWord()

            let end_id = this.full_text_array.length-1
            this.answer = this.full_text_array[end_id]
            this.get_half_word_url(this.answer)

            this.target_words = [this.answer]

          
            this.full_text_array[end_id] = this.blank_symbol
        }
        else
        {
          this.nextExercise()
        }
      },
      TTSInstructions()
      {
        if(this.task==1)
        {
          this.bv_tts.say_sentences([this.instructions_text,this.full_sentence], this.sentence_time_gap)

        }
        else 
        {
            this.bv_tts.say_sentences(this.instructions_text)
        }

      },
      finishedAllTasks()
      {

        this.bv_tts.say(this.finished_text)

        let finished_pop = new Modal(document.getElementById('finished_pop'));
        finished_pop.show()

      },
      setupListenAndRead()
      {
        this.instructions_text = 'Listen to the sentence.'

        let end_text = 'Press Repeat to hear it again.'

        this.bv_tts.say_sentences([this.instructions_text,this.full_sentence,end_text], this.sentence_time_gap)


        this.start_btn_text = 'Repeat'


      },
      setupFinalWord()
      {
        this.instructions_text = "Click Start. Listen to the sentence and then say the missing word."

        this.bv_tts.say(this.instructions_text)

        this.start_btn_text = 'Start'

      },
      runListenAndRead()
      {
        // this.bv_tts.say(this.full_sentence)
        // this.start_btn_text = 'Repeat'
        const _this = this

        _this.bv_tts.cancel()

        this.removeHighlightedWords()


        _this.continue_read_and_highlight = true

        _this.readAndHighlight(0)
        this.start_btn_text = 'Repeat'

      },
      setupChoralReading()
      {

        // Task 2

        this.instructions_text = "Click Start. Let's read it together."

        this.bv_tts.say(this.instructions_text)


      },
      removeHighlightedWords()
      {
        for(let n in this.full_text_array)
        {
            this.btn_class[n] = ''

        }
        this.$forceUpdate()

      },
      runChoralReading()
      {
        // Task 2

        const _this = this

        this.speech_rec = new SpeechRec(function(captions) {
            this.currentSentence = captions.original
        }.bind(this))

        this.is_recording = true



        this.speech_rec.start(this.target_words,false,this.region)


        this.no_hear_message_timeout = setTimeout(() => {
          if(this.is_recording)
          {
            this.speech_rec.stop()
            this.is_recording = false

            let nothing_heard_pop = new Modal(document.getElementById('nothing_heard_pop'));
            nothing_heard_pop.show()

          }
        }, 30000);



        this.start_btn_disabled = true

        this.bv_tts.cancel()

        this.removeHighlightedWords()

        
   

        _this.continue_read_and_highlight = true

        // _this.bv_tts.say(this.full_sentence)
        _this.readAndHighlight(0)
        // setTimeout(function () {_this.readAndHighlight(0)}, 4000);
        this.start_btn_text = 'Repeat'

      },   
      setupIndependentReading()
      {
         // Task 3

        this.instructions_text = 'Read aloud after me.'

        this.bv_tts.say_sentences([this.instructions_text,this.full_sentence], this.sentence_time_gap)

        this.start_btn_text = 'Repeat'


      },  
      runIndependentReading()
      {
        // task 3
        this.instructions_text = 'Read aloud after me.'

        this.bv_tts.say_sentences([this.instructions_text,this.full_sentence], this.sentence_time_gap)


        this.start_btn_text = 'Repeat'

      },
      setupFindWord()
      {
        // task 4

        this.random_index = Math.floor(Math.random() * this.full_text_array.length);

        this.target_click_word = removePunctuationFromString(this.full_text_array[this.random_index])

        this.instructions_text = 'Click on the following word.'

        let first_text = 'Click on the following word.'

        // let end_text = 'Press Repeat to hear it again.'

        let the_word_is = 'The word is; ' + this.target_click_word


        this.bv_tts.say_sentences([first_text,the_word_is], this.sentence_time_gap)
        this.start_btn_text = 'Repeat'



      },
      runFindWord()
      {
        // task 4


        let first_text = 'Click on the following word. '


        let the_word_is = 'The word is; ' + this.target_click_word


        this.bv_tts.say_sentences([first_text,the_word_is], this.sentence_time_gap)

      },
      setupReadWordAloud()
      {
        // task 5
        
        let prev_index = this.random_index

        let rand_index = Math.floor(Math.random() * this.full_text_array.length);

        if(rand_index==prev_index)
        {
          if(rand_index>0)
          {
            rand_index = rand_index-1
          }
          else
          {
            rand_index = rand_index+1
          }
        }

        this.target_click_word = this.full_text_array[rand_index]

        for(let n in this.btn_class)
        {
          this.btn_class[n] = ''
        }

        this.btn_class[rand_index] = 'correct-button'

        this.$forceUpdate()

        this.instructions_text = 'Say the highlighted word aloud.'

        this.bv_tts.say(this.instructions_text)

        this.target_words = []

        this.target_words.push(removePunctuationFromString(this.full_text_array[rand_index]))

        this.playPause()

      },
      runReadWordAloud()
      {
        this.instructions_text = 'Say the highlighted word aloud.'

        this.bv_tts.say(this.instructions_text)

        this.playPause()
      },
      nextQuestion()
      {
        this.current_question_id++
        this.interaction_message = ''
        this.bv_tts.say(this.total_q_a[this.current_question_id].qs)
        this.$forceUpdate()
      }
      },
   
      
  }
  </script>
  <style scoped>
  
  .custom-text{
      font-size: 20px;
      font-family: Arial;
  }

  .basic-button
  {
    color: black;
    background-color: lightgrey;
    border-color: black;
  }

  .basic-button:hover
  {
    background-color:#002ead;
    transition: 0.7s;

  }

  .basic-wide-button:hover
  {
    background-color:#002ead;
    transition: 0.7s;

  }

  .basic-wide-button
  {
    color: blueviolet;
    background-color: lightgray;
    border-color: black;
    width: 45mm;
  }
  .incorrect-wide-button
  {
    color: white;
    background-color: red;
    border-color: red;
    width: 45mm;
  }

  .correct-button
  {
    color:white;
    background-color: green;
    border-color: green;
  }
  .incorrect-button
  {
    color:white;
    background-color: red;
    border-color: red;
  }
  
  </style>
