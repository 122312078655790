<template>
  <div class="d-flex flex-column min-vh-100">
    <div class="row">
      <div class="col-md-9">

        <div class="card card-body">
          <div class="row">
            <div class="card body">
              <div class="row">
                <span class="details">
                  Guess a {{ word_array.length }} letter word.<br> <b>Hint:</b> {{ hint }}
                </span>  
              </div>
              <div class="row mt-2  mb-2 justify-content-center">
                <button v-for="letter, index in word_array" type="text" class="display-button">
                  {{word_array[index]}}
                </button>
              </div>
              <div class="row">
                <div class="details">
                  <div class="row"> Remaining guesses:<b> {{ maxGuesses }} </b> </div>
                  <div class="row"> Wrong guesses:<b> {{ incorrectLetters_display }} </b> </div>
                </div>
              </div>
            </div>
        
            <div class="row mt-4">
              <div class="row justify-content-center">
                <button class="keyboard-button" @click="inputLetter('Q')" :style="btn_colour[0].btn_color" :disabled="btn_colour[0].btn_disable">Q</button>
                <button class="keyboard-button" @click="inputLetter('W')" :style="btn_colour[1].btn_color" :disabled="btn_colour[1].btn_disable">W</button>
                <button class="keyboard-button" @click="inputLetter('E')" :style="btn_colour[2].btn_color" :disabled="btn_colour[2].btn_disable">E</button>
                <button class="keyboard-button" @click="inputLetter('R')" :style="btn_colour[3].btn_color" :disabled="btn_colour[3].btn_disable">R</button>
                <button class="keyboard-button" @click="inputLetter('T')" :style="btn_colour[4].btn_color" :disabled="btn_colour[4].btn_disable">T</button>
                <button class="keyboard-button" @click="inputLetter('Y')" :style="btn_colour[5].btn_color" :disabled="btn_colour[5].btn_disable">Y</button>
                <button class="keyboard-button" @click="inputLetter('U')" :style="btn_colour[6].btn_color" :disabled="btn_colour[6].btn_disable">U</button>
                <button class="keyboard-button" @click="inputLetter('I')" :style="btn_colour[7].btn_color" :disabled="btn_colour[7].btn_disable">I</button>
                <button class="keyboard-button" @click="inputLetter('O')" :style="btn_colour[8].btn_color" :disabled="btn_colour[8].btn_disable">O</button>
                <button class="keyboard-button" @click="inputLetter('P')" :style="btn_colour[9].btn_color" :disabled="btn_colour[9].btn_disable">P</button>
              </div>
              <div class="row mt-3 justify-content-center">
                <button class="keyboard-button" @click="inputLetter('A')" :style="btn_colour[10].btn_color" :disabled="btn_colour[10].btn_disable">A</button>
                <button class="keyboard-button" @click="inputLetter('S')" :style="btn_colour[11].btn_color" :disabled="btn_colour[11].btn_disable">S</button>
                <button class="keyboard-button" @click="inputLetter('D')" :style="btn_colour[12].btn_color" :disabled="btn_colour[12].btn_disable">D</button>
                <button class="keyboard-button" @click="inputLetter('F')" :style="btn_colour[13].btn_color" :disabled="btn_colour[13].btn_disable">F</button>
                <button class="keyboard-button" @click="inputLetter('G')" :style="btn_colour[14].btn_color" :disabled="btn_colour[14].btn_disable">G</button>
                <button class="keyboard-button" @click="inputLetter('H')" :style="btn_colour[15].btn_color" :disabled="btn_colour[15].btn_disable">H</button>
                <button class="keyboard-button" @click="inputLetter('J')" :style="btn_colour[16].btn_color" :disabled="btn_colour[16].btn_disable">J</button>
                <button class="keyboard-button" @click="inputLetter('K')" :style="btn_colour[17].btn_color" :disabled="btn_colour[17].btn_disable">K</button>
                <button class="keyboard-button" @click="inputLetter('L')" :style="btn_colour[18].btn_color" :disabled="btn_colour[18].btn_disable">L</button>
              </div>
              <div class="row mt-3 justify-content-center">
                <button class="keyboard-button" @click="inputLetter('Z')" :style="btn_colour[19].btn_color" :disabled="btn_colour[19].btn_disable">Z</button>
                <button class="keyboard-button" @click="inputLetter('X')" :style="btn_colour[20].btn_color" :disabled="btn_colour[20].btn_disable">X</button>
                <button class="keyboard-button" @click="inputLetter('C')" :style="btn_colour[21].btn_color" :disabled="btn_colour[21].btn_disable">C</button>
                <button class="keyboard-button" @click="inputLetter('V')" :style="btn_colour[22].btn_color" :disabled="btn_colour[22].btn_disable">V</button>
                <button class="keyboard-button" @click="inputLetter('B')" :style="btn_colour[23].btn_color" :disabled="btn_colour[23].btn_disable">B</button>
                <button class="keyboard-button" @click="inputLetter('N')" :style="btn_colour[24].btn_color" :disabled="btn_colour[24].btn_disable">N</button>
                <button class="keyboard-button" @click="inputLetter('M')" :style="btn_colour[25].btn_color" :disabled="btn_colour[25].btn_disable">M</button>
              </div>
            </div>
            <!-- <button class="reset-btn" @click="randomWord()">Reset Game</button> -->
          </div>
      
          <div class="modal fade" id="result_pop" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{finish_statement}}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="row">
                      <span> {{ finish_detail }}</span>
                    </div>
                    <div class="row mt-2">
                      <button class="btn btn-success w-100"   data-bs-dismiss="modal" >Back to Monthly Prizes</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-body">
          <button v-if="finished_exercise" class="btn-info btn btn-lg w-100 mt-2"  @click="Next()">Next </button>
          <button v-else class="btn-primary btn btn-lg w-100 mt-2"  @click="Next()">Skip </button>
        </div>
      </div>
    </div>
    <Footerbar></Footerbar>
  </div>    
</template>
<script>
  
import { Modal } from 'bootstrap'
import {getTodayString} from '@/media_input/utils'
import {getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import { BV_Points } from '@/utils/bv_points'
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  props: { 
    exForm: {
      type: Object,
    },
  },
  name: 'Word_guessing',
  components: {Footerbar, Navbar},
  data() {
    return {
      db_address:'Exercises/General/Daily_Puzzles/Word_Guessing/',
      word:'',
      word_array:[],
      curr_letter:0,
      maxGuesses:0,
      curr_guesses:0,
      year_month:'',
      incorrectLetters: [], 
      incorrectLetters_display:null,
      answer:'',
      hint:'',
      correctLetters: [],
      finish_statement:'',
      finish_detail:'',
      finished_exercise:false,
      // all_keys:['Q','W','E','R','T','Y','U','I','O','P','A','S','D','F','G','H','J','K','L','Z','X','C','V','B','N','M'],
      btn_colour:[{'letter':'Q','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'W','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'E','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'R','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'T','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'Y','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'U','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'I','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'O','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'P','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'A','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'S','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'D','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'F','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'G','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'H','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'J','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'K','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'L','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'Z','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'X','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'C','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'V','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'B','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'N','btn_color':'background-color:lightgrey;','btn_disable':false},
      {'letter':'M','btn_color':'background-color:lightgrey;','btn_disable':false}],
      bv_points: new BV_Points()
    }
  },
  created() 
  {
    const _this = this
    this.userCategory = localStorage.getItem('userCategory');
    this.initialiseKeys();

    if('type' in this.exForm && this.exForm['type']==='daily_puzzle')
    {
      this.loadDailyPuzzle()
    }
    else
    {
      this.loadWord(this.exForm)
    }

  },
  methods: 
  {
    initialiseKeys()
    {
      for(let n in this.btn_colour)
      {
        this.btn_colour[n].btn_color = 'background-color:lightgrey;cursor:default;'
        this.btn_colour[n].btn_disable = false
      }
      //this.$forceUpdate()
    },
    Next()
    {
      const _this = this

      if(_this.finished_exercise)
      {

        let correct = 0
        let incorrect = 1
        if(_this.correctLetters.length === _this.word.length)
        {
          correct = 1
          incorrect=0

        }
      

        let all_results = {Correct:correct,Incorrect:incorrect, Total:1, Guesses:this.curr_guesses,Word:this.word}
        let plot_results = {Correct:correct,Incorrect:incorrect,Total:1}

        let results = {all_results:all_results, plot_results:plot_results}

        var word_guessing_data = {resultsTablePre: {exercise_type: 'Word Guessing', exercise: 'Word Guessing', results: results, audio_address:''}}


        save_pre_computed_results(_this.exForm,word_guessing_data)

        _this.bv_points.add(10)

        _this.$emit('noBackEndClose', word_guessing_data);
      }



      _this.$emit('nextExercise');
      
    },


    loadWord(curr_word_data)
    {
      const _this = this

      _this.word = curr_word_data.word.toLowerCase();

      console.debug('_this.word = ' + _this.word)
      _this.maxGuesses = _this.word.length >= 5 ? 8 : 6;
      _this.correctLetters = []; 
      _this.incorrectLetters = [];
      _this.word_array = []
      for(let n in _this.word)
      {
        _this.word_array.push('*')
      }
      _this.hint = curr_word_data.hint


    },
    loadDailyPuzzle()
    {
      let _this = this;

      let today_string = getTodayString()

    
      this.year_month = today_string.substring(0,7)

      let word_folder_ref = _this.$firebase.database().ref(_this.db_address).child(_this.year_month);


      getData(word_folder_ref , function (err, result) {
        let curr_word_data = result.val()

        _this.loadWord(curr_word_data)


      })
    },
    inputLetter(letter)
    {
      let _this = this
      if(_this.maxGuesses < 1) {
          this.ExceedGuessesMessage()
          return
      }
      let key = letter.toLowerCase();
      if(key.match(/^[A-Za-z]+$/) && !this.incorrectLetters.includes(` ${key}`) && !this.correctLetters.includes(key)) 
      {
        if(this.word.includes(key)) {
          for(let i in this.word)
          {
            
                if(this.word[i].toLowerCase() == key) 
                {
                    this.correctLetters += key;
                    this.word_array[i] = this.word[i]
                    //this.$forceUpdate()
                }

          }
        } else {
          if(!this.incorrectLetters.includes(key) )
          {
            
            this.maxGuesses--;
            this.curr_guesses+=1
            this.incorrectLetters.push(key);
            if(this.incorrectLetters_display==null)
            {
              this.incorrectLetters_display = key
            }
            else
            {
              this.incorrectLetters_display = this.incorrectLetters_display + ', ' + key ;

            }
            let id_key = this.findKey(letter)
            this.btn_colour[id_key].btn_color = 'background-color:dimgrey;cursor: not-allowed;'
            this.btn_colour[id_key].btn_disable = 'true'
          }
        }
        // guessLeft.innerText = maxGuesses;
        // wrongLetter.innerText = incorrectLetters;
      }

      setTimeout(() => {
          if(_this.correctLetters.length === _this.word.length) {
              // alert(`Congrats! You found the word ${_this.word.toUpperCase()}`);
              // return _this.randomWord();
              this.finished_exercise = true
              this.finish_statement = 'Congrats! You guessed the word: '+_this.word.toUpperCase();
              this.finish_detail = 'One more tile is flipped in your monthly prize board! Keep it up to get your prize!'
              let result_pop = new Modal(document.getElementById('result_pop'));
              result_pop.show()
          } else if(_this.maxGuesses < 1) {
              // alert("Game over! You don't have remaining guesses");
              this.ExceedGuessesMessage()
          }
          
      }, 100);

    },
    findKey(input_letter)
    {
      for(let i in this.btn_colour)
      {
        if(this.btn_colour[i].letter == input_letter)
        {
          return i
        }
        else
        {
          console.debug('ERROR: finding the key')
        }
      }

    },
    ExceedGuessesMessage()
    {
      this.finished_exercise = true
      this.finish_statement = 'Game over! You do not have remaining guesses';
      this.finish_detail = "Don't worry, you can challenge it again!"
      for(let i = 0; i < this.word.length; i++) {
      }
      let result_pop = new Modal(document.getElementById('result_pop'));
      result_pop.show()


    }
  }
}
</script>
<style scoped>
.custom-button-BV
{
    width: 130px;
    height: 130px;
    border-radius: 100px;
}
li:hover {
  background-color: #e0e0e2;
  color: white;
}
.keyboard-button
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
}
.display-button
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 1mm;
  background-color: white;
  border-color: blueviolet;
}
.details
{
  font-size: 24px;
}
</style>