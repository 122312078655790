<template>      
  <div class="d-flex flex-column min-vh-100">
    <Navbar :enableBack="true"></Navbar>
      <div class="container pt-4 mb-5">
        <div class="row">
          <h1 class="display-4">{{ name }}</h1>
        </div>
        <div class="row">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Licences assigned</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, key, index) in members" v-bind:key="index">
                <th scope="row">{{index + 1}}</th>
                <td>{{member['firstname']}} {{member['surname']}}</td>
                <td>{{member['email']}}</td>
                <td>{{member['assigned_premium_licenses'] + member['assigned_plain_licenses']}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    <Footerbar class="mt-auto"></Footerbar>
  </div>
</template>
<script>


import {getFirestoreDoc} from '@/firebase/index'
import { Modal } from 'bootstrap'

import Footerbar from '../../Footerbar.vue';
import Navbar from '../../Navbar.vue';

export default {
  components: { Navbar, Footerbar },
  name: 'manage_organisation',
  props:  {
    name: {
      type: String,
      default: '',
      Required: true
    }
  },
  data(){
    return {
      userCategory: localStorage.getItem('userCategory'),
      region: localStorage.getItem('region'),
      members: []
    }
  },
  async created() {
    
    let data = await getFirestoreDoc('organisations', this.name.toLowerCase())
    if (data) {
      this.members = data.members
    }

  },
  mounted() {
    

  },
  methods: {
    
  }   

}
</script>