<template>
  <div>
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
        <div class="row mt-2">
      <div class="card card-body"  style="border-color: blueviolet;">
        <div class="accordion" id="accordionFDA">
          <div class="accordion-item mb-3">
            <div class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fda_collapse" aria-expanded="false" aria-controls="fda_collapse"><p class="text-start" >
                <b>FDA</b></p></button>
            </div>
            <div :class="['accordion-collapse', 'collapse', show_collapse ? 'show': '']" id="fda_collapse" data-bs-parent="#accordionFDA">

              <div class="row mb-2">

                <div v-for="curr_fda, i of table">
                <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

                  <p class="text-start" v-if="'Questionnaire_Name' in table[i]" ><b>{{table[i]['Questionnaire_Name']}} {{ i +1}} </b></p>
                  <p class="text-start" v-else ><b>FDA {{ i +1}} </b></p>

                  
                  <div class="col">
                    
                  <div class="row mt-2 mb-4" style="text-align: left;">
                    <Single_fda
                          :curr_fda="curr_fda">
                        </Single_fda>
                      
                    </div>
                  </div>

                </div>
                </div>

         </div>
        </div>
      </div>
    </div>
    </div>
    </section>
  </div>
</template>


<script>
import Single_fda from './single_fda.vue';


export default {
  components: { 
    Single_fda
  },
  name: 'fda',
  props: ['table', 'show_collapse'],
  setup(props, { emit }) {
    
    return {
      
    }

  }
}

</script>
<style scoped>


</style>





