<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div v-if="game_setting === 'find'">
          <div class="card card-body">
            <h4>
              {{ instructions['find'] }}
              <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(instructions['find'])">

            </h4>
            <h4 style="color: blueviolet;font-size: xx-large;">
              {{ category_display }}
              <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(category_display)"><br> 
            </h4>
          </div>

          <div class="row">
            <p class="h5" :style="interaction_message_color"> {{  interaction_message}}</p> 
          </div>
          <div class="row m-2 justify-content-center">
            <div class="col-3" v-for="j in Math.min(num_option,all_images.length)" :key = j>
              <div class="card card-body mt-2" v-bind:style="{'margin':'auto','background':all_images[j-1].frameColour}" :body-style="{ padding: '5px' }">
                <img  v-bind:src="all_images[j-1].img_display" @click="selectImg(j-1)" height="250" style="object-fit: cover;" >   
                <span style="font-size: x-large;">
                  {{all_images[j-1].word}}
                  <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(all_images[j-1].word)">
                </span>     
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="game_setting === 'add'">
          <div class="card card-body">
            <h4>
              {{ instructions['add'] }} 
              <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(instructions['add'])">

            </h4>
          <div class="row m-2 justify-content-center">
            <div class="col-3" v-for="j in Math.min(num_show_category,show_category.length)" :key = j>
              <div class="card card-body mt-2" v-bind:style="{'margin':'auto','background':show_category[j-1].frameColour}" :body-style="{ padding: '5px' }">
                <img  v-bind:src="show_category[j-1].img_display"  height="250" style="object-fit: cover;" >   
                <span style="font-size: x-large;">
                  {{show_category[j-1].word}}
                  <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(show_category[j-1].word)">
              </span>     
              </div>
            </div>
          </div>

          </div>
          <hr class="mt-4" style="border-width: 5px; color:blueviolet"/>
          <div class="card card-body">

          <div class="row">
            <p class="h5" :style="interaction_message_color">{{interaction_message}}</p> 
          </div>
          <div class="row m-2 justify-content-center">
            <div class="col-3" v-for="j in Math.min(num_option,all_images.length)" :key = j>
              <div class="card card-body mt-2" v-bind:style="{'margin':'auto','background':all_images[j-1].frameColour}" :body-style="{ padding: '5px' }">
                <img  v-bind:src="all_images[j-1].img_display" @click="selectImg(j-1)" height="250" style="object-fit: cover;" >   
                <span style="font-size: x-large;">
                  {{all_images[j-1].word}}
                  <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(all_images[j-1].word)">
              </span>     
              </div>
            </div>
          </div>
          </div>
        </div>
        <div v-else-if="game_setting === 'exclude'">
          <div class="card card-body">
            <h4>
              Click on the picture that <span style="color: blueviolet;">doesn't </span>belong to the same category   
              <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(instructions['exclude'])">

            </h4>
          </div>
          <div class="row">
              <p class="h5" :style="interaction_message_color"> {{  interaction_message}}</p> 
          </div>
          <div class="row m-2 justify-content-center">
            <div class="col-3" v-for="l in Math.min(num_mix_option, mix_category_img.length)" :key = l>
              <div class="card card-body mt-2" v-bind:style="{'margin':'auto','background':mix_category_img[l-1].frameColour}" >
                <img  v-bind:src="mix_category_img[l-1].img_display" @click="selectDifferentImg(l-1)" height="250" style="object-fit: cover;" >    
                <span style="font-size: x-large;">
                  {{  mix_category_img[l-1].word}}
                  <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(mix_category_img[l-1].word)">                  
                </span>  
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
  				@next="nextExercise()"

          :has_show_results="has_results"
          @show_results="goToResults()"
          >

        </Sidebar>
      </template>
    </Layout>        
  </div>    
</template>
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'

import { Modal } from 'bootstrap'
import {startConfetti} from '@/common_js/confetti'
import audioImg from '@/assets/images/audio_logo.png'
import questionImg from '@/assets/images/question_square.png'
import {getData, getUserDataDir, getUserData } from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import { useTTS } from '@/stores/tts'
/*


{
   "exercise_type":"word_category",
   "instruct":"",
   "name":"Word Category - All",
   "setting":"all",
   "plan_name":"Word Category",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Word Category/3",
   "ex_path":"Word Category/3",
   "today":"2023-07-11 17:18",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"word_category",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"",
   "module_name":"Word Category",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
  export default {
    name: 'word_image',
    props: {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data() {
      return {
        instructions:{"find":"Click on the picture that belongs to the category:",
        "add":"Click on the picture that belongs to the same category as the top pictures:",
        "exclude":"Click on the picture that does not belong to the same category:"
        },
        current_exercise_index:1,
        total_exercises:1,
        audioImg:audioImg,
        questionImg:questionImg,
        interaction_message_color:'',
        results:{correct:0,incorrect:0,total:0},
        interaction_message:'',
        category_pool:[{'db_name':'Occupations','display':'Occupation'},
        {'db_name':'Venues','display':'Places'},
        {'db_name':'House Places','display':'Home locations'},
        {'db_name':'Home Items','display':'Objects inside home'},
        {'db_name':'Body Parts','display':'Body parts'},
        {'db_name':'Animals','display':'Animals'}],
        category_display:'',
        num_option:4,
        target_category:[],//0:question, rest: other answers
        all_images:[],
        num_getData:0,
        frameColour:[],
        game_setting:'find',//'find' - find item that fits into the category; 'add' - add one item to the same category,'exclude' - click on the item doesnt belong
        num_show_category:3,
        show_category:[],
        mix_category:[],
        num_mix_option:4,
        num_different:1,
        mix_category_img:[],
        flag_accessdata:[],
        rand_category:[],
        all_data_by_category:[],
        has_results: false,
        bv_tts: useTTS().tts_engine,
      }
    },
    created() 
    {
      const _this = this
      this.userCategory = localStorage.getItem('userCategory');

      _this.current_exercise_index = _this.exForm.current_exercise_index + 1
      _this.total_exercises = _this.exForm.total_all_type_exercises


      _this.has_results = _this.exForm.has_results

      let setting = _this.exForm['setting']

      if(setting!=undefined && setting!=null)
      {
        if(setting==='add')
        {
          this.game_setting='add'
          console.debug('add')
        }
        else if(setting==='exclude')
        {
          this.game_setting='exclude'
          console.debug('exclude')
        }
        else if(setting==='find')
        {
          this.game_setting='find'
          console.debug('find')
        }
        else
        {
          this.randomise_setting()
        }
      }
      else
      {
        this.randomise_setting()
      }

    //   this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];
    //   this.set_up()
    //   this.checkPreviousTopResult()

    },
    beforeUnmount()
    {
        
      const _this = this
      this.bv_tts.cancel()


      clearInterval(window.refreshIntervalId)
      window.speechSynthesis.cancel()

    },
    mounted()
    {
      this.set_up()
    },
    methods: 
    {
        randomise_setting()
        {
            let rand_setting_id = Math.floor(Math.random()*3)

            let game_settings = ['add','find','exclude']

            this.game_setting = game_settings[rand_setting_id]

            console.debug('random')


        },
        set_up()
        {
            let _this = this;
            let temp_category = [..._this.category_pool]
            _this.rand_category = _this.randSequence(temp_category)

            // console.debug('rand_category = ' + _this.rand_category)
            _this.category_display = _this.rand_category[0].display
            // console.debug('_this.category_display = ' + _this.category_display)

            _this.target_category = []
            _this.show_category = []
            _this.mix_category = []
            _this.mix_category_img = []
            _this.all_images = []
            _this.num_getData = 0
            for(let i=0;i<this.num_option;i++)
            {
                _this.target_category.push({})
                _this.flag_accessdata.push(false)
            }

            this.load_data_all()
            
            
        },
        async load_data_all()
        {

            const promiseArray = []

            for(let k=0;k<this.num_option;k++)
            {

                promiseArray.push(this.load_data_single(k))

            }

            this.all_data_by_category = []

            const all_results = await Promise.all(promiseArray)


            for(let i in all_results)
            {
                this.all_data_by_category.push(all_results[i])
                // console.debug(all_results[i])
            }

            if(this.game_setting==='add')
            {

                this.prepare_game_add()

            }
            else if(this.game_setting==='exclude')
            {
                this.prepare_game_exclude()

            }
            else
            {
                this.prepare_game_find()

            }

            if(this.game_setting==='find')
            {
              let category_tts = this.category_display
              this.bv_tts.say_sentences([this.instructions[this.game_setting], category_tts])

            }
            else
            {
              this.bv_tts.say(this.instructions[this.game_setting])

            }


        },
        async load_data_single(ele_id)
        {
            const _this = this

            let data_ref = _this.$firebase.database().ref('Exercises').child('Resources').child('Word_category').child(_this.rand_category[ele_id].db_name)


            let data = new Promise(function(resolve)
            {
                getData(data_ref , function (err, result) {

                    let data = result.val()

                    resolve(data)

                })
            })

            return  data

        },
        prepare_game_add()
        {
            const _this = this

            let base_data = _this.all_data_by_category[0]

            let rand_ids = []

            let counter = 0

            for(let n in base_data)
            {
                rand_ids.push(counter)
                counter = counter+1
            }
            let rand_words_id = _this.randSequence(rand_ids)

   
            // console.debug('rand_words_id = ' + rand_words_id)

            let keys = Object.keys(base_data)
            // console.debug('keys = ' + keys)
            let target_key = rand_words_id[0]
            _this.target_category[0] = base_data[keys[target_key]]

            for(let m = 0; m<_this.num_show_category;m++)
            {
                let show_id = rand_words_id[m+1]
                console.debug(show_id)
                var storageRef = _this.$firebase.storage().ref();

                // console.debug('base_data = ' + base_data)
                // console.debug('base_data[keys] = ' + base_data[keys])
                // console.debug('base_data[keys[show_id]] = ' + base_data[keys[show_id]])

                storageRef.child(base_data[keys[show_id]].image_address[0]).getDownloadURL().then(function(url) {
                                _this.show_category.push({'word':base_data[keys[show_id]].word,'img_display':url})
                            }).catch(function(error) {
                                console.debug(' err show category img = ' + base_data[keys[show_id]].image_address[0])

                });
            }

            for(let n=1;n<_this.all_data_by_category.length;++n)
            {
                let curr_data = _this.all_data_by_category[n]

                let rand_ids = Math.floor(Math.random()*Object.keys(curr_data).length)

                let keys = Object.keys(curr_data)
                _this.target_category[n] = curr_data[keys[rand_ids]]

            }

            let rand_img_add = _this.randSequence(_this.target_category)
            for(let kk = 0;kk<_this.num_option;kk++)
            {
                var storageRef = _this.$firebase.storage().ref();

                // console.debug('rand_img_add[kk] = ' + rand_img_add[kk])
                
                // console.debug('rand_img_add[kk].image_address[0] = ' + rand_img_add[kk].image_address[0])

                storageRef.child(rand_img_add[kk].image_address[0]).getDownloadURL().then(function(url) {
                    // console.debug('!image_address = ' + rand_img_add[kk].image_address[0])
                                _this.all_images.push({'word':rand_img_add[kk].word,'img_display':url,'frameColour':'#FFFFFF'})
                            }).catch(function(error) {
                                console.debug(' err image_address = ' + rand_img_add[kk].image_address[0])

                });
            }

        },
        prepare_game_exclude()
        {

            const _this = this

            let rand_ids = []
            let base_data = _this.all_data_by_category[0]
            
            let keys = Object.keys(base_data)

            for(let nn=0;nn<keys.length;nn++)
            {
                rand_ids.push(nn)
            }
            // console.debug('rand_ids = ' + rand_ids)
            let rand_words_id = _this.randSequence(rand_ids)



            let target_key = rand_words_id[0]
            _this.target_category[0] = base_data[keys[target_key]]

            // console.debug('rand_words_id = ' + rand_words_id)

            // console.debug('base_data = ' + base_data)
            // console.debug('keys = ' + keys)

            for(let mm = 0; mm<(_this.num_mix_option-_this.num_different);mm++)
            {
                let show_id = rand_words_id[mm+1]
                // console.debug('mm = ' + mm)
                // console.debug('show_id = ' + show_id)
                // console.debug('keys[show_id] = ' + keys[show_id])

                // console.debug('a[keys[show_id]] = ' + base_data[keys[show_id]])
                _this.mix_category.push(base_data[keys[show_id]])

            }

            for(let n=1;n<_this.all_data_by_category.length;++n)
            {
                let curr_data = _this.all_data_by_category[n]

                let rand_ids = Math.floor(Math.random()*Object.keys(curr_data).length)

                let keys = Object.keys(curr_data)
                _this.target_category[n] = curr_data[keys[rand_ids]]

            }

            console.debug('exclude!')
            _this.mix_category.push(_this.target_category[1])
            // console.debug('_this.mix_category = ' + _this.mix_category)
            let rand_img_add = _this.randSequence(_this.mix_category)
            // console.debug('rand_img_add = ' + rand_img_add)
            // console.debug('mix array size= '+rand_img_add.length)
            // console.debug('_this.num_mix_option = ' + _this.num_mix_option)
            for(let gg = 0;gg<_this.num_mix_option;gg++)
            {
                var storageRef = _this.$firebase.storage().ref();
                // console.debug('rand_img_add['+gg+'] = ' + rand_img_add[gg])
                storageRef.child(rand_img_add[gg].image_address[0]).getDownloadURL().then(function(url) {
                    // console.debug('mix mage_address = ' + rand_img_add[gg].image_address[0])
                                _this.mix_category_img.push({'word':rand_img_add[gg].word,'img_display':url,'frameColour':'#FFFFFF'})
                            }).catch(function(error) {
                                console.debug(' mix err image_address = ' + rand_img_add[gg].image_address[0])

                });
            }




        },
        prepare_game_find()
        {

            const _this = this

            for(let n=0;n<_this.all_data_by_category.length;++n)
            {
                let curr_data = _this.all_data_by_category[n]

                let rand_ids = Math.floor(Math.random()*Object.keys(curr_data).length)

                let keys = Object.keys(curr_data)
                _this.target_category[n] = curr_data[keys[rand_ids]]

            }

            let rand_img_add = _this.randSequence(_this.target_category)
            for(let kk = 0;kk<_this.num_option;kk++)
            {
                var storageRef = _this.$firebase.storage().ref();

                // console.debug('rand_img_add[kk] = ' + rand_img_add[kk])
                
                // console.debug('rand_img_add[kk].image_address[0] = ' + rand_img_add[kk].image_address[0])

                storageRef.child(rand_img_add[kk].image_address[0]).getDownloadURL().then(function(url) {
                    // console.debug('!image_address = ' + rand_img_add[kk].image_address[0])
                                _this.all_images.push({'word':rand_img_add[kk].word,'img_display':url,'frameColour':'#FFFFFF'})
                            }).catch(function(error) {
                                console.debug(' err image_address = ' + rand_img_add[kk].image_address[0])

                });
            }


        },


        getQ_A(data_ref,ele_id)
        {
            let _this = this
            var do_once = true;
            getData(data_ref , function (err, result) {

            if (do_once) 
            {
                let total_num = result.numChildren()

                let a = result.val()

                if((ele_id == 0) && (_this.game_setting == 'add'))
                {
                    let rand_ids = []

                    for(let nn=0;nn<total_num;nn++)
                    {
                        rand_ids.push(nn)
                    }
                    let rand_words_id = _this.randSequence(rand_ids)

                    let keys = Object.keys(a)
                    let target_key = rand_words_id[0]
                    _this.target_category[ele_id] = a[keys[target_key]]

                    for(let mm = 0; mm<_this.num_show_category;mm++)
                    {
                        let show_id = rand_words_id[mm+1]
                        var storageRef = _this.$firebase.storage().ref();
                        storageRef.child(a[keys[show_id]].image_address[0]).getDownloadURL().then(function(url) {
                                        _this.show_category.push({'word':a[keys[show_id]].word,'img_display':url})
                                    }).catch(function(error) {
                                        console.debug(' err show category img = ' + a[keys[show_id]].image_address[0])

                        });
                    }
                }
                else if((ele_id == 0) && (_this.game_setting == 'exclude'))
                {
                    let rand_ids = []

                    for(let nn=0;nn<total_num;nn++)
                    {
                        rand_ids.push(nn)
                    }
                    let rand_words_id = _this.randSequence(rand_ids)

                    let keys = Object.keys(a)
                    let target_key = rand_words_id[0]
                    _this.target_category[ele_id] = a[keys[target_key]]

                    console.debug('rand_words_id = ' + rand_words_id)

                    console.debug('a = ' + a)
                    console.debug('keys = ' + keys)

                    for(let mm = 0; mm<(_this.num_mix_option-_this.num_different);mm++)
                    {
                        let show_id = rand_words_id[mm+1]
                        console.debug('mm = ' + mm)
                        console.debug('show_id = ' + show_id)
                        console.debug('keys[show_id] = ' + keys[show_id])

                        console.debug('a[keys[show_id]] = ' + a[keys[show_id]])
                        _this.mix_category.push(a[keys[show_id]])


                        // var storageRef = _this.$firebase.storage().ref();
                        // storageRef.child(a[keys[show_id]].image_address[0]).getDownloadURL().then(function(url) {
                        //                 _this.show_category.push({'word':a[keys[show_id]].word,'img_display':url})
                        //             }).catch(function(error) {
                        //                 console.debug(' err show category img = ' + a[keys[show_id]].image_address[0])

                        // });
                    }
                }
                else
                {
                    let rand_ids = Math.floor(Math.random()*total_num)

                    let keys = Object.keys(a)
                    _this.target_category[ele_id] = a[keys[rand_ids]]
                }
                

                
                console.debug('image add = '+_this.target_category[ele_id].image_address)
                console.debug('ele_id = ' + ele_id)
                
                
                _this.flag_accessdata[ele_id] = true
               
                // console.debug('num_getData '+ ele_id+ ' = '+_this.num_getData)
                // if(_this.num_getData == _this.num_option) //if all options data are fetched
                if(!_this.flag_accessdata.includes[false]) //if all options data are fetched
                {
                    if(_this.game_setting == 'exclude')
                    {
                        console.debug('exclude!')
                        _this.mix_category.push(_this.target_category[1])
                        console.debug('_this.mix_category = ' + _this.mix_category)
                        let rand_img_add = _this.randSequence(_this.mix_category)
                        console.debug('rand_img_add = ' + rand_img_add)
                        console.debug('mix array size= '+rand_img_add.length)
                        console.debug('_this.num_mix_option = ' + _this.num_mix_option)
                        for(let gg = 0;gg<_this.num_mix_option;gg++)
                        {
                            var storageRef = _this.$firebase.storage().ref();
                            console.debug('rand_img_add['+gg+'] = ' + rand_img_add[gg])
                            storageRef.child(rand_img_add[gg].image_address[0]).getDownloadURL().then(function(url) {
                                console.debug('mix mage_address = ' + rand_img_add[gg].image_address[0])
                                            _this.mix_category_img.push({'word':rand_img_add[gg].word,'img_display':url,'frameColour':'#FFFFFF'})
                                        }).catch(function(error) {
                                            console.debug(' mix err image_address = ' + rand_img_add[gg].image_address[0])

                            });
                        }
                    }
                    else
                    {
                        let rand_img_add = _this.randSequence(_this.target_category)
                        for(let kk = 0;kk<_this.num_option;kk++)
                        {
                            var storageRef = _this.$firebase.storage().ref();

                            console.debug('rand_img_add[kk] = ' + rand_img_add[kk])
                            
                            console.debug('rand_img_add[kk].image_address[0] = ' + rand_img_add[kk].image_address[0])

                            storageRef.child(rand_img_add[kk].image_address[0]).getDownloadURL().then(function(url) {
                                // console.debug('!image_address = ' + rand_img_add[kk].image_address[0])
                                            _this.all_images.push({'word':rand_img_add[kk].word,'img_display':url,'frameColour':'#FFFFFF'})
                                        }).catch(function(error) {
                                            console.debug(' err image_address = ' + rand_img_add[kk].image_address[0])

                            });
                        }
                    }
                    
                    
                }
               
                do_once = false;
                
            }

            });
        },
        randSequence(arr)
        {
            let new_arr = []
            let cut_arr = [...arr]
            while(cut_arr.length>1)
            {
                let orig_arr = [...cut_arr]
                let rand_id = Math.floor(Math.random()*orig_arr.length)
                new_arr.push(orig_arr[rand_id])
                cut_arr = []
                cut_arr = this.deleteElement(orig_arr,rand_id)
            }
            new_arr.push(cut_arr[0])

            return new_arr
        },
        deleteElement(arr,ele_id)
        {
            let new_arr = []
            for(let i=0;i<arr.length;i++)
            {
                if(i != ele_id)
                {
                    new_arr.push(arr[i])
                }
            }
            return new_arr
        },
        selectImg(ele_id)
        {
            let word_select = this.all_images[ele_id].word
            console.debug('word clicked= '+word_select)
            if(word_select == this.target_category[0].word)
            {
                this.all_images[ele_id].frameColour = '#00FF00'
                this.interaction_message = 'Correct!'
                this.interaction_message_color = 'fontSize:x-large;color:green;white-space: pre-wrap'

                this.bv_tts.system_say("Good job!")

                if(this.results.total==0)
                {
                    this.results.total=1
                    this.results.correct=1
                }
                startConfetti(this)
                setTimeout(() => {
                    this.nextExercise()
                    
                }, 1500);
            }
            else
            {
                if(this.results.total==0)
                {
                    this.results.total=1
                    this.results.incorrect=1
                }
                this.bv_tts.system_say("It's a tricky one. Try again!")

                this.all_images[ele_id].frameColour = '#FF0000'
                this.interaction_message = "It's a tricky one. Try again!"
                this.interaction_message_color = 'fontSize:x-large;color:red;white-space: pre-wrap'
            }
            //this.$forceUpdate()
        },
        nextExercise()
        {
          const _this = this;
          
          let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect'],Total:_this.results['total']}
          
          let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect']}
          let results = {all_results: all_results, plot_results:plot_results}

          var wordCategoryData = {resultsTablePre: {exercise_type: 'Word Category', exercise: this.game_setting, results: results, audio_address:''}}
          
          _this.saveResults(wordCategoryData)
          
          _this.$emit('logExerciseResults', wordCategoryData);
          
          _this.$emit('nextExercise');
            
        },
        goToResults()
        {
          const _this = this
          
          let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect'],Total:_this.results['total']}
          
          let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect']}
          let results = {all_results:all_results, plot_results:plot_results}
          
          var wordCategoryData = {resultsTablePre: {exercise_type: 'Word Category', exercise: this.game_setting, results: results, audio_address:''}}
          
          _this.saveResults(wordCategoryData)
          _this.$emit('logExerciseResults', wordCategoryData);
          
          _this.$emit('goToResults', false);
        },
        saveResults(wordCategoryData)
        {
          const _this = this
          save_pre_computed_results(_this.exForm, wordCategoryData)
        },
        selectDifferentImg(ele_id)
        {
          let word_select = this.mix_category_img[ele_id].word
          console.debug('word clicked= '+word_select)
          if(word_select == this.target_category[1].word)
          {
              this.mix_category_img[ele_id].frameColour = '#00FF00'
              this.interaction_message = 'Correct!'
              this.interaction_message_color = 'fontSize:x-large;color:green;white-space: pre-wrap'

              if(this.results.total==0)
              {
                  this.results.total=1
                  this.results.correct=1
              }

              this.bv_tts.system_say("That's correct!")

              startConfetti(this)
              setTimeout(() => {
                  this.nextExercise()
                  
              }, 1500);
          }
          else
          {
            if(this.results.total==0)
            {
                this.results.total=1
                this.results.incorrect=1
            }
            this.bv_tts.system_say("It's a tricky one. Try again!")

            this.mix_category_img[ele_id].frameColour = '#FF0000'
            this.interaction_message = "It's a tricky one. Try again!"
            this.interaction_message_color = 'fontSize:x-large;color:red;white-space: pre-wrap'
          }
          //this.$forceUpdate()
        },
        updateSetting()
        {
          console.debug('setting is '+this.game_setting)
          this.set_up()
        }
    }
  }
  </script>
  <style scoped>
  .custom-button-BV
  {
      width: 130px;
      height: 130px;
      border-radius: 100px;
  }
  li:hover {
    background-color: #e0e0e2;
    color: white;
  }
  .keyboard-button
  {
    width: 20mm;
    height: 20mm;
    font-size: xx-large;
    margin-left: 5mm;
  }
  .maths-button
  {
    width: 50mm;
    height: 25mm;
    font-size: xx-large;
    margin-left: 1mm;
    background-color: white;
    border-color: blueviolet;
  }
  .details
  {
    font-size: 24px;
  }

  
.tile-container {
  display: grid;
  grid-template-rows: 180px 180px;
  grid-template-columns: 180px 180px;
  grid-gap: 25px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;
}

.unclickable {
  pointer-events: none;
}

.tile-red {
  background-color: #f25022;
  box-shadow: 0 0 0 1px #f25022 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #c93a12, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-red:hover,
.tile-red:focus {
  background-color: #ff451a;
  box-shadow: 0 0 0 1px #FF3000 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #e62b00, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-red:active,
.tile-red.activated {
  box-shadow: 0 0 0 1px #ff5c36 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #ff5c36;
  transform: translateY(10px);
}

.tile-magenta {
  background-color: #FB196C;
  box-shadow: 0 0 0 1px #FB196C inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #A4023E, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-magenta:hover,
.tile-magenta:focus {
  background-color: #D41159;
  box-shadow: 0 0 0 1px #A0023C inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #D80151, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-magenta:active,
.tile-magenta.activated {
  box-shadow: 0 0 0 1px #fc689e inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #fc689e;
  transform: translateY(10px);
}
.tile-green {
  background-color: #7fba00;
  box-shadow: 0 0 0 1px #7fba00 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #638f05, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-green:hover,
.tile-green:focus {
  background-color: #8cc218;
  box-shadow: 0 0 0 1px #8cc218 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #74a802, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6)
}

.tile-green:active,
.tile-green.activated {
  box-shadow: 0 0 0 1px #a0ea00 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #a0ea00;
  transform: translateY(10px);
}

.tile-teal {
  background-color: #64F3DB;
  box-shadow: 0 0 0 1px #64F3DB inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #0DA48B, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-teal:hover,
.tile-teal:focus {
  background-color: #17c6a8;
  box-shadow: 0 0 0 1px #17c6a8 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #02BFA0, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6)
}

.tile-teal:active,
.tile-teal.activated {
  box-shadow: 0 0 0 1px #04E8C2 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #04E8C2;
  transform: translateY(10px);
}
.tile-yellow {
  background-color: #ffb900;
  box-shadow: 0 0 0 1px #ffb900 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #c28e07, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-yellow:hover,
.tile-yellow:focus {
  background-color: #ffb900;
  box-shadow: 0 0 0 1px #ffb900 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #c28e07, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.tile-yellow:active,
.tile-yellow.activated {
  box-shadow: 0 0 0 1px #ffca3d inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #ffca3d;
  transform: translateY(10px);
}

.tile-orange {
  background-color: #FFB000;
  box-shadow: 0 0 0 1px #FFB000 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #966802, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-orange:hover,
.tile-orange:focus {
  background-color: #af7801;
  box-shadow: 0 0 0 1px #af7801 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #c28e07, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.tile-orange:active,
.tile-orange.activated {
  box-shadow: 0 0 0 1px #fbc550 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #fbc550;
  transform: translateY(10px);
}
.tile-blue {
  background-color: #00a4ef;
  box-shadow: 0 0 0 1px #00a4ef inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #0883bc, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-blue:hover,
.tile-blue:focus {
  background-color: #139ddd;
  box-shadow: 0 0 0 1px #139ddd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #0c7cb0, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-blue:active,
.tile-blue.activated {
  box-shadow: 0 0 0 1px #2bbcff inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #2bbcff;
  transform: translateY(10px);
}

.tile-blue-cb {
  background-color: #0C7BDC;
  box-shadow: 0 0 0 1px #0C7BDC inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #155B98, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-blue:hover,
.tile-blue:focus {
  background-color: #025EAF;
  box-shadow: 0 0 0 1px #025EAF inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #0c7cb0, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-blue:active,
.tile-blue.activated {
  box-shadow: 0 0 0 1px #69B7FB inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #69B7FB;
  transform: translateY(10px);
}

</style>

<!-- Results Description
{
  "Word Category": {
    "Exercises": { //Need to remove this layer
      "General": { //Need to remove this layer
        "Aphasia": { //Need to remove this layer
          "WordCategory": { //Need to remove this layer
            "<key>": {
              "audio_address": "<empty>",
              "resultsTablePre": {
                "audio_address": "<empty>",
                "exercise": "<type of word category exercise>",
                "exercise_type": "Word Category",
                "results": {
                  "all_results": {
                    "Correct": "<int>",
                    "Incorrect": "<int>",
                    "Total": "<int>"
                  },
                  "plot_results": {
                    "Correct": "<int>",
                    "Incorrect": "<int>"
                  }
                }
              },
              "results_type": "pre_computed"
            }
          }
        }
      }
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Word Category"
  }
} -->