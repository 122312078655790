<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>
    
    <div class="container mb-5">
      <h1 class="display-5">Understand and compose sentences</h1>

      <div class="card p-2">
        <div class="row text-center">
          <div class="col-md-4">
            <div class="card h-100" >
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>1</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Picking an object as instructed</p>
                <!-- <p> Pick the object according to instructions</p> -->
                <img :src="tokenImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="token_test()"> Start this exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('token')"> Exercise overview</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              
              <div class="card-body">
                <!-- <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div> -->
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>2</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Understanding passive sentences</p>
                <!-- <p> Pick the object according to instructions</p> -->
                <img :src="reversiblePassiveImg" width="250" height="120" style="object-fit: contain">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="reversible_passive()"> Start this exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('reversible_passive')"> Exercise overview</button>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">


                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>3</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Position description</p>
                <img :src="positionImg" width="250" height="120" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="aphasia_position()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('position')"> Exercise overview</button>
              </div>
            </div>
          </div>     -->
        </div>
        <div class="modal fade" id="start_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ module_title }}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body text-center">
                <div class="row justify-content-around">
                  <div class="card card-body">
                    <h5>{{ benfite_statement }}</h5>
                  </div>
                  
                </div>
                <div class="row justify-content-center">
                  <Posture_instructions/>
                </div>
                <div class="row justify-content-center mt-4">
                  <div class="col-3">
                    <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
      
      <!-- <Posture_instructions/> -->

    </div>
    <Footerbar class="mt-auto"></Footerbar>
</div>
</template>
<script>

import tokenImg from '@/assets/images/instructions/token_test.png'
import reversiblePassiveImg from '@/assets/images/instructions/reversible_passive.png'

import positionImg from '@/assets/images/image_position.png'
import Posture_instructions from '../components/posture_instructions.vue'
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
    name: "Sentence_aphasia_instructions",
    components: {Posture_instructions, Footerbar, Navbar},
    props: {
    },
    data() {
        return {
          userCategory: "",
          region: "",
          explanation: "",
          tokenImg:tokenImg,
          positionImg: positionImg,
          reversiblePassiveImg:reversiblePassiveImg,
          module_title:'',
          benfite_statement:'',
          module_cate:''
        };
    },
    created() {
        this.userCategory = localStorage.getItem("userCategory");
        this.region = localStorage.getItem("region");
        // this.explanation[0] = "Make sure you:\n - are about 40cm/16in away from the screen.\n - have your back upright.\n - have a glass water to sip throughout."
        // this.explanation[1] = "1. You will see a video on how to pronounce a certain sound, follow the video to pratice pronouncing the sound."
        // this.explanation[2] = "2. By clicking 'Next', you will then see a few videos showing word examples that contain such sound."
        // this.explanation[3] = "3. Listen to the video, press 'Start' to record your pronunciation."
        this.explanation = "";

    },
    methods: {
        aphasia_position() {
          this.$router.push({ name: 'user_training', params: { blueprint_id: 'PositionExercises' }});
        },
        token_test()
        {
          this.$router.push({ name: "language_assessment" });
        },
        reversible_passive()
        {
          this.$router.push({ name: 'user_training', params: { blueprint_id: 'ReversiblePassive' }});
        },
        gotoBenefit(cate)
        {
          this.module_cate = cate;
          if(cate == 'token')
          {
            this.module_title = 'Picking an object as instructed'
            this.benfite_statement = 'This exercise helps you practise understanding adjectives and descriptions. A key step towards understanding words and sentences.'
          }
          else if(cate == 'reversible_passive')
          {
            this.module_title = 'Understanding passive sentences'
            this.benfite_statement = 'This exercise helps you practise understanding and composing sentences.'
          }
          else if(cate == 'position')
          {
            this.module_title = 'Position description'
            this.benfite_statement = 'This exercise helps you practise understanding and describing where things are.'
          }
          else
          {
            console.log('unknown category = '+cate)
          }
          let start_pop = new Modal(document.getElementById('start_pop'));
          start_pop.show()
        },
        gotoModule()
        {
          if(this.module_cate == 'token')
          {
            this.token_test()
          }
          else if(this.module_cate == 'reversible_passive')
          {
            this.reversible_passive()
          }
          else if(this.module_cate == 'position')
          {
            this.aphasia_position()
          }
          else
          {
            console.log('unknown category = '+this.module_title)
          }
        }
    },
}
</script>
<style scoped>
.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}

</style>