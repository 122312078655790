<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack="true"></Navbar>
    <div class="container mb-5">
      <h1 class="display-5">Daily Training</h1>
      <p class="lead">Choose from our range of exercises to start your training or search for a specific exercise.</p>
      <p> For better training, make sure to select your communication difficulties <b style="color:#f56c6c;cursor:pointer" data-bs-toggle="modal" data-bs-target="#mysettings_modal"> HERE </b></p>

      <div class="row" v-if="issue_flag_dic.aphasia">
        <h3 class="mt-4">Language exercises <i class="bi bi-mortarboard"></i> </h3>
        <div class="row">
          <div class="col-md-4" style="cursor:pointer">
            <!-- <div class="position-relative">
              <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div> -->
            <div class="row divH m-1" @click="gotoReading('aphasia')">
              <div class="col-md-4">
                <img :src="readingImg" style="height:60px;">
                </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Reading</strong></p>
                <p>Read words and sentences</p>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="cursor:pointer">
            <!-- <div class="position-relative">
              <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div> -->
            <div class="row divH m-1" @click="gotoWriting('aphasia')">
              <div class="col-md-4">
                <img :src="writingImg" style="height:60px;">
                </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Writing</strong></p>
                <p>Write words and sentences</p>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-if="!issue_flag_dic.voice && !issue_flag_dic.speech && !issue_flag_dic.motor" style="cursor:pointer">
            <div class="row divH m-1" @click="gotoSound('aphasia')">
              <div class="col-md-4">
                <img :src="mouthImg" style="height:60px;cursor:pointer" >
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Practise voice and speech sounds</strong></p>
                <p>Learn how to make sounds</p>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="cursor:pointer">
            <div class="position-relative">
              <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div>
            <div class="row divH m-1" @click="gotoWord('aphasia')">
              
              <div class="col-md-4">
                <img :src="think_wordImg" style="height:60px;" >
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Word Finding</strong></p>
                <p>Find the right words</p>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="cursor:pointer">
            <!-- <div class="position-relative">
              <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div> -->
            <div class="row divH m-1" @click="gotoSentence('aphasia')">
              <div class="col-md-4">
                <img :src="think_sentenceImg" style="height:60px;">
                </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Sentences and grammar</strong></p>
                <p>Understand and compose sentences</p>
              </div>
            </div>
          </div>
        

        <div class="col-md-4" style="cursor:pointer">
            <!-- <div class="position-relative">
              <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div> -->
            <div class="row divH m-1" @click="gotoAudioryComprehension()">
              <div class="col-md-4">
                <img :src="listeningImg" style="height:70px;">
                </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Auditory comprehension</strong></p>
                <p>Understand what you hear</p>
              </div>
            </div>
          </div>
        

        </div>
        <!-- <div class="row" v-if="true_length == 1">
          <div class="col-md-4" style="cursor:pointer">
            <div class="row divH m-1" @click="gotoWord('pronun')">
              <div class="col-md-4">
                <img :src="speak_wordImg" class="img-fluid" style="height:60px" >
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Speak words</strong></p>
                <p>Say a series of words</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!--
      <div class="row mt-4" v-if="issue_flag_dic.voice || issue_flag_dic.speech || issue_flag_dic.motor"> 
        <h3 class="mt-4">Facial muscle exercises <i class="bi bi-mortarboard"></i> </h3>
        <div class="row">
          <div class="col-md-4" style="cursor:pointer" v-if="(issue_flag_dic.speech || issue_flag_dic.motor) && !issue_flag_dic.voice">

            <div class="row divH m-1" @click="gotoFacialMuscles()">
              <div class="col-md-4">
                <img :src="faceMusclesImg" style="height:60px;cursor:pointer" >
              </div>
              <div class="col-md-8">
                
                <p style="margin-bottom:0px"><strong>Strengthen facial muscles</strong></p>
                <p>Working on facial muscles</p>
              </div>
            
            </div>
          </div>
          <div class="col-md-4" style="cursor:pointer">
            <div class="row divH m-1" @click="gotoFacial()">
              <div class="col-md-4">
                <img :src="expressionImg" style="height:60px;cursor:pointer" >
              </div>
              <div v-if="issue_flag_dic.voice" class="col-md-8">
                
                <p style="margin-bottom:0px"><strong>Big facial expressions</strong></p>
                <p>Making big facial movements</p>
              </div>
              <div class="col-md-8" v-if="(issue_flag_dic.speech || issue_flag_dic.motor)&&!issue_flag_dic.voice">
                <p style="margin-bottom:0px"><strong>Facial expressions</strong></p>
                <p>Exercise facial expressions</p>
              </div>
            </div>
          </div>    
        </div>
      </div>
      -->
      <div class="row mt-4" v-if="issue_flag_dic.voice || issue_flag_dic.motor || issue_flag_dic.speech">
        <h3>Voice and speech exercises <i class="bi bi-mic"></i></h3>
        
        <div class="row mt-4">
          <div class="col-md-4" v-if="issue_flag_dic.voice" style="cursor:pointer;">
            <div class="row divH m-1" @click="warmUp()">                    
              <div class="col-md-4">
                <img :src="warmupImg" style="height:60px;" >
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Voice warm up</strong></p>
                <p>Warming up your voice</p>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4" v-if="issue_flag_dic.voice" style="cursor:pointer;">
            <div class="row divH m-1" @click="gotoSound('pronun')">
              <div class="col-md-4">
                <img :src="soundImg" style="height:60px;">
              </div>
              <div class="col-md-8">
                <p v-if="issue_flag_dic.voice" style="margin-bottom:0px"><strong>Practise sound projection</strong></p>
                <p v-if="issue_flag_dic.voice">Practise loudness and pitch</p>
              </div>
            </div>
          </div> -->
          <div class="col-md-4" style="cursor:pointer">
            <div class="row divH m-1" @click="gotoSound('aphasia')">
              <div class="col-md-4">
                <img :src="mouthImg" style="height:60px;cursor:pointer" >
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Voice and speech sounds</strong></p>
                <p>Loudness, pitch, phonemes</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4" style="cursor:pointer;">
            <div class="position-relative">
            <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div>
            <div class="row divH m-1" @click="gotoWord('pronun')">
              <div class="col-md-4">
                <img :src="speak_wordImg" style="height:60px;">
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Read words & phrases</strong></p>
                <p>Read phrases aloud</p>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="cursor:pointer;">
            <!-- <div class="position-relative">
              <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div> -->
            <div class="row divH m-1" @click="gotoSentence('pronun')">
              <div class="col-md-4">
                <img :src="speak_sentenceImg" style="height:60px;">
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Read sentences</strong></p>
                <p>Read short sentences aloud</p>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="cursor:pointer;">
              
            <div class="row divH m-1" @click="gotoPara('pronun')">
              <div class="col-md-4">
                <img :src="speak_paraImg" style="height:60px;" >
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Read paragraphs</strong></p>
                <p>Practise with longer text</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4" style="cursor:pointer;">
            <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div>
            <div class="row divH m-1" @click="conversation()">
              
              <div class="col-md-4">
                <img :src="conversationImg" style="height:60px;">

              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Conversation</strong></p>
                <p>Talk in real-life situations</p>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="cursor:pointer;">
            <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW SONGS</span>
              </div>
            <div class="row divH m-1" @click="karaoke()">
              <div class="col-md-4">
                <img :src="musicImg" style="height:60px;">
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Singing</strong></p>
                <p>Good for voice & speech</p>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="cursor:pointer;">
            <!-- <div class="position-relative">
              <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div> -->
            <div class="row divH m-1" @click="paceTraining()">
              <div class="col-md-4">
                <img :src="rhythmImg" style="height:60px;">
              </div>
              <div class="col-md-8">
                <p style="margin-bottom:0px"><strong>Pace training</strong></p>
                <p>Practise speaking at a pace</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row mt-4">
        <h3>Brain challenges <font-awesome-icon icon="fa-solid fa-brain" size="xs"/></h3>
    
        <div class="row mt-4 text-center">
          <div class="col">
            <button class="btn btn-success btn-lg custom-button-BV" data-bs-toggle="modal" data-bs-target="#memory_popup">Memory</button>
          </div>
          <div class="col">
            <span class="badge rounded-pill bg-danger position-absolute m-2">NEW</span>

            <button class="btn btn-success btn-lg custom-button-BV" data-bs-toggle="modal" data-bs-target="#life_popup">Life skills</button>
          </div>
          <div class="col">


            <button class="btn btn-success btn-lg custom-button-BV" data-bs-toggle="modal" data-bs-target="#brain_games_popup">Brain games</button>
          </div>
        </div>
      </div>
      <!-- <div class="modal fade" id="sound_pop_up" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Practising loudness and pitch</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-around">
                <div class="col-5">
                  <button class="btn btn-success w-100" @click="gotoPhonation()" data-bs-dismiss="modal" >Sustaining /Ah/</button>
                </div>
                <div class="col-5">
                  <button class="btn btn-success w-100"  @click="gotoPitch()" data-bs-dismiss="modal" >Pitch variation</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="modal fade" id="memory_popup" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">What would you like to do?</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-around">
                <div class="col-4">
                  <button class="btn btn-success w-100" @click="wordMemory()" data-bs-dismiss="modal" >Word memory</button>
                </div>
                <div class="col-4">
                  <button class="btn btn-success w-100"  @click="storyMemory()" data-bs-dismiss="modal" >Story memory</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="life_popup" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">What would you like to do?</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-center">
           
                <div class="col-4">
                  <!-- <span class="badge rounded-pill bg-danger position-absolute">NEW</span> -->

                  <button class="btn btn-success h-100" @click="clockGame()" data-bs-dismiss="modal" >Clock game</button>
                </div>
                <div class="col-4">
                  <button class="btn btn-success h-100"  @click="numberSequenceGame()" data-bs-dismiss="modal" >Number sequencing</button>
                </div>
                <div class="col-4">
                  <!-- <span class="badge rounded-pill bg-danger position-absolute">NEW</span> -->
                  <button class="btn btn-success h-100"  @click="money_recognition()" data-bs-dismiss="modal" >Money recognition</button>
                </div>
              </div>
              <div class="row justify-content-center">
           
           <div class="col-4 mt-4">
             <span class="badge rounded-pill bg-danger position-absolute">NEW</span>

             <button class="btn btn-success h-100" @click="giveDirections()" data-bs-dismiss="modal" >Give directions</button>
              </div>
         
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="brain_games_popup" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">What would you like to do?</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-around">
            
                <div class="col-5">
                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> -->

                  <button class="btn btn-success w-100" @click="simonSays()" data-bs-dismiss="modal" >Simon says</button>
                </div>

                <div class="col-6">
                  <button class="btn btn-success w-100" @click="reverseDirection()" data-bs-dismiss="modal" >Reverse direction</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="mysettings_modal" tabindex="-1">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">My settings <i class="bi bi-gear" style="font-size:20px"></i></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <Patient_setting @changedSetting="getIssue_flag()"> </Patient_setting>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>
</template>
<script>

import mouthImg from '@/assets/images/MOUTH.png'
import expressionImg from '@/assets/images/Expression_34.png'
import faceMusclesImg from '@/assets/images/face_muscles.png'
import readingImg from '@/assets/images/reading.png'
import writingImg from '@/assets/images/writing.png'
import listeningImg from '@/assets/images/listening.png'

import think_wordImg from '@/assets/images/017-thinking.png'
import think_sentenceImg from '@/assets/images/conversation.png'
import warmupImg from '@/assets/images/Fire.png'
import soundImg from '@/assets/images/scream.png'
import speak_wordImg from '@/assets/images/word.png'
import speak_sentenceImg from '@/assets/images/sentence.png'
import speak_paraImg from '@/assets/images/read.png'
import musicImg from '@/assets/images/music.png'
import conversationImg from '@/assets/images/conversation_img.png'

import rhythmImg from '@/assets/images/rhythm-fill.png'
import constants from '@/utils/constants'

import Patient_setting from '../setting.vue'
import { Modal } from 'bootstrap'
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'


export default {
  components: { Patient_setting, Footerbar, Navbar },
  name: 'Daily_training',
  data() {
    return {
      listeningImg:listeningImg,
      writingImg:writingImg,
      conversationImg:conversationImg,
      readingImg:readingImg,
      faceMusclesImg:faceMusclesImg,
      mouthImg:mouthImg,
      expressionImg:expressionImg,
      think_wordImg:think_wordImg,
      think_sentenceImg:think_sentenceImg,
      warmupImg:warmupImg,
      soundImg:soundImg,
      speak_wordImg:speak_wordImg,
      speak_sentenceImg:speak_sentenceImg,
      speak_paraImg:speak_paraImg,
      musicImg:musicImg,
      rhythmImg:rhythmImg,
      start_object: {
        module_path:'', 
        assessment_type: "USER_TRAINING", 
        module_name: '', 
        current_exercise_index: 0,
      },
      // issue_lib:['Slurred/choppy speech', 'Difficulty in moving lips, jaw or tongue', 'Hoarse/breathy voice', 'Dysphasia/aphasia'],
      issue_flag:[false,false,false,false],//speeech,motor,voice,aphasia
      issue_flag_dic:{"speech":false,"motor":false,"voice":false,"aphasia":false},
      conditions:["speech","motor","voice","aphasia"],
      true_length:0,
    }
  },
  created() 
  {
    const _this = this
    this.userCategory = localStorage.getItem('userCategory');
    this.getIssue_flag();
  },
  methods: 
  {
    warmUp()
    {
      this.$router.push({ name: 'voice_warm_up', params: {name: "voice_warm_up"}});
    },
    karaoke()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'Karaoke' }});
    },
    conversation()
    {
      this.$router.push({ name: 'conversation_training' })
    },
    paceTraining()
    {
      this.$router.push({ name: 'pace_training_section' })
    },
    simonSays()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'SimonSays' }});
    },
    steppingStone()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'SteppingStone' }});
    },
    mathsTable()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'MathsTable' }});
    },
    gotoPhonation()
    {
      this.$router.push({ name: 'sound_loudness_loudness_patient' });
    },
    gotoPitch()
    {
      this.$router.push({ name: 'pitch_variation' });
    },
    wordMemory()
    {
      this.$router.push({ name: 'word_memory' });
    },
    storyMemory()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'StoryMemory' }});
    },
    clockGame()
    {
      this.$router.push({ name: "clock_game" });
    },
    giveDirections()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'GiveDirections' }});
    },
    money_recognition()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'MoneyRecognition' }});
    },
    numberSequenceGame()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'NumberSequence' }});
    },
    getIssue_flag() {
      
      if(this.userCategory == 'slp') {
        for(let i in this.issue_flag) {
          this.issue_flag[i] = true
          this.issue_flag_dic[this.conditions[i]] = true
        }
      } else {
        let temp_iss_flag = localStorage.getItem('issue_flag');
        
        if(temp_iss_flag != undefined && temp_iss_flag != null) {
          let issue_flag_arr = temp_iss_flag.split(',')
          //console.log(issue_flag_arr)
          this.issue_flag = [];
          for(let i=0; i<issue_flag_arr.length; i++) {
            //console.log(issue_flag_arr[i] + ' ' + (issue_flag_arr[i] == 'true'))

            let flag = (issue_flag_arr[i].includes('true'))
            //console.log(flag)
            this.issue_flag.push(flag);
            this.issue_flag_dic[this.conditions[i]] = flag
          }
        }
      }
    },
    reverseDirection()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'ReverseDirection' }});
    },
    gotoFacial()
    {
      this.$router.push({ name: 'facial_expression'}); 

    },
    gotoFacialMuscles()
    {
      this.$router.push({ name: 'facial_muscles'}); 

    },
    gotoPara(category)
    {
      if(category == 'pronun')
      {
        this.$router.push({ name: 'advanced_training'});
      }
    },
    gotoSound(category)
    {
      if(category == 'aphasia')
      {
        this.$router.push({ name: 'practise_mouth_movement'});
      }
      else if(category == 'pronun')
      {
        if(this.issue_flag[2])
        {
          let sound_pop_up = new Modal(document.getElementById('sound_pop_up'));
          sound_pop_up.show()
        }
        else
        {
          this.$router.push({ name: 'practise_mouth_movement'});
        }
      }
      
    },
    gotoWord(category)
    {
      if(category == 'pronun')
      {
        this.$router.push({ name: 'speak_words'}); //word pronoun
      }
      else if(category == 'aphasia')
      {
        this.$router.push({ name: 'thinking_of_words'}); //word aphasia
      }
      
    },
    gotoAudioryComprehension()
    {
      this.$router.push({ name: 'auditory_comprehension'}); 

    },
    gotoReading()
    {
      this.$router.push({ name: 'reading'}); //sentence aphasia

    },
    gotoWriting()
    {
      this.$router.push({ name: 'writing'}); //sentence aphasia

    },
    gotoSentence(category)
    {
      if(category == 'aphasia')
      {
        this.$router.push({ name: 'use_sentences'}); //sentence aphasia
      }
      else if(category == 'pronun')
      {
        this.$router.push({ name: 'read_sentences'}); //sentence_pronun
      }
    },
  }
}
</script>
<style scoped>
.custom-button-BV
{
    width: 130px;
    height: 130px;
    border-radius: 100px;
}
li:hover {
  background-color: #e0e0e2;
  color: white;
}
</style>