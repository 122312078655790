<template>
  <div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='MemoryImage';openModal('memory_modal');">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Cognitive_memory" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="background-color: purple">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Memory</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="memory_modal" tabindex="-1" aria-labelledby="memory_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Memory</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('memory_modal')"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:20vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'MemoryImage'?'btn-primary':'btn-light']" type="button" @click="selected_module='MemoryImage'">
                  Image
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'StoryMemory'?'btn-primary':'btn-light']" type="button" @click="selected_module='StoryMemory'">
                  Narrative
                </button>
              </div>
              <div class="col-8">
                <div v-show="selected_module == 'MemoryImage'">
                  <p class="lead">Practise memory matching words to images with increasing complexity</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="6" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:6" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'StoryMemory'">
                  <p class="lead">Practise memory answering questions from a real life scenario</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Cognitive_memory from '@/assets/images/dashboard/slt/training/Cognitive_memory.svg'

import { Modal } from 'bootstrap'

export default {
  name: 'memory',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      selected_module: '',
      local_number_exercises: 5,
      Cognitive_memory: Cognitive_memory, 
      modal_references: {},
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "memory_modal": new Modal(document.getElementById('memory_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('memory_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('memory_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    }
  }
}
</script>