<template>
  <div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='DailyNewsPaceLong';openModal('pace_training_modal')">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Speech_pace_training" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Speech Rate</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="pace_training_modal" tabindex="-1" aria-labelledby="pace_training_modal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Speech Rate</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('pace_training_modal')"></button>
          </div>
          <div class="modal-body">
            <div class="row" style="height:30vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'DailyNewsPaceLong'?'btn-primary':'btn-light']" type="button" @click="selected_module='DailyNewsPaceLong'">
                  Speech rate training with daily news
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'PaceInterest'?'btn-primary':'btn-light']" type="button" @click="selected_module='PaceInterest'">
                  Speech rate training with personal interests
                </button>
              </div>
              
              <div class="col-8">
                <div v-show="selected_module == 'DailyNewsPaceLong'">
                  <p class="lead">Practise rate of speech with news</p>

                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Choose a topic</label>

                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration['topic']">
                        <option value="Art and Design">Art and Design</option>
                        <option value="Business">Business</option>
                        <option value="Cities">Cities</option>
                        <option value="Film">Film</option>
                        <option value="Food">Food</option>
                        <option value="Football">Football</option>
                        <option value="Latest News">Latest News</option>
                        <option value="Leisure">Leisure</option>
                        <option value="Lifestyle">Lifestyle</option>
                        <option value="Politics">Politics</option>
                        <option value="Science">Science</option>
                        <option value="Sport">Sport</option>
                        <option value="Tech">Tech</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="1" 
                      :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'PaceInterest'">
                  <p class="lead">Practise rate of speech with topics of your interest</p>

                  <div class="row mb-2">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Topic (Irish history, Downton Abbey)</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="topic" type="text" name="topic" v-model="pace_interest_topic" 
                        :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                          messages-class="text-danger"
                          validation="required"/>
                    </div>
                  </div>
                  
               
                  <div class="row m-4">
                    <button v-if="!generating_word_list" class="btn btn-success" style="width:100%" :disabled="local_number_exercises<=0" @click="generate_pace_interest()">Generate text </button>
                    <button v-else class="btn btn-success" type="button" style="width:100%" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Generating...
                    </button>

                    <div v-if="module_configuration['pace_interest']!=undefined && module_configuration['pace_interest'].text.length>0">
                      <div class="row mt-2">
                        <div v-for="id in module_configuration['pace_interest'].text.length" :key="id">
                          <div class="input-group mb-3">
                            <textarea class="form-control form-control-lg" v-model="module_configuration['pace_interest'].text[id-1]"></textarea>
                            <button class="input-group-text btn btn-outline-danger" type="button" @click="deleteSentence(id-1, module_configuration['pace_interest'].text, module_configuration['pace_interest'])">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>

                 
                  </div>
                    
                  
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import Speech_pace_training from '@/assets/images/dashboard/slt/training/Speech_pace_training.svg'
import { Modal } from 'bootstrap'
import {short_paragraph} from '@/utils/interface_ai'


export default {
  name: 'speech_rate',
  components: {},
  props: 
  {
    number_exercises: Number
  },
  data(){
    return {
      Speech_pace_training: Speech_pace_training, 

      selected_module: '',
      local_number_exercises: 5,


      modal_references: {},
      pace_interest_topic:'',
      generating_word_list:false,
      module_configuration: {
        data: [],
        name: '',
        topic: 'Latest News',
      },

    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
  },
  mounted() {
    this.modal_references = {
      "pace_training_modal": new Modal(document.getElementById('pace_training_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    check() {

      if (this.selected_module === "PaceInterest") {
        if (this.module_configuration['name'] === '' || this.module_configuration['pace_interest'].text.length < 1) {
          alert("Please generate the topic first.")
          return false
        }
      }
      return true
    },
    emit_start_training() {
      if ( !this.check() ) return
      this.closeModal('pace_training_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      if ( !this.check() ) return
      this.closeModal('pace_training_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      this.reset()
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset() {
      this.module_configuration ={
        data: [],
        name: '',
        topic: 'Latest News',
      }
    },
    deleteSentence(id, sentences_array, sentences_form) {
      sentences_array.splice(id,1)
      this.local_number_exercises = sentences_array.length    // this.sentences_form.text.splice(id, 1)
      this.onNumberExercisesChange()
      // this.number_exercises = this.number_exercises - 1
    },
    generate_pace_interest()
    {
      const _this = this
      

      if(_this.pace_interest_topic.length<=0)
      {
        return
      }
      

      _this.generating_word_list = true

      let pace_interest_result = short_paragraph(_this, _this.pace_interest_topic,this.local_number_exercises)
       
      _this.module_configuration['name']= "PaceInterest"
      _this.module_configuration['topic'] = _this.pace_interest_topic

      pace_interest_result.then(function(result) {

        _this.module_configuration['pace_interest'] = {}
        _this.module_configuration['pace_interest'].topic = _this.pace_interest_topic
        _this.module_configuration['pace_interest'].name="PaceInterest"

        _this.module_configuration['pace_interest'].text = result

        _this.generating_word_list = false

      })

    },

  }
}
</script>