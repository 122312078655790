<template>
  <div class="d-flex flex-column min-vh-100">
    
    <Navbar :enableBack=true></Navbar>
    
    <div class="container-fluid">
      <h1 class="display-5">Word game</h1>
      <div class="text-center">

      <div v-if="startGame">
        <div class="card mb-2">
          <div class="card-body">
            <p class="lead"> Game rules</p>
            <p class="custom-text"> This game aims to encourage the group members to think of words.</p>
            <p class="custom-text"> After the game starts, you will see an instruction and an image of inspirations, just like the ones below.</p>
            <div class="card card-body">
                <span class="custom-text"> Say <b style="color: red;">a country</b> starting with the letter <b style="color: blue;">B</b></span>
                <div class="row justify-content-center">
                    <img :src="countryImg" style="width:30%;margin-top:2%;">
                </div>
            </div>
            <p class="custom-text"> There are 2 ways to play the game:</p>
            <p class="custom-text"> 1. Group members can all give their answers following the instructions. Let's see who is the first one!</p>
            <p class="custom-text"> 2. The group organiser can pick someone to give their answer following the instructions. Let's see who is the lucky one!</p>
          </div>
          <div class="mb-2">
            <button class="btn btn-primary btn-lg" @click="startGame = false">Start</button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card mb-2">
          <div class="card-body">
            <p v-if="scenario === 0" class="read-text"> Say <b style="color: red;">{{word_cate}}</b> starting with the letter <b style="color: blue;">{{rand_letter}}</b></p>
            <p v-else class="read-text"> Complete the following sentence using a word starting with the letter <b style="color: blue;">{{rand_letter}}</b>:<br> <b style="color: red;">{{word_cate}}...</b> </p>
            <img :src="cateImg" style="width:30%;margin-top:2%;">
            <p v-if="show_answer" class="read-text"> <b style="color:green"> {{curr_answer}} </b>  </p>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <button class="btn btn-bd-yellow btn-lg me-2" @click="show_answer=true">Show an answer</button>
            <button class="btn btn-info btn-lg" @click="nextExercise()">Next</button>
          </div>
          <div class="col-6">
            <div style="width:40%">
            <p>Difficulty level</p>
            <div class="row">
              <div class="col-12">
                <input type="range" class="form-range" min="0" max="2" step="1" v-model="diffLevel" @change="updateDifficulty()">
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                Easy
              </div>
              <div class="col-4">
                Medium
              </div>
              <div class="col-4">
                Hard
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footerbar class="mt-auto"></Footerbar>
</div>
</template>
<script>

import {getUserDataDir, getData} from '@/firebase/index'
// import citiImg from '@/assets/images/stopgame/cities2.jpg'
import countryImg from '@/assets/images/stopgame/acountry.jpg'
// import feelingImg from '@/assets/images/stopgame/feelings.jpg'
// import goingImg from '@/assets/images/stopgame/merged.jpg'
// import hobbyImg from '@/assets/images/stopgame/hobbies.jpg'
// import foodImg from '@/assets/images/stopgame/food.jpg'
// import fruitImg from '@/assets/images/stopgame/fruit_vege.jpg'
// import animalImg from '@/assets/images/stopgame/animal2.jpg'
// import objImg from '@/assets/images/stopgame/objects.png'
// import pizzaImg from '@/assets/images/stopgame/pizzas.jpg'
// import professionImg from '@/assets/images/stopgame/professions.jpg'
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  name: 'ui_patient',
  components: {Footerbar, Navbar},
  props: 
  {
  },
  data(){
    return {
      userCategory:'',
      region:'',
      word_cate:'',
      rand_letter:'',
      scenario:0,
      easy_letters:['A','B','C','D','F','G','H','L','M','N','R','S','T'],
      medium_letters:['E','I','J','K','O','P','Q'],
      hard_letters:['U','V','W','X','Y','Z'],
      topic_info:[],
      topic_answers:[],
      sentence_answers:[],
      curr_answer:'',
      sentence_info:[],
      difficulty_level:'Easy',
      startGame:true,
      cateImg:null,
      show_answer: false,
      countryImg:countryImg,
      diffLevel:0,
      diffMarks:{
        0:'Easy',
        1:'Medium',
        2:'Hard'}
    }
  },
 
  //
  created() {
    this.userCategory = localStorage.getItem('userCategory');
    this.region = localStorage.getItem('region');
    this.loadTopic();
  },
  methods: {
    nextExercise()
    {
      this.loadTopic()
      this.show_answer = false
    },
    loadTopic()
    {
      let _this=this;
      _this.game_folder_ref = _this.$firebase.database().ref("Exercises").child('General').child('Aphasia').child('Stop_game');
      var do_once = true;
      getData(_this.game_folder_ref , function (err, result) {

        if(do_once)
        {
          do_once = false;

          var word_info_handle = result.child('word_category');
          word_info_handle.forEach(function (childSnapshot) 
          {
            
              _this.topic_info.push(childSnapshot.key);
              _this.topic_answers.push(childSnapshot.val())
          }); 
          var sentence_info_handle = result.child('sentence_compose');
          sentence_info_handle.forEach(function (childSnapshot) 
          {
              _this.sentence_info.push(childSnapshot.key);
              _this.sentence_answers.push(childSnapshot.val());

          }); 
          console.debug('topic_info_fb= '+_this.topic_info);
          console.debug('senrence_info_fb= '+_this.sentence_info)
        }
          _this.createQuestion();
          _this.loadImage();
      });
    },
    createQuestion()
    {
      let _this = this;
      _this.scenario = Math.floor(Math.random() * 2);
      // console.debug('scenario= '+scenario)
      
      if(_this.difficulty_level == this.diffMarks[0])
      {
          let rand_letter_id = Math.floor(Math.random() * _this.easy_letters.length);
          _this.rand_letter = _this.easy_letters[rand_letter_id];
      }
      else if(_this.difficulty_level == this.diffMarks[1])
      {
          let rand_letter_id = Math.floor(Math.random() * _this.medium_letters.length);
          _this.rand_letter = _this.medium_letters[rand_letter_id];
      }
      else
      {
          let rand_letter_id = Math.floor(Math.random() * _this.hard_letters.length);
          _this.rand_letter = _this.hard_letters[rand_letter_id];
      }

      if(_this.scenario == 0)
      {
          let folder_size = _this.topic_info.length;
          let rand_selection = Math.floor(Math.random() * folder_size);

          if(!(_this.rand_letter.toLowerCase() in _this.topic_answers[rand_selection]))
          {
              _this.createQuestion()

          }
          else
          {
              _this.word_cate = _this.topic_info[rand_selection];
              _this.curr_answer = _this.topic_answers[rand_selection][_this.rand_letter.toLowerCase()]
              console.debug('topic_info= '+_this.topic_info)
          }

      }
      else
      {
          let folder_size = _this.sentence_info.length;
          let rand_selection = Math.floor(Math.random() * folder_size);


          if(!(_this.rand_letter.toLowerCase() in _this.sentence_answers[rand_selection]))
          {
              _this.createQuestion()
          }
          else
          {
              _this.word_cate = _this.sentence_info[rand_selection];
              _this.curr_answer = _this.sentence_answers[rand_selection][_this.rand_letter.toLowerCase()]
          }


      }

      console.debug('word_cate= '+_this.word_cate);
    },
    loadImage()
    {
      console.debug('load image')

      this.cateImg = ''

      try{
        let image_file = this.word_cate.replaceAll(' ','')
        image_file = image_file.toLowerCase()
        console.debug('image_file = ' + image_file)

        this.cateImg = new URL(`../../../../../assets/images/stopgame/${image_file}.jpg`, import.meta.url)
      } catch(e) {

      }
    },
    updateDifficulty()
    {
      this.show_answer = false
      this.difficulty_level = this.diffMarks[this.diffLevel];
      console.debug('diff level= '+this.difficulty_level)
      this.loadTopic();
    }
  },
}
</script>
<style scoped>

.custom-text{
    font-size: 20px;
    font-family: Arial;
}


</style>