<template>
  <div>
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body"  style="border-color: blueviolet;">
          <div class="accordion" id="accordionPictureNaming">
            <div class="accordion-item mb-3">
              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#picture_naming_collapse" aria-expanded="false" aria-controls="picture_naming_collapse"><p class="text-start" >
                  <b>Picture naming</b></p></button>
              </div>
              <div :class="['accordion-collapse', 'collapse', show_collapse ? 'show': '']" id="picture_naming_collapse" data-bs-parent="#accordionPictureNaming">


                <div class="row mt-4 m-2">
                  <div class="col-6 text-start">
                    <p>
                    Correct answers: 
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{pictureNamingOverview.correct}} out of {{pictureNamingOverview.total}} ({{ pictureNamingOverview.percentage}}%)</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>
            
                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>
                      Use hints in: 
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{pictureNamingOverview.total_hints}} out of {{pictureNamingOverview.total}} ({{ pictureNamingOverview.hint_percentage}}%)</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>
                      Show choices in:
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{pictureNamingOverview.total_options}} out of {{pictureNamingOverview.total}} ({{ pictureNamingOverview.option_percentage}}%)</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <!-- <hr style="height:2px;border:none;color:#333;background-color:green;" />
                <span style="float:left">
                  Show answer in: &emsp; &emsp; &emsp; &emsp;&emsp;{{pictureNamingOverview.correct}} out of {{pictureNamingOverview.total}} ({{ pictureNamingOverview.percentage}}%)
                </span>
                <br> -->
                      
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr class="text-center">
                          <th>Words</th>
                          <th>Category</th>
                          <th>Correct</th>
                          <th>Show Choices</th>
                          <th>Sound Hint</th>
                          <th>Show Answer</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center" v-for="(item, index) in table" :key="index" >
                          <td>{{ item.word }}</td>
                          <td>{{ item.category }}</td>
                          <td>
                            <img v-if="item.correct" :src="tickImg" style="width:15px;">
                            <img v-else :src="crossImg" style="width:15px;">
                          </td>
                          <td>
                            <img v-if="item.show_options" :src="tickImg" style="width:15px;">
                            <img v-else :src="crossImg" style="width:15px;">
                          </td>
                          <td>
                            <img v-if="item.sound_hints" :src="tickImg" style="width:15px;">
                            <img v-else :src="crossImg" style="width:15px;">
                          </td>
                          <td>
                            <img v-if="item.show_answer" :src="tickImg" style="width:15px;">
                            <img v-else :src="crossImg" style="width:15px;">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> 
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>

import crossImg from '@/assets/images/wrong_cross.png'
import tickImg from '@/assets/images/Tick.png'

export default {
  components: { 
  },
  name: 'Picture_naming',
  props: ['pictureNamingOverview', 'table', 'show_collapse'],
  setup(props, {emit}) {

    return {
      crossImg,
      tickImg
    }
  }
}

</script>
<style scoped>


</style>
