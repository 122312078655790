<template>
  <div class="d-flex flex-column min-vh-100">
    <nav class="navbar navbar-light" style="background-color: #0D302A;">
      <div class="container-fluid">
        <a class="navbar-brand">
          <img src="../../../assets/images/logo/logo.svg" alt="" width="225" class="d-inline-block align-text-top">
        </a>
      </div>
    </nav>
    <div class="container">
      <div v-if="show_category_selection">
        <Select_Category @selected_category="(cat) => category=cat" @next="show_category_selection=false"/>
      </div>
      <div v-else>
        <div v-if="category == 'patient'">
          <Patient_Registration @backToRoot="category=''; show_category_selection=true" />
        </div>
        <div v-else-if="category == 'slp'">
          <SLT_Registration @backToRoot="category=''; show_category_selection=true"/>
        </div>

      </div>
    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>
</template>

<script>
import firebase from '@firebase/app';
import {getUserDataDir} from '@/firebase/index'
import {ref, watch} from 'vue'
import Footerbar from '@/views/Footerbar.vue'
import Select_Category from '@/views/public_external/registration/components/select_category.vue'
import SLT_Registration from '@/views/public_external/registration/slt/slt.vue'
import Patient_Registration from '@/views/public_external/registration/patient/patient.vue'


export default {
  name: 'Register',
  components: {
    Footerbar,
    Select_Category,
    SLT_Registration,
    Patient_Registration
  },
  setup(props, { emit }) {
    
    const show_category_selection = ref(true) //switch it to true 
    const category = ref('') //leave it empty


    const checkIfUserLoggedIn = () => {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

          getUserDataDir('Data' , function (err, result) {
            category.value = result.child('category').val()
            
            if (category.value == 'patient') {
              show_category_selection.value = false
            }
          });

        }
      });
    }

    checkIfUserLoggedIn()

    return {
      show_category_selection,
      category  
    }

  }
}
</script>

<style scoped>

</style>