<template>
  <div>
    <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
      <div class="d-flex flex-column justify-content-start h-100">

        <div>
          <p class="lead">Exercise {{current_exercise_index}} of {{total_exercises}}</p>
        </div>

        <!-- top -->
        <div class="upper-section d-flex flex-column" style="flex-grow: 1;">
          <slot name="top">
          
          </slot>

          <div v-if="!break_page">
            <div v-if="!audioCaptureOn">

              <button v-if="has_next" class="btn btn-info btn-lg w-100 mt-2" @click="next()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i> </button>
              <button v-if="has_skip" class="btn btn-secondary btn-lg w-100 mt-2" @click="skip()">Skip <i class="bi bi-chevron-bar-right" style="margin-left: 8mm;"></i></button>
              <button v-if="has_show_results" class="btn btn-outline-warning btn-lg w-100 mt-3" @click="show_results()">See your results</button>

            </div>
            <div v-else>
              <div v-if="!mic_on">

                <div class="row justify-content-center" style="height:100px">
                  <!-- this should be empty-->
                </div>

                <div class="row justify-content-center">
                  <button class="btn-primary btn btn-lg rounded-circle" style="aspect-ratio: 1 / 1;width: 120px; height: 120px;" @click="start()"> 
                    <div class="d-flex flex-column">
                      <span>
                        <i class="bi bi-play-fill fs-1"></i>
                      </span>
                      <span style="margin-top: -15px;">
                        Start
                      </span>
                    </div>
                  </button>
                </div>

              </div>
              <div v-else>
                <div class="row justify-content-center" style="height:100px">
                  <img :src="mic_on_animation" style="width: 100%;"/>
                </div>
                <div class="row justify-content-center">
                  
                  <button class="btn-primary btn btn-lg rounded-circle" style="aspect-ratio: 1 / 1;width: 120px; height: 120px;" @click="stop()"> 
                    <div class="d-flex flex-column">
                      <span>
                        <i class="bi bi-stop-fill fs-1"></i>
                      </span>
                      <span style="margin-top: -15px;">
                        Stop
                      </span>
                    </div>
                  </button>

                </div>
              </div>
            </div>
            <div v-if="has_demo_video == true">
              <br/>
              <br/>
              <br/>
              <p class="mb-0">Need help?</p>

              <button
                class="btn btn-outline-primary btn-lg w-100 rounded" 
                @click="goToVideoDemo()">
                <p class="mb-0" style="font-size: 16px;"> <i class="bi bi-play-circle"></i> Watch Therapist Demo</p>
              </button>

            </div>
          </div>
          <div v-else>
            <div>
              <p><b>Good job <i class="bi bi-hand-thumbs-up text-primary"></i></b></p>
            </div>
            <button class="btn btn-primary btn-lg w-100" @click="next()"><strong>Next <i class="bi bi-skip-forward"></i></strong></button>
            <div class="mt-4" v-if="show_break_text">
              <img :src="water_bottle" style="width: 75px;"/>
              <p><b>Do not forget to drink water.</b></p>
            </div>
            <button v-if="has_listen_back" class="btn btn-info w-100 mt-4" @click="listen_back()">Listen Back <i class="bi bi-ear"></i></button>
            <button v-if="has_repeat" class="btn btn-success w-100 mt-4" @click="repeat()">Repeat <i class="bi bi-arrow-repeat"></i></button>
          </div>
        </div>
      

        <!-- bottom -->
        <div class="lower-section" style="flex-shrink: 0;">

          <div v-if="!break_page">
            
            

            <slot name="bottom">
            
            </slot>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>


<script>
  import { getDataDirectoryCallback } from '@/firebase/index';
  import { onMounted, ref, watch } from 'vue';
  import water_bottle from '@/assets/images/session/water_bottle.svg'
  import mic_on_animation from '@/assets/images/session/mic_on.gif'

  export default {
    props: {
      current_exercise_index : {
        type: Number,
        required: true
      },
      total_exercises : {
        type: Number,
        required: true
      },
      has_next : {
        type: Boolean,
        required: false
      },
      has_skip: {
        type: Boolean,
        required: false
      },
      has_show_results: {
        type: Boolean,
        required: false
      },

      has_demo_video: {
        type: Boolean,
        default: false
      },
      break_page: {
        type: Boolean,
        default: false
      },
      mic_on: {
        type: Boolean,
        default: false
      },
      has_listen_back: {
        type: Boolean,
        default: false
      },
      has_repeat: {
        type: Boolean,
        default: false
      },
      audioCaptureOn: {
        type: Boolean,
        default: false
      }

    },
    setup(props, {emit}) {
      const show_break_text = ref(props.break_page)

      watch(
        () => props.break_page,
        (newValue, oldValue) => {
          if (newValue) {
            show_break_text.value = props.break_page
            getSettings()
          }
        }
      );
      
      
      
      async function getSettings() {
        if (show_break_text.value) {
          let uid = localStorage.getItem('uid')
          if (localStorage.getItem('userCategory') == 'slp') {
            uid = localStorage.getItem('client_uid')
          }

          let ref_path_settings = "/Users/" + uid + "/Settings"

          await new Promise(function(resolve) {
            getDataDirectoryCallback(ref_path_settings, (err, childSnapshot) => {
              const patient_data = childSnapshot.val()
            
              if ('show_break_text' in patient_data) {
                show_break_text.value = patient_data['show_break_text']
              } else {
                show_break_text.value = true
              }
              resolve()

            })
          })
        }
      } 
      
      onMounted(async () => {
        await getSettings()
      })
    
      const next = () => {
        emit('next')
      }

      const skip = () => {
        emit('skip')
      }

      const show_results = () => {
        emit('show_results')
      }

      const start = () => {
        emit('started')
      }
      const stop = () => {
        emit('stopped')
      }

      const toggleBackgroundNoise = () => {
        emit('toggled_background_noise')
      }

      const goToVideoDemo = () => {
        emit('toggled_demo_video')
      }

      const listen_back = () => {
        emit('listen_back')
      }

      const repeat = () => {
        emit('repeat')
      }
      
      return {
        show_break_text,
        next,
        skip,
        show_results,
        start,
        stop,
        toggleBackgroundNoise,
        goToVideoDemo,
        listen_back,
        repeat,
        water_bottle,
        mic_on_animation
      };
    }
  };
</script>
  
<style scoped>

.outer-circle {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.outer-circle:hover {
  background-color: transparent;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}

.outer-circle:hover .wave {
  /*animation-play-state: paused; */ /* Pause the animation on hover */
  /*animation:none;*/
}

.wave-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-color: rgba(255, 0, 119, 0.7);
  box-shadow: 0 0 0 0 rgba(255, 0, 119, 0.7);
  animation: wave 5s infinite;
  animation-play-state: running;
}

.wave:nth-child(2) {
  animation-delay: 0.3s;
}

.wave:nth-child(3) {
  animation-delay: 0.6s;
}

.wave:nth-child(4) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0.75);
    opacity: 0.5;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.85;
  }
  75% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(0.75);
    opacity: 0.5;
  }
}

.inner-circle {
  width: 110px;
  height: 110px;
  background-color: rebeccapurple;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

}
</style>
  