<template>
  <div>

    <CommunicationDifficulties :uid="client_uid" :calibrate_mic="false"/>
    
    <div class="card card-body m-2">
      <div class="row mt-4 justify-content-center">
        <div class="col-8">
          <div class="">
            <div class="row mt-2 justify-content-center">
              <div style="width:40%">
                <h5> Intelligibility analysis strictness level</h5>
              </div>

              <div class="row">
                <div class="col-12">
                  <input type="range" class="form-range" min="0" max="1" step="1" v-model="intelligibility_diffLevel" @change="updateIntelligibilityGradingSelection()">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                Less strict
              </div>
              <div class="col-6">
                More strict
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-body m-2">
      <div class="row justify-content-between mt-4">
        <div class="col-5">
          <h5 class="text-start">Swallowing capacity</h5>
          <div class="form-check text-start mt-4">
            <input class="form-check-input mb-2" type="checkbox" v-model="cannot_swallow" @change="change_cannot_swallow()">
            <p>
              I have concerns with the patient's swallowing capacity 
              <span style="display: inline-block; margin-left: 5px;">
                <VTooltip placement="right">
                  <i class="bi bi-question-circle"></i>
                  <template #popper>
                    <p style="width: 400px;">
                      If you have concerns with the patient's swallowing capacity, the patient will not be prompt to drink some water in between exercises. 
                      They will be prompt to take adequate breaks instead.
                    </p>
                  </template>
                </VTooltip>
              </span>
            </p>
          </div>
        </div>
        <div class="col-5">
          <h5 class="text-start">Exercise break text</h5>
          <div class="form-check text-start mt-4">
            <input class="form-check-input mb-2" type="checkbox" v-model="show_break_text" @change="change_show_break_text()">
            <label class="form-check-label">
              Show drink water prompt
              <span style="display: inline-block; margin-left: 5px;">
                <VTooltip placement="left">
                  <i class="bi bi-question-circle"></i>
                  <template #popper>
                    <p style="width: 400px;">
                      If chosen, the patient will be prompt to drink some water in between exercises.
                      If not chosen, the patient will be prompt to take adequate breaks
                    </p>
                  </template>
                </VTooltip>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-body m-2">
      <div class="row mt-4">
        <div class="mb-2">
          <div class="row mt-4 justify-content-start">
            <div class="col-6">
              <h5 class="text-start">Client Information</h5>
              <br/>

              <p class="text-start mb-0" v-if="email">Email: {{ email }}</p>
              <p class="text-start mb-0" v-if="account_auth_id">Account ID: {{ account_auth_id }}</p>
              <p class="text-start mb-0" v-if="password">Password: {{ password }}</p>
              <p class="text-start mt-4">UID: <small><i>{{ client_uid }}</i></small></p>
              <button v-if="client_uid != uid" class="btn btn-danger mb-2 float-start" data-bs-toggle="modal" data-bs-target="#delete_modal" type="button">
                <i class="bi bi-exclamation-triangle-fill"></i> 
                Delete Client
              </button>

              <div class="modal fade" id="delete_modal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Confirmation</h5>
                    </div>
                    <div class="modal-body">
                      Are you sure that you want to delete this account?
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="handleDelete()">Yes, delete</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-6">
              <h5 class="text-start">Share Patient</h5>
              <br/>
              <p class="text-start">Please enter the email of the Therapist that you want to share this patient with. Please note, you can only share patients with a Therapist from the same organisation.</p>
              <div class="row">
                <div class="col">
                  
                  <FormKit input-class="form-control" id="email" type="email" name="email" placeholder="Therapist email address" @keyup="isValidEmail" @focus="isValidEmail" v-model="receipient_slt_email" 
                    :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                    }"
                    messages-class="text-danger"
                    validation="required|email"
                  />
                </div>
                <div class="col-4">
                  <button class="ms-2 btn btn-primary w-100" @click="share_patient()" :disabled="!entered_valid_email"><i class="bi bi-person-up"></i> Share </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import { updateData } from '@/firebase/index'
import { useToast } from "vue-toastification";
import { getAuthToken, getDataDirectoryCallback, updateDataDirectory } from '@/firebase/index'
import axios from 'axios'
import { ref } from 'vue';
import CommunicationDifficulties from '@/views/common/settings/Communication_difficulties.vue'

export default {
    name: 'Client_Settings',
    components: { CommunicationDifficulties },
    props: {
      client_uid: String,
      client_name: String,
    },
    data() {
      return {
        uid: localStorage.getItem('uid'),
        intelligibility_diffLevel: 1,

        receipient_slt_email: '',
        entered_valid_email: false,
        is_processing: false,
        email: null,
        account_auth_id: null,
        password: null,
        api: import.meta.env.VITE_FAST_API,
        cannot_swallow: false,
        show_break_text: false,
      }
    },
    created() {
      let _this = this

      let intelligibility_grading_selection = localStorage.getItem('intelligibility_grading_selection')

      if(intelligibility_grading_selection==undefined || intelligibility_grading_selection==null) {
        intelligibility_grading_selection = 'default'
      }

      if(intelligibility_grading_selection==='default') {
        this.intelligibility_diffLevel = 1
      }

      if(intelligibility_grading_selection==='encouragement') {
        this.intelligibility_diffLevel = 0
      }

      let ref_path = "/Users/" + this.client_uid + "/Data"
      getDataDirectoryCallback(ref_path, (err, childSnapshot) => {
        const patient_data = childSnapshot.val()
        if ('account_auth_id' in patient_data) {
          _this.account_auth_id = patient_data['account_auth_id']
        }

        if ('password' in patient_data) {
          _this.password = patient_data['password']
        }

        if ('Email' in patient_data) {
          _this.email = patient_data['Email']
        }

      })


      let ref_path_settings = "/Users/" + this.client_uid + "/Settings"
      getDataDirectoryCallback(ref_path_settings, (err, childSnapshot) => {
        const patient_data = childSnapshot.val()
        if ('cannot_swallow' in patient_data) {
          _this.cannot_swallow = patient_data['cannot_swallow']
        }

        if ('show_break_text' in patient_data) {
          _this.show_break_text = patient_data['show_break_text']
        } else {
          if (!_this.cannot_swallow) {
            _this.show_break_text = true
          }

        }
      })

    },
    methods: {
      updateIntelligibilityGradingSelection() {
        const _this = this

        let intelligibility_grading_selection = 'default'

        if(this.intelligibility_diffLevel==1) {
          intelligibility_grading_selection = 'default'
        }
        else if(this.intelligibility_diffLevel==0) {
          intelligibility_grading_selection = 'encouragement'
        }

        localStorage.setItem('intelligibility_grading_selection', intelligibility_grading_selection);

        let client_data = 'Users/'+_this.client_uid+'/Settings'

        let pointer = _this.$firebase.database().ref(client_data)
        updateData(pointer, {'intelligibility_grading_selection':intelligibility_grading_selection})
      },
      isValidEmail() {
        // Regular expression for email validation
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.entered_valid_email = emailRegex.test(this.receipient_slt_email);
      },
      share_patient() {

        getAuthToken().then((idToken) => {

          let data = {
            'patient_uid': this.client_uid,
            'slt_uid': this.uid,
            'receipient_slt_email': this.receipient_slt_email
          }
          
          this.is_processing = true
          axios.post( this.api + "/share_patient", data, {
            headers: {
              'Authorization': `Bearer ${idToken}`,
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            
            if (response.data['status'] === "success") {
              const toast = useToast();
              toast.success("Patient has been successfully shared.");
            } else {
              const toast = useToast();
              toast.warning("There has been a system issue, please contact the Beautifulvoice team.");
            }
            this.is_processing = false
          })

        })

      },
      handleDelete() {
        let _this = this
        let patient_path = '/Users/' + this.uid + '/Clients/' + this.client_uid
        let ref = this.$firebase.database().ref(patient_path)

        console.error(ref.toString())
        ref.remove().then(function() {
            console.debug("Patient account deleted")
            _this.$router.push({ name: 'home' });
        })
      },
      change_cannot_swallow() {
        let ref_path_settings = "/Users/" + this.client_uid + "/Settings"
        updateDataDirectory(ref_path_settings, {'cannot_swallow': this.cannot_swallow})

        if (this.cannot_swallow && this.show_break_text) {
          this.show_break_text = false
          this.change_show_break_text()
        } 
      },
      change_show_break_text() {
        let ref_path_settings = "/Users/" + this.client_uid + "/Settings"
        updateDataDirectory(ref_path_settings, {'show_break_text': this.show_break_text})

        if (this.show_break_text && this.cannot_swallow) {
          this.cannot_swallow = false
          this.change_cannot_swallow()
        } 
      }
    }
}
</script>

<style scoped>

</style>