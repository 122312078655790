<template>
  <div>
    <div class="html2pdf__page-break" />

    <section class="pdf-item">

      <div class="row mt-2">
        <div class="card card-body"  style="border-color: blueviolet;">
          <div class="accordion" id="accordionWWCA">
            <div class="accordion-item mb-3">
              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WWCA_collapse" aria-expanded="false" aria-controls="WWCA_collapse"><p class="text-start" >
                  <b>Written Words Comprehension Assessment</b></p></button>
              </div>
              <div :class="['accordion-collapse', 'collapse', show_collapse ? 'show': '']" id="WWCA_collapse" data-bs-parent="#accordionWWCA">

            <div class="row mb-2">

      
                <div v-for="wwca, i of table">

              <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

                <!-- <p class="text-start" v-if="'Questionnaire_Name' in table[i]" ><b>Questionnaire {{ i +1}} - {{ all_results_tables['questionnaires'][i]['Questionnaire_Name'] }}</b></p> -->
                <p class="text-start" ><b>Written Words Comprehension</b> from {{table[i]['assessment_start']}} to {{table[i]['assessment_end']}}</p>

                <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

                <table class="table mt-4" >

                  <thead>
                    <tr>
                      <th scope="col">Target</th>
                      <th scope="col">Score</th>
                      <th scope="col">Phonological Distractor</th>
                      <th scope="col">Different features & position</th>
                      <th scope="col">Semantic Distractor</th>
                      <th scope="col">Unrelated Distractor</th>
                      <th scope="col">Delay (s)</th>

                    </tr>
                  </thead>
                
                  <tbody>
                    <tr v-for="row, index in table[i]['results']" v-bind:key="index">

                      <td v-if="row['selection']==='target'"><b>{{ row['target']['name'].charAt(0).toUpperCase() + row['target']['name'].slice(1)}}</b></td>
                      <td v-else>{{ row['target']['name'].charAt(0).toUpperCase() + row['target']['name'].slice(1)}}</td>

                      <td v-if="row['score']=='1-D'">1 - delay > 5s</td>
                      <td v-else-if="row['score']=='1-Sc'">1 - self-correction</td>
                      <td v-else-if="row['score']=='1-R'">1 - repetition of stimulus</td>

                      <td v-else>{{ row['score']}}</td>


                      <td v-if="row['selection']==='phonological distractor'"><b>{{ row['phonological distractor']['name'].charAt(0).toUpperCase() + row['phonological distractor']['name'].slice(1) }}</b></td>
                      <td v-else>{{ row['phonological distractor']['name'].charAt(0).toUpperCase() + row['phonological distractor']['name'].slice(1) }}</td>

                      <td >{{ row['phonological distractor']['n_features_different']+'df - ' + row['phonological distractor']['position']}}</td>

                      <td v-if="row['selection']==='semantic distractor'"><b>{{ row['semantic distractor']['name'].charAt(0).toUpperCase() + row['semantic distractor']['name'].slice(1) }}</b></td>
                      <td v-else>{{ row['semantic distractor']['name'].charAt(0).toUpperCase() + row['semantic distractor']['name'].slice(1) }}</td>

                      <td v-if="row['selection']==='unrelated distractor'"><b>{{ row['unrelated distractor']['name'].charAt(0).toUpperCase() + row['unrelated distractor']['name'].slice(1) }}</b></td>
                      <td v-else>{{ row['unrelated distractor']['name'].charAt(0).toUpperCase() + row['unrelated distractor']['name'].slice(1) }}</td>
                      <td>{{ row['delay'].toFixed(0) }}</td>


                    </tr>
                    <tr class="table-group-divider">


                      <th scope="col">{{ table[i]['average_results']['Target']}}/{{ table[i]['average_results']['Max Score']/2}}</th>
                      <th scope="col">{{ table[i]['average_results']['Score']}}/{{ table[i]['average_results']['Max Score']}}</th>

                      <th scope="col">{{ table[i]['average_results']['Phonological Distractor']}}/{{ table[i]['average_results']['Max Score']/2}}</th>
                      <th scope="col"></th>
                      <th scope="col">{{ table[i]['average_results']['Semantic Distractor']}}/{{ table[i]['average_results']['Max Score']/2}}</th>
                      <th scope="col">{{ table[i]['average_results']['Unrelated Distractor']}}/{{ table[i]['average_results']['Max Score']/2}}</th>
                      <th scope="col">{{ table[i]['average_results']['Mean_Delay'].toFixed(0)}}</th>



                    </tr>

                  </tbody>
                </table>
                


              </div>

              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'Word_finding',
  props: ['word_findingOverview', 'table', 'show_collapse'],
  setup(props, { emit }) {
    
    return {
      
    }

  }
}

</script>
<style scoped>


</style>





