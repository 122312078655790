<template>
  <div>
  <div class="container">

    <FormKit type="form" :actions="false" classs="mt-2" id="global_exercise_meta_data" @submit="submit">
      <div class="row">
        <div class="card card-body">
          <div class="v-speech_custom-0">
            <div class="row mb-4">
              <p class="text-start" for="exercise_name">Module name<span style="color:red">*</span></p>
              <div class="row">
                <VTooltip>
                  <FormKit input-class="form-control" id="exercise_name" validation-name="Module name" name="name" type="text" v-model="module_name" 
                    :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                      options: { $el: 'div' },
                      option: { $el: 'div' },
                    }"
                    messages-class="text-danger"
                    validation="required"
                  />
                  <template #popper>
                    Enter the name of your training module
                  </template>
                </VTooltip>
              </div>
            </div>

            
          </div>
        </div>
        <div class="card card-body mt-2">
          <div class="v-speech_custom-1">
            <p class="text-start mb-4">Words<span style="color:red">*</span></p>
            <p style="text-align: left; font-size: 14px;margin-top: -2%;">Upload images and add any acceptable names/synonyms. Separate synonyms with the symbol ";" </p>
            <p style="text-align: left; font-size: 12px; color: #494f54;margin-top: -1.5%;">E.g car;motor car;automobile;Ford;</p>


            <hr/>
            <div class="row mb-4" v-for="exercise_, index in exercises" :key="index">
              <div class="row">
                <div class="col-2 d-flex align-items-center justify-content-center">
                  <p class="text-center">{{ index + 1 }}. name </p>
                </div>
                <div class="col-6">
                  <VTooltip>
                    <textarea type="textarea" class="form-control" name="content" v-model="exercise_['text']" />
                    <template #popper>
                      Enter the image name 
                    </template>
                  </VTooltip>
                  <label v-if="!exercise_['text'] && validation_status === 'error'" :id="'validation_text_'+index" style="color: red;">
                    Please upload a image
                  </label>
                  <button   @click="search_synonyms(index)">
                     Search synonyms
                  </button>
                </div>
                <div class="col-3">
                  <button  v-if="!('image_url' in exercise_)" class="btn btn-primary"  @click="triggerFileInput(index)">
                     Upload image
                  </button>
                  <button  v-else class="btn btn-danger" @click="triggerFileInput(index)">
                    Replace image
                  </button>

                  <input
                    type="file"
                    ref="fileInputs"
                    :id="'file-input-' + index"
                    style="display: none;"
                    accept="image/*"
                    @change="(event) => handleImageUpload(event, index)"
                  />
                  <label v-if="!exercise_['image_url'] && validation_status === 'error'" :id="'validation_img_'+index" style="color: red;">
                    Please upload a image
                  </label>

                <div v-if="('image_url' in exercise_)" style="margin-top: 5%;">
                  <img :src="exercise_['image_url']" style="width:80px;height: 80px;" id="image-preview">
                </div>
                </div>
                <div class="col" >
                  <div class="float-end">
                    <button type="button" class="mt-4 btn btn-outline-danger" style='vertical-align: middle' @click="deleteExercise(index)"><i class="bi bi-x-lg"></i></button>
                  </div>
                </div>
                

              <hr class="mt-4"/>
              </div>
            </div>
            <div class="row">
              <div>
                <button type="button" class="btn btn-info w-25" @click="addExercise()"><i class="bi bi-plus-lg"></i> Add exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div class="row justify-content-center">
        <div class="col-6">
          <p>Save to: </p>
          <select class="form-select" v-model="module_location">
            <option v-if="!module_folder_locations.includes('General')" value="General">General</option>
            <option v-for="ml in module_folder_locations" v-bind:key="ml" :value="ml">{{ ml }}</option>
            <option value="__other__">Other Location</option>
          </select>
        </div>
      </div>
      <div class="row justify-content-center mt-2" v-if="module_location === '__other__'">
        <div class="col-6">
          <input type="text" class="form-control" v-model="custom_location" placeholder="Name of location"/>
        </div>
      </div>
      <div class="row mt-4 mb-4 justify-content-center">
        <button class="btn btn-secondary w-25 me-2" type="button" @click="cancel_submit">Cancel</button>
        <button class="btn btn-success w-25 me-2" type="submit" @click="add_homework = false">Save</button>
        <button class="btn btn-outline-success w-25" type="submit" @click="add_homework = true">Add to homework list</button>
      </div>
    </FormKit>
    

    

  </div>
</div>
</template>
<script>

import {updateData, updateDataDirectory, setData, getDataDirectoryCallback} from '@/firebase/index'
import { reactive } from 'vue'
import { useToast } from "vue-toastification";

import { search_synonyms} from '@/utils/interface_ai'

export default {
  name: 'picture_naming',
  components: {},
  props: {
    edit_meta_module_pointer: null,
    module_folder_locations: []
  },
  data(){
    return {
      module_location: 'General',
      custom_location: '',
      curr_item_index: 0,
      module_name: '',
      validation_status: 'unchecked',
      no_image_entered: [],
      global_exercise_meta_data: {
        instruct: '',
        voicetype: [],
        speechtype: [],
        assist: "",
      },
      imageUrls:{},
      exercises: reactive([]),
      custom_module_pointer: null,
      add_homework: false,

      client_uid: localStorage.getItem('client_uid')
    }
  },
  created() {


    let _this = this

    if (this.edit_meta_module_pointer) {

      let module_data = this.edit_meta_module_pointer.module_handle.val()

      _this.module_location = _this.edit_meta_module_pointer.location
      _this.module_name = _this.edit_meta_module_pointer.module_name

      var storageRef = _this.$firebase.storage().ref();


      for (let i=0; i<module_data['exercises'].length; i++) {

        let exercise_address = module_data['exercises'][i]['address']

        getDataDirectoryCallback(exercise_address, (err, exercise_data) => {


          let exercise_data_ = exercise_data.val()


          let Address = exercise_data_['Address']


          _this.exercises.push( {
            text: exercise_data_['text'],
            instruct: exercise_data_['instruct'],
            Address:exercise_data_['Address'],


          })

          let index = _this.exercises.length-1

          console.debug('Address = ' + Address)

          storageRef.child(Address[0]).getDownloadURL().then(function(url) {

            console.debug(url)

            
            _this.exercises[index]['image_url'] = url;
            }).catch(function(error) {

          });


          //these are global settings that we store in each exercise_data but for UI we only store once
          _this.global_exercise_meta_data['instruct'] = exercise_data_['instruct'] ? exercise_data_['instruct'] : ''

        });
      }

      
    } else {
      this.exercises.push({
        text: '',
       
      })
    }



  },
  mounted() {
  },
  methods: {
    triggerFileInput(index) {
      // Programmatically click the file input
      this.$refs.fileInputs[index].click();
    },
    async search_synonyms(index)
    {
      const _this = this

      let curr_words = this.exercises[index]['text']

      console.debug('curr_words = ' + curr_words)

      this.exercises[index]['text'] = await search_synonyms(_this,curr_words)

    },
    handleImageUpload(event,index) {

      console.debug('onImageUpload')

      console.debug(event)

      console.debug(event.target)
      console.debug(event.target.files)

      const file = event.target.files[0];
      console.debug(file)
      if (file) {
        this.exercises[index]['image_url'] = URL.createObjectURL(file);
        this.exercises[index]['file'] = file;

        this.exercises[index]['Address'] = undefined


        this.$forceUpdate
      }
    },
    addExercise() {
      this.exercises.push(
        {
          text: "",
        }      
      )

      this.validation_status = "unchecked"
    },
    deleteExercise(index) {
      if (this.exercises.length-1 > 0) {
        this.exercises.splice(index, 1)
      } else {
        alert("You need to have at least one exercise.")
      }
    },
    validate() {

      for (let i in this.exercises) {
        if (!this.exercises[i].text) {
          return false
        }
        if (!this.exercises[i].image_url) {
          return false
        }
      }

      return true
    },
    submit() {
      const _this = this

      const storage = this.$firebase.storage(); // Access the Firebase storage instance
      
      if (!this.validate()) {
        this.validation_status = 'error'
        return
      }


      let uid = localStorage.getItem('uid');
      
      if (this.module_location === '__other__') {
        if (this.custom_location === '') {
          this.module_location = 'General'
        } else {
          this.module_location = this.custom_location
        }
      }

      

      // for (let n=0; n < _this.exercises.length; n++) {

      //   if(!('file' in _this.exercises[n]) || _this.exercises[n].text.length<1)
      //   {
      //     alert('All exercises must contain the name field filled and an image uploaded before proceeding')
      //     return
          
      //   }


      // }

      

      let module_page_ref = _this.$firebase.database().ref('/Custom_Modules').child(uid).child(this.module_location).child('PictureNaming');

      let module_info = {
        mod_name: _this.module_name,
        plan_type:'random_assortment'
      }

      updateData(module_page_ref.child(_this.module_name), module_info);
      setData(module_page_ref.child(_this.module_name).child('exercises'), {});

      let base_exercise_address = 'Custom_Exercises/' + uid + '/' +this.module_location + '/PictureNaming/' + _this.module_name

      let exercise_page_ref = _this.$firebase.database().ref(base_exercise_address);

      let exercise_index = 0
      
      for (let n=0; n < _this.exercises.length; n++) {

        let Address = ''

        if(!('Address' in _this.exercises[n]) || _this.exercises[n]['Address']==undefined)
        {


          let random_identifier = Math.random().toString(16).slice(2) + '.png'
          let image_address = 'user_instructions/picture_naming_images/' + uid  + '/' + random_identifier

          console.debug('image_address = ' + image_address)

          const storageRef = storage.ref(image_address); // Reference to the desired storage location

          // Upload the file
          const uploadTask = storageRef.put(_this.exercises[n]['file']);

          Address = [image_address]
          
        }
        else
        {
          Address = _this.exercises[n]['Address']
        }

     



        let temp_form = {
          instruct: 'Name the picture',
          text: _this.exercises[n].text,  
          Address:Address,
          name:_this.exercises[n].text

      
        }

        temp_form['exercise_type'] = 'name_a_picture_easy'


        let exercise_address = base_exercise_address + '/' + exercise_index
        
        updateDataDirectory(exercise_address, temp_form)

        let module_exercise_info = {
          address: exercise_address,
          exercise_range:'single_exercise'
        }

        console.log(module_exercise_info)
        updateData(module_page_ref.child(_this.module_name).child('exercises').child(exercise_index), module_exercise_info);

        exercise_index = exercise_index + 1
      
      }

      _this.custom_module_pointer = module_page_ref.child(_this.module_name)

      if (this.add_homework) {
        this.addToHomeworkList()
      } else {
        const toast = useToast()
        toast.success("Module saved!")
        this.module_saved()
      }
    },
    async addToHomeworkList() {
      const _this = this
      let pro = await this.custom_module_pointer.get()
      _this.$emit('add_to_assignment_list', pro)
    },
    cancel_submit() {
      this.$emit('cancel_submit')
    },
    module_saved () {
      this.$emit('module_saved', this.module_location)
    }
    
  },
}
</script>

<style scoped>

</style>