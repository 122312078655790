<template>
  <div>

    <div class="container-fluid">
        <div class="card card-body">
          <Instructions v-if="!showGame" @showGame="(v) => showGame = v"/>
          <Module v-if="showGame" @goToResults="goToResults($event)" @nextExercise="nextExercise($event)"/>
        </div>
    </div>
  </div>
</template>
<script>


import Instructions from './components/instructions.vue'
import Module from './components/module.vue'

export default {
  name: "Reverse_direction",
  components: { Instructions, Module },
  data() {
    return {
      showGame: false,
      userCategory: '',
      results_data: '',
    }
  },
  created() 
  {
    const _this = this
    _this.userCategory = localStorage.getItem("userCategory")
  },

  methods: 
  {
    goToResults(reverse_direction_data) {

      console.log('reverse_direction_data = ' + reverse_direction_data)
      this.$emit('logExerciseResults', reverse_direction_data);
      this.$emit('goToResults', true);
    },
    nextExercise(reverse_direction_data)
    {

      console.log('reverse_direction_data2 = ' + reverse_direction_data)

      this.$emit('logExerciseResults', reverse_direction_data);

      this.$emit('nextExercise');

    }

  }
  
}
</script>
<style scoped>

</style>

<!-- Results Description
{
  "Reverse Direction_d5c9aabc96e81": { //Need to fix this
  "audio_address": "<empty>",
  "exercise": "Reverse Direction Game",
    "exercise_type": "Brain Games",
    "results": {
      "all_results": {
        "Correct": "<<int>",
        "Incorrect": "<int>"
      },
      "plot_results": {
        "Correct": "<int>",
        "Incorrect": "<int>"
      }
    },
    "results_type": "pre_computed"
  },
  "assessment_type": "USER_TRAINING",
  "plan_name": "Reverse Direction"
} -->