<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row"> 
          <p class="h3"> {{Ex_Description}}</p>
        </div>

        <div>
          <div v-if="interaction_message!=''" class="row" style="height: 15mm;">
            <p class="h5" :style="interaction_message_color"> {{  interaction_message}}</p> 
          </div>
          <div class="row mt-3">
            <div class="col-md-4 ">
            </div>

            <div class="col-md-4 ">
              <div class="card card-body" v-bind:style="{'width':'300px', 'height': '300px'}" :body-style="{ padding: '5px' }">
                <span class="h3 align-bottom">Target Shape:</span>

                <img id= "img_target" v-bind:src="all_img[target_img_idx]" width="250" height="250"   style="object-fit: cover" >        
              </div>  
            </div>
          </div>
        </div>

        <div v-if="n_images==2">
          <div class="row mt-4 justify-content-center">
            <div class="col-md-4">
              <div class="card card-body" v-bind:style="{'width':'280px', 'height': '280px','background':frameColour[0]}" :body-style="{ padding: '5px' }">
                <img id= "img_1" v-bind:src="all_img[0]"  @click="selectImg(0)"  style="object-fit: contain" >        
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-body" v-bind:style="{'width':'280px', 'height': '280px','background':frameColour[1]}" :body-style="{ padding: '5px' }">
                  <img id= "img_2" v-bind:src="all_img[1]"  @click="selectImg(1)" style="object-fit: contain">
              </div>
            </div>
          </div>
        </div>

        <div v-if="n_images==3">
          <div class="row mt-4 justify-content-center">
            <div class="col-md-4">
              <div class="card card-body" v-bind:style="{'width':'280px', 'height': '280px','background':frameColour[0]}" :body-style="{ padding: '5px' }">
                <img id= "img_1" v-bind:src="all_img[0]"  @click="selectImg(0)"  style="object-fit: contain" >        
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-body" v-bind:style="{'width':'280px', 'height': '280px','background':frameColour[1]}" :body-style="{ padding: '5px' }">
                <img id= "img_2" v-bind:src="all_img[1]"  @click="selectImg(1)" style="object-fit: contain">
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-body" v-bind:style="{'width':'280px', 'height': '280px','background':frameColour[2]}" :body-style="{ padding: '5px' }">
                <img id= "img_3" v-bind:src="all_img[2]"  @click="selectImg(2)" style="object-fit: contain">
              </div>
            </div>

          </div>
        </div>

        <div v-if="n_images==4">
          <div class="row mt-4 justify-content-center">
            <div class="col-md-3">
              <div class="card card-body" v-bind:style="{'width':'265px', 'height': '265px','background':frameColour[0]}" :body-style="{ padding: '5px' }">
                <img id= "img_1" v-bind:src="all_img[0]"  @click="selectImg(0)"    style="object-fit: contain" >        
              </div>
            </div>
            <div class="col-md-3">
              <div class="card card-body" v-bind:style="{'width':'265px', 'height': '265px','background':frameColour[1]}" :body-style="{ padding: '5px' }">
                <img id= "img_2" v-bind:src="all_img[1]"  @click="selectImg(1)" style="object-fit: contain">
              </div>
            </div>
            <div class="col-md-3">
              <div class="card card-body" v-bind:style="{'width':'265px', 'height': '265px','background':frameColour[2]}" :body-style="{ padding: '5px' }">
                <img id= "img_3" v-bind:src="all_img[2]"  @click="selectImg(2)"  style="object-fit: contain">
              </div>
            </div>
            <div class="col-md-3">
              <div class="card card-body" v-bind:style="{'width':'265px', 'height': '265px','background':frameColour[3]}" :body-style="{ padding: '5px' }">
                <img id= "img_4" v-bind:src="all_img[3]"  @click="selectImg(3)"  style="object-fit: contain">
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
          @next="nextExercise()"

          :has_show_results="show_results"
          @show_results="goToResults()"   
          >

        </Sidebar>
      </template>
    </Layout>
    <div class="modal fade" id="level_up_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Level up</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="m-3">
              <div class="row">
                <h4 >{{ level_up_message }}</h4>
              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Let's go</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'

import { Modal } from 'bootstrap'
import {startConfetti} from '@/common_js/confetti'

import {getData, updateDataDirectory} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'

import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'

export default {
  name: "Matching_shapes",
  /*

  {
   "exercise_type":"memory_image",
   "instruct":"memory_image",
   "n_images":8,
   "n_words":5,
   "name":4,
   "plan_name":"Memory (image)",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Memory (image)/4",
   "ex_path":"Memory (image)/4",
   "today":"2023-07-11 17:24",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"memory_image",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"memory_image",
   "module_name":"Memory (image)",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
  props: {
    exForm: {
      type: Object,
    }
  },
  components: {Layout, Sidebar},
  data() {
    return {
      Ex_Description: 'Click on the shape that is the same as the target shape',
      target_img_idx:0,
      listen_count:0,
      destroying: false,
      first_answer:true,
      n_images: 2,
      current_level:0,
      interaction_message_color:'',
      interaction_message:'',
      level_up_counter:0,
      level_up_target:2,
      level_properties:{
        1:{n_images:2,multi_colour:false,only_simple_shapes:true},
        2:{n_images:3,multi_colour:false,only_simple_shapes:true},
        3:{n_images:3,multi_colour:false,only_simple_shapes:false},
        4:{n_images:4,multi_colour:false,only_simple_shapes:false},
        5:{n_images:4,multi_colour:true,only_simple_shapes:true}
      },
      level_up_message:'You have been doing really well! Let’s try something harder.',
      results:{correct:0,incorrect:0,n_images:0, total:0},
      shape_folder_data:{},
      base_level_address:'/Levels/Matching_shapes',
      level_address:'',
      multi_colour:false,
      only_simple_shapes:true,
      frameColour: ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
      frameColour_mem:  ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
      all_img:[],
      img_idx: ['img_1','img_2','img_3','img_4','img_5','img_6','img_7','img_8'],
      chosen_images:[],
      audioTimeout: null,
      curr_ex_done:false,
      current_exercise_index:1,
      total_exercises: 1,
      show_results:false,
      bv_tts: useTTS().tts_engine,
    }
  },
  created() {
    logActivity( 'accessed', 'matching_shapes')
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()

    this.destroying = true

    clearTimeout(this.audioTimeout)
  },
  mounted() {
    this.setup()
  },
  methods: 
  {
    setup() {

      const _this = this

      _this.show_results = _this.exForm.current_exercise_index >= 1

      _this.current_exercise_index = _this.exForm.current_exercise_index + 1

      _this.total_exercises = _this.exForm.total_all_type_exercises

      setTimeout(() => {
        _this.bv_tts.say_sentences([this.Ex_Description])
      }, 500)

      _this.loadDifficulty();
    },
    loadDifficulty()
    {

      let _this = this

      let userType = localStorage.getItem('userCategory');

      let uid = localStorage.getItem('uid');

      this.level_address = '/Users/' + uid + this.base_level_address

      if(userType!=undefined || userType==='slp') {
        let client_number = localStorage.getItem('client_uid');

        if(client_number != 'undefined' && client_number != null && client_number != '') {
          this.level_address = '/Users/'+ client_number + this.base_level_address
        }
      }

      getData(this.level_address, function (err, result) {

        let current_level = result.child('current_level').val()
        let lvl_up_counter = result.child('level_up_counter').val()

        if(current_level == undefined || lvl_up_counter==undefined) {
          console.debug('level reload')
          current_level = 1
          lvl_up_counter = 0
          updateDataDirectory(_this.level_address,{current_level:current_level, level_up_counter:lvl_up_counter})
        }

        console.debug('current_level = ' + current_level)

        if(current_level<1 || current_level>Object.keys(_this.level_properties).length) {
          current_level = 1
        }

        _this.current_level = current_level

        _this.level_up_counter = lvl_up_counter

        let curr_level_config = _this.level_properties[_this.current_level]

        _this.n_images = curr_level_config['n_images']
        _this.only_simple_shapes = curr_level_config['only_simple_shapes']
        _this.multi_colour = curr_level_config['multi_colour']

        _this.loadShapes()            
      })
    },
    loadShapes() {
      let _this = this;
      let shape_folder_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('Shapes');

      getData(shape_folder_ref , function (err, result) {

        _this.shape_folder_data = result.val();

        _this.randomiseImages2Use()
      });
    },
    randomiseImages2Use()
    {

      const _this = this
      
      let selectedShapesForRandomisation = []

      if(this.multi_colour)
      {
        for(let shape_complexity in this.shape_folder_data)
        {

          if(!this.only_simple_shapes || shape_complexity==='Simple')
          {
            for(let colour in this.shape_folder_data[shape_complexity])
            {
              for(let shape in this.shape_folder_data[shape_complexity][colour])
              {
                selectedShapesForRandomisation.push(this.shape_folder_data[shape_complexity][colour][shape])
              }
            }

          }
        }
      }
      else
      {
        let all_colours = Object.keys(this.shape_folder_data['Simple'])
        let n_colours = all_colours.length


        let target_colour_index = Math.floor(Math.random() * n_colours)

        let target_colour = all_colours[target_colour_index]

        for(let shape_complexity in this.shape_folder_data)
        {

          if(!this.only_simple_shapes || shape_complexity==='Simple')
          {
            for(let shape in this.shape_folder_data[shape_complexity][target_colour])
            {

              selectedShapesForRandomisation.push(this.shape_folder_data[shape_complexity][target_colour][shape])
            }
          }
        }
        
      }


      _this.target_img_idx = Math.floor(Math.random() * _this.n_images) 

      let k = 0

      while(k<_this.n_images)
      {
        let curr_set_num = Math.floor(Math.random() * selectedShapesForRandomisation.length ) 

        if(!_this.chosen_images.includes(curr_set_num))
        {
            _this.chosen_images.push(curr_set_num);
            let img_address = selectedShapesForRandomisation[curr_set_num]['image'];



            var storageRef = _this.$firebase.storage().ref();
            const x = k
            storageRef.child(img_address).getDownloadURL().then(function(url) {

              _this.all_img[x] = url;
              document.getElementById(_this.img_idx[x]).src = url;

              if(x==_this.target_img_idx)
              {
                _this.load_target_image()
              }
            }).catch(function(error) {

            });
            k=k+1;
        }
      }

    },
    load_target_image()
    {
      const _this = this
      document.getElementById('img_target').src = _this.all_img[_this.target_img_idx];
    },
    storeResults()
    {
      const _this = this;

      if(_this.curr_ex_done) {
        let all_results = {
          Correct: _this.results['correct'], 
          Incorrect: _this.results['total'] - _this.results['correct'], 
          Total: _this.results['total'], 
          Level: _this.current_level
        }

        let plot_results = {
          Correct: _this.results['correct'], 
          Incorrect: _this.results['total'] - _this.results['correct']
        }

        let results = {
          all_results: all_results, 
          plot_results: plot_results
        }
        
        let matchingShapesData = {
          resultsTablePre: {
            exercise_type: 'Matching Shapes', 
            exercise: 'Matching Shapes', 
            results: results, 
            audio_address: ''
          }
        }

        save_pre_computed_results(_this.exForm, matchingShapesData)

        _this.$emit('logExerciseResults', matchingShapesData);

      }
     
    },
    nextExercise()
    {
      this.storeResults()
      this.$emit('nextExercise');
    },
    goToResults()
    {
      const _this = this
      this.storeResults()
      
      _this.$emit('goToResults', _this.curr_ex_done);
    },
    selectImg(img_num)
    {
      const _this = this
      this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];
      this.frameColour = this.frameColour_mem;

      _this.results['n_images'] = _this.n_images    

      if(this.target_img_idx==img_num)
      {
        this.frameColour[img_num] = '#00FF00';

        if(this.first_answer)
        {
          this.first_answer = false
          _this.results['correct'] = _this.results['correct']  + 1
          
          _this.level_up_counter = _this.level_up_counter+1

          console.debug('_this.current_level = ' + _this.current_level)

          if(_this.level_up_counter>=_this.level_up_target && _this.current_level<Object.keys(_this.level_properties).length)
          {
            _this.current_level = _this.current_level+1
            _this.level_up_counter = 0

            let level_up_pop = new Modal(document.getElementById('level_up_pop'));
            level_up_pop.show()

          }

          updateDataDirectory(this.level_address,{current_level:_this.current_level,level_up_counter:_this.level_up_counter})

        }



        let correct_text = 'Correct.'

        this.interaction_message = 'Correct. \n Press next to do more.'
        this.interaction_message_color = 'color:green;white-space: pre-wrap'



        
        _this.bv_tts.system_say_sentences([correct_text])

        startConfetti(this)

      } else {
          
        if(this.first_answer) {
          this.first_answer = false
          _this.results['incorrect'] = _this.results['incorrect']  + 1     
          
          _this.level_up_counter = _this.level_up_counter-1

          _this.level_up_counter = Math.max(_this.level_up_counter,0)
          updateDataDirectory(this.level_address,{current_level:_this.current_level,level_up_counter:_this.level_up_counter})
        }

        this.frameColour[img_num] = '#FF0000';

        let incorrect_text = 'Not correct'

        this.interaction_message = 'Not quite right. \n Try again.'
        this.interaction_message_color = 'color:red;white-space: pre-wrap'
        _this.bv_tts.system_say_sentences([incorrect_text])

      }
      _this.curr_ex_done = true
      _this.results['total'] = 1
      this.frameColour_mem = this.frameColour;

    },

  },
}
</script>
<style scoped>
.std-pre{
  word-break: break-all

}
.page_title {
  width: 100%;
  height: 13mm;
  background-color: #7cc543;
    /* opacity: 80%; */
}
.page_main {
  width: 100%;
  height: calc(100vh - 13mm);
  background-color: #195e52;
  /* opacity: 80%; */
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 13mm;
  margin-left: 2%;
  font-size: 30px;
}
.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}

.text_item {
/* margin-bottom: 18px; */
font-size: 30px;
/* vertical-align: middle; */
/* padding: 40px 200px; */
/* height:40%; */
}

.custom-text{
  font-size: 20px;
    /* color:#FFFFFF; */
}

.menu__item {
  display: block;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 24px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}

.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

.button::-moz-focus-inner{
  border: 0;
  padding: 0;
}


</style>

<!-- Result Description
{
  "Memory Image":
  {
    "<key>": {
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>", //audio address is here because that's how I used to find where the results are
        "exercise": "Memory Image",
        "exercise_type": "Memory Games",
        "results": {
          "all_results": { 
            "Correct": "<int>",
            "Incorrect": "<int>",
            "ListenCount": "<int>",
            "Total": "<int>"
          },
          "plot_results": { //These values are the ones that feed into the end results plot
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Memory Image"
  }
} -->