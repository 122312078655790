<template>
  <div>
    <div class="card card-body mt-2">
      <span style="display:flex;justify-content:center;font-size:22px"> {{name}}</span>
      <div class="row mt-2 justify-content-center">
        <div v-for="(row, index) in selected_features" class="row" style="margin-bottom: 20px;">
          <!-- Row Container -->
          <div 
            style="display: flex; align-items: center; gap: 10px;"
          >
            <!-- Label Bar -->
            <div
              :style="{
                backgroundColor: row['color'],
                width: '20%',
                padding: '10px',
                textAlign: 'left',
                height:'50px',
                borderRadius: '8px',
                color: '#fff',
              }"
            >
              {{ row['name'] }}
            </div>

            <div
      style="width: 2px; height: 40px; background-color: #000; margin: 0 5px;"
    ></div>

            <div v-if="results[index] > 0"
              :style="{
                backgroundColor: row['color'],
                height:'50px',
                width: (results[index] / total_exercises * 60) + '%',
                borderRadius: '8px',
              }"
            >
            </div>

            <!-- Result Number -->
            <div style="font-size: 16px; font-weight: bold; color: black;">
              {{ results[index] }}/{{ total_exercises }}
            </div>
          </div>
        </div>

          </div>

    </div>
  </div>
</template>
  
<script>
import { height } from '@fortawesome/free-solid-svg-icons/fa0';
import { right } from '@popperjs/core';
import { result } from 'lodash';




  export default {
    name: 'bar_result',
    components: {},
    props: {
      results: {
        type: Array,
        default: {}
      },
      selected_features: {
        type: Array,
        default: {}
      },
      name: {
        type: String,
        default: ''
      }
    },
    setup(props) {
      let t_exercises = 0
      const results = props.results
      const selected_features = props.selected_features

      for(let k in selected_features)
      {
        t_exercises = t_exercises+ results[k]
      }

      if(t_exercises<=0)
      {
        t_exercises = 1
      }

      const total_exercises = t_exercises


      return {
        total_exercises
      };

    },
  }
</script>
