<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row"> 
          <p class="h3"> {{instructions}}</p>
        </div>
        <div class="row">
          <div class="card card-body">
            <div class="row">
              <div class="col-12">
                <img class="backimg" :src="cookieTheftImg" style="width: 60%; object-fit:cover">
              </div>
            </div>
            <div class="row justify-content-center" v-if="!(hasSPLMeter||hasSpectrogram)" id="meter-row" >
              <div class="col-6">
                <canvas id="meter" height="30"></canvas>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          >
          <template v-slot:top>
            <div v-if="audioCaptureOn">
              <button v-if="action==='record'" class=" btn-primary btn btn-lg w-100 mt-2" @click="playPause()">Start</button>
              <button v-if="action==='recording'" class=" btn-info btn btn-lg w-100 mt-2" @click="playPause()">End</button>
              <button v-if="action==='recorded'" class=" btn-primary btn btn-lg w-100 mt-2" @click="goToNextStage()">Use Recording</button>
            </div>

            <div style="margin-top: 20mm;">
              <div class="row">
                <i class="bi bi-sign-stop" style="color: #f56c6c;font-size: 80px;"></i>
                <p v-if="audioCaptureOn">Stop if your voice is strained</p>
                <p v-else>Stop if your muscles are strained</p>
              </div>
            </div>
          </template>
        </Sidebar>
      </template>
    </Layout>
  </div>
</template>

<script>

import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'

import homeplanImg from '@/assets/images/homeplan.png'
import audioImg from '@/assets/images/audio_logo.png'

import cookieTheftImg from '@/assets/images/cookie_theft.png'

import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import {checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia} from '@/media_input/microphone_recording'
import smoothie from 'smoothie'
import {removeWeirdCharactersFromString,removeQuotesAndSimilarForAnalysis} from '@/media_input/utils'
import {updateDataDirectory } from '@/firebase/index'
import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'
import { BV_Points } from '@/utils/bv_points'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {generate_results_path} from '@/utils/results/data_save_utils'

export default {
    name: 'cookie_theft',
    props: {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data() {
      return {
        instructions:'Press START. Say aloud everything that you see on the image below. Then press END.',
        cookieTheftImg:cookieTheftImg,
        action:'record',
        stored_audio_blobs:{'cs':null, 'sv':null},
        circles:[
          {
            id: "1",
            size: 0,
            steps: 10,
            value: 0,
            strokeColor: "#7cc543",
            underneathStrokeColor: "grey",
            stepLength: 1,
            label: ''
          }
        ],
        hasTimer: false,
        push_structure:null,
        audio_blobs:[],
        ex_descriptions:['Read the sentence out loud'],
        ex_texts:['When the sunlight strikes raindrops in the air, they act as a prism and form a rainbow. The rainbow is a division of white light into many beautiful colours. These take the shape of a long round arch, with its path high above, and its two ends apparently beyond the horizon.',''],
        seconds_passed: 0,
        showTimer:true,
        frequency_data:[],
        progress: 0,
        timeLimit:5,
        timePassed:0,
        timerInterval:null,
        maxdBValue:80,
        mindBValue:40,
        hasVideoInstructions: false,
        hasAudioInstructions: false,
        video_instructions_address: '',
        audio_instructions_address: '',
        audioImg: audioImg,
        sound_l:'',
        sound_l2:'',
        chart_data: null,
        chart_data_spect: null,
        smoothie_spect: null,
        start_btn_text: 'Start',
        smoothie: null,
        drawInterval: null,
        Ex_Text: '',    
        Ex_Description: [],
        ex_name: '',
        avg_loudness: 0,
        avg_loudness_show: 0,
        hasSPLMeter: false,
        last_loudness: 0,
        total_loudness: 0,
        counter_loudness: 0,
        audioCaptureOn: true,
        hasSpectrogram: false,
        chart_not_created: true,
        chart_not_created_spect: true,
        canvasContext: null,
        tts_enabled: true,
        play_pause_text: 'Record',
        finished_msg: '',
        play_status: true,
        current_exercise_index: 0,
        total_exercises: 1,
        homeplanImg: homeplanImg,
        crossImg: crossImg,
        doneIcon: doneIcon,
        inputAcc:'',
        modules_results_path: null,
        resultsTableBack: [],
        has_checkbox: false,
        checkbox_done: false,
        has_results: false,
        is_recording: false,
        url:'',
        url2image:'',
        userType: 'patient',
        client_number: null,
        uid: '',
        loud_target:65,
        timerID: 0,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points()
      }
    },
    created() {
      let _this = this;
      
      checkPermissionsMedia(); 
      _this.nextExercise();
      
      logActivity( 'accessed', 'cookie_theft')

      
      // var user = _this.$firebase.auth().currentUser;
      // let uid = user.uid;

      _this.userType = localStorage.getItem('userCategory');

      if(this.userType=='slp')
      {
          let client_number = localStorage.getItem('client_uid');

          if(client_number!='undefined' && client_number!=null && client_number!='')
          {
            _this.client_number = client_number; 
          }
      }

      let uid = localStorage.getItem('uid');
      _this.uid = uid;
        
    },

    beforeUnmount()
    {
        
        const _this = this
        this.bv_tts.cancel()


        clearInterval(window.refreshIntervalId)
        window.speechSynthesis.cancel()


        if(_this.is_recording)
        {

            _this.endRecordingAudio(true);
        }
        else
        {
            _this.endRecordingAudio(false);
        }

        deleteSoundMeter()



    },
    mounted: function()
    {

      

      // this.renderGraphs()
   
    },
    updated: function()
    {

    },

    methods: 
    {
      renderGraphs()
      {
        const _this = this
            
        if(_this.hasSPLMeter)
        {
          document.getElementById("SPL-div").hidden = false
          
          _this.createChart()


        }
        else
        {
          document.getElementById("SPL-div").hidden = true
        }


        if(_this.hasSpectrogram)
        {
          document.getElementById("Spect-div").hidden = false
          
          _this.createChartSpect()

        }
        else
        {
          document.getElementById("Spect-div").hidden = true
        }

      },
     
      nextExercise()
      {
        const _this = this;
        
        if(_this.is_recording)
        {
            // _this.endRecordingAudio(true)
        }

        _this.pause();

        _this.loadSingleExercise()


      },
      goToNextStage()
      {
       this.goToResults()

      },
      repeatExercise()
      {
        const _this = this



        if(_this.play_status)
        {

          console.debug('_this.avg_loudness = ' + _this.avg_loudness)

          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0)
          {
            avg_loudness_array.push(_this.avg_loudness)
          }

          console.debug('avg_loudness_array = ' + avg_loudness_array)

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;



          if(_this.is_recording)
          {
            _this.is_recording = false

            _this.endRecordingAudio(true)

          }


          // let resultsTableBack = []
          // for(let i in _this.resultsTableBack)
          // {
          //   resultsTableBack.push(_this.resultsTableBack[i])

          // }

          // var basicData = {resultsTableBack: resultsTableBack, avg_loudness_array: avg_loudness_array}

          // _this.resultsTableBack = []
          // _this.$emit('logExerciseResults', basicData);

          _this.play_status = false

          _this.play_pause_text = _this.start_btn_text

          

        }
        else
        {
          this.action = 'record' 
        }

      },
      startTimer() {
        const _this = this

        _this.timerInterval = setInterval(function() {

          if(_this.timePassed<_this.timeLimit)
          {
            _this.timePassed += 1
          }
          else
          {

            clearInterval(_this.timerInterval)


            _this.playPause()


            _this.timePassed = 0
            _this.seconds_passed = 0
            _this.progress = 0



            _this.restart_now = true

          }
          
         }, 1000);
      },

      stopTimer() {
        let _this = this

        _this.hasTimer = false
        _this.seconds_passed = 0
        _this.progress = 0

        _this.avg_loudness = 0;
        _this.total_loudness = 0;
        _this.counter_loudness = 0;

        if(_this.is_recording)
        {
          _this.is_recording = false

          _this.endRecordingAudio(true)

        }


        clearInterval(window.refreshIntervalId)
        clearInterval(_this.timerID)
      }, 

      loadSingleExercise()
      {
          
        const _this = this



      
      //   _this.Ex_Text = _this.exForm.text; 

      
        _this.tts_enabled = true




        _this.current_exercise_index = _this.exForm.current_exercise_index + 1
        _this.has_results = _this.exForm.has_results
        _this.total_exercises = _this.exForm.total_all_type_exercises


        if(_this.exForm.start_btn_text!=undefined && _this.exForm.start_btn_text!=null)
        {
          _this.start_btn_text = _this.exForm.start_btn_text
          _this.play_pause_text = _this.start_btn_text
        }


        _this.bv_tts.say_sentences([this.instructions])


    

        let captureAudio = _this.exForm.capture_audio

        if(captureAudio != undefined && captureAudio != null)
        {
          _this.audioCaptureOn = captureAudio
        }




      },

      runTimer() {

    

        var interval_time = 250;

        const _this = this;

        clearInterval(window.refreshIntervalId)
        


        window.refreshIntervalId = setInterval(function () {

        
        

        if (soundMeter != null) {



            

            // if(element!=null)
            {

              // var sound_l = soundMeter.instant.toFixed(2) ;
              var sound_l = soundMeter.db.toFixed(2);
              _this.sound_l2 = soundMeter.db2.toFixed(2);
              var max_freq_index = soundMeter.max_freq_index

              if(max_freq_index==undefined || max_freq_index==null)
              {
                max_freq_index = 0
              }


              if(_this.hasTimer==false && sound_l>50)
              {
                _this.hasTimer = true;
                _this.seconds_passed = 1;

                //timer indicator
                _this.timerID = setInterval(function () {
                  _this.seconds_passed = _this.seconds_passed + 1
                  if (_this.progress <= 99 ) {
                    _this.progress = _this.progress + 5;
                  }
                }, 1000);
              }
              
              max_freq_index = max_freq_index.toFixed(2);


              // let sound_calibration = 20;
              let sound_threshold = 35;
              

              console.debug('sound_l = ' + sound_l)
              if(sound_l>sound_threshold)
              {
              
                _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
                
                _this.avg_loudness_show = _this.roundNumber(_this.avg_loudness,0)

                console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)


                _this.counter_loudness += 1;

                
              
              }

                const min_spect_loudness = 50



                if(_this.hasSpectrogram && sound_l>min_spect_loudness)
                {


                  
                  _this.addDataChartSpect(max_freq_index)


                  _this.frequency_data.push(Number(max_freq_index))

              

                }

              if(!(_this.hasSPLMeter||_this.hasSpectrogram))
              {
                let element = document.getElementById("meter")

                _this.canvasContext = element.getContext("2d");
                _this.drawLoop(sound_l);

              }




              if(_this.hasSPLMeter)
              {



              // _this.addDataChart(max_freq_index)
              _this.addDataChart(sound_l)
              }

            
            
            }

        }

        }, interval_time);
      

      },
      roundNumber(number,number_of_decimals)
      {   
        let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)

        return rounded_number;

      },
      skipExercise()
      {
        const _this = this

        _this.$emit('nextExercise');

      },
      goToResults()
      {
        const _this = this

        // if(_this.play_status)
        // {
        //   _this.last_loudness = _this.avg_loudness

        //   let avg_loudness_array = []

        //   if(_this.avg_loudness>0)
        //   {
        //     avg_loudness_array.push(_this.avg_loudness)
        //   }

        //   _this.avg_loudness = 0;
        //   _this.total_loudness = 0;
        //   _this.counter_loudness = 0;



        //   if(_this.is_recording)
        //   {
        //     _this.is_recording = false

        //     _this.endRecordingAudio(true)

        //   }



        // }

        console.debug('go to results')

        var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: [0]}

        _this.$emit('logExerciseResults', basicData);
        _this.$emit('goToResults', true);
      },
      playPause()
      {
          
        const _this = this;

        this.bv_tts.cancel()

        console.debug('this.play_status = ' + this.play_status)


        if(_this.play_status)
        {

          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0)
          {
            avg_loudness_array.push(_this.avg_loudness)
          }

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;

          this.play_status = false


          if(_this.is_recording)
          {
            _this.is_recording = false

            _this.endRecordingAudio(true)

            
  
            clearInterval(window.refreshIntervalId)
            window.speechSynthesis.cancel()

            this.action='recorded'

            this.goToNextStage()
    
    
            // deleteSoundMeter()
          }



          // var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}


          // _this.$emit('logExerciseResults', basicData);

          
          // _this.$emit('nextExercise');
          
          // _this.hasTimer = false;
          
        }
        else
        {
            _this.play_status = true;
            _this.play_pause_text = 'Stop Recording'

            this.action='recording'


            // clear_blobs()





            _this.resumeRecording()

            // if(_this.hasSPLMeter)
            {
                
                _this.runTimer()
            
            }



   


        }

      },
      resumeRecording()
      {
          var _this = this;
      
          // resumeRecordingMedia();
          if(!_this.is_recording)
          {
              recordAudioMedia()
              _this.is_recording = true;
          }
  
      },
      pauseRecordingAudio()
      {

          const _this = this;

          if(_this.is_recording)
          {
              stopRecordingMedia(null,null,null);
              _this.is_recording = false;

          }
      

      },
      endRecordingAudio(save_audio)
        {

        
            const _this = this;

  
    
            if(save_audio)
            {
  
  
              let results_addresses = generate_results_path(this.exForm)

              let random_identifier = results_addresses['random_identifier']

              let exercises_results_path = results_addresses['exercises_results_path']
              let modules_results_path = results_addresses['modules_results_path']

              let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;
    
              if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
                audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
              }

              var audio_address = 'audios/' + audio_folder + '.mp3';

              audio_address = audio_address.replaceAll('//','/')


              
              let today = _this.exForm.today
              
              let voicetype = _this.exForm.voicetype
              let speechtype = _this.exForm.speechtype
              
            
              _this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')

              updateDataDirectory(modules_results_path, {plan_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})

              let name = 'Cookie theft'
              let push_structure = {'date':today, 'name': name, 'path':exercises_results_path , 'audio_address':audio_address, 'uid':_this.uid, 'loudness':_this.last_loudness,'localisation':_this.region}

              let ground_truth = 'cookie_theft'

              console.log('ground_truth = ' + ground_truth)

              if(ground_truth!=undefined && ground_truth!=null)
              {

                ground_truth = removeQuotesAndSimilarForAnalysis(ground_truth)

              }
              else{
                ground_truth = ''
              }



              let has_analysis = true


              push_structure['cookie_theft'] = {'type':'cookie_theft'}




              console.debug('push_structure')


              console.debug(push_structure)


              if(has_analysis)
              {
                _this.resultsTableBack.push(exercises_results_path)
                _this.modules_results_path = modules_results_path
                // console.debug('path = ' + modules_results_path)
                // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
                stopRecordingMedia(audio_address, push_structure, _this);

            
              }
              else if('capture_audio' in _this.exForm && _this.exForm.capture_audio)
              {
                push_structure['real_life_exercise'] = this.Ex_Text

                _this.resultsTableBack.push(exercises_results_path)
                _this.modules_results_path = modules_results_path
                // console.debug('path = ' + modules_results_path)
                // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
                stopRecordingMedia(audio_address, push_structure, _this);


              }
              else
              {


                stopRecordingMedia(audio_address, null, null);

              }
                
            }
            else
            {
                stopRecordingMedia(null,null,null);
            }            

  
            this.is_recording = false;
  
        }, 
    createChart()
    {
      const _this = this
      
    //   return
      if(_this.chart_not_created)
      {
        _this.chart_not_created = false
        console.debug('creating chart')
        console.debug('this.loud_target = ' + this.loud_target)
        _this.smoothie = new smoothie.SmoothieChart({millisPerPixel:11,grid:{fillStyle:'#dfd7d7',verticalSections:2},labels:{fillStyle:'#000000',fontSize:15},horizontalLines:[{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}],maxValue:_this.maxdBValue,minValue:_this.mindBValue})
        var canvas = document.getElementById("loud_canvas")
        _this.chart_data = new smoothie.TimeSeries();
        _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
        // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
        _this.smoothie.streamTo(canvas, 500);

      }
    },
    updateChart()
    {
      const _this = this


      let uid = localStorage.getItem('uid');
      var ref = _this.$firebase.database().ref('Users').child(uid).child('Data');


      _this.smoothie.stop()

      _this.smoothie.options.horizontalLines = [{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}]
      
      _this.smoothie.start()
      // var canvas = document.getElementById("loud_canvas")
      // _this.chart_data = new smoothie.TimeSeries();
      // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
      // // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
      // _this.smoothie.streamTo(canvas, 500);

    },
    createChartSpect()
    {
      const _this = this
      
      if(_this.chart_not_created_spect)
      {
        _this.chart_not_created_spect = false
        console.debug('creating spec chart')


        _this.smoothie_spect = new smoothie.SmoothieChart({millisPerPixel:11,labels:{fillStyle:'#000000',  precision:0},grid:{fillStyle:'rgba(40,40,154,0.60)',strokeStyle:'#ffffff', verticalSections:4},maxValue:500,minValue:50})
        var canvas = document.getElementById("spect_canvas")
        _this.chart_data_spect = new smoothie.TimeSeries();

        _this.smoothie_spect.addTimeSeries(this.chart_data_spect, {lineWidth:3.3,strokeStyle:'#00ff00'});
        _this.smoothie_spect.streamTo(canvas, 500);

      }

    },
    addDataChart(in_data)
    {
      const _this = this
      if(_this.smoothie!=undefined && _this.smoothie!= null)
      {
        _this.chart_data.append(new Date().getTime(),in_data)
      }

    },
    addDataChartSpect(in_data)
    {
      const _this = this
      if(_this.smoothie_spect!=undefined && _this.smoothie_spect!= null)
      {
        _this.chart_data_spect.append(new Date().getTime(),in_data)
      }
    },
    pause()
    {
        const _this = this;
        _this.play_status = false;
        _this.play_pause_text = _this.start_btn_text

        clearInterval(window.refreshIntervalId)

    },
    drawLoop(sound_l) {
      // clear the background

      var _this = this;


      var WIDTH = 400;//document.getElementById('meter').clientWidth;
      var HEIGHT = 30;//document.getElementById('meter').clientHeight;;

      var green_threshold = 50;

      // console.debug("Width = " + WIDTH);
      
      // console.debug("HEIGHT = " + HEIGHT);

      _this.sound_l = sound_l;



      if(_this.canvasContext != undefined && _this.canvasContext !=null)
      {
        _this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
      
        if (sound_l > green_threshold) {
          _this.canvasContext.fillStyle = "green";


        }
        else {

          // _this.silence_flag = true;

          _this.canvasContext.fillStyle = "red";
        }
        _this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
                // _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);


      }

    // draw a bar based on the current volume


    },


    
  
    playAudioInstructions(url)
    {
      document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="50px" width="400px" controls autoplay> </video>';
      var player =document.getElementById('movie');
      player.load();
    },

   
    }
}
</script>

<style scoped>
  .text {
    font-size: 14px;
  }

  .text_item {
    /* margin-bottom: 18px; */
    font-size: 30px;
    /* vertical-align: middle; */
    padding: 40px 0;
    /* height:80px; */
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 60%;
    margin:auto;
  }

  .demo-input-label {
    display: inline-block;
    width: 130px;
  }

  .legend-custom-style {
    font-size: 20px;
  }

  .backimg {
    position: relative;
    top: 0;
    left: 0;
    /* border: 1px red solid; */
  }


  

</style>

