<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>
    <div class="container mb-5">
      <h1 class="display-5">Read sentences aloud</h1>

      <div class="card p-2 text-center">
        <div class="row">
          
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>1</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> News highlights</p>
                <img :src="newsImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="generalNewsDashboard()"> Start the exercise</button>
                <br/> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="generalNewsDashboard()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('news')"> Exercise overview</button>
                <p> To choose news topics, update your <b style="color:#f56c6c;cursor:pointer" data-bs-toggle="modal" data-bs-target="#settingsModal" @click="checkSetting=true"> Topics of interest </b> </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>2</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Fun facts about your interests</p>
                <img :src="fun_factsImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="fun_facts()"> Start the exercise</button> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="fun_facts()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('fun_facts')"> Exercise overview</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>3</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Pub quiz</p>
                <img :src="quizImg" width="120" height="120" style="object-fit: cover">
                <!-- <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span> -->
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="goToQuiz()"> Start the exercise</button> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="goToQuiz()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('quiz')"> Exercise overview</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>4</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Good-ish jokes</p>
                <img :src="jokeImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="generateJokes()"> Start the exercise</button>
                <br/> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="generateJokes()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('jokes')"> Exercise overview</button>
                <!-- <p>To practise words with specific sounds, <br>click <b style="color:#f56c6c;cursor:pointer" @click="articulationDashboard()"> HERE </b>.</p> -->
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>5</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Tongue twisters</p>
                <img :src="tongueImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="generateTongueTwisters()"> Start the exercise</button> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="generateTongueTwisters()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('tongue_twisters')"> Exercise overview</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>

                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>6</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Speak in a noisy environment</p>

                <img :src="noisyImg" width="120" height="120" style="object-fit: cover">

                <br/>

                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="speechWithBackgroundNoise()"> Start the exercise</button> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="speechWithBackgroundNoise()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('noise')"> Exercise overview</button>
              </div>
            </div>
          </div>
          
        </div>
              
         <!-- <div class="row mt-5 justify-content-center">
          <div class="col-3">
            <div class="position-relative" >
              <img :src="allImg" class="img-fluid">
              <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
            </div>

            <p class="lead">Pub Quiz</p>
            <button class="btn btn-primary btn-lg mt-2" type="button" @click="goToQuiz()"> Start this exercise</button>
          </div>
        </div>
        <div class="row mt-4">
          <p>Interested in specific topics? Check your <b style="color:#f56c6c;cursor:pointer" data-bs-toggle="modal" data-bs-target="#settingsModal" @click="checkSetting=true"> settings for interests </b>.</p>
        </div> -->
        
        <!-- Modal -->
        <div v-show="checkSetting" class="modal fade" id="settingsModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-xl" >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">My settings</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <Patient_setting></Patient_setting>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="start_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ module_title }}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body text-center">
                <div class="row justify-content-around">
                  <div class="card card-body">
                    <h5>{{ benfite_statement }}</h5>
                  </div>
                  
                </div>
                <div class="row justify-content-center">
                  <Posture_instructions/>
                </div>
                <div class="row justify-content-center mt-4">
                  <div class="col-3">
                    <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <Posture_instructions/> -->

    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>
</template>
<script>

import newsImg from '@/assets/images/instructions/News.jpeg'
import jokeImg from '@/assets/images/instructions/jokes.jpeg'
import tongueImg from '@/assets/images/instructions/tongue_twister.jpg'
import noisyImg from '@/assets/images/instructions/noisy_pub.jpg'

import quizImg from '@/assets/images/instructions/pub_quiz.jpeg'
import fun_factsImg from '@/assets/images/instructions/fun_facts.jpg'
import allImg from '@/assets/images/random.png'
import Patient_setting from '../../setting.vue'
import Posture_instructions from '../components/posture_instructions.vue'
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
  name: 'Read_sentences',
  components: { Patient_setting, Posture_instructions, Footerbar, Navbar },
  props: 
  {
    
  },
  data(){
    return {
        userCategory:'',
        region:'',
        explanation:'',        

        pre_loaded_exercises:[],
        autoGenWords:[],
        newsImg:newsImg,
        jokeImg:jokeImg,
        tongueImg:tongueImg,
        noisyImg:noisyImg,
        quizImg:quizImg,
        fun_factsImg:fun_factsImg,
        allImg:allImg,
        checkSetting:false,
        module_title:'',
        benfite_statement:'',
        module_cate:''
    }
  },
  created() {

    this.userCategory = localStorage.getItem('userCategory');
    this.region = localStorage.getItem('region');
    this.explanation = ""
    window.scrollTo(0, 0);

  },
  beforeUnmount() {
    const backdrop = document.querySelector('.modal-backdrop')
    if (backdrop) {
      backdrop.remove();
    }
  },
  methods: {
    goToQuiz()
    {
      this.$router.push({ name: 'pub_quiz_setup' })

    },
    generateJokes()
    {
      this.$router.push({ name: 'jokes' })
    },
    speechWithBackgroundNoise()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'Speech_BackgroundNoise' }});
    },
    generateTongueTwisters()
    {
      this.$router.push({ name: 'tongue_twisters' })
    },
    fun_facts()
    {
      this.$router.push({ name: 'fun_facts_setup' } );

    },  
    generateMixMatch()
    {
      this.$router.push({ name: 'mix_match' })
    },
    generalNewsDashboard()
    {
      this.$router.push({ name: 'latest_news' })
    },
    closePop()
    {
      this.checkSetting = false;
    }, 
    gotoBenefit(cate)
    {
      this.module_cate = cate;
      if(cate == 'news')
      {
        this.module_title = 'News highlights'
        this.benfite_statement = 'News contains most phonemes (sounds) needed for practising articulation control. This exercise aims to help you improve volume and articulation control while speaking long sentences.'
      }
      else if(cate == 'fun_facts')
      {
        this.module_title = 'Fun facts about your interests'
        this.benfite_statement = 'Fun facts encourage more entertaining practice of articulation control in long sentences. The more you practise, the better you become. This exercise aims to help you improve volume and articulation control while speaking long sentences.'
      }
      else if(cate == 'quiz')
      {
        this.module_title = 'Pub quiz'
        this.benfite_statement = 'Pub quiz encourages more entertaining practice of speaking while challenging your knowledge. The more you practise, the better you become. This exercise aims to help you improve volume and articulation control while speaking long sentences.'
      }
      else if(cate == 'jokes')
      {
        this.module_title = 'Good-ish jokes'
        this.benfite_statement = 'Jokes encourage more entertaining practice of articulation control in long sentences. The more you practise, the better you become. This exercise aims to help you improve volume and articulation control while speaking long sentences.'
      }
      else if(cate == 'tongue_twisters')
      {
        this.module_title = 'Tongue twisters'
        this.benfite_statement = 'Tongue twisters are great ways to help you practise articulating various phoneme (sound) combinations in long sentences. This exercise aims to help you improve volume and articulation control while speaking long sentences.'
      }
      else if(cate == 'noise')
      {
        this.module_title = 'Speak in a noisy environment'
        this.benfite_statement = 'This exercise helps you practise speaking in a distracting environment. It aims to help you speak loudly and clearly in daily life environments.'
      }
      else
      {
        console.log('unknown category = '+cate)
      }
      let start_pop = new Modal(document.getElementById('start_pop'));
      start_pop.show()
    },
    gotoModule()
    {
      if(this.module_cate == 'news')
      {
        this.generalNewsDashboard()
      }
      else if(this.module_cate == 'fun_facts')
      {
        this.fun_facts()
      }
      else if(this.module_cate == 'quiz')
      {
        this.goToQuiz()
      }
      else if(this.module_cate == 'jokes')
      {
        this.generateJokes()
      }
      else if(this.module_cate == 'tongue_twisters')
      {
        this.generateTongueTwisters()
      }
      else if(this.module_cate == 'noise')
      {
        this.speechWithBackgroundNoise()
      }
      else
      {
        console.log('unknown category = '+this.module_title)
      }
    }
  },
}
</script>
<style scoped>
.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}
</style>