<template>
  <div>
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Preferences</h5>
        <div class="row">
          <div class="d-flex flex-row">
            <div class="col-lg-5 me-2">
              <label class="form-label">Region</label>
              <div class="row justify-content-between">
                <div class="col">
                  <select class="form-select" v-model="region" placeholder="Select" @change="updateUserInfo('region')">
                    <option label="United Kingdom" value="UK"></option>
                    <option label="United States" value="US"></option>
                  </select>
                </div>
              </div>

            </div>
            <div class="col-lg-6">
              <label class="form-label">Language</label>
              <div class="row justify-content-between">
                <div class="col">
                  <select class="form-select" v-model="language" placeholder="Select" @change="updateUserInfo('language')">
                    <option label="British English" value="UK"></option>
                    <option label="American English" value="US"></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-between mt-4">
          <div class="col-lg-6" v-if="voices.length > 0">
            <label class="form-label">Select voice</label>
            <div class="row justify-content-between">
              <div class="col">
                <select class="form-select" v-model="preferred_voice" placeholder="Select" @change="updateUserInfo('preferred_voice')">
                  <option 
                    v-for="(voice, key) in voices.filter(voice => voice.lang.includes(language == 'UK'? 'GB': language) && voice.name != 'Fiona')" 
                    :key="key" 
                    :label="voice.name" 
                    :value="voice.name">
                  </option>
                </select>
              </div>
              <div class="col-4 text-start">
                <button class="btn btn-primary" v-if="voices.some(voice => voice.lang.includes(language == 'UK' ? 'GB' : language) && voice.name === preferred_voice)" @click="test_voice()">Test Voice</button>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <label class="form-label">Text-to-Speech Synthesis</label>
            <br/>
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" v-model="tts_enabled" @change="update_auto_tts()">
              <label v-if="tts_enabled" class="form-check-label">Text-to-Speech synthesis is <span class="text-success">active</span></label>
              <label v-else class="form-check-label">Text-to-Speech synthesis is <span class="text-secondary">inactive</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getUserDataDir, updateDataDirectory } from '@/firebase/index'
import { useToast } from "vue-toastification";
import { useTTS } from '@/stores/tts'

export default {
  components: { },
  name: 'LanguageRegion',
  props: 
  {
    uid: String
  },
  data(){
    return {
      userCategory: localStorage.getItem('userCategory'),
      region: localStorage.getItem('region'),
      language: localStorage.getItem('language'),


      bv_tts: useTTS().tts_engine,
      voices: [],
      preferred_voice: null,

      tts_enabled: false,
    }
  },
  created() {
    this.loadUserInfo()
  },
  methods: {
    update_auto_tts() {
      let ref_path = 'Users/' + this.uid + '/Settings'
      updateDataDirectory(ref_path, {'auto_tts': this.tts_enabled})
    },
    test_voice() {
      this.bv_tts.say("Hello! What a nice day to practice speech!")
    },
    async loadUserInfo() {
      let _this = this;


      await new Promise(resolve => {
        getUserDataDir('Settings/', async function (err, result) {      
          let region = result.child('region').val();

          if(region == undefined || region == null) {
            region = 'UK' 
          }
          localStorage.setItem('region', region); 

          let language = result.child('language').val();
          if(language == undefined || language == null) {
            language = 'UK' 
          }
          localStorage.setItem('language', language); 

          let value = result.child('auto_tts').val()
          if (value != null) {
            _this.tts_enabled = value
          } else {
            _this.tts_enabled = true
            _this.update_auto_tts()
          }

          _this.preferred_voice = await _this.bv_tts.preferred_voice
          _this.voices = _this.bv_tts.language_voices //depends on the above promise()
          _this.voices.unshift({"lang": "GB", "name": "BV AI"})
          _this.voices.unshift({"lang": "US", "name": "BV AI"})
          
          resolve()
        })
      })

    },
    updateUserInfo(inp) {
      let _this = this;

      let settings_ref_path = 'Users/' + this.uid + '/Settings/'
      
      let info_updated = false
      
       if(inp == 'preferred_voice') {
        updateDataDirectory(settings_ref_path, {'preferred_voice': _this.preferred_voice})
        this.bv_tts.set_voice()

        info_updated = true
      } else if(inp == 'region') {
        updateDataDirectory(settings_ref_path, {'region': _this.region})
        localStorage.setItem('region', _this.region)
        
        info_updated = true
      } else if(inp == 'language') {
        updateDataDirectory(settings_ref_path, {'language': _this.region})
        localStorage.setItem('language', _this.language)
        
        info_updated = true
      }

      if (info_updated) {
        const toast = useToast()
        toast.info("Your information has been updated.")
      }

      _this.$emit('changedSetting')
    },

  }
}
</script>
<style scoped>

.text-overlay {
  /* margin-top:5%; */

  color:rgb(0, 0, 0);

  font-size: 16px;
}


</style>