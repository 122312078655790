import { createRouter, createWebHashHistory } from 'vue-router'

import signIn from '@/views/public_external/authentication/signIn.vue'
import Forgot_password from '@/views/public_external/authentication/forgot_password.vue'
import Register from '@/views/public_external/registration/register.vue'

import image_search_selection_tool from '@/views/development/image_selection_tool.vue'

import Subscribe from '@/views/subscribe/Subscribe.vue'

import Dashboard from '@/views/dashboard/home.vue'
import Goals_page from '@/views/dashboard/patient/goals_page/goals_page.vue'
import Settings from '@/views/dashboard/settings.vue'
import Microphone_calibration from '@/views/dashboard/microphone_calibration.vue'


import Daily_training from '@/views/dashboard/patient/daily_training/daily_training.vue'
import Auditory_comprehension from '@/views/dashboard/patient/daily_training/auditory_comprehension/auditory_comprehension.vue'

import Thinking_of_words from '@/views/dashboard/patient/daily_training/thinking_of_words/thinking_of_words.vue'
import Facial_muscles from '@/views/dashboard/patient/daily_training/facial_expression/facial_muscles.vue'

import Facial_expression from '@/views/dashboard/patient/daily_training/facial_expression/facial_expression.vue'
import Use_sentences from '@/views/dashboard/patient/daily_training/use_sentences/use_sentences.vue'
import Reading from '@/views/dashboard/patient/daily_training/reading/reading.vue'
import Writing from '@/views/dashboard/patient/daily_training/writing/writing.vue'

import Fun_facts_setup from '@/views/dashboard/patient/daily_training/speak_words/fun_facts_setup/fun_facts_setup.vue'
import Speak_words from '@/views/dashboard/patient/daily_training/speak_words/speak_words.vue'
import Advanced_training from '@/views/dashboard/patient/daily_training/advanced_training/advanced_training.vue'
import Conversation_training from '@/views/dashboard/patient/daily_training/conversation_training/conversation_training.vue'

import Practise_mouth_movement from '@/views/dashboard/patient/daily_training/practise_mouth_movement/practise_mouth_movement.vue'
import Read_sentences from '@/views/dashboard/patient/daily_training/read_sentences/read_sentences.vue'
import Pub_quiz_setup from '@/views/dashboard/patient/daily_training/read_sentences/pub_quiz_setup/pub_quiz_setup.vue'
import Reading_comprehension_setup from '@/views/dashboard/patient/daily_training/reading_comprehension/reading_comprehension_setup.vue'
import Pace_interest_setup from '@/views/dashboard/patient/daily_training/pace_training_section/pace_interest_setup/pace_interest_setup.vue'
import Pace_training_section from '@/views/dashboard/patient/daily_training/pace_training_section/pace_training_section.vue'

import Word_guessing from '@/views/dashboard/patient/daily_puzzles/word_guessing/word_guessing.vue'
import Number_guessing from '@/views/dashboard/patient/daily_puzzles/number_guessing/number_guessing.vue'

import Homework from '@/views/dashboard/patient/assignments/assignments.vue'
import MyProgress from '@/views/dashboard/patient/myProgress/myProgress.vue'


import Session from '@/views/session/Normal_Session/Session.vue'
import Training_room from '@/views/session/Interactive_Session/Interactive_Session.vue'

import GroupActivities from '@/views/dashboard/patient/groupActivities/groupActivities.vue'


import IPA from '@/views/dashboard/patient/daily_training/IPA/IPA.vue'


import Image_game from '@/views/dashboard/patient/groupActivities/imageGame/image_game.vue'

import Word_game from '@/views/dashboard/patient/groupActivities/wordGame/word_game.vue'


import client_interface from '@/views/dashboard/slt/client_interface/client_interface.vue'


import client_demo from '@/views/dashboard/slt/clientDemo/client_demo.vue'


import patient_history_display_master from '@/views/research/individual_results.vue'


import user_activity from '@/views/research/user_activity.vue'

import Manage_organisation from '@/views/dashboard/slt/manage_organisation.vue'

import register_interest from '@/views/public_external/register_interest/index.vue'
import Company from '@/views/public_external/Company.vue'
import About from '@/views/public_external/About.vue'


import Session_results from '@/views/dashboard/slt/client_interface/pages/results/list/session/session_results.vue'
import Results_list from '@/views/dashboard/slt/client_interface/pages/results/list/session_list.vue'

const routes = [
  {
    path:"/company",
    name:'company',
    component:Company,
  },
  {
    path:"/about",
    name:'about',
    component:About,
  },
  {
    path:"/subscribe",
    name:'subscribe',
    component:Subscribe,
    meta: {
      requiresAuth: true
    }
  }, 
  {
    path:"/",
    name:'home',
    component:Dashboard,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path:"/signIn",
    name:'signIn',
    component:signIn,
  },
  {
    path:"/register",
    name:'register',
    component:Register,
  },
  {
    path:"/forgot_password",
    name:'forgot_password',
    component:Forgot_password,
  },
  {
    path:"/settings",
    name:'settings',
    component:Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path:"/microphone_calibration",
    name:'microphone_calibration',
    component:Microphone_calibration,
    meta: {
      requiresAuth: true
    }
  },
  {
    path:"/organisation",
    name:'manage_organisation',
    component:Manage_organisation,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path:"/home/interactive_session/:session_name?/",
    name:'training_room',
    component: Training_room, 
    props: true,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path: "/home/goals",
    name: 'goals',
    component: Goals_page,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/home/daily_training",
    name: 'daily_training',
    component:Daily_training,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/home/homework/assignment",
    name: "assignment",
    component:Session,
    props: {requested: "assignment"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/homework",
    name:'homework',
    component: Homework,   
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/ipa",
    name:'IPA',
    component: IPA,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/auditory_comprehension",
    name:'auditory_comprehension',
    component:Auditory_comprehension,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/auditory_comprehension/write_numbers",
    name:'write_numbers',
    component:Session,
    props: {requested: "write_numbers"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path: "/home/daily_training/:blueprint_id",
    name: 'user_training',
    component: Session,
    props: route => ({ requested: "user_training", blueprint_id: route.params.blueprint_id }),
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/thinking_of_words",
    name:'thinking_of_words',
    component:Thinking_of_words,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/facial_expression",
    name:'facial_expression',
    component:Facial_expression,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/facial_muscles",
    name:'facial_muscles',
    component:Facial_muscles,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/thinking_of_words/word_finding",
    name:'word_finding',
    component:Session,
    props: {requested: "word_finding"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/practise_mouth_movement",
    name:'practise_mouth_movement',
    component:Practise_mouth_movement,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/practise_mouth_movement/mouth_movement",
    name:'mouth_movement',
    component:Session,
    props: {requested: "mouth_movement"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/voice_warm_up",
    name:'voice_warm_up',
    component:Session,
    props: {requested: "voice_warm_up"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/use_sentences",
    name:'use_sentences',
    component:Use_sentences,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/reading",
    name:'reading',
    component:Reading,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/writing",
    name:'writing',
    component:Writing,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/speak_words",
    name:'speak_words',
    component:Speak_words,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/speak_words/fun_facts_setup",
    name:"fun_facts_setup",
    component: Fun_facts_setup,
    meta: { requiresAuth: true },
  },
  {
    path:"/home/daily_training/reading_comprehension/reading_comprehension_setup",
    name:"reading_comprehension_setup",
    component: Reading_comprehension_setup,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/pub_quiz/pub_quiz_setup",
    name:"pub_quiz_setup",
    component: Pub_quiz_setup,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/speak_words/fun_facts",
    name:"fun_facts",
    component:Session,
    props: {requested: "fun_facts"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/speak_words/single_words",
    name:'single_words',
    component:Session,
    props: {requested: "single_words"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/speak_words/functional_sentences",
    name:'functional_sentences',
    component:Session,
    props: {requested: "functional_sentences"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/practise_sounds/sound_loudness",
    name:'sound_loudness_loudness_patient',
    component:Session,
    props: {requested: "sound_loudness_loudness_patient"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/practise_sounds/pitch_variation",
    name:'pitch_variation',
    component:Session,
    props: {requested: "pitch_variation"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/read_sentences",
    name:'read_sentences',
    component:Read_sentences,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/read_sentences/latest_news",
    name:'latest_news',
    component:Session,
    props: {requested: "latest_news"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/reading/reading_comprehension_news_mid",
    name:'reading_comprehension_news_mid',
    component:Session,
    props: {requested: "reading_comprehension_news_mid"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/reading/reading_comprehension_news_long",
    name:'reading_comprehension_news_long',
    component:Session,
    props: {requested: "reading_comprehension_news_long"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/read_sentences/quiz",
    name:'quiz',
    component:Session,
    props: {requested: "Pub Quiz"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/read_sentences/quiz",
    name:'quiz_interest',
    component:Session,
    props: {requested: "Pub Quiz"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/reading_comprehension/reading_comprehension",
    name:'reading_comprehension_interest',
    component:Session,
    props: {requested: "Reading Comprehension Interest"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/reading_comprehension/reading_comprehension",
    name:'reading_comprehension_interest',
    component:Session,
    props: {requested: "Reading Comprehension Interest"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/read_sentences/jokes",
    name:'jokes',
    component:Session,
    props: {requested: "jokes"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/read_sentences/tongue_twisters",
    name:'tongue_twisters',
    component:Session,
    props: {requested: "tongue_twisters"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/conversation_training/chat_scenario",
    name:'chat_scenario',
    component:Session,
    props: {requested: "chat_scenario"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/read_sentences/mix_match",
    name:'mix_match',
    component:Session,
    props: {requested: "mix_match"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/advanced_training",
    name:'advanced_training',
    component:Advanced_training,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/conversation_training",
    name:'conversation_training',
    component:Conversation_training,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/advanced_training/karaoke",
    name:'karaoke',
    component:Session,
    props: {requested: "karaoke"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/advanced_training/long_news",
    name:'long_news',
    component:Session,
    props: {requested: "long_news"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/pace_training",
    name:'pace_training_section',
    component:Pace_training_section,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/pace_training/pace_training",
    name:'pace_training',
    component:Session,
    props: {requested: "pace_training"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/pace_training/pace_interest_setup",
    name:"pace_interest_setup",
    component: Pace_interest_setup,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/pace_training/pace_interest",
    name:'pace_interest',
    component:Session,
    props: {requested: "Pace Training Interest"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/assessments/voice/vhi",
    name:'vhi',
    component:Session,
    props: {requested: "vhi"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/self-check/language_assessment",
    name:'language_assessment',
    component:Session,
    props: {requested: "language_assessment"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/self-check/loudness_assessment",
    name:'loudness_assessment',
    component:Session,
    props: {requested: "loudness_assessment"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/self-check/avqi",
    name:'AVQI',
    component:Session,
    props: {requested: "AVQI"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/self-check/speech_assessment",
    name:'speech_assessment',
    component:Session,
    props: {requested: "speech_assessment"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/memory/word_memory",
    name:'word_memory',
    component:Session,
    props: {requested: "word_memory"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/life_skills/clock_game",
    name:"clock_game",
    component:Session,
    props: {requested: "clock_game"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_muscles/stroke_puff_cheeks_both",
    name:"stroke_puff_cheeks_both",
    component:Session,
    props: {requested: "stroke_puff_cheeks_both"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_muscles/stroke_puff_cheeks_side",
    name:"stroke_puff_cheeks_side",
    component:Session,
    props: {requested: "stroke_puff_cheeks_side"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_muscles/stroke_blow",
    name:"stroke_blow",
    component:Session,
    props: {requested: "stroke_blow"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_muscles/stroke_pout_lips",
    name:"stroke_pout_lips",
    component:Session,
    props: {requested: "stroke_pout_lips"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_muscles/stroke_eyebrows",
    name:"stroke_eyebrows",
    component:Session,
    props: {requested: "stroke_eyebrows"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_muscles/stroke_facial_say_p",
    name:"stroke_facial_say_p",
    component:Session,
    props: {requested: "stroke_facial_say_p"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_muscles/stroke_smile_sides",
    name:"stroke_smile_sides",
    component:Session,
    props: {requested: "stroke_smile_sides"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/stroke_smile",
    name:"stroke_smile",
    component:Session,
    props: {requested: "stroke_smile"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/pd_smile",
    name:"pd_smile",
    component:Session,
    props: {requested: "pd_smile"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/stroke_frown",
    name:"stroke_frown",
    component:Session,
    props: {requested: "stroke_frown"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/pd_angry",
    name:"pd_angry",
    component:Session,
    props: {requested: "pd_angry"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/stroke_disgust",
    name:"stroke_disgust",
    component:Session,
    props: {requested: "stroke_disgust"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/pd_disgust",
    name:"pd_disgust",
    component:Session,
    props: {requested: "pd_disgust"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/stroke_excited",
    name:"stroke_excited",
    component:Session,
    props: {requested: "stroke_excited"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/pd_excited",
    name:"pd_excited",
    component:Session,
    props: {requested: "pd_excited"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/stroke_surprised",
    name:"stroke_surprised",
    component:Session,
    props: {requested: "stroke_surprised"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/pd_surprised",
    name:"pd_surprised",
    component:Session,
    props: {requested: "pd_surprised"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_training/daily_training/facial_expressions/pd_mixed",
    name:"pd_mixed",
    component:Session,
    props: {requested: "pd_mixed"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/group_activities",
    name:'groupActivities',
    component:GroupActivities,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/group_activities/image_game",
    name:'image_game',
    component:Image_game,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/daily_puzzles/number_guessing",
    name:'number_guessing_challenge',
    component:Number_guessing,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/groupActivities/word_game",
    name:'word_game',
    component:Word_game,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/myProgress",
    name:'myProgress',
    component:MyProgress,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {//this is used both in SLT and Patient
    path:"/home/session",
    name:'Session',
    component:Session,
    meta: { transition: 'fade', requiresAuth: true },
  }, 
  {//this is used both in SLT and Patient
    path:"/home/session/results",
    name:'results',
    props: {requested: "results"},
    component:Session,
    meta: { transition: 'fade', requiresAuth: true },
  }, 

  ///SLT////
  {
    path:"/home/client",
    name:'client_interface',
    component:client_interface,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path:"/home/client/session_results_list",
    name:'results_list',
    component: Results_list,
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path: "/home/client/clinic_assessment/",
    name: "clinic_assessment",
    component: Session,
    props: route => ({ requested: "clinic_assessment", blueprint_id: null, blueprint_path: route.query.blueprint_path }),
    meta: { transition: 'fade', requiresAuth: true },
  }, ///////////////////////
  {
    path: "/home/client/clinic_assessment_package/",
    name: "clinic_assessment_package",
    component: Session,
    props: {requested: "clinic_assessment_package"},
    meta: { transition: 'fade', requiresAuth: true },
  },
  {
    path: "/home/client/resume_clinic_assessment_package/",
    name: "resume_clinic_assessment_package",
    component: Session,
    props: {requested: "resume_clinic_assessment_package"},
    meta: { transition: 'fade', requiresAuth: true },
  }, 
  {
    path: "/home/client/clinic_training/",
    name: "clinic_training",
    component: Session,
    props: route => ({ requested: "clinic_training", blueprint_id: null, blueprint_path: route.query.blueprint_path }),
    meta: { transition: 'fade', requiresAuth: true },
  }, ///////////////////////
  {
    path: "/home/client_demo",
    name: 'client_demo',
    component: client_demo,
    meta: { requiresAuth: true },
  },   
  {
    path:"/research/individual_results",
    name:'patient_history_display_master',
    component:patient_history_display_master,
    meta: { requiresAuth: true },
  },
  {
    path:"/research/user_activity",
    name:"user_activity",
    component: user_activity,
    meta: { requiresAuth: true },
  },
  {
    path:"/home/client/session_results/",
    name:'session_results',
    component: Session_results,
    meta: { requiresAuth: true },
  },
  //register_interest
  {
    path:"/register_interest",
    name:'register_interest',
    component:register_interest,
  },
  {
    path:'/development/image_search_selection_tool',
    name:'image_search_selection_tool',
    component:image_search_selection_tool,
    meta: { requiresAuth: true },
  },
]


const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})

export default router;