<template>
  <div class="d-flex flex-column min-vh-100">
    <div class="row">
      <div class="col-md-9">
        <div class="card card-body">
          <div class="row">
            <div class="card card-body">
              <h4>
                  Click on all the buttons that show <span style="color: blueviolet;">{{ selection_condition }} </span>
              </h4>
              <h4 v-if="num_btn_display>1">
                  Hint: there are <b>{{ num_btn_display }}</b> buttons to click
              </h4>
              <h4 v-else>
                  Hint: there is <b>{{ num_btn_display }}</b> button to click
              </h4>
            </div>
          </div>

          <div class="row">
            <p :style="interaction_message_color"> {{ interaction_highlight }} </p>
            <span :style="interaction_message_color"> {{  interaction_message}}</span> 
          </div>
          
          <div class="row mt-3" v-for="row in max_row_num" :key="row">
            <div class="col" v-for="col in max_col_num" :key="col">
              <button :class=all_btn_display[(row-1)*max_col_num+col-1].class @click="checkCondition_id((row-1)*max_col_num+col-1)" :style="all_btn_display[(row-1)*max_col_num+col-1].cursor" :disabled="all_btn_display[(row-1)*max_col_num+col-1].disable">
                  {{all_btn_display[(row-1)*max_col_num+col-1].display}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-body">
          <button class="btn-info btn btn-lg w-100 mt-2"  @click="finalCheck()">Next </button>
          <button v-if="flag_showBtn" class="btn-success btn btn-lg w-100 mt-2"  @click="showAnswer()">Show answer </button>
          <!-- <button class='btn btn-secondary btn-lg w-100 mt-2' @click="skipExercise()"> Skip  </button> -->
        </div>
      </div>
      <div class="modal fade" id="result_pop" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{finish_statement}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-center">
                <div class="row">
                    <span> {{ finish_detail }}</span>
                </div>
                <div class="row mt-2">
                  <button class="btn btn-success w-100" data-bs-dismiss="modal" @click="skipExercise()">Yes, skip this exercise</button>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>    
</template>
<script>
  
  import { Modal } from 'bootstrap'
  import {startConfetti} from '@/common_js/confetti'
  import {toWords} from '@/utils/utils'
  import {getData, getUserDataDir, updateUserData} from '@/firebase/index'
  import Footerbar from '@/views/Footerbar.vue'
  import Navbar from '@/views/Navbar.vue'
  export default {
    components: { Navbar, Footerbar },
    name: 'Stepping_stone',
    data() {
      return {
        answer:0,
        input_guess:'',
        interaction_message:'',
        interaction_message_color:'',
        selection_condition:'',
        ex_category:'',
        max_row_num:4,
        max_col_num:6,
        all_btn_display:[],
        difficulty_lvl:'easy', //easy or hard
        key_answer:'',
        check_num:0,
        interaction_highlight:'',
        multipleOf:[3,5],
        flag_recreate:false,
        num_btn_display:0,
        flag_showBtn:false,
        finish_statement:'',
        finish_detail:'',
        flag_stay_difficulty:false,
      }
    },
    created() 
    {
      const _this = this
      this.userCategory = localStorage.getItem('userCategory');
      this.ex_category = 'numbers'//numbers or words
    },
    mounted()
    {
        this.getDifficulty()

    },
    methods: 
    {

        getDifficulty()
        {
            const _this = this

            let address = 'Levels/SteppingStone'

            let do_once = true

            getUserDataDir(address, function (err, result) {

                if(do_once)
                {
                do_once = false

                let difficulty = result.child('Level').val()


                if(difficulty==undefined || difficulty==null)
                {
                    difficulty = 'easy'

                    updateUserData(address,{Level:difficulty})
                }


                    _this.difficulty_lvl = difficulty

                    console.debug('_this.difficulty_lvl = ' + _this.difficulty_lvl)


                    _this.intialise_setup()
                }

                
            })
        },

        intialise_setup()
        {   
            
            this.all_btn_display = []
            if(this.ex_category === 'numbers')
            {
                if(this.difficulty_lvl === 'easy')
                {
                    let rand_odd_even = Math.floor(Math.random()*2)
                    if(rand_odd_even == 0)
                    {
                        this.selection_condition = 'even numbers'
                        this.key_answer = 'even_num'
                    }
                    else
                    {
                        this.selection_condition = 'odd numbers'
                        this.key_answer = 'odd_num'
                    }
                    this.initialiseNumbers(100)
                }
                else
                {
                    let rand_multiple= Math.floor(Math.random()*this.multipleOf.length)
                    this.key_answer = this.multipleOf[rand_multiple]
                    this.selection_condition = 'the multiple of '+this.key_answer
                    let range = this.key_answer * 10
                    this.initialiseNumbers(range)
                    if(this.check_num<3) //if only find 2 elements satisfying criteria, redo the table
                    {
                        this.flag_recreate = true
                        while(flag_recreate)
                        {
                            this.check_num = 0;
                            this.all_btn_display = []
                            this.initialiseNumbers(range);
                            if(this.check_num>=3)
                            {
                                this.flag_recreate = false
                            }
                        }
                        
                    }
                }
                this.num_btn_display = this.check_num
                //this.$forceUpdate()
            }
            else
            {
                this.initialiseWords()
            }
        },
        initialiseNumbers(num_range)
        {
            for(let i=0;i<this.max_row_num;i++)
            {
                for(let j=0; j<this.max_col_num;j++)
                {
                    let randNum = Math.floor(Math.random()*num_range)+1
                    let display = randNum
                    if(randNum<=20)
                    {
                        let rand_to_word = Math.floor(Math.random()*2)
                        if(rand_to_word == 0)
                        {
                            display = toWords(randNum)
                        }
                    }
                    let if_target = this.checkCondition(randNum)
                    if(if_target == true)
                    {
                        this.check_num++
                    }
                    let element_content = {'display':display,'content':randNum,'class':'num-button','target':if_target,'if_click':false,'disable':false,'cursor':'cursor: default;'}
                    
                    this.all_btn_display.push(element_content)
                }
            }
            console.debug('check num = '+this.check_num)
        },
        initialiseWords()
        {

        },
        checkCondition(input)
        {
            let default_check = false
            if((this.key_answer == 'even_num')&&(input%2 == 0))
            {
                default_check = true
            }
            else if((this.key_answer == 'odd_num') && (input%2 != 0))
            {
                default_check = true
            }
            else if((this.key_answer == 3) && (input%3 == 0))
            {
                default_check = true
            }
            else if((this.key_answer == 5) && (input%5 == 0))
            {
                default_check = true
            }
            // else if((this.key_answer == 7) && (input%7 == 0))
            // {
            //     default_check = true
            // }

            return default_check
        },
        checkCondition_id(input_id)
        {
            this.all_btn_display[input_id].if_click = true
            let content = this.all_btn_display[input_id].content
            let default_check = false
            default_check = this.checkCondition(content)
            if(default_check)
            {
                this.all_btn_display[input_id].class = 'correct-button'
                
                if(this.num_btn_display > 1)
                {
                    this.interaction_message = 'Correct! '
                    this.interaction_message_color = 'fontSize:x-large;color:green;white-space: pre-wrap'
                    this.num_btn_display--
                }
                else
                {
                    this.num_btn_display = 0
                    if((this.difficulty_lvl == 'easy') && !this.flag_stay_difficulty)
                    {
                        this.interaction_message = 'Fantastic! Your difficulty is levelling up!'
                        this.difficulty_lvl = 'hard'

                        let address = 'Levels/SteppingStone'

                        updateUserData(address,{Level: this.difficulty_lvl})

                    }
                    else
                    {
                        this.interaction_message = 'Well done! You got them all'
                    }
                    this.interaction_message_color = 'fontSize:x-large;color:green;white-space: pre-wrap'
                    startConfetti(this)
                    setTimeout(() => {
                        this.skipExercise()
                        
                    }, 1500);
                }
            }
            else
            {
                this.all_btn_display[input_id].class = 'incorrect-button'
                this.interaction_message = this.showMsg()
                this.interaction_highlight = "Not correct..."
                this.interaction_message_color = 'fontSize:x-large;color:red;white-space: pre-wrap'
                this.flag_showBtn = true
                this.flag_stay_difficulty = true
            }
            //this.$forceUpdate()
        },
        showMsg()
        {
            let msg_output = ''
            if(this.key_answer == 'even_num')
            {
                msg_output = 'Even numbers can be divided by 2 completely'
            }
            else if(this.key_answer == 'odd_num')
            {
                msg_output = 'When odd numbers are divided by 2, they leave the remainder 1'
            }


            return msg_output
        },
        showAnswer()
        {
            this.interaction_highlight = "Other correct buttons are highlighted in purple."
            this.interaction_message = this.showMsg()
            this.interaction_message_color = 'fontSize:x-large;color:blueviolet;white-space: pre-wrap'

            for(let i in this.all_btn_display)
            {
                if((this.all_btn_display[i].target == true)&&(!this.all_btn_display[i].if_click))
                {
                    this.all_btn_display[i].class = 'display-button'
                    this.all_btn_display[i].disable = true
                    this.all_btn_display[i].cursor = 'cursor: not-allowed;'
                }
            }
            this.num_btn_display = 0
            this.flag_stay_difficulty = true
            //this.$forceUpdate()
        },
        finalCheck()
        {
            if(this.num_btn_display>0)
            {
                this.finish_statement = 'Are you sure you want to go next?';
                this.finish_detail = `It seems that you still have ${this.num_btn_display} button${this.num_btn_display > 1 ? 's' : ''} to click`;
                let result_pop = new Modal(document.getElementById('result_pop'));
                result_pop.show()
            }
            else
            {
                this.skipExercise()
            }
        },
       skipExercise()
       {
            this.interaction_message = ''
            this.interaction_highlight = ''
            this.all_btn_display = []
            this.check_num = 0
            this.num_btn_display = 0
            this.flag_showBtn = false
            this.flag_stay_difficulty = false
            this.$emit('nextExercise');

       }
    }
  }
  </script>
  <style scoped>
  .custom-button-BV
  {
      width: 130px;
      height: 130px;
      border-radius: 100px;
  }
  li:hover {
    background-color: #e0e0e2;
    color: white;
  }
  .display-button
  {
    width: 40mm;
    height: 25mm;
    font-size: x-large;
    color:white;
    background-color: blueviolet;
    border-color: blueviolet;
  }
  .num-button
  {
    width: 40mm;
    height: 25mm;
    font-size: x-large;
    background-color: white;
    border-color: blueviolet;
  }
  .correct-button
  {
    width: 40mm;
    height: 25mm;
    font-size: x-large;
    color:white;
    background-color: green;
    border-color: green;
  }
  .incorrect-button
  {
    width: 40mm;
    height: 25mm;
    font-size: x-large;
    color:white;
    background-color: red;
    border-color: red;
  }
  .details
  {
    font-size: 24px;
  }
  </style>