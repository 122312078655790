<template>
  <div>
    <div class="html2pdf__page-break" />

    <section class="pdf-item" >
      <div class="row mt-2">
        <div class="card card-body" style="border-color: blueviolet;">
          <div class="accordion" id="accordionSentenceIntelligibility">
            <div class="accordion-item mb-3">
              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sentence_intelligibility_collapse" aria-expanded="false" aria-controls="sentence_intelligibility_collapse"><p class="text-start" >
                  <b>Sentence intelligibility</b></p></button>
              </div>
              <div :class="['accordion-collapse', 'collapse', show_collapse ? 'show': '']" id="sentence_intelligibility_collapse" style="min-height: 120px;" data-bs-parent="#accordionSentenceIntelligibility">
                      
                <div class="row m-2 mt-4" v-if="sentence_intelligibilityOverview.words_2_work_on!=null">
                  <div class="col-5 text-start">
                    <p>
                      Words with weakest intelligibility:
                    </p>
                  </div>
                  <div class="col-7 text-start">
                    <p>
                      {{sentence_intelligibilityOverview.words_2_work_on}} 
                    </p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>
                <!-- <div class="row" style="float:left;"> 
                  Average intelligibility for 1 syllable words: &emsp; &emsp; {{sentence_intelligibilityOverview.average_syllable_count_score[0]}} 
                </div>
                <br>
                <hr style="height:2px;border:none;color:#333;background-color:green;" /> -->

                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr class="text-center">
                          <!-- <th>Name</th> -->
                          <th>(dB)</th>
                          <th>(%)</th>
                          <th>Sentence pronunciation quality breakdown</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center" v-for="(row, index) in table" :key="index" >
                          <!-- <td>{{ row.name }}</td> -->
                          <td>{{ row.loudness }}</td>
                          <td>{{ row.accuracy }}</td>
                          <td>

                            <div class="mt-2">
                              <b v-for="(word, id) in row.sentence_intelligibility_info" :key="word" :style="'color:'+row.sentence_intelligibility_info[id]['colour']"><strong>{{row.Words[id]}}  &nbsp; </strong></b>
                            </div>
                            <!-- <div class="mt-2">
                              <p v-for="(word, id) in row.WordGrade" :key="word" :class="['d-inline lead', {'text-secondary' : row.WordGrade[id] === 'Omission', 'text-danger' : row.WordGrade[id] === 'Needs Improvement', 'text-success' : row.WordGrade[id] === 'Very Good', 'text-warning' : row.WordGrade[id] === 'OK'}]" style="cursor: pointer;"  @click="listenPhoneme(row.Words[id])"><strong>{{row.Words[id]}}  &nbsp; </strong></p>
                            </div> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'sentence_intelligibility',
  props: ['sentence_intelligibilityOverview', 'table', 'show_collapse'],
  setup(props, { emit }) {
    
    return {
    }

  }
}

</script>
<style scoped>


</style>





