<template>
  <div class="row mt-2">


    
      
    <div v-if="'la_trobe_self' in q_data && 'total_score' in q_data['la_trobe_self']" class="row mt-2" style="text-align: left;">
          <div class="col">
            <h4>Total score</h4>
          </div>
          <div class="col">
            <h4>{{q_data['la_trobe_self']['total_score'].toFixed(0)}}</h4>
          </div>
        </div>

        <div v-if="'la_trobe_relative' in q_data && 'total_score' in q_data['la_trobe_relative']" class="row mt-2" style="text-align: left;">
          <div class="col">
            <h4>Total score</h4>
          </div>
          <div class="col">
            <h4>{{q_data['la_trobe_relative']['total_score'].toFixed(0)}}</h4>
          </div>
        </div>


        <div class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">LCQ score</th>
                  <th scope="col">Level of impact</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30-40</td>
                  <td>No communication difficulties</td>
                </tr>
                <tr>
                  <td>41-60</td>
                  <td>Mild communication difficulties</td>
                </tr>
                <tr>
                  <td>61-80</td>
                  <td>Moderate communication difficulties</td>
                </tr>
                <tr>
                  <td>81-100</td>
                  <td>Significant communication difficulties</td>
                </tr>
                <tr>
                  <td>101-120</td>
                  <td>Severe communication difficulties</td>
                </tr>
              </tbody>
            </table>
          </div>

       
          <div class="mt-4">
            <p class="h4 text-start">Individual questionnaire responses</p>
          </div>
          <table class="table mt-4" >
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Question</th>
                <th scope="col">Score</th>

                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row, index in q_data['questions']" v-bind:key="index">
                <td>{{ index +1 }}</td>
                <td class="text-start">{{ q_data['questions'][index] }}</td>
                <td v-if="'rating_scores' in q_data">{{ q_data['rating_scores'][index]}}</td>
                <td v-else>NA</td>

                <td>{{ q_data['answers'][index]}}</td>
              </tr>
            </tbody>
          </table>
      
    </div>
</template>

<script>

export default {
  name: "single_la_trobe",
  props: {
    q_data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>
