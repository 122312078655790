<template>
  <div v-if="mic_permission_allowed === false">
    <MicPermission @response="(rtn) => mic_permission_allowed = rtn" />
    </div>
  <div v-else >
    <div class="d-flex flex-column min-vh-100">
      <Navbar currentIndex="home"></Navbar>
      <div class="container pt-4 mb-5">
        <Patient_dashboard v-if="userCategory != 'slp'"></Patient_dashboard>
        <Slt_dashboard v-if="userCategory == 'slp'"> </Slt_dashboard>
      </div>

      <Footerbar class="mt-auto"></Footerbar>
    </div>
  </div>
  
</template>

<script>
import Navbar from '../Navbar.vue'
import Footerbar from '../Footerbar.vue'
import Patient_dashboard from './patient/dashboard.vue'
import Slt_dashboard from './slt/dashboard.vue'
import MicPermission from '@/views/components/mic_permission/MicPermission.vue'
import {get_microphone_permission} from '@/media_input/mic/permission.js'
import { useTTS } from '@/stores/tts'

export default {
  components: { Navbar, Footerbar, Patient_dashboard, Slt_dashboard, MicPermission },
  name: 'Dashboard',
  data(){
    return {
      mic_permission_supported_browser: false,
      mic_permission_allowed: "",
      userCategory: localStorage.getItem('userCategory'),
      bv_tts: useTTS().tts_engine,
    }
  },
  async created() {
    let _this = this

    await get_microphone_permission(_this)
  },
  mounted() {

  },
  methods: {

  }
}
</script>






