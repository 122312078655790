<template>
  <div class="d-flex flex-column min-vh-100">
    <v-tour name="slt_patient_interface" :steps="steps"></v-tour>

    <Navbar currentIndex="patient"></Navbar>

    <div class="container-fluid">
      <h1 class="display-5">Health page for  {{client_name}}</h1>
      <div class="row text-center">
        <div class="col-md-3">
          <div class="card h-100">
            <div class="card-body v-patient_interface-0">
              <ul class="nav flex-column nav-pills text-start" >
                <li class="nav-item m-2">
                  <a :class="['nav-link', selectedIndex == 1? 'active': '']" style="cursor:pointer;font-size:20px" @click="handleSelect(1)"><i class="bi bi-easel"></i> Training</a>
                </li>
                <li class="nav-item m-2">
                  <a :class="['nav-link', selectedIndex == 2? 'active': '']" style="cursor:pointer;font-size:20px;" @click="handleSelect(2)"><i class="bi bi-clipboard-check"></i> Assessment</a>
                </li>
                <li class="nav-item m-2">
                  <a :class="['nav-link', selectedIndex == 3? 'active': '']" style="cursor:pointer;font-size:20px" @click="handleSelect(3)"><i class="bi bi-dice-4"></i> Group Games</a>
                </li>
                <li class="nav-item m-2">
                  <a :class="['nav-link', selectedIndex == 4? 'active': '']" style="cursor:pointer;font-size:20px" @click="handleSelect(4)"><i class="bi bi-crosshair"></i> Goals</a>
                </li>
                <li class="nav-item m-2">
                  <a :class="['nav-link', selectedIndex == 5? 'active': '']" style="cursor:pointer;font-size:20px" @click="handleSelect(5)"><i class="bi bi-bar-chart"></i> Results</a>
                </li>
                <li class="nav-item m-2">
                  <a :class="['nav-link', selectedIndex == 6? 'active': '']" style="cursor:pointer;font-size:20px" @click="handleSelect(6)"><i class="bi bi-file-text"></i> Case Notes</a>
                </li>
                <li class="nav-item m-2">
                  <a :class="['nav-link', selectedIndex == 7? 'active': '']" style="cursor:pointer;font-size:20px" @click="handleSelect(7)"><i class="bi bi-person"></i> Client Settings</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-body">
              <div v-if="this.selectedIndex === 0">
                Loading...
              </div>
              <Training v-if="this.selectedIndex == 1"></Training>
              <Assessment v-if="this.selectedIndex == 2"></Assessment>
              <Games v-if="this.selectedIndex == 3"></Games>
              <Goals_page v-if="this.selectedIndex == 4"></Goals_page>
              <Results v-if="this.selectedIndex == 5"></Results>
              <Case_notes v-if="this.selectedIndex == 6"></Case_notes>

              <Client_Settings v-if="this.selectedIndex == 7"
                      :client_name="client_name"
                      :client_uid="client_uid">
              </Client_Settings>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>
</template>
<script>
import Navbar from '@/views/Navbar.vue'
import Footerbar from '@/views/Footerbar.vue'
import Assessment from './pages/clinic_session/assessment/assessment.vue'
import Training from './pages/clinic_session/training/training.vue'

import Games from './pages/group_games.vue'
import Goals_page from './pages/goals_page.vue'
import Results from './pages/results/results.vue'
import Case_notes from './pages/case_notes/case_notes.vue'
import Client_Settings from './pages/settings.vue'

import {updateDataDirectory, updateUserData, getUserDataDir, getData} from '@/firebase/index'

export default {
  components: { 
    Client_Settings, 
    Assessment, 
    Games, 
    Goals_page, 
    Training, 
    Results, 
    Case_notes, 
    Footerbar,
    Navbar 
  },
  name: "client_interface",
  data() {
     return {
        selectedIndex: '0',
        client_name: '',
        client_uid: '',
        steps: [
        {
          target: '.v-patient_interface-0',
          content:  "<div class='text-start'>Welcome to the patient’s Health Page. <br/><br/> You can navigate to all the content through the left-hand side panel." +
                    "<ul>" + 
                      "<li class='mt-2'><strong>Training:</strong> contains therapy materials that are both pre-made and created by you.</li>" +
                      "<li class='mt-2'><strong>Assessment:</strong> contains measurement tools that can assist your assessments.</li>" +
                      "<li class='mt-2'><strong>Group games:</strong> contains interactive speaking exercises, suitable for in-clinic use.</li>" +
                      "<li class='mt-2'><strong>Results:</strong> for both in-clinic and home assessments/training results.</li>" +
                      "<li class='mt-2'><strong>Report:</strong> automatically recorded activities performed during the clinical sessions.</li>" +
                      "<li class='mt-2'><strong>Account:</strong> patient details</li>" +
                    "</ul>" + 
                    "</div>",
          params: {
            highlight: true,
            placement: 'right',
            enableScrolling: true
          }
        },
        {
          target: '.v-patient_interface-1',
          content: "<div class='text-start'>In this panel, you will find all the training materials nested under different categories. <br/> All modules can be used in clinic or sent as homework.</div>",
          params: {
            highlight: true,
            placement: 'left',
          }
        },
        {
          target: '.v-patient_interface-2',
          content: "<div class='text-start'>You can create personalised exercise modules using Custom Training.</div",
          params: {
            highlight: true
          }
        },
        {
          target: '.v-patient_interface-3',
          content: "<div class='text-start'>Training modules added as homework will be listed here. You can adjust the module sequence, delete individual modules or clear all the added modules.<br/>Click <strong>Send list to patient</strong> to assign the homework. Your patient will receive an email notification. <br/>Click on <strong>Finish</strong> and then click on <strong>Results</strong> on the left-hand-side panel to continue the tour. </div>",
          params: {
            highlight: true,
            placement: 'top',
          }
        },
      ],
     
    }
  },
  created() {

    let _this = this;
    _this.userCategory = localStorage.getItem('userCategory');

    _this.client_uid = this.$route.query.uid

    _this.getClientData()

    let ref_path = 'Clients/' + _this.client_uid
    getUserDataDir(ref_path, function (err, childSnapshot) {

      if (childSnapshot) {
        let firstname = childSnapshot.child('firstname').val()
        let surname = childSnapshot.child('surname').val()
        
        _this.client_name = ''

        if(firstname != null) {
          _this.client_name = firstname
        }

        if(surname != null) {
          _this.client_name = _this.client_name + ' ' + surname
        }

        localStorage.setItem('clientName', _this.client_name)

        _this.selectedIndex = sessionStorage.getItem('patient_interface_selected_page');

        if((_this.selectedIndex == undefined) || (_this.selectedIndex == null)) {
          _this.selectedIndex = '1';
        }

        const timestampInSeconds = Math.floor(Date.now() / 1000);
        updateUserData(ref_path, {'last_accessed': timestampInSeconds})
      } else {
        alert("Patient data could not be retrieved, please contact BeautifulVoice")
        _this.$router.push({name: "home"});
      }
    });
  },
  mounted() {
    let _this = this

    let user_page_dir = 'Data/';

    getUserDataDir(user_page_dir , function (err, result) {
      let startTour = result.child('startTour').val();
      
      if (startTour === null || startTour['patient_interface'] === undefined || startTour['patient_interface'] === false) {
        _this.$tours['slt_patient_interface'].start()
        
        if (typeof startTour !== "object") {
          startTour = {}
        }

        startTour['patient_interface'] = true

        let uid = localStorage.getItem('uid')
        let ref_path = 'Users/' + uid + '/Data/'

        updateDataDirectory(ref_path, {'startTour': startTour})
      }
    });
  },
  methods: {
    getClientData() {

      const _this = this

      let client_data = 'Users/' + _this.client_uid + '/Settings/'

      getData(client_data , function (err, result) {
        if(result!=undefined) {
          let settings = result.val()
          if(settings && 'intelligibility_grading_selection' in settings) {
            localStorage.setItem('intelligibility_grading_selection', settings['intelligibility_grading_selection']);
          }
        }
      })
    
    },
    handleSelect(key) {
      this.selectedIndex = key;      
      sessionStorage.setItem('patient_interface_selected_page', key);
    },
  }
}
</script>

<style scoped>
a:hover {
  background-color: rgb(218, 218, 218);
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
}
</style>