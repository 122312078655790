<template>
  <div>
    <Layout>
      <template v-slot:content>
        <!-- <div class="row text-center" v-for="(line,lineNumber) of Ex_Description_Split" v-bind:key="lineNumber"> 
          <p class="h3"> {{line}} </p>
        </div> -->

        <div class="card">
          <h4>
            The first word is _ _ _, the second word is _ _ _, are they the same or different?
          </h4>
          <div class="row justify-content-center mt-2">
            <!-- <div class="col-md-4">
              <button type="button" class="btn btn-outline-dark btn-lg w-100" @click="bv_tts.say(word_1)">First word
                <img :src="audioImg" style="width:40px;;cursor: pointer;" >
              </button>

            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-outline-dark btn-lg w-100" @click="bv_tts.say(word_2)">Second word
                <img :src="audioImg" style="width:40px;;cursor: pointer;" >
              </button>

            </div> -->
            <div class="col-md-6">
              <button type="button" class="btn btn-primary btn-lg w-100" @click="listen_again()">Listen to the words again
                <img :src="audioImg" style="width:40px;;cursor: pointer;" >
              </button>

            </div>
          </div>
        </div>
        <!-- <br> -->
        <!-- <br> -->
        <!-- <br> -->
        <div class="row mt-2">
          <p class="fs-3" :style="interaction_color">{{interaction_msg}}</p>
        </div>

        <div class="row justify-content-center" style="margin-top: 20mm;">
          
          <div class="col-md-4">
            <button class="custom-button-BV" @click="select(true)">Same
              <img :src="equalImg" style="width:60px;;cursor: pointer;" >
                <!-- <br> -->
            </button>
          </div>
  
          <div class="col-md-4">
            <button class="custom-button-BV" style="margin-left:2%" @click="select(false)" >Different
              <img :src="notEqualImg" style="width:60px;;cursor: pointer;" >
                <!-- <br> -->
            </button>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
          @next="nextExercise()"

          :has_show_results="show_results"
          @show_results="goToResults()"   
          >
        </Sidebar>
      </template>
    </Layout>
  </div>

</template>
<script>

import { Modal } from 'bootstrap'
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import {getData,getUserDataDir,updateUserData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {startConfetti} from '@/common_js/confetti'
import equalImg from '@/assets/images/equal.png'
import notEqualImg from '@/assets/images/not_equal.png'
import audioImg from '@/assets/images/audio_logo.png'

import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'

export default {
  name: "Matching_shapes",
  /*

  {
   "exercise_type":"memory_image",
   "instruct":"memory_image",
   "n_images":8,
   "n_words":5,
   "name":4,
   "plan_name":"Memory (image)",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Memory (image)/4",
   "ex_path":"Memory (image)/4",
   "today":"2023-07-11 17:24",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"memory_image",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"memory_image",
   "module_name":"Memory (image)",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
  props: {
    exForm: {
      type: Object,
    }
  },
  components: {Layout, Sidebar},
  data() {
    return {
      Ex_Description: "You will listen to two words. \n Press 'Same' if they are the same word. \n Press 'Different' if they are different.",
      target_img_idx:0,
      is_same: false,
      listen_count:0,
      destroying: false,
      first_answer:true,
      results:{correct:0,incorrect:0, total:0},
      audioTimeout: null,
      curr_ex_done:false,
      current_exercise_index:1,
      total_exercises: 1,
      word_1_text:'',
      word_2_text:'',
      word_1:'',
      word_2:'',
      show_results:false,
      bv_tts: useTTS().tts_engine,
      notEqualImg:notEqualImg,
      equalImg:equalImg,
      audioImg:audioImg,
      interaction_color:'',
      interaction_msg:'',
    }
  },
  created() 
  {
    let _this = this;
    
    logActivity( 'accessed', 'listening_minimal_pairs')


    _this.setup()
  },
  computed: {
      Ex_Description_Split: function () {
        // `this` points to the vm instance
        return this.Ex_Description.toString().split('\n')
      }
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()

    this.destroying = true

    clearTimeout(this.audioTimeout)
  },
  mounted()
  {
    const _this = this
  },
  methods: 
  {
    setup()
    {

      const _this = this


      _this.show_results = _this.exForm.current_exercise_index>=1

      _this.current_exercise_index = _this.exForm.current_exercise_index+1

      _this.total_exercises = _this.exForm.total_all_type_exercises

      let target_words = _this.exForm.words

      _this.is_same = (Math.floor(Math.random() * 2))==1

      if(_this.is_same)
      {
        let word_index = (Math.floor(Math.random() * 2))
        _this.word_1 = target_words[word_index]
        _this.word_2 = target_words[word_index]




      }
      else
      {
        _this.word_1 = target_words[0]
        _this.word_2 = target_words[1]
      }

      _this.word_1_text = 'First word: ' + _this.word_1
      _this.word_2_text = 'Second word: ' + _this.word_2


      setTimeout(() => {
        // _this.bv_tts.say_sentences([this.Ex_Description,_this.word_1_text, _this.word_2_text],this)
        _this.bv_tts.say_sentences(['the first word is', _this.word_1, 'the second word is', _this.word_2, 'are they the same or different'])
      }, 500)


    },
    select(selection)
    {
      const _this = this

      this.curr_ex_done = true

      if(selection==this.is_same)
      {
        if(this.first_answer)
        {
          _this.results['correct'] = _this.results['correct']  + 1
          _this.results['total'] = _this.results['total']  + 1
          startConfetti(this)

        }
        let correct_text = 'Correct.'
        _this.interaction_msg = correct_text;
        _this.interaction_color = 'fontSize:24px;color:green';

        _this.bv_tts.system_say_sentences([correct_text])

        setTimeout(() => {
          this.nextExercise()
        }, 1200);
      } else {
        if(this.first_answer)
        {
          _this.results['incorrect'] = _this.results['incorrect']  + 1
          _this.results['total'] = _this.results['total']  + 1
        }

        let incorrect_text = 'Not quite right.'

        if(_this.is_same)
        {
          // incorrect_text = incorrect_text + _this.word_1 + ' is the same word as ' + _this.word_2
          incorrect_text = incorrect_text + ' Both words are '+ _this.word_1 
        }
        else
        {
          incorrect_text = incorrect_text +' '+ _this.word_1 + ' is different to ' + _this.word_2
        }

        _this.interaction_msg = incorrect_text;
        _this.interaction_color = 'fontSize:24px;color:red';

        _this.bv_tts.system_say_sentences([incorrect_text],100)


      }

    },
    listen_again()
    {
      this.listen_count = this.listen_count + 1

      this.bv_tts.say_sentences([this.word_1_text,this.word_2_text])
    },
    storeResults()
    {
      const _this = this;

      if(_this.curr_ex_done)
      {
        let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'],Total:_this.results['total'],ListenCount:_this.listen_count}

        let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}
        let results = {all_results:all_results, plot_results:plot_results}
        
        var listenMinimalPairsData = {resultsTablePre: {exercise_type: 'Listen Minimal Pairs', exercise: 'Listen Minimal Pairs', results: results, audio_address:''}}


        save_pre_computed_results(_this.exForm, listenMinimalPairsData)

        _this.$emit('logExerciseResults', listenMinimalPairsData);

      }
     
    },
    nextExercise()
    {
      this.storeResults()
      this.$emit('nextExercise');
    },
    goToResults()
    {
      const _this = this
      this.storeResults()
      
      _this.$emit('goToResults', _this.curr_ex_done);


    },

  },
}
</script>
<style scoped>
.std-pre{
  word-break: break-all

}
.page_title {
  width: 100%;
  height: 13mm;
  background-color: #7cc543;
    /* opacity: 80%; */
}
.page_main {
  width: 100%;
  height: calc(100vh - 13mm);
  background-color: #195e52;
  /* opacity: 80%; */
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 13mm;
  margin-left: 2%;
  font-size: 30px;
}
.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}

.text_item {
/* margin-bottom: 18px; */
font-size: 30px;
/* vertical-align: middle; */
/* padding: 40px 200px; */
/* height:40%; */
}

.custom-text{
  font-size: 20px;
    /* color:#FFFFFF; */
}

.menu__item {
  display: block;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 24px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}

.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

.button::-moz-focus-inner{
  border: 0;
  padding: 0;
}

.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: #195e52;
    height: 35mm;
    width: 60mm;
    font-size: xx-large;
}


</style>

<!-- Result Description
{
  "Memory Image":
  {
    "<key>": {
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>", //audio address is here because that's how I used to find where the results are
        "exercise": "Memory Image",
        "exercise_type": "Memory Games",
        "results": {
          "all_results": { 
            "Correct": "<int>",
            "Incorrect": "<int>",
            "ListenCount": "<int>",
            "Total": "<int>"
          },
          "plot_results": { //These values are the ones that feed into the end results plot
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Memory Image"
  }
} -->