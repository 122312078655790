<template>
  <div>
    <div class="page_title">
      <span class="title_txt">Previous results</span>
      <img :src="backImg" style="width:40px;float:right;margin-top:20px;margin-right:20px;cursor: pointer;" @click="backhome()">
    </div>
    <div class="page_main">
      <div class="card card-body">
        <div class="row">
          <button class="btn btn-primary w-50" @click="updateUser()">Search by uid</button>
        </div>
        <div class="row mt-4">
          <p class="h4">
            User information:
          </p>
        </div>
        <div class="row" v-if="category==='slp'">
          <div class="row">
            <div class="col">
              <p>UID</p> 
            </div>
            <div class="col">
              <input v-model="target_uid"/>
            </div>
            <div class="col">
              <p>Category</p> 
            </div>
            <div class="col">
              <input v-model="category"/>
            </div>
          </div>
          <div class="row justify-content-start">
            <div class="col">
              <p>SLP interests</p> 
            </div>
            <div class="col">
              <input v-model="slp_interests"/>
            </div>
            <div class="col">
              <div v-for="(n,id) in activity_list" :key="n">
                <button class="btn btn-primary">{{activity_list[id]}}</button>
              </div>
            </div>
          </div>

        </div>
        <div class="row" v-else>
          <div class="row">
            <div class="col">
              <p>UID</p> 
            </div>
            <div class="col">
              <input v-model="target_uid"/>
            </div>
            <div class="col">
              <p>Category</p> 
            </div>
            <div class="col">
              <input v-model="category"/>
            </div>
            <div class="col">
              <p>Conditions</p> 
            </div>
            <div class="col">
              <input v-model="conditions"/>
            </div>
          </div>
          <div class="row justify-content-start">
            <div class="col">
              <p>SLP contact</p> 
            </div>
            <div class="col">
              <input v-model="slp_contact"/>
            </div>
            <div class="col">
              <div v-for="(n,id) in activity_list" :key="n">
                <button class="btn btn-primary">{{activity_list[id]}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div class="card card-body">
        <div class="row"><h4>Results monitoring:</h4></div>
          <div class="row" v-if="dataExist">
            <div class="col-6">
              <div class="row"><h5>{{chosenDisplay[chosenFeature]}}</h5></div>

                <div id="chartjs-bar">
                  <canvas id="canvas"></canvas>
                </div>
            </div>
            <div class="col-6">
              <div class="row"><h5>Speech and voice quality monitoring</h5></div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectIntelligibilityAccuracy()" :disabled="disabledArray['accuracy_score']" >Word intelligibility<i class="bi bi-chevron-right"></i></button>
                </div>
                <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectSentenceIntelligibilityAccuracy()" :disabled="disabledArray['sentence_intelligibility']" >Sentence intelligibility<i class="bi bi-chevron-right"></i></button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectSpeechRate()" :disabled="disabledArray['speech_rate']">Speech rate<i class="bi bi-chevron-right"></i></button>
                </div>
                  <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectLoudness()" :disabled="disabledArray['loudness']">Voice volume<i class="bi bi-chevron-right"></i></button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectMeanF0()" :disabled="disabledArray['meanF0']">Voice pitch<i class="bi bi-chevron-right"></i></button>
                </div>
                <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectMeanF0()" :disabled="disabledArray['meanF0']">Voice pitch range<i class="bi bi-chevron-right"></i></button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectDDK()" :disabled="disabledArray['ddk_rate']">DDK rate<i class="bi bi-chevron-right"></i></button>
                </div>
                <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectWordNaming()" :disabled="disabledArray['word_naming']">Word naming<i class="bi bi-chevron-right"></i></button>
                  </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-outline-secondary" @click="selectWordFinding()" :disabled="disabledArray['word_finding']">Word finding<i class="bi bi-chevron-right"></i></button>
                </div>
                  <div class="col">
                    <!-- <button class="btn btn-outline-secondary" @click="selectWordNaming()" :disabled="disabledArray['word_naming']">Word naming<i class="bi bi-chevron-right"></i></button> -->
                  </div>
              </div>
            </div>
        </div>
        
        <div class="row" v-else>
          <p class="custom-text">You do not have results in record yet. </p>
          <br>
          <p class="custom-text">Please perform speech and/or voice assessment first.</p>
        </div>
      </div>  

      <!-- currErrorLog = {audio_address: audio_address, error: error, error_counter:error_counter} -->

      <div class="card card-body">
        <div class="row">
          <p>Error log: {{error_average}}%</p>  
        </div>
        <div class="row">
          <p>Errors: {{error_counter}} Total Exercises: {{errorTable.length}}</p>
        </div>
        <div class="row">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Error count</th>
                <th scope="col">Error</th>
                <th scope="col">Playback</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entity, id in errorTable" v-bind:key="id" style="cursor: pointer">
                <th scope="row">{{ entity.date }}</th>
                <td>{{ entity.error_counter }}</td>
                <td>{{ entity.error }}</td>
                <td>
                  <button class="btn btn-danger" @click="listenPlayback(entity)">Playback</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
        
</template>


<script>
import homeImg from '@/assets/images/home.png'
import backImg from '@/assets/images/back.png'
import planImg from '@/assets/images/plan.png'
import warnImg from '@/assets/images/warn.png'
import addImg from '@/assets/images/add.png'
import { getData } from '@/firebase/index'


export default {
  name: 'patient_history_display',
    data() {
        return {
          first_time: true,
          planImg: planImg,
          warnImg: warnImg,
          addImg: addImg,
          backImg: backImg,
          allResults: {},
          chosenFeature: 'word_intelligibility',
          target_uid:'',
          dataExist:true,
          errorTable: [],
          audio_player: null,
          error_counter: 0,
          error_average: 0,
          disabledArray: {word_finding: true, word_naming: true, ddk_rate: true, loudness: true, speech_rate: true, sentence_intelligibility: true, word_intelligibility: true, meanF0: true, localJitter: true, localShimmer:true },
          chosenDisplay: {word_finding: 'Word finding (average number of words said)', word_naming: 'Word naming (% correct answers)',ddk_rate: "Diadochokinetic rate (syllables/s)",loudness:'Average Sound Pressure Level (dB)',speech_rate: 'Speech rate (syllables/s)', sentence_intelligibility: 'Sentence intelligibility accuracy (%)', word_intelligibility: 'Word intelligibility accuracy (%)', localJitter: 'Local Jitter (%)' , localShimmer: 'Local Shimmer (%)' , meanF0: 'Mean F0 (Hz)' },
          slp_contact:'',
          conditions:'',
          slp_interests:'',
          activity_list:[],
          activity_count:[],
          category:'',
          form_patient:'',
        }
    },

created() {
    this.target_uid = this.$route.params.name;
    this.activity_list = this.$route.params.activity_list;
    this.activity_count = this.$route.params.activity_count;
    this.getUserInfo();
    this.seeResults();
},
mounted: function()
{


},
updated: function()
{

},
methods:
{
  updateUser(){
    this.getUserInfo();
    this.seeResults();
  },
  seeResults() {
    this.loadPreviousExercises()
  },
  getUserInfo() {
    let _this = this;
    let usr_ref = _this.$firebase.database().ref('Users').child(_this.target_uid).child('Data');

    getData(usr_ref, function (err, result) {
      _this.category = result.child('category').val();
      
      if (_this.category == 'slp') {
        _this.slp_interests = [];
        result.child('Qs_foi').forEach(function (childSnapshot) {
          _this.slp_interests.push(childSnapshot.val());
        }); 
      } else {
        _this.slp_contact = result.child('SLP').val();
        _this.conditions = [];
        result.child('Qs_diseaseType').forEach(function (childSnapshot) {
          _this.conditions.push(childSnapshot.val());
        }); 
      }
    });
  },
    backhome() {
      this.$router.push({ path: '/research/user_activity' })
    },
    loadPreviousExercises() {

      const _this  = this;

      _this.errorTable = []

      var do_once = true;

      var pastResults = {}

      let target_folder = '/Users/' + _this.target_uid + '/Results/' 




      getData(target_folder,function (err, result) {

        if(do_once) {
          do_once = false;
          var results_dir_handle = result

          results_dir_handle.forEach(function(singleDateTimeAssessments)
          {

              var date = ''

              if(singleDateTimeAssessments.hasChild('Date'))
              {
                  date = singleDateTimeAssessments.child('Date').val()
                  
              }
              else
              {
                  date = singleDateTimeAssessments.key
              }

              let split_date = date.split(' ')

              date = split_date[0]


              if(!(date in pastResults))
              {
                  pastResults[date] = {}

              }

              let dir = ''



              let resultsAddresses = _this.findKey(singleDateTimeAssessments,'audio_address',dir,_this)


              for(let result in resultsAddresses)
              {

                let curr_address = resultsAddresses[result]

                curr_address = curr_address.substring(curr_address.indexOf("/")+1, curr_address.length);
                curr_address = curr_address.substring(curr_address.indexOf("/")+1,curr_address.length);

                curr_address = '/' + curr_address


                let singleExercise = singleDateTimeAssessments.child(curr_address)

                
                _this.getErrorLog(singleExercise,date)


                if(singleExercise.hasChild('intelligibility'))
                {

                    if(!('word_intelligibility' in pastResults[date]))
                    {
                        pastResults[date]['word_intelligibility'] = []

                        _this.disabledArray['word_intelligibility'] = false

                        _this.chosenFeature = 'word_intelligibility'


                    }

                    let accuracy_score = singleExercise.child('intelligibility').child('accuracy_score').val()
                    pastResults[date]['word_intelligibility'].push(accuracy_score)
                }
                
                if(singleExercise.hasChild('sentencegibility'))
                {


                    if(!('sentence_intelligibility' in pastResults[date]))
                    {
                        pastResults[date]['sentence_intelligibility'] = []

                        _this.disabledArray['sentence_intelligibility'] = false

                        _this.chosenFeature = 'sentence_intelligibility'

                    }

                    let accuracy_score = singleExercise.child('sentencegibility').child('accuracy_score').val()

                    pastResults[date]['sentence_intelligibility'].push(accuracy_score)
                }

                if(singleExercise.hasChild('loudness'))
                {

                    if(!('loudness' in pastResults[date]))
                    {
                        pastResults[date]['loudness'] = []

                        _this.disabledArray['loudness'] = false

                        _this.chosenFeature = 'loudness'

                    }

                    let accuracy_score = singleExercise.child('loudness').child('loudness').val()
                    pastResults[date]['loudness'].push(accuracy_score)
                }

                
                if(singleExercise.hasChild('speechrate_dictionary'))
                {

                    if(!('speech_rate' in pastResults[date]))
                    {
                        pastResults[date]['speech_rate'] = []

                        _this.disabledArray['speech_rate'] = false
                        _this.chosenFeature = 'speech_rate'


                    }

                    let accuracy_score = singleExercise.child('speechrate_dictionary').child('speechrate(nsyll by dur)').val()
                    pastResults[date]['speech_rate'].push(accuracy_score)
                }
              

                if(singleExercise.hasChild('ddk_rate_dictionary'))
                {

                    if(!('ddk_rate' in pastResults[date]))
                    {
                        pastResults[date]['ddk_rate'] = []

                        _this.disabledArray['ddk_rate'] = false
                        _this.chosenFeature = 'ddk_rate'


                    }

                    let ddk_rate = singleExercise.child('ddk_rate_dictionary').child('ddk_rate').val()
                    pastResults[date]['ddk_rate'].push(ddk_rate)
                }

                if(singleExercise.hasChild('ddk_rate_dictionary'))
                {

                    if(!('ddk_rate' in pastResults[date]))
                    {
                        pastResults[date]['ddk_rate'] = []

                        _this.disabledArray['ddk_rate'] = false
                        _this.chosenFeature = 'ddk_rate'


                    }

                    let ddk_rate = singleExercise.child('ddk_rate_dictionary').child('ddk_rate').val()
                    pastResults[date]['ddk_rate'].push(ddk_rate)
                }

                if(singleExercise.hasChild('word_naming'))
                {

                    if(!('word_naming' in pastResults[date]))
                    {
                        pastResults[date]['word_naming'] = []

                        _this.disabledArray['word_naming'] = false
                        _this.chosenFeature = 'word_naming'


                    }

                    let word_naming_success = singleExercise.child('word_naming').child('success').val()
                    if(word_naming_success==true)
                    {
                      pastResults[date]['word_naming'].push(100)
                    }
                    else if(word_naming_success==false)
                    {
                        pastResults[date]['word_naming'].push(0.001)

                    }
                }

                if(singleExercise.hasChild('word_finding'))
                {

                    if(!('word_finding' in pastResults[date]))
                    {
                        pastResults[date]['word_finding'] = []

                        _this.disabledArray['word_finding'] = false
                        _this.chosenFeature = 'word_finding'


                    }

                    let n_words_found = singleExercise.child('word_finding').child('n_found_words').val()
    
                    pastResults[date]['word_finding'].push(n_words_found)

                    console.debug(' pastResults[date][word_finding] = '  +  pastResults[date]['word_finding'])

                  
                }

              }
        

          });


          for(let label in _this.chosenDisplay) {
            _this.allResults[label] = _this.mergeResults(pastResults,label)
          }
          _this.createBarChart()
        }

      })
    },
    getErrorLog(singleExerciseHandle,date)
    {
      const _this = this


      let audio_address = singleExerciseHandle.child('audio_address').val()

      let error = ''

      if(singleExerciseHandle.hasChild('error')) {
        let curr_error = singleExerciseHandle.child('error').child('error').val()
        error += 'top_level_error = ' + curr_error + ';'
      }

      let assessment_list = ['intelligibility','sentencegibility','loudness','speechrate_dictionary','ddk_rate_dictionary','word_naming','word_finding']


      for(let n in assessment_list) {

        let assessment = assessment_list[n]

        if(singleExerciseHandle.hasChild(assessment)) {
          console.debug('here')
          if(singleExerciseHandle.child(assessment).hasChild('Error')) {
            console.debug('here 2')

            let curr_error =  singleExerciseHandle.child(assessment).child('Error').val()

            console.debug('curr_error = ' + curr_error)

            if(curr_error) {
              error += assessment + ' = ' + curr_error
            }
          }
        }
      }



      let curr_error_counter = (error=='')?0:1

      _this.error_counter += curr_error_counter

      let currErrorLog = {date: date, audio_address: audio_address, error: error, error_counter:curr_error_counter}

      _this.errorTable.push(currErrorLog) 
      
      _this.error_average = _this.roundNumber(_this.error_counter/_this.errorTable.length*100,2)
     

    },
    findKey(firebaseHandle,target_key,dir_root) {

      var _this = this;


      let results = _this.findKeyCrawler(firebaseHandle,target_key,dir_root,_this)


      // console.debug(results.length)

      return results


    },
    findKeyCrawler(firebaseHandle,target_key,dir_root,_this) {
      if (firebaseHandle.key != target_key) {
        if (firebaseHandle.hasChildren()) {

          let all_results = []

          firebaseHandle.forEach(function (childSnapshot) {

            let c_snapshot = childSnapshot
            let new_dir = dir_root + '/' + firebaseHandle.key


            let curr_result = _this.findKeyCrawler2(c_snapshot, target_key,new_dir,_this)

            if(curr_result!=null) {
              all_results =  all_results.concat(curr_result)
            }


          });

          if(all_results.length>0) {
            return all_results
          }
        }
      }
      else{
        return [dir_root + '/']
      }

      return null

    },
    findKeyCrawler2(firebaseHandle,target_key,dir_root,_this)
    {
      if(firebaseHandle.key !=target_key)
      {
        if(firebaseHandle.hasChildren())
        {

          let all_results = []

          firebaseHandle.forEach(function (childSnapshot) {

            let c_snapshot = childSnapshot
            let new_dir = dir_root + '/' + firebaseHandle.key

            let curr_result = _this.findKeyCrawler(c_snapshot, target_key,new_dir,_this)

            if(curr_result!=null)
            {
              all_results =  all_results.concat(curr_result)

            }


          });

          if(all_results.length>0)
          {
            return all_results
          }

        }
      }
      else {
        return [dir_root + '/']
      }

      return null

    },
    listenPlayback(entity) {
      let _this = this;

      var audio_file_location = row['audio_address']

      console.debug('audio_file_location = ' + audio_file_location)
      _this.playAudio(audio_file_location)
    },
    playAudio(audio_file_location)
    {

      let _this = this;
      var storage = _this.$firebase.storage();

      storage.ref(audio_file_location).getDownloadURL()
      .then((url) => {
        _this.playSound(url);
      })
      .catch((error) => {
      
      });
    
    },
    playSound(url) {
      let _this = this

      if(this.audio_player==null) {
        this.audio_player = new Audio(url);
        this.audio_player.play();
      } else {
        if(!this.audio_player.paused) {
          this.audio_player.pause();
        }
       
        {
          this.audio_player = new Audio(url);
          this.audio_player.play();
        }
      }

    },
    mergeResults(pastResults,label)
    {
      var labelResults = {}

      for(let date in pastResults) {
        if(label in pastResults[date]) {
          let currResult = pastResults[date][label]

          let sum_values = 0
          let n_values = 0

          for(let n in currResult) {
            if(currResult[n]!=null && currResult[n]>0) {
              sum_values = sum_values + currResult[n]
              n_values = n_values + 1
            }
          }

          if(label == 'word_finding') {
            console.debug('pastResults[date][label] = ' + pastResults[date][label])
          }
          if(n_values>0) {
            labelResults[date] = sum_values/n_values
          }
        }
      }

      return labelResults
    },
    roundNumber(number,number_of_decimals)
    {
      let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)

      return rounded_number;

    },
    findMinMax(data)
    {
      let minimum = 1000
      let maximum = 0

      for(let k in data)
      {


        if(data[k]>maximum)
        {
          maximum = data[k]

        }


        
        if(data[k]<minimum)
        {

          minimum = data[k]
        }
      }

      return [minimum,maximum]

    },
    createBarChart()
    {
  
        var _this = this;

        var data = _this.allResults[_this.chosenFeature]

    

        var labels = []
        var fdata = []
        var final_data = {}

        final_data['data'] = []


        for(let date in data)
        {
          labels.push(date)
          final_data['data'].push(data[date])

        }


        var minMax = this.findMinMax(data)

        let minimum = minMax[0]
        let maximum = minMax[1]


        maximum = maximum +(maximum-minimum)/20


        maximum = this.roundNumber(maximum + 0.5,0)



        if(maximum > 10)
        {
          let lastDigit = maximum % 10;

          
          maximum = maximum + 10 - lastDigit
          
        }



         if(this.chosenFeature == 'loudness')
         {
           minimum = Math.min(50,minimum)
         }
         else if(this.chosenFeature == 'word_intelligibility')
         {
            maximum = 100

           minimum = Math.min(50,minimum)
         }
         else if(this.chosenFeature == 'sentence_intelligibility')
         {
           maximum = 100
           minimum = Math.min(50,minimum)
         }
         else if(this.chosenFeature == 'word_naming')
         {
           maximum = 100
           minimum = Math.min(50,minimum)
         }
         else
         {
          minimum = 0
         }


          // final_data['backgroundColor'] = ['rgba(230, 25, 75,0.2)', 'rgba(60, 180, 75,0.2)', 'rgba(255, 225, 25,0.2)', 'rgba(0, 130, 200,0.2)', 'rgba(245, 130, 48,0.2)', 'rgba(145, 30, 180,0.2)', 'rgba(70, 240, 240,0.2)', 'rgba(240, 50, 230,0.2)', 'rgba(210, 245, 60,0.2)', 'rgba(250, 190, 212,0.2)', 'rgba(0, 128, 128,0.2)', 'rgba(220, 190, 255,0.2)', 'rgba(170, 110, 40,0.2)', 'rgba(255, 250, 200,0.2)', 'rgba(128, 0, 0,0.2)', 'rgba(170, 255, 195,0.2)', 'rgba(128, 128, 0,0.2)', 'rgba(255, 215, 180,0.2)', 'rgba(0, 0, 128,0.2)', 'rgba(128, 128, 128,0.2)', 'rgba(255, 255, 255,0.2)', 'rgba(0, 0, 0,0.2)'
          //   ]

          // final_data['borderColor'] = ['rgba(230, 25, 75)', 'rgba(60, 180, 75)', 'rgba(255, 225, 25)', 'rgba(0, 130, 200)', 'rgba(245, 130, 48)', 'rgba(145, 30, 180)', 'rgba(70, 240, 240)', 'rgba(240, 50, 230)', 'rgba(210, 245, 60)', 'rgba(250, 190, 212)', 'rgba(0, 128, 128)', 'rgba(220, 190, 255)', 'rgba(170, 110, 40)', 'rgba(255, 250, 200)', 'rgba(128, 0, 0)', 'rgba(170, 255, 195)', 'rgba(128, 128, 0)', 'rgba(255, 215, 180)', 'rgba(0, 0, 128)', 'rgba(128, 128, 128)', 'rgba(255, 255, 255)', 'rgba(0, 0, 0)'
          //   ]


          final_data['backgroundColor'] = ['rgba(255, 99, 132, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 205, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(128, 0, 0 , 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 205, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(128, 0, 0 , 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 205, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(128, 0, 0 , 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 205, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(128, 0, 0 , 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 205, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(128, 0, 0 , 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 205, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(128, 0, 0 , 0.2)'
                                ]

          final_data['borderColor'] = ['rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(128, 0, 0 )',
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(128, 0, 0 )',
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(128, 0, 0 )',
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(128, 0, 0 )',
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(128, 0, 0 )',
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(128, 0, 0 )'
                            ]
          final_data['borderWidth'] = 1
          final_data['pointBackgroundColor'] = "rgba(10,181,198,1)"
          final_data['pointBorderColor'] =  "#fff"
          final_data['pointHoverBorderColor'] = "rgba(10,181,198,1)"


        
        fdata.push(final_data)


       
            if(this.first_time)
            {

               _this.barChartData = {
                labels: labels,
                datasets:  fdata
            
              };

                _this.options = {
                  responsive: true,
                  maintainAspectRadio: true,
                  scales:{
                      y: {
                        
                          title: {
                          // color: 'red',
                          display: true,
                          text: 'Minutes'
                          }
                      }   
                  },
                  legend: {
                    display: false,
                    position: 'top',
                    },
                  title: {
                      display: true,
                      // text: 'Monthly Update'
                      },
                  
                  scales: {
                        yAxes: [{
                            ticks: {
                                min: minimum,
                                max : maximum
                            }
                        }],
                        
                        xAxes: [{
                            ticks: {
                                fontSize: 10
                            }
                        }]
                    }

                  };


              this.first_time = false
              window.myBar = new Chart(document.getElementById("canvas"), {
                    type: 'bar',
                    data: _this.barChartData,
                    options: _this.options
              });
            }
            else
            {

              window.myBar.options.scales.yAxes[0].ticks.min = minimum;
              window.myBar.options.scales.yAxes[0].ticks.max = maximum;


         
              

              _this.barChartData.datasets = fdata;
            _this.barChartData.labels = labels;

              window.myBar.update();
            }
    },
      selectLoudness()
    {
      this.chosenFeature = 'loudness'
      // this.choseDisplay = 'Average Sound Pressure Level (dB)'
      this.createBarChart()

    },
    selectJitter()
    {
      this.chosenFeature = 'localJitter'
      // this.choseDisplay = 'Local Jitter (ms)'
      this.createBarChart()
    },
    selectShimmer()
    {
      this.chosenFeature = 'localShimmer'
      // this.choseDisplay = 'Local Shimmer (dB)'
      this.createBarChart()
    },
    selectMeanF0()
    {
      this.chosenFeature = 'meanF0'
      // this.choseDisplay = 'Mean F0 (Hz)'
      this.createBarChart()
    },
    selectDDK()
    {
      this.chosenFeature = 'ddk_rate'
      // this.choseDisplay = 'Mean F0 (Hz)'
      this.createBarChart()
    },
    selectWordNaming()
    {
      this.chosenFeature = 'word_naming'
      // this.choseDisplay = 'Mean F0 (Hz)'
      this.createBarChart()
    },
    selectWordFinding()
    {
      this.chosenFeature = 'word_finding'
      // this.choseDisplay = 'Mean F0 (Hz)'
      this.createBarChart()
    },
    selectArticulationRate()
    {
      this.chosenFeature = 'articulationrate'
      this.createBarChart()
    },
    selectIntelligibilityAccuracy()
    {
      this.chosenFeature = 'word_intelligibility'
      // this.choseDisplay = 'Intelligibility accuracy (%)'
      this.createBarChart()
    },
    selectSentenceIntelligibilityAccuracy()
    {
      this.chosenFeature = 'sentence_intelligibility'
      // this.choseDisplay = 'Intelligibility accuracy (%)'
      this.createBarChart()
    },
    selectSpeechRate()
    {
      this.chosenFeature = 'speech_rate'
      // this.choseDisplay = 'Speech rate (syllables/s)'
      this.createBarChart()

    }
}

}
</script>
<style scoped>
.page_title {
  width: 100%;
  height: 80px;
  background-color: #fce5c6;
}
.page_main {
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #ebe1d8;
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 80px;
  margin-left: 2%;
  font-size: 40px;
}
.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.custom-text{
  font-size:20px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}
 .text_item {
    /* margin-bottom: 18px; */
    font-size: 30px;
    /* vertical-align: middle; */
    /* padding: 40px 200px; */
    /* height:40%; */
  }

.menu__item {
  display: block;
  line-height: 20px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 16px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}

.newLineDiv{
  margin-top: 20px;
  clear:both;
  float: left;
  margin-left: 10px;

}

.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

.button::-moz-focus-inner{
  border: 0;
  padding: 0;
}

.button{
  display: inline-block;
  *display: inline;
  zoom: 1;
  padding: 6px 20px;
  margin: 0;
  cursor: pointer;
  border: 1px solid #bbb;
  overflow: visible;
  font: bold 13px arial, helvetica, sans-serif;
  text-decoration: none;
  white-space: nowrap;
  color: #555;
  
  background-color: #ddd;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,1)), to(rgba(255,255,255,0)));
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
  background-image: -moz-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
  background-image: -ms-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
  background-image: -o-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
  background-image: linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
  
  -webkit-transition: background-color .2s ease-out;
  -moz-transition: background-color .2s ease-out;
  -ms-transition: background-color .2s ease-out;
  -o-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  background-clip: padding-box; /* Fix bleeding */
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .3), 0 2px 2px -1px rgba(0, 0, 0, .5), 0 1px 0 rgba(255, 255, 255, .3) inset;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .3), 0 2px 2px -1px rgba(0, 0, 0, .5), 0 1px 0 rgba(255, 255, 255, .3) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .3), 0 2px 2px -1px rgba(0, 0, 0, .5), 0 1px 0 rgba(255, 255, 255, .3) inset;
  text-shadow: 0 1px 0 rgba(255,255,255, .9);
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:hover{
  background-color: #eee;
  color: #555;
}

.button:active{
  background: #e9e9e9;
  position: relative;
  top: 1px;
  text-shadow: none;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
}

.button[disabled], .button[disabled]:hover, .button[disabled]:active{
  border-color: #eaeaea;
  background: #fafafa;
  cursor: default;
  position: static;
  color: #999;
  /* Usually, !important should be avoided but here it's really needed :) */
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
}

/* Smaller buttons styles */

.button.small{
  padding: 4px 12px;
}

/* Larger buttons styles */

.button.large{
  /* font-size: 30px; */
  padding: 12px 30px;
  text-transform: uppercase;


}

.button.large:active{
  top: 2px;
}

/* Colored buttons styles */

.button.green, .button.red, .button.blue {
  color: #fff;
  text-shadow: 0 1px 0 rgba(0,0,0,.2);
  
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,.3)), to(rgba(255,255,255,0)));
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image: -moz-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image: -ms-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image: -o-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image: linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
}

/* */

.button.green{
  background-color: #57a957;
  border-color: #57a957;
}

.button.green:hover{
  background-color: #62c462;
}

.button.green:active{
  background: #57a957;
}

/* */

.button.red{
  background-color: #ca3535;
  border-color: #c43c35;
}

.button.red:hover{
  background-color: #ee5f5b;
}

.button.red:active{
  background: #c43c35;
}

/* */

.button.blue{
  background-color: #269CE9;
  border-color: #269CE9;
}

.button.blue:hover{
  background-color: #70B9E8;
}

.button.blue:active{
  background: #269CE9;
}

/* */

.green[disabled], .green[disabled]:hover, .green[disabled]:active{
  border-color: #57A957;
  background: #57A957;
  color: #D2FFD2;
}

.red[disabled], .red[disabled]:hover, .red[disabled]:active{
  border-color: #C43C35;
  background: #C43C35;
  color: #FFD3D3;
}

.blue[disabled], .blue[disabled]:hover, .blue[disabled]:active{
  border-color: #269CE9;
  background: #269CE9;
  color: #93D5FF;
}

/* Group buttons */

.button-group,
.button-group li{
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.button-group{
  font-size: 0; /* Inline block elements gap - fix */
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}

.button-group li{
  margin-right: -1px; /* Overlap each right button border */
}

.button-group .button{
  font-size: 13px; /* Set the font size, different from inherited 0 */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.button-group .button:active{
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, 5px 0 5px -3px rgba(0, 0, 0, .2) inset, -5px 0 5px -3px rgba(0, 0, 0, .2) inset;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, 5px 0 5px -3px rgba(0, 0, 0, .2) inset, -5px 0 5px -3px rgba(0, 0, 0, .2) inset;
  box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, 5px 0 5px -3px rgba(0, 0, 0, .2) inset, -5px 0 5px -3px rgba(0, 0, 0, .2) inset;
}

.button-group li:first-child .button{
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.button-group li:first-child .button:active{
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, -5px 0 5px -3px rgba(0, 0, 0, .2) inset;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, -5px 0 5px -3px rgba(0, 0, 0, .2) inset;
  box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, -5px 0 5px -3px rgba(0, 0, 0, .2) inset;
}

.button-group li:last-child .button{
  -moz-border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.button-group li:last-child .button:active{
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, 5px 0 5px -3px rgba(0, 0, 0, .2) inset;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, 5px 0 5px -3px rgba(0, 0, 0, .2) inset;
  box-shadow: 0 0 1px rgba(0, 0, 0, .2) inset, 5px 0 5px -3px rgba(0, 0, 0, .2) inset;
}
</style>





