import constants from '@/utils/constants'

import {phonemeToWord} from '@/utils/utils'
import { getData, setData, getUserData } from '@/firebase/index'

let phonemeLib = ['b','ch','d','dh','f','g','h','jh','k','l','m','n','p','r','s','sh','t','th','v','w','y','z','zh','aa','aar','ae','ah','ao','aw','ax','ay','b','ch','d','dh','eh','ehr','er','ey','f','g','h','ih','ihr','iy','j','jh','k','l','m','n','ng','ow','oy','p','r','s','sh','t','th','uh','uhr','uw','v','w','y','z','zh']

export function getWord_intelligibility_data(exercise_data, name) {
  
  var ground_truth = exercise_data['intelligibility']['reference_text']
  var accuracy = exercise_data['intelligibility']['accuracy_score'] 


  let loudness = Math.round(exercise_data['loudness']['loudness'])
  

  let wordSuggest = []
  let phonemeSuggest = []
  var intelligibility = {}

  console.debug('accuracy = ' + accuracy)
  if(accuracy!=undefined && accuracy!=null && accuracy!=0)
  {
    accuracy = accuracy + "\%"

    let n_phonemes = exercise_data['intelligibility']['Phonemes'].length;

    var phoneme_quality = []
    var phonemes = []
    var phoneme_grade = []
    var phonemes_display = []
    let phoneme_intelligibility_info = []
    var total_phoneme_quality_score = 0

    console.debug('n_phonemes!!!! = ' + n_phonemes)
    

    for(let n=0;n<n_phonemes;++n)
    {
      var curr_phoneme = exercise_data['intelligibility']['Phonemes'][n]['Phoneme']
      
      curr_phoneme = curr_phoneme.split('"').join('');

      var curr_phoneme_UK = curr_phoneme

      let curr_phoneme_quality = exercise_data['intelligibility']['Phonemes'][n]['PronunciationAssessment']['AccuracyScore'] 



      if(constants.phonemeVowelList.includes(curr_phoneme))
      {
        var correction_factor = 1
        if(curr_phoneme_quality<60)
        {
          correction_factor = 1.2
        }
        else
        {
          correction_factor = 1.1
        }
        curr_phoneme_quality = Math.min(100,curr_phoneme_quality*correction_factor)
      }




      let region = localStorage.getItem('region')



      if(region==undefined || region==null || region==='UK')
      {  

       //Only for UK - check if the 'r' phoneme should be replaced or deleted, XXX region variable to be created
        if((n == n_phonemes-1)&& (curr_phoneme_UK == 'r')&& (n_phonemes>1)) 
        {
          var second_last_phoneme = exercise_data['intelligibility']['Phonemes'][n_phonemes-2]['Phoneme']
          second_last_phoneme = second_last_phoneme.split('"').join('');

          if((second_last_phoneme == 'ax') || (second_last_phoneme == 'aa')|| (second_last_phoneme == 'er'))
          {
            n_phonemes = n_phonemes - 1
            break
          }
          else
          {
            curr_phoneme_UK = 'ax'
          }
        }
      }

      total_phoneme_quality_score = total_phoneme_quality_score + curr_phoneme_quality


      let intelligibility_grading_selection = localStorage.getItem('intelligibility_grading_selection')
      
      let intelligibility_grading = constants.intelligibility_grading_strict
              
      if(intelligibility_grading_selection!=undefined && intelligibility_grading_selection!=null && intelligibility_grading_selection==='encouragement')
      {

        intelligibility_grading = constants.intelligibility_grading_encouragement

      }
    

      console.log('intelligibility_grading_selection = ' + intelligibility_grading_selection)
    


      let intelligibility_info = intelligibility_grading[intelligibility_grading.length-1]
      let curr_phoneme_grade = 'Very Good'

      for(let k in intelligibility_grading)
      {
        if(curr_phoneme_quality>=intelligibility_grading[k]['min'] && curr_phoneme_quality<intelligibility_grading[k]['max'])
        {
          intelligibility_info = intelligibility_grading[k]
          curr_phoneme_grade = intelligibility_info['grade']

          break

        }
      }

      // if(curr_phoneme_quality<50)
      // {
      //   curr_phoneme_grade = 'Needs Improvement'

      // }
      // else if(curr_phoneme_quality<80)
      // {
      //   curr_phoneme_grade = 'OK'
      // }

      phoneme_quality.push(curr_phoneme_quality)
      phoneme_grade.push(curr_phoneme_grade)
      phonemes.push(curr_phoneme)
      phoneme_intelligibility_info.push(intelligibility_info)


      var phoneme_display = curr_phoneme_UK
      let IPA_Phoneme_Representation = true


      if(curr_phoneme_UK in constants.phonemeDisplayDictionary && IPA_Phoneme_Representation)
      {
        phoneme_display = constants.phonemeDisplayDictionary[curr_phoneme_UK]
      }
      else
      {
        console.debug('phoneme display not found: ' + curr_phoneme_UK)
      }
      phonemes_display.push(phoneme_display)

    }

    let curr_word_grade = 'Very Good'
    let display_word_grade = 'People can understand you well.'

    accuracy = Math.round(total_phoneme_quality_score/n_phonemes)
    
    if(accuracy<40)
    {
      curr_word_grade = 'Needs Improvement'
      display_word_grade = "It's hard for people to understand what you mean."
    }
    else if(accuracy<70)
    {
      curr_word_grade = 'OK'
      display_word_grade = "People can understand you with familiar words or context."
    }

    accuracy = accuracy + "\%"
    var audio_address = exercise_data['audio_address']
    // console.log('result here')
    let [pho_w,pho_word_pos] = phonemeToWord(ground_truth, phonemes)

    let pho_word_grade = [];
    let phoneme_word_intelligibility_info = [];
    for(let nn=0;nn<pho_w.length;nn++)
    {
      pho_word_grade.push(phoneme_grade[pho_word_pos[nn]])
      phoneme_word_intelligibility_info.push(phoneme_intelligibility_info[pho_word_pos[nn]])
    }    

    // console.log('phoneme_word_intelligibility_info = ' + phoneme_word_intelligibility_info.length)
    // console.log('phonemes_display = ' + phonemes_display.length)

    intelligibility = {Name: name, GroundTruth: ground_truth, loudness:loudness, WordGrade:curr_word_grade, GradeMeaning:display_word_grade, Accuracy:accuracy, AudioAddress: audio_address, PhonemeQuality:phoneme_quality, Phoneme:phonemes, PhoWordGrade: pho_word_grade, phoWord_display: ground_truth.split(''),PhonemeGrade: phoneme_grade, phonemes_display: phonemes_display,phoneme_intelligibility_info:phoneme_intelligibility_info,phoneme_word_intelligibility_info:phoneme_word_intelligibility_info};



    var acc_num = parseInt(accuracy, 10);
    console.debug('acc_num = ' + acc_num)
    if(acc_num<80)
    {
      console.debug('low accuracy phoneme found')
      var wordList ={GroundTruth:ground_truth, Accuracy:acc_num};
      wordSuggest.push(wordList);
      for(let i=0; i<phoneme_quality.length;i++)
      {
        if(parseInt(phoneme_quality[i])<80)
        {
          var phonemeList={PhonName:phonemes[i], phonAccuracy:phoneme_quality[i]};
          phonemeSuggest.push(phonemeList);
        }
      }
    }
  }

  let r = makeSuggestions(wordSuggest, phonemeSuggest);
  r['intelligibility'] = intelligibility

  return r
}

export function getSentence_intelligibility_data(exercise_data, name, sentence_thresh_poor, sentence_thresh_ok) {

  var ground_truth = exercise_data['sentencegibility']['reference_text']
  var accuracy = Math.round(exercise_data['sentencegibility']['accuracy_score']) 
  let loudness = Math.round(exercise_data['loudness']['loudness'])

  let curr_sentence_grade = 'Very Good'
  if(accuracy < sentence_thresh_poor)
  {
    curr_sentence_grade = 'Needs Improvement'
  }
  else if(accuracy< sentence_thresh_ok)
  {
    curr_sentence_grade = 'Ok'
  }

  let wordSuggest = []
  let phonemeSuggest = []

  
  let accuracy_p = accuracy + "\%"

  let n_words = exercise_data['sentencegibility']['Words'].length;
  
  var word_quality = []
  var words = []
  var word_grade = []
  let word_offset = []

  let intelligibility_grading_selection = localStorage.getItem('intelligibility_grading_selection')

      
  let sentence_intelligibility_grading = constants.sentence_intelligibility_grading_strict

  if(intelligibility_grading_selection!=undefined && intelligibility_grading_selection!=null && intelligibility_grading_selection==='encouragement')
  {

    sentence_intelligibility_grading = constants.sentence_intelligibility_grading_encouragement

  }

  let sentence_intelligibility_info = []

  for(let k=0; k < n_words; ++k)
  { 
    let error_type = exercise_data['sentencegibility']['Words'][k]['error_type'];

    if(error_type!="Insertion")
    {
      let curr_word = exercise_data['sentencegibility']['Words'][k]['word'];
      let curr_offset =  exercise_data['sentencegibility']['Words'][k]['Offset'];
      let curr_word_quality = exercise_data['sentencegibility']['Words'][k]['accuracy_score'];

      word_offset.push(curr_offset)
      let curr_word_grade = 'Very Good'


      for(let m in sentence_intelligibility_grading)
      {
          if(curr_word_quality>=sentence_intelligibility_grading[m]['min'] && curr_word_quality<sentence_intelligibility_grading[m]['max'])
          {
              let curr_word_intelligibility_info = sentence_intelligibility_grading[m]
              curr_word_grade = sentence_intelligibility_grading['grade']

              sentence_intelligibility_info.push(curr_word_intelligibility_info)
  
              break
  
          }
      }


      // let curr_word_grade = 'Very Good'
      // if(curr_word_quality<5)
      // {
      //   curr_word_grade = 'Omission'

      // }
      // else if(curr_word_quality<50)
      // {
      //   curr_word_grade = 'Needs Improvement'
      // } else if(curr_word_quality<80) {
      //   curr_word_grade = 'OK'
      // }






      word_quality.push(curr_word_quality)
      word_grade.push(curr_word_grade)
      words.push(curr_word)

    }
  }

  var audio_address = exercise_data['audio_address']

  var sentencegibility = {Name: name, GroundTruth: ground_truth, loudness:loudness, SentenceGrade: curr_sentence_grade, Accuracy:accuracy_p, AudioAddress: audio_address, WordQuality:word_quality, Words:words, WordGrade: word_grade,sentence_intelligibility_info:sentence_intelligibility_info,Offset:word_offset};

  for(let i = 0; i<words.length;i++)
  {
    if(parseInt(word_quality[i])>5 && parseInt(word_quality[i])<80)
    {
      var wordList={GroundTruth:words[i], Accuracy:word_quality[i]};
      wordSuggest.push(wordList);
    }
  }
  let r = makeSuggestions(wordSuggest, phonemeSuggest);
  r['sentencegibility'] = sentencegibility
  r['accuracy'] = accuracy

  return r
}

export function getTranscriptionData(exercise_data, name, sentence_thresh_poor, sentence_thresh_ok) {

  let loudness = Math.round(exercise_data['loudness']['loudness'])

  let accuracy = exercise_data['transcription']['transcription']['Accuracy']
  let fluency = exercise_data['transcription']['transcription']['Fluency']


  


  let wordSuggest = []
  let phonemeSuggest = []

  let transcription_data = []


  let n_words = 0
  if(('Words' in exercise_data['transcription']['transcription']))
  {

    transcription_data = exercise_data['transcription']['transcription']['Words']

    n_words = transcription_data.length;

  }







  let topic = exercise_data['transcription']['topic']


  console.debug('n_words = ' + n_words)
  
  var word_quality = []
  var words = []
  var word_grade = []
  let word_offset = []

  let intelligibility_grading_selection = localStorage.getItem('intelligibility_grading_selection')

      
  let sentence_intelligibility_grading = constants.sentence_intelligibility_grading_strict


  if(intelligibility_grading_selection!=undefined && intelligibility_grading_selection!=null && intelligibility_grading_selection==='encouragement')
  {

    sentence_intelligibility_grading = constants.sentence_intelligibility_grading_encouragement

  }

  let sentence_intelligibility_info = []



  for(let k=0; k < n_words; ++k)
  { 
   

    let curr_word = transcription_data[k]['Word'];
    let curr_offset =  transcription_data[k]['Offset'];
    let curr_word_quality = transcription_data[k]['accuracy_score']

    word_offset.push(curr_offset)
    let curr_word_grade = 'Very Good'


    for(let m in sentence_intelligibility_grading)
    {
      if(curr_word_quality>=sentence_intelligibility_grading[m]['min'] && curr_word_quality<sentence_intelligibility_grading[m]['max'])
      {
          let curr_word_intelligibility_info = sentence_intelligibility_grading[m]
          curr_word_grade = sentence_intelligibility_grading['grade']

          sentence_intelligibility_info.push(curr_word_intelligibility_info)

          break

      }
    }


    word_quality.push(curr_word_quality)
    word_grade.push(curr_word_grade)
    words.push(curr_word)

    
  }

  let curr_sentence_grade = 'Very Good'
  if(accuracy < sentence_thresh_poor)
  {
    curr_sentence_grade = 'Needs Improvement'
  }
  else if(accuracy< sentence_thresh_ok)
  {
    curr_sentence_grade = 'Ok'
  }

  let accuracy_p = accuracy + "\%"


  let fluency_p = fluency + "\%"

  var audio_address = exercise_data['audio_address']

  var transcription = {Name: name, Topic:topic,loudness:loudness, SentenceGrade: curr_sentence_grade, Fluency:fluency_p, Accuracy:accuracy_p, AudioAddress: audio_address, WordQuality:word_quality, Words:words, WordGrade: word_grade,sentence_intelligibility_info:sentence_intelligibility_info,Offset:word_offset};

  for(let i = 0; i<words.length;i++)
  {
    if(parseInt(word_quality[i])>5 && parseInt(word_quality[i])<80)
    {
      var wordList={GroundTruth:words[i], Accuracy:word_quality[i]};
      wordSuggest.push(wordList);
    }
  }
  let r = makeSuggestions(wordSuggest, phonemeSuggest);
  r['transcription'] = transcription
  r['accuracy'] = accuracy

  return r
}

export function getUnscriptedSpeechData(exercise_data, name, sentence_thresh_poor, sentence_thresh_ok) {

  let loudness = Math.round(exercise_data['loudness']['loudness'])

  let accuracy = exercise_data['unscripted_speech_recognition']['transcription']['Accuracy']
  let fluency = exercise_data['unscripted_speech_recognition']['transcription']['Fluency']


  


  let wordSuggest = []
  let phonemeSuggest = []





  let transcription_data = exercise_data['unscripted_speech_recognition']['transcription']['Words']

  let topic = exercise_data['unscripted_speech_recognition']['topic']

  let n_words = transcription_data.length;

  console.debug('n_words = ' + n_words)
  
  var word_quality = []
  var words = []
  var word_grade = []
  let word_offset = []

  let intelligibility_grading_selection = localStorage.getItem('intelligibility_grading_selection')

      
  let sentence_intelligibility_grading = constants.sentence_intelligibility_grading_strict


  if(intelligibility_grading_selection!=undefined && intelligibility_grading_selection!=null && intelligibility_grading_selection==='encouragement')
  {

    sentence_intelligibility_grading = constants.sentence_intelligibility_grading_encouragement

  }

  let sentence_intelligibility_info = []



  for(let k=0; k < n_words; ++k)
  { 
   

    let curr_word = transcription_data[k]['Word'];
    let curr_offset =  transcription_data[k]['Offset'];
    let curr_word_quality = transcription_data[k]['accuracy_score']

    word_offset.push(curr_offset)
    let curr_word_grade = 'Very Good'


    for(let m in sentence_intelligibility_grading)
    {
      if(curr_word_quality>=sentence_intelligibility_grading[m]['min'] && curr_word_quality<sentence_intelligibility_grading[m]['max'])
      {
          let curr_word_intelligibility_info = sentence_intelligibility_grading[m]
          curr_word_grade = sentence_intelligibility_grading['grade']

          sentence_intelligibility_info.push(curr_word_intelligibility_info)

          break

      }
    }


    word_quality.push(curr_word_quality)
    word_grade.push(curr_word_grade)
    words.push(curr_word)

    
  }

  let curr_sentence_grade = 'Very Good'
  if(accuracy < sentence_thresh_poor)
  {
    curr_sentence_grade = 'Needs Improvement'
  }
  else if(accuracy< sentence_thresh_ok)
  {
    curr_sentence_grade = 'Ok'
  }

  let accuracy_p = accuracy + "\%"


  let fluency_p = fluency + "\%"

  var audio_address = exercise_data['audio_address']

  var transcription = {Name: name, Topic:topic,loudness:loudness, SentenceGrade: curr_sentence_grade, Fluency:fluency_p, Accuracy:accuracy_p, AudioAddress: audio_address, WordQuality:word_quality, Words:words, WordGrade: word_grade,sentence_intelligibility_info:sentence_intelligibility_info,Offset:word_offset};

  for(let i = 0; i<words.length;i++)
  {
    if(parseInt(word_quality[i])>5 && parseInt(word_quality[i])<80)
    {
      var wordList={GroundTruth:words[i], Accuracy:word_quality[i]};
      wordSuggest.push(wordList);
    }
  }
  let r = makeSuggestions(wordSuggest, phonemeSuggest);
  r['unscripted_speech_recognition'] = transcription
  r['accuracy'] = accuracy

  return r
}

function makeSuggestions(wordSuggest, phonemeSuggest)
{
  let suggestionData = [];
  let weakPhonemes = []
  let weakWords = []
  let suggestionList = [];
  let funcFinished = [];


  let wordTotal = 0;
  if(wordSuggest.length>15)
  {
    wordTotal = 15;
  } else {
    wordTotal = wordSuggest.length;
  }

  for(let i=0;i<wordTotal;i++)
  {
    if(!weakWords.includes(wordSuggest[i]['GroundTruth']))
    {
      weakWords.push(wordSuggest[i]['GroundTruth'])
      let wordList = {score: wordSuggest[i]['Accuracy']+'%',suggestion:'Practise word: '+ wordSuggest[i]['GroundTruth']};
      //not shwing words to be praticed for now since there is no video showing how to pronunce teh words
      // _this.suggestionData.push(wordList);
      // _this.suggestionList.push(_this.wordSuggest[i]['GroundTruth']);
      // _this.funcFinished.push('none');
    }
  }
  
  let phonemeTotal = 0;
  
  if(phonemeSuggest.length>15)
  {
    phonemeTotal = 15;
  } else {
    phonemeTotal = phonemeSuggest.length;
  }

  console.debug('phonemeSuggest.length = ' + phonemeSuggest.length)


  let phonemeDisplayDictionary = constants.phonemeDisplayDictionary


  for(let j=0;j<phonemeTotal;j++)
  {

    if(!weakPhonemes.includes(phonemeSuggest[j]['PhonName']))
    {

      weakPhonemes.push(phonemeSuggest[j]['PhonName'])

      let phonemeList = {score:phonemeSuggest[j]['phonAccuracy']+'%', suggestion:'Practise phoneme: /'+ phonemeDisplayDictionary[phonemeSuggest[j]['PhonName']]+'/'};
      // let phonemeList = {score:_this.phonemeSuggest[j]['phonAccuracy']+'%',suggestion:'Practise phoneme: /'+_this.phonemeSuggest[j]['PhonName']+'/'};
      // let phonemeList = {score:_this.phonemeSuggest[j]['phonAccuracy']+'%',suggestion:'Practise phoneme: /'+_this.phonemeSuggest[j]['PhonName']+'/ (e.g. '+exampleWords+')'};

      suggestionData.push(phonemeList);
      suggestionList.push(phonemeSuggest[j]['PhonName']);
      let bool_pho = checkPhonemeExistence(phonemeSuggest[j]['PhonName']);
      funcFinished.push(bool_pho);
    }
    
  }

  return {'suggestionList': suggestionList, 'funcFinished': funcFinished, 'suggestionData': suggestionData}
}

export async function searchExamplesWithPhoneme(phoneme)
{
  let do_once = true;


  let targetDir = 'Exercises/Articulation/'

  let phoneme_exercise_list = []
  let promise = new Promise((resolve, reject) => {

    getData(targetDir, function (err, result) {

      //Inputs new exercise on the current user's database

      if (do_once) {

        do_once = false;

        result.child('Consonants').forEach(function(childSnapshot)
        {
          if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
          {
            let exercise_address = targetDir + childSnapshot.key 

            let curr_exercise = {exercise_address: exercise_address, exerciseSnapshot: childSnapshot, type: 'Consonant'}
            
            phoneme_exercise_list.push(curr_exercise)
          }
        });

        result.child('Words').forEach(function(childSnapshot)
        {
          if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
          {
            childSnapshot.forEach(function(exerciseSnapshot)
            {
              let exercise_address = targetDir + childSnapshot.key + "/" + exerciseSnapshot.key

              let curr_exercise = {exercise_address: exercise_address, exerciseSnapshot: exerciseSnapshot, type: 'Word'}
              
              phoneme_exercise_list.push(curr_exercise)
            });
          }
        });

        resolve(phoneme_exercise_list)
      }

      reject(phoneme_exercise_list)
    });

  });
  
  return promise
}

function checkPhonemeExistence(target)
{
  console.debug('phonemeLib = ' + phonemeLib)

  //XXX to be programmed: get phoneme list existing in the database from articulation_consonants and articualtion_words.
  if(phonemeLib.includes(target))
  {
    return 'pho';
  }
  else
  {
    return 'none';
  }
}