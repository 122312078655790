<template>
  <div> 
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-2">Account information</h5>
        <div class="row justify-content-between">
          <div class="col-md-5">
            <p class="lead mt-3">{{ name }}</p>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-lg-5">
            <p class="mt-3">Email Address</p>
            <input class="form-control" v-model="email" autocomplete="off" disabled>
            <div>
              <p class="mt-3">Display name</p>
              <div class="input-group">
                <input class="form-control" v-model="userName" autocomplete="off">
                <button class="btn btn-outline-primary" @click="updateUserInfo('userName')">Update</button>
              </div>
            </div>
          </div>
          <div class="col-lg-6 h-100">
            <div class="card" v-if="tier_name && next_payment_due">
              <div class="card-body">
                <p><strong>Subscription plan:</strong> <i>{{ tier_name }}</i></p>
                <p v-if="(tier !== 'F1' || tier !== 'Free_1') && expiry_date != ''" ><strong>Expiry date:</strong> <i>{{ expiry_date }}</i></p>
                <p v-if="(tier !== 'F1' && tier !== 'Free_1') && tier !== 'Trial1'" ><strong>Next payment due:</strong> <i>{{ next_payment_due }}</i></p>
                <button v-if="tier === 'F1' || tier === 'Free_1' || tier === 'Trial1'" class="btn btn-primary float-start" 
                @click="goToSubscriptionPage()">Upgrade Subscription</button>
                <button v-if="!tier.includes('Trial1') && !tier.includes('Free_1')" class="btn btn-outline-success float-end" 
                @click="goToCustomerPortal()">Payment details</button>
              </div>
            </div>
            <div class="card" v-if="tier === 'Free_1'">
              <div class="card-body">
                <div class="input-group">
                  <input class="form-control" v-model="voucher_code" autocomplete="off" placeholder="Do you have a voucher?">
                  <button class="btn btn-outline-primary" @click="apply_voucher()">Apply Voucher</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Affiliation</h5>
        <div class="row justify-content-between">
          <div class="col-lg-5 col-12 mb-4">
            <p class="form-label">Organisation</p>
            <div class="input-group">
              <input class="form-control" v-model="affiliation" placeholder="Name of your organisation" type="text"> 
              <button class="btn btn-outline-primary" @click="updateUserInfo('affiliation')">Update</button>
            </div>
          </div>
        </div>
        <div class="row" v-if="is_organisation_admin">
            <div class="col">
              <button class="btn btn-outline-info" @click="$router.push({name: 'manage_organisation', query: {name: affiliation}})">Manage Organisation</button>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
            <br/>
            <div class="form-check form-check-inline d-flex">
              <input class="form-check-input me-2" type="checkbox" v-model="discoverable" @change="updateUserInfo('discoverable')">
              <label class="form-check-label"> I would like patients looking for speech and language therapists to be able to find and contact me through the platform (optional).</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Field of interests</h5>
        <div class="row justify-content-center">
          
          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d1" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Voice">
                <label class="form-check-label" for="d1">
                  Voice
                </label>
            </div>
          </div>
          
          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d2" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Speech"> 
              <label class="form-check-label" for="d2">
                Speech
              </label>
            </div>
          </div>

          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d3" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Language"> 
              <label class="form-check-label" for="d3">
                Language
              </label>
            </div>
          </div>

          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d3" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Cognition"> 
              <label class="form-check-label" for="d3">
                Cognition
              </label>
            </div>
          </div>
          
          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d4" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Swallowing"> 
              <label class="form-check-label" for="d4">
                Swallowing
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
    
    <LanguageRegion :uid="uid"></LanguageRegion>
    
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Calibrate your microphone</h5>
        <div class="row justify-content-between">
          <div class="col-lg-10">
            <label class="form-label">Calibrating your microphone will make your loudness readings more accurate. Contact contact@beautifulvoice.co.uk for help.</label>
          </div>
          <div class="col-lg-2">        
            <button class="btn btn-primary" @click="goToCalibrate()">Calibrate</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {getUserDataDir, updateDataDirectory, getAuthToken} from '@/firebase/index'
import { useToast } from "vue-toastification";
import { useTTS } from '@/stores/tts'
import axios from 'axios';
import LanguageRegion from '@/views/common/settings/LanguageRegion.vue'


export default {
  components: { LanguageRegion },
  name: 'slt_settings',
  props: 
  {
    tier_name: {
      type:String,
      required: true,
    }, 
    next_payment_due: {
      type:String,
      required: true,
    }, 
  },
  data(){
    return {
      userCategory: localStorage.getItem('userCategory'),
      region: localStorage.getItem('region'),
      language: localStorage.getItem('language'),
      name: '',
      email: '',
      userName: '',
      field_of_interest: [],
      
      affiliation: '', 
      discoverable: false,
      voucher_code: '',
      expiry_date: "",
      is_organisation_admin: false,
      bv_tts: useTTS().tts_engine,
      voices: [],
      prefered_voice: null,
      tts_enabled: false,

      tier: sessionStorage.getItem("tier"),

      uid: localStorage.getItem('uid'),
      api: import.meta.env.VITE_FAST_API,
    }
  },
  created() {
    this.loadUserInfo(); 
  },
  methods: {
    reset_tour() {
      
      let ref_path = 'Users/' + this.uid + '/Data/startTour'

      sessionStorage.setItem('patient_interface_selected_page', 1)
      updateDataDirectory(ref_path, {
        dashboard: false,
        patient_home_dashboard: false,
        patient_interface: false,
        results_page_tour: false,
        voice_speech_custom: false,
      })
      this.$router.push({name: "home"})
    },
    async loadUserInfo() {
      let _this = this;

      getUserDataDir('Data/', async function (err, result) {

        _this.is_organisation_admin = result.child('is_organisation_admin').val();
        _this.email = result.child('Email').val()

        _this.name =  result.child('Firstname').val().charAt(0).toUpperCase() + result.child('Firstname').val().slice(1) + ' ' +
                      result.child('Surname').val().charAt(0).toUpperCase() + result.child('Surname').val().slice(1)
      });


      await new Promise(resolve => {
        getUserDataDir('Settings/', async function (err, result) {

          let affiliation = result.child('affiliation').val()

          if (affiliation == undefined || affiliation == null) {
            _this.affiliation = result.child('affiliation').val();
          } else {
            _this.affiliation = affiliation;
          }
          

          _this.field_of_interest = [];
          _this.field_of_interest = result.child('Qs_foi').val();

          if (_this.field_of_interest == undefined || _this.field_of_interest == null) {
            _this.field_of_interest = []
          }

          let discoverable = result.child('discoverable').val()
          
          if (discoverable == "True" || discoverable == true) {
            _this.discoverable = true
          } else {
            _this.discoverable = false
          }
          
          let userName = result.child('userName').val()

          if (userName == undefined || userName == null) {
            _this.userName = _this.firstname;
          } else {
            _this.userName = userName;
          }

          resolve()
        })
      })

      this.tier = sessionStorage.getItem("tier")
    },
    updateUserInfo(inp) {
      let _this = this;

      let settings_ref_path = 'Users/' + this.uid + '/Settings/'
      
      let info_updated = false
      if(inp == 'userName') {
        updateDataDirectory(settings_ref_path, {'userName':_this.userName})

        info_updated = true
      } else if(inp == 'field_of_interest') {
        updateDataDirectory(settings_ref_path, {'Qs_foi':_this.field_of_interest})

        info_updated = true
      } else if(inp == 'affiliation') {
        updateDataDirectory('Users/' + this.uid + '/Data/', {'affiliation':_this.affiliation})

        info_updated = true
      } else if(inp == 'discoverable') {
        updateDataDirectory(settings_ref_path, {'discoverable':_this.discoverable})

        info_updated = true
      }

      if (info_updated) {
        const toast = useToast()
        toast.info("Your information has been updated.")
      }

      _this.$emit('closeDialog', false);
    },
    goToCalibrate() {
      this.$router.push({name: "microphone_calibration"})
    },
    goToSubscriptionPage() {
      this.$router.push({name: "subscribe"})
    },
    goToCustomerPortal() {
      window.location.href = import.meta.env.VITE_CUSTOMER_PORT_LINK + this.email
    },
    apply_voucher() {

      getAuthToken().then((idToken) => {
        axios.post(this.api + '/apply_voucher', null, {params: {user_voucher_code: this.voucher_code}, 
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': `multipart/form-data`,
          }}).then(response => {
            console.log(response.data) 
            if (response.data === 'success') {
              const toast = useToast()
              toast.success("Voucher applied successfully!")
              setTimeout(() => {
                this.loadUserInfo(); 
                location.reload();
              }, 2000);
            } else {
              const toast = useToast()
              toast.error("Voucher was not applied, please contact Beautifulvoice.")
            }
          }).catch(error => {
            const toast = useToast()
            toast.error("Voucher was not applied, please contact Beautifulvoice.")
          });
      })
    }
  },
}
</script>
<style scoped>

li:hover {
  background-color: #e0e0e2;
  color: white;
}
</style>