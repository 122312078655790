<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div v-if="stage==='cs'">
          <div class="card card-body h-100">
            <div class="row"> 
              <p class="h3"> {{Ex_Description}}</p>
            </div>
            <div class="row">
              <div class="card card-body" v-if="Ex_Text!==''" >
                <div class="row">
                  <div class="col-12">
                    <p class="lead" style="font-size: 30px">{{Ex_Text}} 
                    
                      <VTooltip>
                      <img v-if="tts_enabled" :src="audioImg" style="width:35px;cursor: pointer;" @click="readContentAloud()">
                      <template #popper>
                        Click to listen
                      </template>
                    </VTooltip>
                    </p>
                  </div>
                </div>


                
                <div class="row justify-content-center" v-if="!(hasSPLMeter||hasSpectrogram)" id="meter-row" >
                  <div class="col-6">
                    <canvas id="meter" height="30"></canvas>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div v-if="stage==='sv'">
          <div class="card card-body h-100">
            <div class="row"> 
              <p class="h3">Press Record and follow the instruction below</p>
            </div>
            <div class="row">
              <div class="card card-body" v-if="Ex_Text!==''" >
                <div class="row">
                  <div class="col-12">
                    <p class="lead" style="font-size: 30px">{{'Say Ahh for 5 seconds'}} 
                    
                      <VTooltip>
                        <img v-if="tts_enabled" :src="audioImg" style="width:35px;cursor: pointer;" @click="bv_tts.say_sentences('Say Ahh for 5 seconds')">
                        <template #popper>
                          Click to listen
                        </template>
                      </VTooltip>
                    </p>
                  </div>
                </div>
                <div class="row justify-content-center" v-if="!(hasSPLMeter||hasSpectrogram)" id="meter-row" >
                  <div class="col-6">
                    <canvas id="meter" height="30"></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row base-timer" @click="playPause()" style="margin:auto;margin-top:1%">
          <svg
            class="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
              <circle
                class="base-timer__path-elapsed"
                cx="50"
                cy="50"
                r="46.5"
              />
            </g>
            <path
              class="base-timer__path-remaining"
              :stroke-dasharray="circleDasharray"
              d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0">
            </path>

          </svg>
          <span class="base-timer__label">
            {{formattedTimeLeft}}
          </span>
        </div>

      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises">
          <template v-slot:top>

            <p v-if="stage==='cs'" class="lead">Exercise 1 of 2</p>
            <p v-if="stage==='sv'" class="lead">Exercise 2 of 2</p>

            <div v-if="audioCaptureOn">
              <button v-if="action==='record'" class=" btn-primary btn btn-lg w-100 mt-2" @click="playPause()">Record</button>
              <button v-if="action==='recording' && stage!='sv'" class=" btn-info btn btn-lg w-100 mt-2" @click="playPause()">Stop Recording</button>

              <button v-if="action==='recording' && stage==='sv'" class=" btn-info btn btn-lg w-100 mt-2" disabled>Recording</button>

              <button v-if="action==='storing'" class=" btn-info btn btn-lg w-100 mt-2"  disabled>Processing Recording</button>

              <button v-if="action==='recorded'" class=" btn-primary btn btn-lg w-100 mt-2" @click="goToNextStage()">Use Recording</button>

              <!-- <button v-bind:class="[play_pause_text===start_btn_text ? 'btn-primary' : 'btn-info', 'btn btn-lg w-100 mt-2']" @click="playPause()">{{play_pause_text}}</button> -->
              <!-- <button class='btn btn-secondary btn-lg mt-2 w-100' @click="skipExercise()"> Skip  </button>       -->

              <button v-if="action==='recorded'" class='btn btn-success w-100 btn-lg mt-2' @click="repeatExercise()"> Record again </button>
              <button v-if="action==='finished' && stored_audio_blobs['cs']!=null && stored_audio_blobs['sv']!=null" class='btn btn-outline-warning w-100 btn-lg mt-4' @click="ComputeAVQI()">Finish Assessment</button>
              <!-- <button  class='btn btn-outline-warning w-100 btn-lg mt-4' @click="ClearBlobs()">Clear Blobs</button> -->

            </div>
            <div v-else>
              <button class="btn btn-info btn-lg w-100" @click="noAudioCaptureNext()">Next</button>
            </div>
            <div style="margin-top: 20mm;">
              <div class="row">
                <i class="bi bi-sign-stop" style="color: #f56c6c;font-size: 80px;"></i>
                <p v-if="audioCaptureOn">Stop if your voice is strained</p>
                <p v-else>Stop if your muscles are strained</p>

              </div>
            </div>
          </template>
        </Sidebar>
      </template>
    </Layout>

  </div>
</template>

<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import homeplanImg from '@/assets/images/homeplan.png'
import audioImg from '@/assets/images/audio_logo.png'

import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import {checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia, change_save_blob_config, get_blobs, clear_blobs } from '@/media_input/microphone_recording'
import smoothie from 'smoothie'
import {removeWeirdCharactersFromString} from '@/media_input/utils'
import {updateDataDirectory, getAuthToken} from '@/firebase/index'
import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'
import { BV_Points } from '@/utils/bv_points'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {generate_results_path} from '@/utils/results/data_save_utils'

export default {
    name: 'avqi',
    props: {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data() {
      return {
        action:'record',
        stage:'cs',
        audio_blobs_watcher:0,
        stored_audio_blobs:{'cs':null,'sv':null},
          hasTimer: false,
          push_structure:null,
          audio_blobs:[],
          ex_descriptions:['Read the sentence out loud'],
          ex_texts:['When the sunlight strikes raindrops in the air, they act as a prism and form a rainbow. The rainbow is a division of white light into many beautiful colours. These take the shape of a long round arch, with its path high above, and its two ends apparently beyond the horizon.',''],
          seconds_passed: 0,
          showTimer:true,
          frequency_data:[],
          progress: 0,
          timeLimit:5,
          timePassed:0,
          timerInterval:null,

          maxdBValue:80,
          mindBValue:40,
          hasVideoInstructions: false,
          hasAudioInstructions: false,
          video_instructions_address: '',
          audio_instructions_address: '',
          audioImg: audioImg,
          sound_l:'',
          sound_l2:'',
          chart_data: null,
          chart_data_spect: null,
          smoothie_spect: null,
          start_btn_text: 'Start',
          smoothie: null,
          drawInterval: null,
          Ex_Text: '',    
          Ex_Description: [],
          ex_name: '',
          avg_loudness: 0,
          avg_loudness_show: 0,
          hasSPLMeter: false,
          last_loudness: 0,
          total_loudness: 0,
          counter_loudness: 0,
          audioCaptureOn: true,
          hasSpectrogram: false,
          chart_not_created: true,
          chart_not_created_spect: true,
          canvasContext: null,
          tts_enabled: true,
          play_pause_text: 'Record',
          play_status: true,
          current_exercise_index: 0,
          total_exercises: 1,
          homeplanImg: homeplanImg,
          crossImg: crossImg,
          doneIcon: doneIcon,
          modules_results_path: null,
          resultsTableBack: [],
          has_checkbox: false,
          is_recording: false,
          url:'',
          url2image:'',
          userType: 'patient',
          client_number: null,
          uid: '',
          loud_target:65,
          timerID: 0,
          bv_tts: useTTS().tts_engine,
          bv_points: new BV_Points()
        }
    },
    created() {
      let _this = this;
      
      checkPermissionsMedia(); 
      _this.nextExercise();
      
      logActivity( 'accessed', 'avqi')
      console.debug('_this.exForm = '  + _this.exForm)

      
      // var user = _this.$firebase.auth().currentUser;
      // let uid = user.uid;

      _this.userType = localStorage.getItem('userCategory');

      if(this.userType=='slp')
      {
          let client_number = localStorage.getItem('client_uid');

          if(client_number!='undefined' && client_number!=null && client_number!='')
          {
            _this.client_number = client_number; 
          }
      }

      let uid = localStorage.getItem('uid');
      _this.uid = uid;
      console.debug('uid = ' + uid)
        
    },
    computed: {

    Ex_Description_Split: function () {
      return this.Ex_Description.toString().split('\n')
    },
    Ex_Text_Split: function()
    {

      console.debug('this.Ex_Text = ' + this.Ex_Text)

     let split_text =  this.Ex_Text.toString().split('\n')

     console.debug('split_text = ' + split_text)

     console.debug('split_text.length = ' + split_text.length)
   



      return split_text

    },

  },
    beforeUnmount()
    {
        
        const _this = this
        this.bv_tts.cancel()


        clearInterval(window.refreshIntervalId)
        window.speechSynthesis.cancel()


        if(_this.is_recording)
        {

            _this.endRecordingAudio(true);
        }
        else
        {
            _this.endRecordingAudio(false);
        }

        deleteSoundMeter()

        clear_blobs()


    },
    mounted: function()
    {

      

      // this.renderGraphs()
   
    },
    updated: function()
    {

    },
    computed:{
      circleDasharray: function() {
      const FULL_DASH_ARRAY = 2 * 3.14 * 45
      // this.timePassed = 10
      let circle_ratio = (this.timeFraction()* FULL_DASH_ARRAY)
      console.debug('circleDasharray = ' + circle_ratio)
      return `${circle_ratio.toFixed()} 283`;
    },
    formattedTimeLeft: function() {

      // if(this.play_status==false)
      // {
      //   if(this.restart_now)
      //   {
      //     return 'Restart'

      //   }
      //   else
      //   {
      //     return 'Ready'
      //   }

      // }
      // else
      {
        
        let timeLeft = this.timeLimit - this.timePassed

        if(timeLeft==0)
        {
          return `0`
        }
        else
        {
          return `${timeLeft}`
        }
        // The largest round integer less than or equal 
          //  to the result of time divided being by 60.
        // const minutes = Math.floor(timeLeft / 60)
        // // Seconds are the remainder of the time divided
        //   //  by 60 (modulus operator)
        // let seconds = timeLeft % 60
        // // If the value of seconds is less than 10,
        //   //  then display seconds with a leading zero
        // if (seconds < 10) {
        //   seconds = `0${seconds}`
        // }
        // // The output in MM:SS format
        // // return `${minutes}:${seconds}`
      
      }
    }


  },
    watch: {
      audio_blobs_watcher(new_audio_blobs_watcher, old_audio_blobs_watcher) {


        if(this.audio_blobs.length>0)
        {
          if(this.stage==='cs')
          {
            this.stored_audio_blobs['cs'] = this.audio_blobs[new_audio_blobs_watcher-1]
          }
          else if(this.stage==='sv')
          {
            this.stored_audio_blobs['sv'] = this.audio_blobs[new_audio_blobs_watcher-1]
          }

          this.action = 'recorded'
        }


      }
    },

    methods: 
    {
      renderGraphs()
      {
        const _this = this
            
        if(_this.hasSPLMeter)
        {
          document.getElementById("SPL-div").hidden = false
          
          _this.createChart()


        }
        else
        {
          document.getElementById("SPL-div").hidden = true
        }


        if(_this.hasSpectrogram)
        {
          document.getElementById("Spect-div").hidden = false
          
          _this.createChartSpect()

        }
        else
        {
          document.getElementById("Spect-div").hidden = true
        }

      },
      readContentAloud()
      {
        const _this = this


        if(_this.Ex_Text.length>200)
        {
          let array_text = _this.Ex_Text.split('.')

          this.bv_tts.say_sentences(array_text, 75)

        }
        else
        {
          _this.bv_tts.say(_this.Ex_Text)

        }



      },
      openURL()
      {
        const _this = this

        
        window.open(_this.url);


      },
      timeFraction() {
        const rawTimeFraction = (this.timeLimit - this.timePassed) / this.timeLimit
        
        return rawTimeFraction -
          (1 / this.timeLimit) * (1 - rawTimeFraction)
      },

     
      nextExercise()
      {
        const _this = this;
        
        if(_this.is_recording)
        {
            // _this.endRecordingAudio(true)
        }

        _this.pause();

        _this.loadSingleExercise()


      },
      goToNextStage()
      {
        if(this.stage==='cs')
        {
          this.stage='sv'
          this.action='record'
        }
        else if(this.stage==='sv')
        {
          this.action='finished' 
        }

      },
      repeatExercise()
      {
        const _this = this



        if(_this.play_status)
        {

          console.debug('_this.avg_loudness = ' + _this.avg_loudness)

          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0)
          {
            avg_loudness_array.push(_this.avg_loudness)
          }

          console.debug('avg_loudness_array = ' + avg_loudness_array)

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;



          if(_this.is_recording)
          {
            _this.is_recording = false

            _this.endRecordingAudio(true)

          }


          // let resultsTableBack = []
          // for(let i in _this.resultsTableBack)
          // {
          //   resultsTableBack.push(_this.resultsTableBack[i])

          // }

          // var basicData = {resultsTableBack: resultsTableBack, avg_loudness_array: avg_loudness_array}

          // _this.resultsTableBack = []
          // _this.$emit('logExerciseResults', basicData);

          _this.play_status = false

          _this.play_pause_text = _this.start_btn_text

          

        }
        else
        {
          this.action = 'record' 
        }

      },
      startTimer() {
        const _this = this

        _this.timerInterval = setInterval(function() {

          if(_this.timePassed<_this.timeLimit)
          {
            _this.timePassed += 1
          }
          else
          {

            clearInterval(_this.timerInterval)


            _this.playPause()


            _this.timePassed = 0
            _this.seconds_passed = 0
            _this.progress = 0



            _this.restart_now = true

          }
          
         }, 1000);
      },

      stopTimer() {
        let _this = this

        _this.hasTimer = false
        _this.seconds_passed = 0
        _this.progress = 0

        _this.avg_loudness = 0;
        _this.total_loudness = 0;
        _this.counter_loudness = 0;

        if(_this.is_recording)
        {
          _this.is_recording = false

          _this.endRecordingAudio(true)

        }


        clearInterval(window.refreshIntervalId)
        clearInterval(_this.timerID)
      }, 

      loadSingleExercise()
      {
          
        const _this = this



      
      //   _this.Ex_Text = _this.exForm.text; 

      _this.Ex_Text = _this.ex_texts[0]
      _this.Ex_Description = _this.ex_descriptions[0]
      
        _this.tts_enabled = true

        let voicetype = _this.exForm.voicetype



        if(voicetype!=undefined && voicetype!=null)
        {
          if(Array.isArray(voicetype) && voicetype.includes('max_phonation'))
          {
            _this.showTimer = true
          }

        }



        if(_this.Ex_Text==undefined || _this.Ex_Text==null)
        {
          _this.tts_enabled = false
        }
        else
        {
          let list_tts_fail_words = ['phoneme','/ah/','/eh/','/ih/','/oh/','/uh/']

          let ex_text_lower = _this.Ex_Text
          ex_text_lower = ex_text_lower.toLowerCase()

          for(let i in list_tts_fail_words)
          {
            if(ex_text_lower.includes(list_tts_fail_words[i]))
            {
              _this.tts_enabled = false
              break
            }

          }
        }

        if(_this.exForm.image_address!=undefined && _this.exForm.image_address!=null && _this.exForm.image_address!='')
        {
          _this.showimage(_this.exForm.image_address)
        }

        _this.Ex_Description = _this.exForm.instruct;

        _this.current_exercise_index = _this.exForm.current_exercise_index + 1
        
        _this.total_exercises = _this.exForm.total_all_type_exercises

        let url = _this.exForm.url
        let url2image = _this.exForm.url2image

        if(url!=undefined && url!=null)
        {
          _this.url = url
        }

        if(url2image!=undefined && url2image!=null)
        {
          _this.url2image = url2image
          // document.getElementById(_this.img_1).src = url2image;

        }


        if(_this.exForm.start_btn_text!=undefined && _this.exForm.start_btn_text!=null)
        {
          _this.start_btn_text = _this.exForm.start_btn_text
          _this.play_pause_text = _this.start_btn_text
        }




        _this.Ex_Description = removeWeirdCharactersFromString(_this.Ex_Description)
        
        _this.Ex_Text = removeWeirdCharactersFromString(_this.Ex_Text)

        let ex_text_split = _this.Ex_Text.split(' ')

    
        if(_this.exForm.search_img!=undefined && _this.exForm.search_img!=null && _this.exForm.search_img!='')
        {
          _this.imgSearchWord(_this.Ex_Text)
        }

        if(_this.exForm.sound_autoplay!=undefined && _this.exForm.sound_autoplay!=null && _this.exForm.sound_autoplay==true)
        {
          setTimeout(() => {
            _this.bv_tts.say_sentences(_this.Ex_Text)
          }, 750)
        }
        
  
    

        let captureAudio = _this.exForm.capture_audio

        if(captureAudio != undefined && captureAudio != null)
        {
          _this.audioCaptureOn = captureAudio
        }

        let has_checkbox = _this.exForm.checkbox

        if(has_checkbox != undefined && has_checkbox != null)
        {
          _this.has_checkbox = has_checkbox
        }




        if(_this.Ex_Text == undefined || _this.Ex_Text == null )
        {
            _this.Ex_Text = ""
        }
        if(_this.Ex_Description == undefined || _this.Ex_Description == null )
        {
            _this.Ex_Description = ""
        }


        _this.checkAudioVideoInstructions()
        _this.checkAssistance()


      },
      imgSearchWord(word)
      {
        const _this = this
      

        let CSE_ID = '0a00ce12c9207f97c'
        let API_KEY = 'AIzaSyAyaTunYyJEdu_QbzvBN_xMJYmRDMWB_sc'


        const Http = new XMLHttpRequest();
        const url='https://customsearch.googleapis.com/customsearch/v1?cx='+CSE_ID+'&key='+API_KEY+'&q='+word+'&searchType=image&imgType=clipart&page=1&num=1&fields=items(image/thumbnailLink)'
        //&imgType=clipart'
        console.debug(url)
        Http.open("GET", url);
        Http.send();


        Http.onreadystatechange = (e) => {

          let json_response = JSON.parse(Http.response)

          // console.debug(json_response['items'][0])

          if(json_response['items'].length>0)
          {
            _this.url2image = json_response['items'][0]['image']['thumbnailLink']
          }
          

        }

        // const imageSearch = require('image-search-google');

        // const client = new imageSearch(CSE_ID, API_KEY);

        // window.setImmediate = window.setTimeout
        // const options = {page:1,num:1};



        // client.search(word, options).then(images => {

        //   if(images!=undefined && images!=null && images.length>0)
        //   {
        //     _this.url2image = images[0]['thumbnail']

        //   }


        //   /*
        //   [{
        //       'url': item.link,
        //       'thumbnail':item.image.thumbnailLink,
        //       'snippet':item.title,
        //       'context': item.image.contextLink
        //   }]
        //   */
        // }).catch(error => console.debug('error = ' + error));


      },
      checkAudioVideoInstructions()
      {
        const _this = this

        console.debug('_this.exForm.videoIns = ' + _this.exForm.videoIns)

        let hasVideoInstructions = _this.exForm.videoIns;

        if(hasVideoInstructions!=undefined && hasVideoInstructions!=null)
        {
            _this.hasVideoInstructions = hasVideoInstructions;
        }
        else
        {
            _this.hasVideoInstructions = false;

            if(_this.exForm.Video_Address!=undefined && _this.exForm.Video_Address!=null)
            {
              _this.hasVideoInstructions = true
              _this.exForm.videoAddress = _this.exForm.Video_Address
            }
        }


        // let hasAudioInstructions = _this.exForm.audioIns;

        let audioIns = _this.exForm.audioIns

        if(audioIns!=undefined & audioIns!=null)
        {
            _this.hasAudioInstructions = true;
        }
        else
        {
            _this.hasAudioInstructions = false;
        }

        if(_this.hasVideoInstructions)
        {
            _this.video_instructions_address = _this.exForm.videoAddress;

            console.debug('_this.video_instructions_address = ' + _this.video_instructions_address)
            _this.watchVideoInstructions()
        }
        else if(_this.hasAudioInstructions)
        {
          _this.audio_instructions_address = audioIns
            // _this.audio_instructions_address = _this.exForm.audioAddress;

            _this.watchVideoInstructions()
        }

      },
      watchVideoInstructions()
      {
        const _this = this;

        if(_this.is_recording)
        {
          _this.is_recording = false
          _this.endRecordingAudio(false);
          _this.play_pause_text = _this.start_btn_text
        }

        
        if(_this.hasVideoInstructions)
        {



          _this.is_recording = false;
      
          var storage = _this.$firebase.storage();


          storage.ref(_this.video_instructions_address).getDownloadURL()
            .then((url) => {
              _this.playVideoInstructions(url);

            })
            .catch((error) => {
              // Handle any errors
            });
        }
        else if(_this.hasAudioInstructions)
        {

          // document.getElementById('ex_div').hidden = true;

          // document.getElementById('mic_dist_div').hidden = true;

          // document.getElementById('video_instructions_div').hidden = false;

          stopRecordingMedia(null,null,null);
          
          // clearInterval(_this.drawInterval);
          clearInterval(window.refreshIntervalId);
  
          _this.drawLoop(0);

          _this.is_recording = false;
      
          var storage = _this.$firebase.storage();


          storage.ref(_this.audio_instructions_address).getDownloadURL()
            .then((url) => {
              _this.playAudioInstructions(url);

            })
            .catch((error) => {
              // Handle any errors
            });
        }
      },
      playVideoInstructions(url)
      {      

        console.debug('url = ' + url)
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="380px" width="600px" controls autoplay> </video>';
        var player =document.getElementById('movie');
        player.load();

        
      },
      
      checkAssistance()
      {
        const _this = this

        let assistance = _this.exForm.assist


        _this.hasSPLMeter = false;

        if(assistance!=null)
        {
          console.debug('assistance = ' + assistance)
          
          if(Array.isArray(assistance) && assistance.includes('spm_live'))
          {
              _this.hasSPLMeter = true;


          }
          else if('spm_live' in assistance)
          {
              _this.hasSPLMeter = true;

          }

          if(Array.isArray(assistance) && assistance.includes('spl_live'))
          {
              _this.hasSPLMeter = true;


          }
          else if('spl_live' in assistance)
          {
              _this.hasSPLMeter = true;

          }

          if(Array.isArray(assistance) && assistance.includes('spectrogram'))
          {
              _this.hasSpectrogram = true;


          }
          else if('spl_live' in assistance)
          {
              _this.hasSpectrogram = true;

          }



        }

        // _this.hasSpectrogram = true

      },
      runTimer() {

    

        var interval_time = 250;

        const _this = this;

        clearInterval(window.refreshIntervalId)
        


        window.refreshIntervalId = setInterval(function () {

        
        

        if (soundMeter != null) {



            

            // if(element!=null)
            {

              // var sound_l = soundMeter.instant.toFixed(2) ;
              var sound_l = soundMeter.db.toFixed(2);
              _this.sound_l2 = soundMeter.db2.toFixed(2);
              var max_freq_index = soundMeter.max_freq_index

              if(max_freq_index==undefined || max_freq_index==null)
              {
                max_freq_index = 0
              }


              if(_this.hasTimer==false && sound_l>50)
              {
                _this.hasTimer = true;
                _this.seconds_passed = 1;

                //timer indicator
                _this.timerID = setInterval(function () {
                  _this.seconds_passed = _this.seconds_passed + 1
                  if (_this.progress <= 99 ) {
                    _this.progress = _this.progress + 5;
                  }
                }, 1000);
              }
              
              max_freq_index = max_freq_index.toFixed(2);


              // let sound_calibration = 20;
              let sound_threshold = 35;
              

              console.debug('sound_l = ' + sound_l)
              if(sound_l>sound_threshold)
              {
              
                _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
                
                _this.avg_loudness_show = _this.roundNumber(_this.avg_loudness,0)

                console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)


                _this.counter_loudness += 1;

                
              
              }

                const min_spect_loudness = 50



                if(_this.hasSpectrogram && sound_l>min_spect_loudness)
                {


                  
                  _this.addDataChartSpect(max_freq_index)


                  _this.frequency_data.push(Number(max_freq_index))

              

                }

              if(!(_this.hasSPLMeter||_this.hasSpectrogram))
              {
                let element = document.getElementById("meter")

                _this.canvasContext = element.getContext("2d");
                _this.drawLoop(sound_l);

              }




              if(_this.hasSPLMeter)
              {



              // _this.addDataChart(max_freq_index)
              _this.addDataChart(sound_l)
              }

            
            
            }

        }

        }, interval_time);
      

      },
      roundNumber(number,number_of_decimals)
      {   
        let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)

        return rounded_number;

      },
      showimage(img_address) {


        const _this = this
        var storageRef = _this.$firebase.storage().ref();
        storageRef.child(img_address).getDownloadURL().then(function(url) {
            _this.url2image = url
            // var test = url;
            // document.getElementById('img_naming').src = test;

        }).catch(function(error) {

        });
      },
      skipExercise()
      {
        const _this = this

        _this.$emit('nextExercise');

      },
      noAudioCaptureNext()
      {
        const _this = this;

        _this.is_recording = false

        let avg_loudness_array = []

        var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}


        _this.$emit('logExerciseResults', basicData);

        
        _this.$emit('nextExercise');


      },  
      goToResults()
      {
        const _this = this

        let curr_ex_done = false

        if(_this.play_status)
        {
          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0)
          {
            avg_loudness_array.push(_this.avg_loudness)
          }

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;



          if(_this.is_recording)
          {
            _this.is_recording = false

            _this.endRecordingAudio(true)

          }



        }

        var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: [0]}
        
        curr_ex_done = true

        _this.$emit('logExerciseResults', basicData);

        _this.$emit('nextExercise')
        //_this.$emit('goToResults', curr_ex_done);


      },
      ClearBlobs()
      {
        clear_blobs()

      },
      ComputeAVQI()
      {
        const _this = this

        // _this.audio_blobs = get_blobs()


        if(_this.audio_blobs.length==1)
        {
          _this.audio_blobs.push(_this.audio_blobs[0])

        }
        // else
        // {
        //   _this.audio_blobs[1] = _this.audio_blobs[0]
        // }


        if(_this.audio_blobs.length>1)
        {
          const form = new FormData();


          let audio_addresses = []

          for(let i in _this.audio_blobs)
          {

            let random_identifier = '_' + Math.random().toString(16).slice(2)


            let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;

            if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
              audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
            }

            var audio_address = 'audios/' + audio_folder + '.mp3';

            audio_address = audio_address.replaceAll('//','/')

            audio_addresses.push(audio_address)

            console.debug("Saving " + audio_address);
          }

          _this.push_structure['audio_address'] = audio_addresses

          const json = JSON.stringify(_this.push_structure);
      
    
          let file1 = new File([_this.audio_blobs[0]], 'cs.mp3');
          form.append('json_data', json);
    
          console.debug('file1 = ' + file1)
          form.append('cs', file1, 'cs.mp3');

          let file2 = new File([_this.audio_blobs[1]], 'sv.mp3');

          console.debug('file2 = ' + file2)
          form.append('sv', file2, 'sv.mp3');
          
          let api =  import.meta.env.VITE_FAST_API + "/analysis_runner_multi_audio"

          //set processing_status (started, processing, finished, failed)
          updateDataDirectory(_this.push_structure['path'], {"processing_status": "started"})

          getAuthToken().then((idToken) => {
            _this.axios.post(api, form, {
              headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': `multipart/form-data`,
              }}).then(response => {
                console.debug(response)
                console.debug(response.status)
                console.debug(response.info)
              });
          })
            

          _this.resultsTableBack.push(this.push_structure['path'])

          _this.goToResults()

          // let resultsTableBack = []
          // for(let i in _this.resultsTableBack)
          // {
          //   resultsTableBack.push(_this.resultsTableBack[i])

          // }

          // var basicData = {resultsTableBack: resultsTableBack, avg_loudness_array: [0]}

          // _this.resultsTableBack = []
          // _this.$emit('logExerciseResults', basicData);
          // _this.$emit('nextExercise');



        }

      },
      playPause()
      {
          
        const _this = this;

        console.debug('this.play_status = ' + this.play_status)


        if(_this.play_status)
        {

          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0)
          {
            avg_loudness_array.push(_this.avg_loudness)
          }

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;

          this.play_status = false


          if(_this.is_recording)
          {
            _this.is_recording = false

            _this.endRecordingAudio(true)

            
  
            clearInterval(window.refreshIntervalId)
            window.speechSynthesis.cancel()

            this.action='storing'
    
    
            // deleteSoundMeter()
          }



          // var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}


          // _this.$emit('logExerciseResults', basicData);

          
          // _this.$emit('nextExercise');
          
          // _this.hasTimer = false;
          
        }
        else
        {
            _this.play_status = true;
            _this.play_pause_text = 'Stop Recording'

            this.action='recording'


            // clear_blobs()



            change_save_blob_config(true,this)


            _this.resumeRecording()

            // if(_this.hasSPLMeter)
            {
                
                _this.runTimer()
            
            }


            if(this.stage==='sv')
            {


              _this.startTimer()

            }
   


        }

      },
      resumeRecording()
      {
          var _this = this;
      
          // resumeRecordingMedia();
          if(!_this.is_recording)
          {
              recordAudioMedia()
              _this.is_recording = true;
          }
  
      },
      pauseRecordingAudio()
      {

          const _this = this;

          if(_this.is_recording)
          {
              stopRecordingMedia(null,null,null);
              _this.is_recording = false;

          }
      

      },
      endRecordingAudio(save_audio)
      {
      
          const _this = this;


  
          if(save_audio)
          {

            console.debug('curr_exercise_path = ' + _this.exForm.curr_exercise_path)

            _this.exForm.assessment_type = 'SLP_ASSESSMENT'

            let results_addresses = generate_results_path(this.exForm)
            
            let random_identifier = results_addresses['random_identifier']

            let exercises_results_path = results_addresses['exercises_results_path']
            let modules_results_path = results_addresses['modules_results_path']


            let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;

            if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
              audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
            }

            var audio_address = 'audios/' + audio_folder + '.mp3';

            audio_address = audio_address.replaceAll('//','/')


            console.debug("Saving " + audio_address);


            
            let today = _this.exForm.today
            
            let voicetype = _this.exForm.voicetype
            let speechtype = _this.exForm.speechtype
            
            _this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')

            console.debug('exercises_results_path = ' + exercises_results_path)
            console.debug('assessment_type = ' + _this.exForm.assessment_type)

            updateDataDirectory(modules_results_path, {plan_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})

            let name = _this.exForm.name
            if(name == undefined || name == null || name == '')
            {
              name = _this.Ex_Text
            }

            let push_structure = {
              'date': today, 
              'name': name, 
              'path':exercises_results_path, 
              'audio_address': audio_address,
              'uid': _this.uid,
              'loudness': _this.last_loudness
            }

            let ground_truth = _this.Ex_Text

            if(ground_truth!=undefined && ground_truth!=null)
            {
              ground_truth = ground_truth.replaceAll('\n','')
            }
            else{
              ground_truth = ''
            }
            let has_analysis = false




            if(voicetype!=null && voicetype!=undefined)
            {
                has_analysis = true               

              if(Array.isArray(voicetype) && voicetype.includes('intelligibility'))
              {
              push_structure['intelligibility'] = ground_truth
              }
              else if('Intelligibility' in voicetype)
              {
              push_structure['intelligibility'] = ground_truth

              }

              if(Array.isArray(voicetype) && voicetype.includes('sentencegibility'))
              {

              push_structure['sentencegibility'] = ground_truth

              }
              else if('sentencegibility' in voicetype)
              {
              push_structure['sentencegibility'] = ground_truth
              }


              if(Array.isArray(voicetype) && voicetype.includes('max_phonation'))
              {
              push_structure['max_phonation'] = '1'
              }
              else if('max_phonation' in voicetype)
              {
              push_structure['max_phonation'] = '1'
              }


              if(Array.isArray(voicetype) && voicetype.includes('speech_rate'))
              {
                console.debug('speech_rate')
              push_structure['speech_rate'] = '1'
              }
              else if('speech_rate' in voicetype)
              {
              push_structure['speech_rate'] = '1'
              }

              if(Array.isArray(voicetype) && voicetype.includes('jitter_shimmer'))
              {
              push_structure['jitter_shimmer'] = '1'
              }
              else if('jitter_shimmer' in voicetype)
              {
              push_structure['jitter_shimmer'] = '1'
              }

              if(Array.isArray(voicetype) && voicetype.includes('f0'))
              {
              push_structure['f0'] = '1'
              }
              else if('f0' in voicetype)
              {
              push_structure['f0'] = '1'
              }
                    
            }


            if(speechtype!=null && speechtype!=undefined)
            {
              has_analysis = true

              if(Array.isArray(speechtype) && speechtype.includes('ddk_rate'))
              {
              push_structure['ddk_rate'] = '1'
              }

              if(Array.isArray(speechtype) && speechtype.includes('intelligibility'))
              {
              push_structure['intelligibility'] = ground_truth
              }
              else if('Intelligibility' in speechtype)
              {
              push_structure['intelligibility'] = ground_truth
              }


              if(Array.isArray(speechtype) && speechtype.includes('sentencegibility'))
              {
              push_structure['sentencegibility'] = ground_truth


              }
              else if('sentencegibility' in speechtype)
              {
              push_structure['sentencegibility'] = ground_truth


              }

              if(Array.isArray(speechtype) && speechtype.includes('speech_rate'))
              {
              push_structure['speech_rate'] = '1'
              }
              else if('speech_rate' in speechtype)
              {
              push_structure['speech_rate'] = '1'
              }

            }


            if(Array.isArray(voicetype) && voicetype.includes('pitch_range'))
            {

              if(this.frequency_data.length>0)
              {
                
                let max_freq = 0
                let min_freq = 1e6
                let mean_freq = 0


                for(let i in this.frequency_data)
                {
                  let curr_freq = this.frequency_data[i]
                  mean_freq = mean_freq+curr_freq
                  if(curr_freq>max_freq)
                  {
                    max_freq = curr_freq
                  }
                  else if(min_freq>curr_freq)
                  {
                    min_freq = curr_freq
                  }
                }

                mean_freq = mean_freq/this.frequency_data.length

                let freq_range = max_freq-min_freq

                let freq_results = {freq_range:freq_range,mean_freq:mean_freq,max_freq:max_freq,min_freq:min_freq}

                let resultsTablePre = {exercise_type: 'Pitch Range', exercise: _this.exForm.name, results: freq_results, audio_address:audio_address}

                var pitch_range_data = {resultsTablePre: resultsTablePre}



                save_pre_computed_results(_this.exForm, pitch_range_data)

                this.$emit('logExerciseResults', pitch_range_data);

              }
                
            }


            console.debug('push structure = ' + push_structure)


            if(true)
            {
              // _this.resultsTableBack.push(exercises_results_path)
              // _this.modules_results_path = modules_results_path
              // console.debug('path = ' + modules_results_path)
              // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
              stopRecordingMedia(audio_address, push_structure, _this);

              _this.push_structure = push_structure

          
            }
            else
            {
              stopRecordingMedia(audio_address, null,null);

            }
              
          }
          else
          {
              stopRecordingMedia(null,null,null);
          }            


          this.is_recording = false;

      }, 
    createChart()
    {
      const _this = this
      
    //   return
      if(_this.chart_not_created)
      {
        _this.chart_not_created = false
        console.debug('creating chart')
        console.debug('this.loud_target = ' + this.loud_target)
        _this.smoothie = new smoothie.SmoothieChart({millisPerPixel:11,grid:{fillStyle:'#dfd7d7',verticalSections:2},labels:{fillStyle:'#000000',fontSize:15},horizontalLines:[{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}],maxValue:_this.maxdBValue,minValue:_this.mindBValue})
        var canvas = document.getElementById("loud_canvas")
        _this.chart_data = new smoothie.TimeSeries();
        _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
        // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
        _this.smoothie.streamTo(canvas, 500);

      }
    },
    updateChart()
    {
      const _this = this


      let uid = localStorage.getItem('uid');
      var ref = _this.$firebase.database().ref('Users').child(uid).child('Data');


      _this.smoothie.stop()

      _this.smoothie.options.horizontalLines = [{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}]
      
      _this.smoothie.start()
      // var canvas = document.getElementById("loud_canvas")
      // _this.chart_data = new smoothie.TimeSeries();
      // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
      // // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
      // _this.smoothie.streamTo(canvas, 500);

    },
    createChartSpect()
    {
      const _this = this
      
      if(_this.chart_not_created_spect)
      {
        _this.chart_not_created_spect = false
        console.debug('creating spec chart')


        _this.smoothie_spect = new smoothie.SmoothieChart({millisPerPixel:11,labels:{fillStyle:'#000000',  precision:0},grid:{fillStyle:'rgba(40,40,154,0.60)',strokeStyle:'#ffffff', verticalSections:4},maxValue:500,minValue:50})
        var canvas = document.getElementById("spect_canvas")
        _this.chart_data_spect = new smoothie.TimeSeries();

        _this.smoothie_spect.addTimeSeries(this.chart_data_spect, {lineWidth:3.3,strokeStyle:'#00ff00'});
        _this.smoothie_spect.streamTo(canvas, 500);

      }

    },
    addDataChart(in_data)
    {
      const _this = this
      if(_this.smoothie!=undefined && _this.smoothie!= null)
      {
        _this.chart_data.append(new Date().getTime(),in_data)
      }

    },
    addDataChartSpect(in_data)
    {
      const _this = this
      if(_this.smoothie_spect!=undefined && _this.smoothie_spect!= null)
      {
        _this.chart_data_spect.append(new Date().getTime(),in_data)
      }
    },
    pause()
    {
        const _this = this;
        _this.play_status = false;
        _this.play_pause_text = _this.start_btn_text

        clearInterval(window.refreshIntervalId)

    },
    drawLoop(sound_l) {
      // clear the background

      var _this = this;


      var WIDTH = 400;//document.getElementById('meter').clientWidth;
      var HEIGHT = 30;//document.getElementById('meter').clientHeight;;

      var green_threshold = 50;

      // console.debug("Width = " + WIDTH);
      
      // console.debug("HEIGHT = " + HEIGHT);

      _this.sound_l = sound_l;



      if(_this.canvasContext != undefined && _this.canvasContext !=null)
      {
        _this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
      
        if (sound_l > green_threshold) {
          _this.canvasContext.fillStyle = "green";


        }
        else {

          // _this.silence_flag = true;

          _this.canvasContext.fillStyle = "red";
        }
        _this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
                // _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);


      }

    // draw a bar based on the current volume


    },


    
  
    playAudioInstructions(url)
    {
      document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="50px" width="400px" controls autoplay> </video>';
      var player =document.getElementById('movie');
      player.load();
    },

   
    }
}
</script>

<style scoped>
  .text {
    font-size: 14px;
  }

  .text_item {
    /* margin-bottom: 18px; */
    font-size: 30px;
    /* vertical-align: middle; */
    padding: 40px 0;
    /* height:80px; */
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 60%;
    margin:auto;
  }

  .demo-input-label {
    display: inline-block;
    width: 130px;
  }

  .legend-custom-style {
    font-size: 20px;
  }


  

  .base-timer {
    position: relative;
    width: 175px;
    height: 175px;
  }
  /* Removes SVG styling that would hide the time label */
  .base-timer__circle {
      fill:none;
      stroke: none;
    }
  /* The SVG path that displays the timer's progress */
  .base-timer__path-elapsed {
      stroke-width: 7px;
      stroke:grey;
  }

  .base-timer__label {
    position: absolute;    
    
    /* Size should match the parent container */    
    width: 175px;
    height: 175px;
    /* Keep the label aligned to the top */    
    top: 0;
    /* Create a flexible box that centers content vertically and horizontally */    
    display: flex;
    align-items: center;
    justify-content: center;
    /* Sort of an arbitrary number; adjust to your liking */
    font-size: 48px;
  }

  .base-timer__path-remaining {
    /* Just as thick as the original ring */
    stroke-width: 7px;
    /* Rounds the line endings to create a seamless circle */
    stroke-linecap: round;
    /* Makes sure the animation starts at the top of the circle */
    transform: rotate(90deg);
    transform-origin: center;
    /* One second aligns with the speed of the countdown timer */
    transition: 1s linear all;
    /* Allows the ring to change color when the color value updates */
    stroke: rgb(65, 184, 131);   
    fill:white;
  }

  .base-timer__svg {
    /* Flips the svg and makes the animation to move left-to-right */
    transform: scaleX(-1);
  }
</style>

