<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>
    
    <div class="container mb-5">
      <h1 class="display-5">Writing and spelling words</h1>

      <div class="card p-2">
        <div class="row text-center">
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <!-- <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div> -->

                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>1</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Copy writing a word</p>
                <img :src="copyWordImg" class="img-fluid">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="selected_module='Common_words';openModal('copy_writing_modal')"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('copy_word')"> Exercise overview</button>
              </div>
            </div>
          </div> 
          <!-- Pop up for selecting different copy writing resources -->
          <div class="modal fade" id="copy_writing_modal" tabindex="-1" aria-labelledby="copy_writing_modal" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Copy writing</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('copy_writing_modal')"></button>
                </div>
                <div class="modal-body">
                  <div class="row mt-2">
                    <h5>Choose words to copy from the following categories:</h5>
                  </div>
                  <div class="row mt-2" style="height:50vh">
                    <div class="col-4 border-3 border-success border-end">
      
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Common_words'?'btn-primary':'btn-light']" type="button" @click="selected_module='Common_words'">
                        General common words
                      </button>
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Starting_letter'?'btn-primary':'btn-light']" type="button" @click="selected_module='Starting_letter'">
                        Starting with a given letter
                      </button>
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Subject'?'btn-primary':'btn-light']" type="button" @click="selected_module='Subject'">
                        Words from a given subject
                      </button>
                    </div>

                    <div class="col-8">
                      <div v-show="selected_module == 'Starting_letter'">
                        <p class="lead">Practise writing words starting with a given letter. <br> Such as words starting with letter k.</p>
                      </div>
                      <div v-show="selected_module == 'Subject'">
                        <p class="lead">Practise writing words related to a subject. <br> Such as items in the kitchen or the name of animals.</p>
                      </div>
                      <div v-show="selected_module == 'Common_words'">
                        <p class="lead">Practise writing words from a list of commonly used words by the public.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="copy_word(selected_module)" :disabled="this.local_number_exercises <= 0">Start training</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <!-- <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div> -->

                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>2</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Spell the word you hear</p>
                <img :src="writingAudio" class="img-fluid">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="write_words_audio()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('audio_write')"> Exercise overview</button>
              </div>
            </div>
          </div> 
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <!-- <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div> -->

                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>3</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Spell the word for the picture</p>
                <img :src="writingImg" class="img-fluid">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="write_words_image()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('image_write')"> Exercise overview</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center mt-4">
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>4</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Search words </p>
                <img :src="wordSearchImg" class="img-fluid">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="word_search()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('word_search')"> Exercise overview</button>
              </div>
            </div>
          </div>  
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>5</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Hangman </p>
                <img :src="hangmanImg" width="90" height="100" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="hangman()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('hangman')"> Exercise overview</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>7</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Words with opposite meaning</p>
                  <img :src="oppositeImg" width="230" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="oppositeMeaning()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('opposite')"> Exercise overview</button>
                </div>
              </div>
            </div>        
        </div>
        <div class="modal fade" id="start_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ module_title }}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body text-center">
                <div class="row justify-content-around">
                  <div class="card card-body">
                    <h5>{{ benfite_statement }}</h5>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <Posture_instructions/>
                </div>
                <div class="row justify-content-center mt-4">
                  <div class="col-3">
                    <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footerbar class="mt-auto"></Footerbar>
</div>
</template>
<script>
import copyWordImg from '@/assets/images/instructions/copy_word_img.png'

import writingAudio from '@/assets/images/instructions/writing_words_audio.png'
import wordSearchImg from '@/assets/images/instructions/word_search_image.png'
import hangmanImg from '@/assets/images/instructions/hangman.png'
import oppositeImg from '@/assets/images/instructions/opposite_example.png'
import writingImg from '@/assets/images/instructions/writing_words_image.png'
import Posture_instructions from '../components/posture_instructions.vue'
import Footerbar from '@/views/Footerbar.vue'

import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
    name: "Writing_aphasia_instructions",
    components: {Posture_instructions, Navbar, Footerbar},
    props: {
    },
    data() {
      return {
        copyWordImg:copyWordImg,
        hangmanImg:hangmanImg,
        writingAudio:writingAudio,
        writingImg:writingImg,
        wordSearchImg:wordSearchImg,
        userCategory: "",
        region: "",
        explanation: "",
        module_title:'',
        benfite_statement:'',
        module_cate:'',
        selected_module: '',
        local_number_exercises: 5,
        modal_references: {},
        oppositeImg:oppositeImg,
      };
    },
    created() {
      this.userCategory = localStorage.getItem("userCategory");
      this.region = localStorage.getItem("region");
      // this.explanation[0] = "Make sure you:\n - are about 40cm/16in away from the screen.\n - have your back upright.\n - have a glass water to sip throughout."
      // this.explanation[1] = "1. You will see a video on how to pronounce a certain sound, follow the video to pratice pronouncing the sound."
      // this.explanation[2] = "2. By clicking 'Next', you will then see a few videos showing word examples that contain such sound."
      // this.explanation[3] = "3. Listen to the video, press 'Start' to record your pronunciation."
      this.explanation = "";
      window.scrollTo(0, 0);
    },
    mounted() {
      this.modal_references = {
        "copy_writing_modal": new Modal(document.getElementById('copy_writing_modal')),
      }
      this.local_number_exercises = this.number_exercises
    },
    methods: {
      oppositeMeaning()
      {
        this.$router.push({ name: 'user_training', params: { blueprint_id: 'AntonymExercises' }});
      },
      hangman() {
        this.$router.push({ name: 'user_training', params: { blueprint_id: 'Hangman' }});
      },
      write_words_image() {
        this.$router.push({ name: 'user_training', params: { blueprint_id: 'WriteWordsPicture' }});
      },
      write_words_audio() {
        this.$router.push({ name: 'user_training', params: { blueprint_id: 'WriteWordsAudio' }});
      },
      word_search() {
        this.$router.push({ name: 'user_training', params: { blueprint_id: 'WordSearch' }});
      },
      copy_word()
      {
        // this.$router.push({ name: 'copy_word'});
        if(this.selected_module == 'Common_words')
        {
          this.closeModal('copy_writing_modal')
          this.$router.push({ name: 'user_training', params: { blueprint_id: 'CopyWord' }});
        }
        else if (this.selected_module=='Starting_letter')
        {
          this.closeModal('copy_writing_modal')
          this.$router.push({ name: 'user_training', params: { blueprint_id: 'CopyWordLetter' }});

        }
        else if (this.selected_module=='Subject')
        {
          this.closeModal('copy_writing_modal')
          this.$router.push({ name: 'user_training', params: { blueprint_id: 'CopyWordSubject' }});
        }

      },
      closeModal(name_id) {
        if (name_id in this.modal_references) {
      
          this.modal_references[name_id].hide()
        }
      },
      openModal(name_id) 
      {
        if (name_id in this.modal_references) {
          this.$emit('selected_module', this.selected_module)
          this.modal_references[name_id].show()
        }
      },
      gotoBenefit(cate)
      {
        this.module_cate = cate;
        if(cate == 'audio_write')
        {
          this.module_title = 'Spell the word you hear'
          this.benfite_statement = 'This exercise helps you practise spelling words based on its pronunciation. A key step towards writing words.'
        }
        else if(cate ==='copy_word')
        {
          this.module_title = 'Copy writing a word'
          this.benfite_statement = 'This exercise helps you write a word by copying it letter by letter.'
        }
        else if(cate == 'opposite')
        {
          this.module_title = 'Words with opposite meaning'
          this.benfite_statement = 'This exercise helps you practise finding words and spelling.'
        }
        else if(cate==='word_search')
        {
          this.module_title = 'Search words'
          this.benfite_statement = 'This exercise helps you practise spelling out words.'

        }
        else if(cate==='hangman')
        {
          this.module_title = 'Hangman'
          this.benfite_statement = 'This exercise helps you practise composing a word letter by letter.'

        }
        else if(cate == 'image_write')
        {
          this.module_title = 'Spell the word for the picture'
          this.benfite_statement = 'This exercise helps you practise spelling words. A key step towards writing longer sentences.'
        }
        else
        {
          console.log('unknown category = '+cate)
        }
        let start_pop = new Modal(document.getElementById('start_pop'));
        start_pop.show()
      },
      gotoModule()
      {
        if(this.module_cate == 'audio_write')
        {
          this.write_words_audio()
        }
        else if(this.module_cate == 'image_write')
        {
          this.write_words_image()
        }
        else if(this.module_cate == 'hangman')
        {
          this.hangman()
        }
        else if(this.module_cate == 'word_search')
        {
          this.word_search()
        }
        else
        {
          console.log('unknown category = '+this.module_title)
        }
      }
  },
}
</script>
<style scoped>
.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;
}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}

</style>