<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>

    <div class="container mb-5">
      <h1 class="display-5">Thinking of words</h1>

      <div class="card p-2">
        <div clas="card-body text-center">
          <div class="row">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>1</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Naming a picture</p>
                  <img :src="fishingImg" width="120" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="wordNaming()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('pic_name')"> Exercise overview</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> -->

                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>2</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Common sayings</p>
                  <img :src="commonSayingsImg" width="260" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="common_sayings()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('common_sayings')"> Exercise overview</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">


                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>3</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Components of a word</p>
                  <!-- <p> Find items inside a scene </p> -->
                  <img :src="pcaImg" width="320" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="pca()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('pca')"> Exercise overview</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">

                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> -->
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>4</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Spoken phonological components analysis</p>
                  <img :src="spokenPCAImg" width="300" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="spoken_pca()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('spoken_pca')"> Exercise overview</button>
                </div>
              </div>
            </div>  
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>5</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Picking objects</p>
                  <!-- <p> Find items inside a scene </p> -->
                  <img :src="sceneClickImg" width="120" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="sceneClick()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('find_item')"> Exercise overview</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>6</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Answering basic questions</p>
                  <!-- <p> Answer questions about items inside a scene </p> -->
                  <img :src="sceneImg" width="120" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="aphasia_scene()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('answer_question')"> Exercise overview</button>
                </div>
              </div>
            </div>
            
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>7</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Linking words and category</p>
                  <img :src="categoryImg" width="220" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="wordCategory()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('word_category')"> Exercise overview</button>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>8</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Name items in categories</p>
                  <img :src="animalsImg" width="120" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="wordFinding()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('word_finding')"> Exercise overview</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
     
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>9</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Matching shapes</p>
                  <!-- <p> Answer questions about items inside a scene </p> -->
                  <img :src="matchShapesImg" width="120" height="120" style="object-fit: contain">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="matching_shapes()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('match_shape')"> Exercise overview</button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div>
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>10</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Count items in an image</p>
                  <img :src="count_in_images_Img" width="200" height="140" style="object-fit: contain">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="count_in_images()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('count_in_images')"> Exercise overview</button>
                </div>
              </div>
            </div>
    
          </div>

          <div class="modal fade" id="start_pop" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">{{ module_title }}</h4>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center">
                  <div class="row justify-content-around">
                    <div class="card card-body">
                      <h5>{{ benfite_statement }}</h5>
                    </div>
                    
                  </div>
                  <div class="row justify-content-center">
                    <Posture_instructions/>
                  </div>
                  <div class="row justify-content-center mt-4">
                    <div class="col-3">
                      <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
                    </div>
                    <div class="col-3">
                      <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
    <Footerbar class="mt-auto"></Footerbar> 
  </div>
</template>
<script>

import animalsImg from '@/assets/images/instructions/animals.jpg'
import fishingImg from '@/assets/images/fishing.jpg'
import Posture_instructions from '../components/posture_instructions.vue'
import sceneImg from '@/assets/images/scene_description.png'
import matchShapesImg from '@/assets/images/match_shapes.png'
import listenMinimalPairsImg from '@/assets/images/listen_minimal_pairs.png'
import commonSayingsImg from '@/assets/images/instructions/common_sayings.png'

import count_in_images_Img from '@/assets/images/instructions/count_in_images_Img.png'

import spokenPCAImg from '@/assets/images/instructions/spoken_pca.png'

import pcaImg from '@/assets/images/instructions/pca.png'

import sceneClickImg from '@/assets/images/scene_click.png'
import categoryImg from '@/assets/images/instructions/word_category.jpeg'

import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
  name: 'Word_aphasia_instructions',
  components: {Posture_instructions, Footerbar, Navbar},
  props: 
  {
  },
  data(){
    return {
      userCategory:'',
      region:'',
      commonSayingsImg:commonSayingsImg,
      spokenPCAImg:spokenPCAImg,
      pcaImg:pcaImg,
      animalsImg:animalsImg,
      fishingImg:fishingImg,
      sceneImg:sceneImg,
      sceneClickImg:sceneClickImg,
      categoryImg:categoryImg,
      matchShapesImg:matchShapesImg,
      listenMinimalPairsImg:listenMinimalPairsImg,
      count_in_images_Img:count_in_images_Img,
      module_title:'',
      benfite_statement:'',
      module_cate:''
    }
  },
  created() {
    this.userCategory = localStorage.getItem('userCategory');
    this.region = localStorage.getItem('region');

    window.scrollTo(0, 0);
  },
  methods: {
    wordNaming() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'PictureNaming' }});
    },
    common_sayings() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'CommonSayings' }});
    },
    matching_shapes() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'MatchingShapes' }});
    },
    listen_minimal_pairs() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'ListenMinimalPairs' }});
    },
    count_in_images() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'Count_in_images' }});
    },
    wordCategory() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'WordCategory' }});      
    },
    sceneClick() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'SceneClick' }});
    },
    pca() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'PCA' }});      
    },
    spoken_pca() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'SpokenPCA' }});
    },
    wordFinding() {
      this.$router.push({ name: 'word_finding'});
    },
    aphasia_scene() {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'SceneDescription' }});
    },
    gotoBenefit(cate)
    {
      this.module_cate = cate;
      if(cate == 'pic_name')
      {
        this.module_title = 'Naming a picture'
        this.benfite_statement = 'This exercise helps you practise naming things in everyday life.'
      }
      else if(cate == 'common_sayings')
      {
        this.module_title = 'Common sayings'
        this.benfite_statement = 'Practise your word finding using common phrases.'
      }
      else if(cate == 'pca')
      {
        this.module_title = 'Components of a word'
        this.benfite_statement = 'Practise word finding by paying attention to word sounds.'

      }
      else if(cate == 'count_in_images')
      {
        this.module_title = 'Count in images'
        this.benfite_statement = 'Practice numbers by identifying how many objects of a certain type you see on an image.'

      }
      else if(cate == 'spoken_pca')
      {
        this.module_title = 'Spoken phonological components analysis'
        this.benfite_statement = 'Practise your word finding by hearing and repeating sounds.'

      }
      else if(cate == 'word_category')
      {
        this.module_title = 'Linking words and category'
        this.benfite_statement = 'Grouping words in categories helps you find words when speaking. This exercise aims to help you improve categorising words.'
      }
      else if(cate == 'find_item')
      {
        this.module_title = 'Picking objects'
        this.benfite_statement = 'This exercise helps you practise understanding and recognising words.'
      }
      else if(cate == 'answer_question')
      {
        this.module_title = 'Answering basic questions'
        this.benfite_statement = 'This exercise helps you practise describing objects. Linking objects with their features help you improve word finding.'
      }
      else if(cate == 'word_finding')
      {
        this.module_title = 'Name items in categories'
        this.benfite_statement = 'This exercise helps you practise finding words in each category.'
      }
      else if(cate == 'match_shape')
      {
        this.module_title = 'Matching shapes'
        this.benfite_statement = 'This exercise helps you practise recognising and differentiating shapes.'
      }
      else if(cate == 'listen_pair')
      {
        this.module_title = 'Differentiate similar words'
        this.benfite_statement = 'This exercise helps you practise listening and differentiating similar words.'
      }
      else
      {
        console.log('unknown category = '+cate)
      }
      let start_pop = new Modal(document.getElementById('start_pop'));
      start_pop.show()
    },
    gotoModule()
    {
      if(this.module_cate == 'pic_name')
      {
        this.wordNaming()
      }
      else if(this.module_cate == 'word_category')
      {
        this.wordCategory()
      }
      else if(this.module_cate == 'common_sayings')
      {
        this.common_sayings()
      }
      else if(this.module_cate == 'find_item')
      {
        this.sceneClick()
      }
      else if(this.module_cate == 'pca')
      {
        this.pca()
      }
      else if(this.module_cate == 'spoken_pca')
      {
        this.spoken_pca()
      }
      else if(this.module_cate == 'answer_question')
      {
        this.aphasia_scene()
      }
      else if(this.module_cate == 'word_finding')
      {
        this.wordFinding()
      }
      else if(this.module_cate == 'opposite')
      {
        this.oppositeMeaning()
      }
      else if(this.module_cate == 'match_shape')
      {
        this.matching_shapes()
      }
      else if(this.module_cate == 'listen_pair')
      {
        this.listen_minimal_pairs()
      }
      else
      {
        console.log('unknown category = '+this.module_title)
      }
    }
  },
}
</script>
<style scoped>

.custom-card_blue{
    background-color: #cfe2f3;
    border-color: #cfe2f3;
    font-size: 20px;
}
.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}


</style>