<template>
      <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div>
  <div>

    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module = 'DailyNewsShortInstructions';openModal()">
                    
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img v-if="section=='paragraph_level'" :src="Voice_Speech_text" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline">
          <img v-else :src="Voice_Speech_text" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline">
        </div>
        
        <div class="col-9 d-flex align-items-center">
          <strong>Paragraph Level</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="section" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Paragraph Level</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
          </div>
          <div class="modal-body">
            <div class="row" style="height:50vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'DailyNewsShortInstructions' || selected_module ==  'DailyNewsLongInstructions'?'btn-primary':'btn-light']" type="button" @click="selected_module='DailyNewsShortInstructions'">
                  Daily news
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ShortParagraph'?'btn-primary':'btn-light']" type="button" @click="selected_module='ShortParagraph'">
                  Short paragraphs by topic of interest
                </button>
                <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ShortParagraphPhoneme'?'btn-primary':'btn-light']" type="button" @click="selected_module='ShortParagraphPhoneme'">
                  Short paragraphs by topic of interest (targeted phoneme)
                </button>
                <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'PictureDescriptionNoAnalysis'?'btn-primary':'btn-light']" type="button" @click="selected_module='PictureDescriptionNoAnalysis'">
                  Picture description (non-scripted talking) - no speech analysis 
                </button>
                <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'PictureDescriptionSpeech'?'btn-primary':'btn-light']" type="button" @click="selected_module='PictureDescriptionSpeech'">
                  Picture description (non-scripted talking) - with speech analysis 
                </button>
              </div>
              <div class="col-8">
                <div v-if="selected_module == 'DailyNewsShortInstructions' || selected_module == 'DailyNewsLongInstructions'">
                  <p class="lead">Practise long phonation times with extended passages of text</p>
                  <div class="row mb-2">
                    <label class="col-6 form-label">Choose text length</label>
                    <div class="col-6">
                      <select class="form-select form-select" v-model="selected_module">
                        <option value="DailyNewsShortInstructions">about 30 words</option>
                        <option value="DailyNewsLongInstructions">about 60 words</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Choose a topic</label>

                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration['topic']">
                        <option value="Art and Design">Art and Design</option>
                        <option value="Business">Business</option>
                        <option value="Cities">Cities</option>
                        <option value="Film">Film</option>
                        <option value="Food">Food</option>
                        <option value="Football">Football</option>
                        <option value="Latest News">Latest News</option>
                        <option value="Leisure">Leisure</option>
                        <option value="Lifestyle">Lifestyle</option>
                        <option value="Politics">Politics</option>
                        <option value="Science">Science</option>
                        <option value="Sport">Sport</option>
                        <option value="Tech">Tech</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-if="selected_module == 'ShortParagraph'">
                  <p class="lead">Practise long phonation times with extended passages of text</p>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        alidation="required|min:1|max:15" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Topic (The Beatles, Gardening)</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="topic" type="text" name="topic" v-model="module_configuration['paragraphtopic']" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                          messages-class="text-danger"
                      validation="required"/>
                    </div>
                  </div>
                  <div class="row m-4">
                    <button v-if="!generating_word_list" class="btn btn-success" style="width:100%" :disabled="module_configuration['paragraphtopic'].length==0" @click="generate_short_paragraphs()">Generate Paragraphs </button>
                    <button v-else class="btn btn-success" type="button" style="width:100%" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Generating...
                    </button>

                    <div v-if="module_configuration['short_paragraph_form'].text.length>0 && module_configuration['short_paragraph_form'].name === 'ShortParagraph'">
                      <div class="row mt-2">
                        <div v-for="id in module_configuration['short_paragraph_form'].text.length" :key="id">
                          <div class="input-group mb-3">
                            <textarea class="form-control form-control-lg" v-model="module_configuration['short_paragraph_form'].text[id-1]"></textarea>
                            <button class="input-group-text btn btn-outline-danger" type="button" @click="deleteSentence(id-1, module_configuration['short_paragraph_form'].text, module_configuration['short_paragraph_form'])">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div v-if="selected_module == 'ShortParagraphPhoneme'">
                  <p class="lead">Practise long phonation times with extended passages of text focusing on a specific phoneme</p>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        alidation="required|min:1|max:15" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Topic (The Beatles, Gardening)</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="topic" type="text" name="topic" v-model="module_configuration['paragraphtopic']" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                          messages-class="text-danger"
                      validation="required"/>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Choose a sound</label>
                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration['paragraphphoneme']">
                        <option v-for="(item, key, index) in phonemeArticulationDisplayDictionary" :value="item.IPA" :key="index">{{item.IPA}} {{item.example_0}}<b style="color:#FF9333">{{item.example_1}}</b>{{item.example_2}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row m-4">
                    <button v-if="!generating_word_list" class="btn btn-success" style="width:100%" :disabled="module_configuration['paragraphtopic'].length==0" @click="generate_short_paragraphs_phoneme()">Generate Paragraphs </button>
                    <button v-else class="btn btn-success" type="button" style="width:100%" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Generating...
                    </button>

                    <div v-if="module_configuration['short_paragraph_form'].text.length>0 && module_configuration['short_paragraph_form'].name === 'ShortParagraph'">
                      <div class="row mt-2">
                        <div v-for="id in module_configuration['short_paragraph_form'].text.length" :key="id">
                          <div class="input-group mb-3">
                            <textarea class="form-control form-control-lg" v-model="module_configuration['short_paragraph_form'].text[id-1]"></textarea>
                            <button class="input-group-text btn btn-outline-danger" type="button" @click="deleteSentence(id-1, module_configuration['short_paragraph_form'].text, module_configuration['short_paragraph_form'])">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div v-if="selected_module == 'PictureDescriptionNoAnalysis' || selected_module == 'PictureDescriptionNoAnalysis'">
                  <p class="lead">Picture description (non-scripted talking) - no speech analysis</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="3" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-if="selected_module == 'PictureDescriptionSpeech' || selected_module == 'PictureDescriptionSpeech'">
                  <p class="lead">Picture description (non-scripted talking) - with speech analysis</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="3" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Voice_Speech_text from '@/assets/images/dashboard/slt/training/Voice_Speech_text.svg'

import { Modal } from 'bootstrap'
import { short_paragraph, short_paragraph_phoneme} from '@/utils/interface_ai'
import constants from '@/utils/constants'

export default {
  name: 'paragraph_level',
  components: {},
  props: 
  {
    number_exercises: Number,
    section: String
  },
  data(){
    return {
      Voice_Speech_text: Voice_Speech_text, 
      modal_reference: null,

      selected_module: '',
      local_number_exercises: 5,

      module_configuration: {
        topic: 'Latest News',

        sentences_form: 
        {
          name: 'temp',
          keywords:'',
          instruct: 'Press Start. Read the following sentence with a loud and clear voice. Press Stop.',
          text: [],
          n_item:5,
        },

        paragraphtopic: '',
        paragraphphoneme:'',
        short_paragraph_form:
        {
          name: 'temp',
          keywords:'',
          instruct: 'Press Start. Read the following paragraph with a loud and clear voice. Press Stop.',
          text: [],
          n_item:3,
        },

      },

      generating_word_list: false,

    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    this.phonemeArticulationDisplayDictionary = constants.phonemeDisplayListWithExamples

    this.tongue_twisters_phonemes_simple_representation = constants.extract_tongue_twisters_phonemes()
  },
  mounted() {

    this.modal_reference = new Modal(document.getElementById(this.section))

    this.local_number_exercises = this.number_exercises
  },
  methods: {
    check() {
      
      if (this.selected_module === "ShortParagraph") {
        if (this.module_configuration['short_paragraph_form'].text.length < 1) {
          alert("Please generate a paragraph first.")
          return false
        }
      }

      return true

    },
    emit_start_training() {
      if ( !this.check() ) return
      this.closeModal()
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      if ( !this.check() ) return
      this.closeModal()
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal() {
      if (this.modal_reference) {
        this.$emit('selected_module', this.selected_module)
        this.modal_reference.show()
      }
    },
    closeModal() {
      if (this.modal_reference) {
    
        this.modal_reference.hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    generate_fun_facts()
    {
      const _this = this
      
      _this.generating_word_list = true
      
      let list_facts = fun_facts(_this, _this.module_configuration['funtopic'], _this.number_exercises)
       
      _this.module_configuration['sentences_form'].topic = _this.module_configuration['funtopic']
      _this.module_configuration['sentences_form'].name = "FunFacts"

      list_facts.then(function(result) {
        console.debug('List_facts = ' + result)

        _this.module_configuration['sentences_form'].text = result
        _this.generating_word_list = false

      })
      
    },
    deleteSentence(id, sentences_array, sentences_form) {
      sentences_array.splice(id,1)
      this.local_number_exercises = sentences_array.length    // this.sentences_form.text.splice(id, 1)
      this.onNumberExercisesChange()
      // this.number_exercises = this.number_exercises - 1
    },
    generate_short_paragraphs()
    {
      const _this = this
      
      _this.generating_word_list = true
      
      let list_paragraph = short_paragraph(_this, _this.module_configuration['paragraphtopic'], _this.number_exercises)
       

      _this.module_configuration['short_paragraph_form'].topic = _this.module_configuration['paragraphtopic']
      _this.module_configuration['short_paragraph_form'].name="ShortParagraph"
      list_paragraph.then(function(result) {
        console.debug('List_facts = ' + result)

        _this.module_configuration['short_paragraph_form'].text = result
        _this.generating_word_list = false

      })
    },
  
    generate_short_paragraphs_phoneme()
    {
      const _this = this
      
      _this.generating_word_list = true
      
      console.debug('here')

      console.debug('phoneme = ' + _this.module_configuration['paragraphphoneme'])
        
      let list_paragraph = short_paragraph_phoneme(_this, _this.module_configuration['paragraphtopic'], _this.number_exercises, _this.module_configuration['paragraphphoneme'])

      _this.module_configuration['short_paragraph_form'].topic = _this.module_configuration['paragraphtopic']
      _this.module_configuration['short_paragraph_form'].name="ShortParagraph"
      _this.module_configuration['short_paragraph_form'].paragraphphoneme=_this.module_configuration['paragraphphoneme']

      list_paragraph.then(function(result) {
        console.debug('List_facts = ' + result)

        _this.module_configuration['short_paragraph_form'].text = result
        _this.generating_word_list = false

      })
    }
  }
  
}
</script>