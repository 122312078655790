<template>
  <div>
    <div v-if="mic_permission_allowed === false">
      <MicPermission @response="(rtn) => mic_permission_allowed = rtn" />
    </div>
    <div v-else class="d-flex flex-column min-vh-100">

      <Navbar  :enableBack="userCategory === 'slp' && room_meta_reactive.mode === 'waiting_room'"></Navbar>
      <div class="container-fluid">
        <div class="row m-2 mb-4">
          <div class="d-flex justify-content-between">
            <div>
              <div class="d-inline"> 
                <h5>
                  Session Status:
                  <div v-if="userCategory ==='patient'" class="d-inline">
                    <span v-if="connected && room_meta_reactive.slt_connected" style="color:green">Online </span>
                    <span v-else-if="connected && !room_meta_reactive.slt_connected" style="color:orange">Please wait for therapist to connect </span>
                    <span v-else style="color:red">Offline <button class="btn btn-sm btn-outline-dark rounded-pill" @click="reloadPage()"><i class="bi bi-arrow-clockwise"></i></button></span>
                  </div>
                  <div v-else-if="userCategory ==='slp'" class="d-inline">
                    <span v-if="connected && room_meta_reactive.users && Object.values(room_meta_reactive.users).filter(user => user.category === 'patient').length > 0" style="color:green">Online </span>
                    <span v-else-if="connected && room_meta_reactive.users && Object.values(room_meta_reactive.users).filter(user => user.category === 'patient').length < 1" style="color:orange">Please wait for patient to connect</span>
                    <span v-else style="color:red">Offline <button class="btn btn-sm btn-outline-dark rounded-pill" @click="reloadPage()"><i class="bi bi-arrow-clockwise"></i></button></span>
                  </div>
                </h5>
              </div>
              <!--<div v-if="received_stream.value && userCategory !='patient' && true">
                <span style="color:rgb(248, 17, 0)">Latency (ms):  {{ Date.now() - received_stream.value['timestamp'] }}</span>
              </div>-->
            </div>
            <div v-if="room_meta_reactive.exercise_index > 0 && room_meta_reactive.mode === 'exercise'">
              <h5>Exercise: {{ room_meta_reactive.exercise_index }}</h5>
            </div>
          </div>
        </div>
        <div v-if="room_meta_reactive.mode === 'waiting_room'" class="text-center container">
          <div v-if="userCategory === 'patient'">
            <div class="row">
              <h1>This is an interactive session.</h1>
              <br/>
              <div v-if="!room_meta_reactive.slt_connected">
                <h1>Please wait for your therapist to connect.</h1>
              </div>
              <div v-else>
                <h1>Your therapist has joined the session. Please wait for them to start the assessment.</h1>
              </div>
            </div>
          </div>
          <div v-else-if="userCategory === 'slp'">
            <div v-if="room_meta_reactive.session_name === 'Interactive Session Words' || room_meta_reactive.session_name === 'Interactive Session Sentences'" class="row">
              <h1>Press start to show to the patient text to pronounce</h1>
            </div>
            <div v-else-if="room_meta_reactive.session_name === 'questionnaire'" class="row">
              <h1>Press start to show to the patient a question to answer</h1>
            </div>
            <br/>
            <br/>
            <div class="row m-4 justify-content-center">
              <div class="col-6">
                <div class="input-group input-group-lg">
                  <span class="input-group-text">Session ID</span>
                  <input type="text" class="form-control" v-model="session_slt_id" placeholder="Optional Session ID">
                </div>
              </div>
            </div>
            <div class="row mt-4 justify-content-center">
              <button class="btn btn-lg btn-primary w-25" @click="start()">Start</button>
            </div>
          </div>
          <div v-else>
            
          </div>
        </div>
        <div v-else> <!--Modules go here-->
          <div v-if="room_meta_reactive.slt_connected">
            <Intelligibility v-if="room_meta_reactive.session_name === 'Interactive Session Words' || room_meta_reactive.session_name === 'Interactive Session Sentences'"
              :room_meta="room_meta_reactive"
              :received_stream="received_stream"
              @save="save"
              @next="next"
              @back="back"
              @end="end"
              @exit="exit"
              @command="command" 
              @transmit_stream="transmit_stream">
            </Intelligibility>
            <Questionnaire v-if="room_meta_reactive.session_name === 'Interactive Session Questionnaire'"
              :room_meta="room_meta_reactive"
              @save="save"
              @next="next"
              @back="back"
              @end="end"
              @exit="exit"
              @command="command"
              @transmit_stream="transmit_stream">
            </Questionnaire>
            <Phonation v-if="room_meta_reactive.session_name === 'Interactive Session Phonation'"
              :room_meta="room_meta_reactive"
              :received_stream="received_stream"
              @save="save"
              @next="next"
              @back="back"
              @end="end"
              @exit="exit"
              @command="command"
              @transmit_stream="transmit_stream">
            </Phonation>
            <Picture_Naming v-if="room_meta_reactive.session_name === 'Interactive Session Picture Naming'"
              :room_meta="room_meta_reactive"
              @save="save"
              @next="next"
              @back="back"
              @end="end"
              @exit="exit"
              @command="command"
              @transmit_stream="transmit_stream">
            </Picture_Naming>
          </div>
          <div v-else class="text-center">
            <h1>The interactive assessment session is paused.</h1>
            <h1>Your therapist is disconnected. Please remain online and wait for your therapist to reconnect.</h1>
          </div>
        </div>
      </div>
      <Footerbar class="mt-auto"></Footerbar>
    </div>
  </div>
</template>
  
<script>
  
import {logActivity} from '@/media_input/utils'

import { io } from 'socket.io-client'
import { ref, onBeforeUnmount } from 'vue'
import {getAuthToken} from '@/firebase/index'
import { reactive } from 'vue'
import {checkPermissionsMedia } from '@/media_input/microphone_recording'
import MicPermission from '@/views/components/mic_permission/MicPermission.vue'
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'

import { useRoute, useRouter } from 'vue-router';
import { useInteractiveStatusStore } from '@/stores/interactiveSessionStatus'

import Picture_Naming from './interactive_modules/Picture_Naming/picture_naming.vue'
import Intelligibility from './interactive_modules/Intelligibility/intelligibility.vue'
import Questionnaire from './interactive_modules/Questionnaire/questionnaire.vue'
import Phonation from './interactive_modules/Phonation/phonation.vue'

export default {
  name: 'training_room',
  components: {
    Footerbar,
    Navbar,
    MicPermission,
    Intelligibility,
    Questionnaire,
    Phonation,
    Picture_Naming
  },
  props: ['session_name'],
  setup(props) {

    const route = useRoute()
    const router = useRouter()
    
    const api = import.meta.env.VITE_FAST_API

    const uid = localStorage.getItem('uid')
    const userCategory = localStorage.getItem('userCategory')
    const connected = ref(false)
    const session_slt_id = ref("")

    const store = useInteractiveStatusStore()

    let room_meta_reactive = reactive({
      mode: 'waiting_room',
      exercise_index: 0,
      slt_connected: false,
      currently_recording_uid: '',
      session_id: '',
      session_date: '',
      session_name: '',
      session_slt_id: '',
      total_exercises: 0,
      blackboard: {},
      users: null,
      // Add other properties as needed
    })

    let received_stream = reactive({})
    
    checkPermissionsMedia()

    logActivity( 'accessed', 'interactive_session')

    const mic_permission_allowed = ref(false)
    navigator.permissions.query({ name: "microphone" })
      .then((permissionStatus) => {
        console.debug("microphone" + " " + permissionStatus.state); // granted, denied, prompt

        if (permissionStatus.state !== "granted") {
          //not granted so show mic page
          mic_permission_allowed.value = false
          localStorage.setItem('mic_permission_allowed', false)
        } else {
          mic_permission_allowed.value = true
          localStorage.setItem('mic_permission_allowed', true)
        }
        
        permissionStatus.onchange = () => {
          console.debug("Permission changed to " + permissionStatus.state);

          if (permissionStatus.state !== "granted") {
            //not granted so show mic page
            mic_permission_allowed.value = false
            localStorage.setItem('mic_permission_allowed', false)
          }
        };

      })
      .catch((error) => {
        //probably firefox or unsupported browser in which get getUserMedia
        //console.debug('Error querying permissions:', error);
        mic_permission_allowed.value = false
        if (localStorage.getItem('mic_permission_allowed') && localStorage.getItem('mic_permission_allowed') === 'true') {
          mic_permission_allowed.value = true
          localStorage.setItem('mic_permission_allowed', true)
        } else {
          mic_permission_allowed.value = false
          localStorage.setItem('mic_permission_allowed', false)
        }
      });


    let socketConnection

    getAuthToken().then( async (idToken) => {

      let counter_party_uid = localStorage.getItem('client_uid');

      if (userCategory === 'patient') {
        
        if (store.status === 'active') {
          counter_party_uid = store.counter_party_uid
        } else {
          const cuid = ref(route.query.slt)
          counter_party_uid = cuid.value
        }
      }
      
      let auth_data = {
        'uid': uid, 
        'counterParty_uid': counter_party_uid, 
        'userCategory': userCategory
      }

      if (userCategory === 'slp') {
        auth_data['session_name'] = props.session_name
      }

      /* Establish Connection */
      socketConnection = io(api, {
        path: '/ws/socket.io', 
        query: auth_data,
        transports: ['websocket'],
        auth: {
          token: idToken
        }
      })


      socketConnection.on("connect", (data) => {
        console.log('socket connected!!')
        connected.value = true
      })

      socketConnection.on("error", (data) => {
        console.error(data)
        //alert("You tried to connect to: " + props.session_name + ". You have an active session, please end the current session before starting a new one.")
      })

      socketConnection.on("disconnect", (data) => {
        console.debug(data)
        connected.value = false
      })

      socketConnection.on('room_update', (data) => {
        Object.keys(data).forEach((property) => {
          room_meta_reactive[property] = data[property]
        });

        socketConnection.emit("session_update_users", data['users'])

        if (room_meta_reactive.slt_connected && room_meta_reactive.mode !== 'waiting_room' && room_meta_reactive.session_name !== '') {
          store.setInteractiveSessionStatus('active')
          store.setCounterPartyID(counter_party_uid)
          store.setInteractiveSessionName(room_meta_reactive.session_name)
        }
      });

      socketConnection.on('receive_stream', (data) => {
        received_stream.value = reactive(data)
      });

      socketConnection.on('exit', () => {
        if (room_meta_reactive.slt_connected) {
          store.setInteractiveSessionStatus("inactive")
          store.setCounterPartyID("")
          store.setInteractiveSessionName("")
        }
        
        if (userCategory === 'patient') {
          router.push({name: 'home'});
        } else {
          router.push({ name: 'client_interface', query: {uid: counter_party_uid} });
        }
      });
    })

    onBeforeUnmount(()=> {
      socketConnection.close()
    })

    ///these are only available on the SLT view
    const start = () => {
      socketConnection.emit("start", props.session_name, {session_slt_id: session_slt_id.value})
    }

    const save = (v) => {
      socketConnection.emit("save", v)
    }

    const next = () => {
      socketConnection.emit("next")
    }

    const back = () => {
      socketConnection.emit("back")
    }

    const end = () => {
      socketConnection.emit("end")
    }

    const exit = () => {
      socketConnection.emit("exit")
    }

    const command = (v) => {
      console.log(v)
      socketConnection.emit("command", v);
    }

    const transmit_stream = (v) => {
      console.debug(v)
      socketConnection.emit("transmit_stream", v)
    }

    const reloadPage = () => {
      window.location.reload();
    }

    return {
      uid,
      userCategory,
      session_slt_id,
      connected,
      mic_permission_allowed,
      room_meta_reactive,
      received_stream,
      
      reloadPage,
      start,
      save,
      next,
      back,
      end,
      exit,
      command,
      transmit_stream
    }
  }
}
</script>

