<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>
    
    <div class="container">
      <h1 class="display-5">My progress</h1>
      <div class="card card-body">
        <div class="row p-2" >
          <div class="col-md-8">
            <div v-show="selected_metric && !no_data" class="justify-content-center" style="height: 350px; width: 100%;">
              <canvas id="chartContainer" class="mt-4"></canvas>
            </div>
            <div v-show="no_data" class="text-center mt-4">
              <h4>No result data available</h4>
            </div>
          </div>

          <div class="col-md-4">
            <div class="row mt-4">
              <p>Select time range:</p>
            </div>
            <div class="row" >
              <div class="col-4">
                <button v-if="chart_period==='week'" class="btn btn-primary"  @click="changeTimeRange('week')" >Last week</button>
                <button v-else class="btn btn-secondary"  @click="changeTimeRange('week')" >Last week</button>
              </div>
              <div class="col-4">
                <button v-if="chart_period==='month'" class="btn btn-primary"  @click="changeTimeRange('month')">Last month</button>
                <button v-else class="btn btn-secondary"  @click="changeTimeRange('month')">Last month</button>

              </div>
              <div class="col-4">
                <button v-if="chart_period==='all'" class="btn btn-primary"  @click="changeTimeRange('all')">All time</button>
                <button v-else class="btn btn-secondary"  @click="changeTimeRange('all')">All time</button>

              </div>
            </div>
            <!-- <div class="row mt-2">
              <p><strong>Speech and voice quality monitoring</strong></p>
            </div> -->
            <div class="row mt-4">
              <p class="lead">
                Select a module to view:
              </p>
              <div>
                <select v-model="selected_module" class="form-select" id="selected_module">
                  <option v-for="module_v in Object.keys(results_by_module)" :value="module_v">{{module_v}}</option>
                </select>
              </div>
            </div>
            <div class="row mt-4" v-if="selected_module in results_by_module && Object.keys(results_by_module[selected_module]).length > 0">
              <p class="lead">
                Select a quality metric below to view:
              </p>
              <div>
                <div class="form-check form-check-inline m-2" v-for="(metric, index) in Object.keys(results_by_module[selected_module])">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" :id="index" :value="metric" v-model="selected_metric">
                  <label class="form-check-label" :for="index">{{ metrics_map[metric] }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>        
</template>


<script>
import {getData } from '@/firebase/index'
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import {getMetricPaths, agregateForPlot} from '@/utils/results/common'
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'
import {roundNumber} from '@/utils/utils'

export default {
  name: 'my_progress',
  components: {Footerbar, Navbar},
    data() {
      return {
        chart: null,
        chart_period: 'all',
        chart_period_table:{week:7, month:31, year:365, all:1000},

        units:{'loudness':' dB','intelligibility':'%','sentencegibility':'%','plot_results':'%','speechrate_dictionary':' syl/s'},

        all_modules: [],
        selected_module: "All modules",
        selected_metric:'intelligibility',

        result_dates_by_module: {},

        metrics_map: {
          "intelligibility": "Word Intelligibility",  
          "sentencegibility": "Sentence Intelligibility", 
          "speechrate_dictionary": "Speech Rate", 
          "loudness": "Loudness", 
          "ddk_rate_dictionary": "DDK rate",
          "plot_results": "% Correct Answers"
        },

        results_by_module: {},
        
        userCategory: localStorage.getItem("userCategory"),

        no_data: true
      }
    },

created() {
  this.loadGenericResults()
},
watch: {
  selected_metric(newMetric, oldMetric) {
    const _this = this
  
    _this.processAndCreateChart()
  },
  selected_module(newm, oldm) {

    if (this.results_by_module[newm].hasOwnProperty(this.selected_metric)) {
      this.processAndCreateChart()
    } else {
      this.selected_metric = Object.keys(this.results_by_module[newm])[0]
    }

  }
},
methods:
{
  loadGenericResults() {
    const _this  = this;
    
    let uid = localStorage.getItem('uid');
    
    let results_address = 'Users/' + uid + '/Results/training/general/' 
    
    this.all_modules = []
    this.all_modules.splice(0);
    this.all_modules = ['All modules']

    this.results_by_module['All modules'] = {}

    let do_once = true;
    
    getData(results_address, function (err, moduleParentLocation) {

      if(do_once)
      {
        do_once = false;

        moduleParentLocation.forEach(function(modulesBySingleDateTime)
        {
          let date = modulesBySingleDateTime.key

          let split_date = date.split(' ')

          date = split_date[0] //get only date not time
          
          ////Gets module name and date////////////////////////////////////
          for (const [key, module] of Object.entries(modulesBySingleDateTime.val())) {
            let module_name = key

            if (_this.result_dates_by_module[module_name]) {
              _this.result_dates_by_module[module_name].push(date)
            } else {
              _this.result_dates_by_module[module_name] = [date]
            }

            if (_this.result_dates_by_module['All modules']) {
              _this.result_dates_by_module['All modules'].push(date)
            } else {
              _this.result_dates_by_module['All modules'] = [date]
            }

            if (!_this.all_modules.includes(module_name)) {
              _this.all_modules.push(module_name)
            }      

            ////Gets metric name and date////////////////////////////////////
            for (let metric of Object.keys(_this.metrics_map)) {
              let exercise_parent_array = getMetricPaths(modulesBySingleDateTime.child(key), metric, _this)
            
              if (exercise_parent_array) { //something was found

                if (_this.results_by_module[module_name]) {
                  if (_this.results_by_module[module_name][metric]) {
                  } else {
                    _this.results_by_module[module_name][metric] = {}
                  }
                } else {
                  _this.results_by_module[module_name] = {}
                  _this.results_by_module[module_name][metric] = {}
                }
                
                if (_this.results_by_module['All modules'][metric]) {
                } else {
                  _this.results_by_module['All modules'][metric] = {}
                }

                if (!_this.results_by_module['All modules'][metric][date]) {
                  _this.results_by_module['All modules'][metric][date] = []
                }

                if (!_this.results_by_module[module_name][metric][date]) {
                  _this.results_by_module[module_name][metric][date] = []
                }

                for (let results_parent of exercise_parent_array) {
                  _this.results_by_module['All modules'][metric][date].push(results_parent)
                  _this.results_by_module[module_name][metric][date].push(results_parent)
                }
              }
            }
          }

        });

        _this.processAndCreateChart()
      }
    })
    
  },
  changeTimeRange(time)
  {
    const _this = this

    _this.chart_period = time

    _this.processAndCreateChart()
  },
  removeDataByPeriod(data)
  {
    const _this = this

    let data_cut = {}

    let range_days = _this.chart_period_table[_this.chart_period]
    

    let date = new Date();

    let year = date.getFullYear()
    let month = date.getMonth()
    let day = date.getDate()

    let today = new Date(year, month, day)

    for(let date in data)
    {
      let curr_date = new Date(date)

      const diffTime = Math.abs(today - curr_date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      if(diffDays <= range_days)
      {
        data_cut[date] = data[date]
      }
    }
    return data_cut
  },
  processAndCreateChart()
  {
    const _this = this;
    
    let data = _this.results_by_module[_this.selected_module][_this.selected_metric]
    data = _this.removeDataByPeriod(data)
    
    ////////////////////////////////////////////////////////////////
    let plot_results = agregateForPlot(data, _this.selected_metric)
    ////////////////////////////////////////////////////////////////

    if (this.chart) {
      let chartStatus = Chart.getChart(this.chart)
      if (chartStatus) {
        chartStatus.destroy()
      }
    }

    if (Object.keys(data).length === 0) {
      //alert("There are no results in this period")
      //return
    }

    if (plot_results.length > 0) {
      
      plot_results.sort(function(a, b) {
        let keyA = new Date(a.x)
        let keyB = new Date(b.x);
        // Compare the 2 dates
        if (keyA <= keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      let unit = ''
      let max = ''

      if(this.selected_metric in this.units)
      {
        unit = this.units[this.selected_metric]
        if(unit==='%')
        {
          max = 100
          
        }
      }

      console.debug('this.selected_metric = ' + this.selected_metric ) 

      const xValues = plot_results.map(a => a.x);
      const yValues = plot_results.map(a => a.y);

      if (_this.chart) {
        _this.chart.destroy()
        _this.chart = null
      }

      let ctx = document.getElementById("chartContainer").getContext("2d");
      
      _this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: xValues,
          datasets: [{
            label: _this.metrics_map[_this.selected_metric],
            backgroundColor:"rgba(0,0,255,1.0)",
            borderColor: "rgba(0,0,255,0.2)",
            borderWidth: "2",
            tension: 0.5,
            data: yValues
          }]
        },
        options: {
          scales: {
            x: {
              ticks: {
                source: 'auto'
              },
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'D'
                },
                tooltipFormat: 'D'
              }
            },
            y:{
              ticks:{
                callback: value => `${value+ unit} `
              }
            },
          },
        }
      });

      if(max!='')
      {
        _this.chart.options.scales.y.max = max
      }

      if(this.selected_metric==='speechrate_dictionary')
      {
        _this.chart.options.scales.y.ticks = {
          callback: value => `${roundNumber(value,2)+ unit} `

        }
      }

      _this.no_data = false
    } else {
      _this.no_data = true
    }

  },
}

}
</script>
<style scoped>

.custom-text{
  font-size:20px;
}
</style>





