<template>
<div class="d-flex flex-column min-vh-100">
  <Navbar  :enableBack=true></Navbar>
  
  <div class="container mb-5">
    <h1 class="display-5">Read longer text</h1>

    <div class="card text-center p-2">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="special-badge">
              </div>
              <div class="mask-t">
                <strong>1</strong>
              </div>
              <p class="lead"> Daily news (long)</p>
              <img :src="newsImg" style="width: 100%;margin-top:2%;">
              <button class="btn btn-primary btn-lg" @click="longNews()"> Start this exercise</button>
              <br/>
              <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('long_news')"> Exercise overview</button>
              <p> To choose news topics, update your <b style="color:#f56c6c;cursor:pointer" data-bs-toggle="modal" data-bs-target="#settingsModal" @click="checkSetting=true"> Topics of interest </b> </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6">
          <div class="card h-100">
            <div class="card-body">
              <p class="lead"> 2. Singing for voice and speech training</p>
              <img :src="karaokeImg" style="width: 100%;">    
              <button class="btn btn-primary btn-lg mt-2" @click="karaoke()"> Start this exercise</button>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div v-if="ifVoice" class="row mt-2">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <p class="lead"> 3. Talking in a pace</p>
              <img :src="paceImg" style="width: 100%;margin-top:2%;">
              <button class="btn btn-primary btn-lg" @click="paceTraining()"> Start the exercise</button>
            </div>
          </div>            
        </div>
      </div> -->
    </div>     
    
    <!-- <Posture_instructions/> -->

  </div>

  <!-- Modal -->
  <div v-show="checkSetting" class="modal fade" id="settingsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl" >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">My settings</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Patient_setting></Patient_setting>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="start_pop" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ module_title }}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div class="row justify-content-around">
            <div class="card card-body">
              <h5>{{ benfite_statement }}</h5>
            </div>
            
          </div>
          <div class="row justify-content-center">
            <Posture_instructions/>
          </div>
          <div class="row justify-content-center mt-4">
            <div class="col-3">
              <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
            </div>
            <div class="col-3">
              <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footerbar class="mt-auto"></Footerbar>
</div>
  
</template>
<script>
import newsImg from '@/assets/images/instructions/daily_news.png'
import karaokeImg from '@/assets/images/instructions/karaoke.jpg'

import {getIssueFlag} from '@/utils/utils'
import Patient_setting from '../../setting.vue'
import Posture_instructions from '../components/posture_instructions.vue'
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
  name: 'advanced_training',
  components: { Patient_setting, Footerbar, Posture_instructions, Navbar },
  props: 
  {
  },
  data(){
    return {
      userCategory:'',
      region:'',
      explanation:'',
      newsImg:newsImg,
      karaokeImg:karaokeImg,
      checkSetting:false,
      ifVoice:false,
      module_title:'',
      benfite_statement:'',
      module_cate:''
    }
  },
  created() {
    this.userCategory = localStorage.getItem('userCategory');
    this.region = localStorage.getItem('region');
    // this.explanation[0] = "Make sure you:\n - are about 40cm/16in away from the screen.\n - have your back upright.\n - have a glass water to sip throughout."
    // this.explanation[1] = "1. You will see a video on how to pronounce a certain sound, follow the video to pratice pronouncing the sound."
    // this.explanation[2] = "2. By clicking 'Next', you will then see a few videos showing word examples that contain such sound."
    // this.explanation[3] = "3. Listen to the video, press 'Start' to record your pronunciation."
    this.explanation = ""

    let issue_flag = getIssueFlag()
    this.ifVoice = issue_flag[2];
  },
  methods: {
    karaoke()
    {
      this.$router.push({ name: 'user_training', params: { blueprint_id: 'Karaoke' }});
    },
    longNews()
    {
      this.$router.push({ name: 'long_news' })
    },
    closePop()
    {
      this.checkSetting = false;
    },
    paceTraining()
    {
      this.$router.push({ name: 'pace_training' })
    },
    gotoBenefit(cate)
    {
      this.module_cate = cate;
      if(cate == 'long_news')
      {
        this.module_title = 'Daily news (long)'
        this.benfite_statement = 'News contains most phonemes (sounds) needed for practising articulation control. This exercise aims to help you improve your breathing and articulation control when speaking at length.'
      }
      else
      {
        console.log('unknown category = '+cate)
      }
      let start_pop = new Modal(document.getElementById('start_pop'));
      start_pop.show()
    },
    gotoModule()
    {
      if(this.module_cate == 'long_news')
      {
        this.longNews()
      }
      else
      {
        console.log('unknown category = '+this.module_title)
      }
    }
  },
}
</script>
<style scoped>
.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}
</style>