<template>
  <div>
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body"  style="border-color: blueviolet;">
          <div class="accordion" id="accordionCookieTheft">
            <div class="accordion-item mb-3">
              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie_theft_collapse" aria-expanded="false" aria-controls="cookie_theft_collapse"><p class="text-start" >
                  <b>Cookie theft</b></p></button>
              </div>
              <div :class="['accordion-collapse', 'collapse', show_collapse ? 'show': '']" id="cookie_theft_collapse" data-bs-parent="#accordionCookieTheft">
                <div class="col-md-6">Transcriptions</div>


                <div v-for="row,ind in table">

                <table class="table mt-4" style="text-align: left;">
        
                <tbody class="table-group-divider" >
                  <tr v-for="row, index in table[ind]" v-bind:key="index">
                    <td>
                      <strong class="editable" contenteditable="true"
                        v-for="(word, id) in row['all_words']" 
                        :key="id"
                        :style="'cursor: pointer;color:'+ row['all_words'][id]['colour'].toLowerCase()"
                      >
                        {{row['all_words'][id]['word']}}&nbsp;
                      </strong>

                    </td> 
                    </tr>
                    </tbody>
                </table>

                <div class="row" style="text-align: left;">
                  <div class="col-md-8">Summary</div>
                </div>
                <div class="row">
                  <div class="col">
                    <b>Subject</b>
                  </div>
                  <div class="col">
                    <b>Object</b>
                  </div>
                  <div class="col">
                    <b>Verb</b>
                  </div>
                </div>
                <div class="row">
                  <strong class="col" v-for="(dummy, word_type) in table[ind]['cookie_theft']['processed_info']" :key="word_type">
                    <p v-for="(dummy, word) in table[ind]['cookie_theft']['processed_info'][word_type]"
                      :style="'cursor: pointer;color:'+table[ind]['cookie_theft']['colour_code'][word_type].toLowerCase()">
                      {{ word }} = {{table[ind]['cookie_theft']['processed_info'][word_type][word]['count']  }}
                    </p>
                    <p :style="'cursor: pointer;color:'+table[ind]['cookie_theft']['colour_code'][word_type].toLowerCase()"></p>
                  </strong>
                </div>
                <tr class="table-group-divider"></tr>
                <div class="row" style="text-align: left;">
                  <div class="col-md-8">Total</div>
                </div>    
                <div class="row">
                  <strong class="col"
                    v-for="(dummy,word_type) in table[ind]['cookie_theft']['processed_info']" 
                    :key="word_type"                       
                  >
                    <p>
                      {{ Object.keys(table[ind]['cookie_theft']['processed_info'][word_type]).length }}
                    </p>
                  </strong>
                </div>

              </div>
                
              </div>
            </div> 
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>


export default {
  components: { 
  },
  name: 'Cookie_theft',
  props: ['cookieTheftOverview', 'table', 'show_collapse'],
  setup(props, {emit}) {

    return {

    }
  }
}

</script>
<style scoped>


</style>
