<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div v-show="show_break_page" class="card card-body h-100">
          <div class="row"> 
            <p class="h3"> Press Next to do more or press Repeat to do it again. </p>                
          </div>

          <div v-if="playback_audio" class="card card-body h-100">
            <p class="h3"> {{Ex_Text}}</p>
          </div>
          <div v-else class="card card-body h-100">
            <div class="text-center">
              <img class="text-center" :src="memeImg" width="425" height="325" style="object-fit: contain"><h1 class="carousel-caption">Well done!</h1>
            </div>
          </div>
          <div class="card card-body h-100">
            <div class="row mt-4">
              <p class="h4" v-if="last_loudness>0"> Loudness: {{ last_loudness }} dB</p>
              <p class="h4" v-if="seconds_passed>0 && showPhonation"> Phonation time: {{ seconds_passed }} seconds </p>
              <p class="h4" v-if="freq_range>0"> Frequency: {{ freq_range }} Hz, from {{ min_freq }} Hz to {{ max_freq }} Hz </p>
              <div class="text-center">
                <button  class='btn btn-outline-warning w-50 btn-lg mt-4' @click="goToResults()">See your results</button>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!show_break_page" class="card card-body h-100">
          <div>
            <div class="row">
              <p class="lead fs-4"> {{Ex_Description}}</p>
            </div>
            <div class="row">
              <div class="card card-body" v-if="Ex_Text!==''" >
                <div class="row">
                  <div class="col-12">
                    <div v-if="url2image!==''" class="float-lg-end" >
                      <img id= "img_small" v-bind:src="url2image" class="ms-2 rounded mx-auto d-block img-thumbnail shadow" style="height:150px">
                      <p><a class="link-opacity-75" v-bind:href="url" target="_blank"> <i class="bi bi-newspaper"></i> Read more of this news</a></p>
                    </div>
                    <p class="h3">{{Ex_Text}} 
                      <VTooltip>
                        <img v-if="tts_enabled" :src="audioImg" style="width:35px;cursor: pointer;" @click="readContentAloud()">
                        <template #popper>
                          Click to listen
                        </template>
                      </VTooltip>
                    </p>
                  </div>
                </div>
              </div> 
            </div>
    
            <div class="row mt-4" v-if="has_checkbox" >
              <div class="input-group justify-content-center">
                <div class="form-check">
                  <label class="form-check-label" for="checkboxid">Mark activity as completed</label>
                  <input class="form-check-input" type="checkbox" v-model="checkbox_done" id="checkboxid" :value="checkbox_done">
                </div>
              </div>
            </div>

            <div class="row mt-4"> <!--graphs / indicators-->
              <div class="row" v-if="hasVideoInstructions || hasAudioInstructions" id="video_instructions_div">
                <div id="video_placeholder"></div>
              </div>
            
              <div class="row justify-content-center" v-if="!(hasSPLMeter||hasSpectrogram)" id="meter-row" >
                <div class="col-6">
                  <canvas id="meter" height="30"></canvas>
                </div>
              </div>
              
              <div id="SPL-div" class="row justify-content-center align-items-center">
                <div class="col-md-6">
                  <canvas ref="loud_canvas" id="loud_canvas" width="400" height="180"></canvas>
                  <p>Your loudness graph <button type="button" class="btn btn-outline-success" @click="graph_instruction('loudness')">How to use <i class="bi bi-info-circle"></i></button></p>
                </div>
                
                <!-- <div class="col-md-3">
                  <div class="row">
                    <button type="button" class="btn btn-outline-success" @click="graph_instruction('loudness')">Graph instructions <i class="bi bi-info-circle"></i></button>
                  </div>
                  <div class="row mt-4">
                    <div v-if="hasSPLMeter">
                      <label class="form-label mt-2">Loudness target</label>
                      <select class="form-select mb-3" v-model="loud_target" placeholder="Select" style="width:100%"  @change="updateChart()">
                        <option key="2" label="60 dB - Quiet conversation" value=60> </option>
                        <option key="3" label="65 dB - Normal conversation" value=65> </option>
                        <option key="4" label="70 dB - Loud conversation" value=70> </option>
                      </select>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-md-3"> -->
                  <div v-if="showTimer" class="col-md-3">
                    <transition name="el-zoom-in-bottom">
                    <div v-if="hasTimer" height="100%" style="color: black;">
                      <ve-progress
                        :progress="progress"
                        :angle="-90"
                        :color="{
                          radial: false,
                          colors: [
                            {
                              color: 'blue',
                              offset: '0',
                              opacity: '1',
                            },
                            {
                              color: 'lime',
                              offset: '100',
                              opacity: '0.6',
                            },
                          ]
                        }"
                        emptyColor="#8ec5fc"
                        :size="140"
                        :thickness="5"
                        emptyThickness="5%"
                        lineMode="out-over"
                        :legend="seconds_passed"
                        :legend-formatter="({currentValue}) => currentValue + 's'"
                        legendClass="legend-custom-style"
                        dash="60 0.9"
                        animation="default 500 200"
                        :noData="false"
                        :loading="false"                      
                        fontColor="black"
                        dot="10 blue"
                        fontSize="25px">
                        
                        <span style="" slot="legend-value">s</span>
                        
                      </ve-progress>
                    </div>
                    </transition>
                  </div>
                <!-- </div> -->
              </div>
    
              <div id="Spect-div" class="row justify-content-center">
                
                <div class="col-md-6">
                  <canvas ref="spect_canvas" id="spect_canvas" width="400" height="200"></canvas> 
                  <p>Your pitch graph <button type="button" class="btn btn-outline-success" @click="graph_instruction('pitch')">How to use <i class="bi bi-info-circle"></i></button></p>
                </div>
                
                <!-- 
                <div class="col-md-3">
                  <div class="row" style="margin-top: 30mm;">
                    <button type="button" class="btn btn-outline-success" @click="graph_instruction('pitch')">Graph instructions <i class="bi bi-info-circle"></i></button>
                  </div>
                </div> 
                -->
              </div>
            </div>
          </div>

          <div class="modal fade" id="demo_video_pop" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Therapist demo</h4>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="row justify-content-around">
                    <div class="col-md-10">
                      <div class="card card-body h-100">
                        <div class="row" id="video_demo_instructions_div">
                          <div id="video_demo_placeholder"></div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-2">
                      <div class="card card-body h-100">
                        <p class="lead">Demo {{demo_video_index+1}} of {{demo_video_pool.length}}</p>

                        <button v-if="demo_video_index<(demo_video_pool.length-1)" class="btn btn-info w-100 mt-2" @click="nextDemoVideo()" >Next <i class="bi bi-skip-forward" style="margin-left: 4mm;"></i> </button>
                        <button class="btn btn-secondary w-100 mt-2"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                      </div>
                    </div>

                  </div>                  
                </div>
              </div>
            </div>
          </div>
          
          <div class="modal fade" id="graph_pop" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Instructions - {{ graph_type }} graph</h4>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div v-if="graph_type === 'loudness'">
                    <div class="row justify-content-center">
                      <img :src="loudness_graph_img" style="width:800px;margin-top:0.5%;">
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-md-8">
                        <ol class="list-group list-group-numbered" style="text-align: left;font-size: x-large;">
                          <li class="list-group-item">The <b style="color:rgb(15, 105, 26)">green</b> line shows your voice volume as you speak.</li>
                          <li class="list-group-item">The <b style="color:#f56c6c">red</b> line shows your volume target. Try to get your voice to the red line (target) as you speak.</li>
                          <li class="list-group-item">You can change the volume target at any time in the 'Settings' tab.</li>
                          <li class="list-group-item">The 'Settings' tab can be found on the top right corner of the page.</li>

                        </ol>
                      </div>
                      
                    </div>
                  </div>
                  <div v-else class="row justify-content-around">
                    <div class="col-md-6">
                      <img :src="spectrogram_graph_img" style="width:450px;margin-top:0.5%;">
                    </div>
                    <div class="col-md-6">
                      <ol class="list-group list-group-numbered" style="text-align: left;font-size: x-large;">
                        <li class="list-group-item">The <b style="color:rgb(15, 105, 26)">green</b> line shows your pitch (speech frequency) as you speak.</li>
                        <li class="list-group-item">Try to increase the range of your pitch (difference between lowest and highest values) while maintaining a loud and clear voice.</li>
                        <li class="list-group-item">Voice quality is more important than the pitch range.</li>
                      </ol>
                    </div>
                  </div>
                  
                  <div class="row justify-content-center mt-4">
                    <div class="col-3">
                      <button class="btn btn-success w-100"  data-bs-dismiss="modal" aria-label="Close">I understand</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100" 
          :current_exercise_index = "current_exercise_index" 
          :total_exercises = "total_exercises"
          :has_demo_video = "demo_video_pool && demo_video_pool.length > 0"
          :audioCaptureOn = "audioCaptureOn"
          :break_page = "show_break_page"
          :mic_on = "is_recording"
          :has_listen_back = "true"
          :has_repeat = "true"
          :has_next = "true"
          @next = "noAudioCaptureNext()"
          @started = "start_stop()"
          @stopped = "start_stop()"
          @toggled_background_noise = "()=>{}"
          @toggled_demo_video = "gotoVideoDemo()"
          @repeat = "repeatExerciseModal()"
          @listen_back = "getBufferCallback()"
          >
        </Sidebar>
      </template>
    </Layout>

  </div>
</template>
  
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import audioImg from '@/assets/images/audio_logo.png'
import constants from '@/utils/constants'
import loudness_graph_img from '@/assets/images/instructions/loudness_graph_instruction2.png'
import spectrogram_graph_img from '@/assets/images/spectrogram_graph_img.png'
import { get_last_recording, checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia } from '@/media_input/microphone_recording'
import smoothie from 'smoothie'
import { removeWeirdCharactersFromString,removeQuotesAndSimilarForAnalysis } from '@/media_input/utils'
import { updateDataDirectory } from '@/firebase/index'
import { logActivity} from '@/media_input/utils'

import { useTTS } from '@/stores/tts'

import { getDifficulty,updateLevel } from '@/utils/session/levels/level_engine'
import {toWords} from '@/utils/utils'

import { BV_Points } from '@/utils/bv_points'
import { generate_results_path, save_pre_computed_results } from '@/utils/results/data_save_utils'
import { Modal } from 'bootstrap'

/*
{
   "Video_Address":"/video_instructions/loudness/warm_up/MayMeMyMoeMum.mp4",
   "Video_Instructions":true,
   "capture_audio":false,
   "instruct":"Watch the video and follow the instructions. Then press Next.",
   "name":"MayMeMyMoeMum example",
   "text":"",
   "plan_name":"MayMeMyMoeMum",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"MayMeMyMoeMum//Exercises/General/PD_Intensive_EN/Video_Instructions/Words/MayMeMyMoeMum",
   "ex_path":"MayMeMyMoeMum//Exercises/General/PD_Intensive_EN/Video_Instructions/Words/MayMeMyMoeMum",
   "today":"2023-07-11 16:48",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":"/video_instructions/loudness/warm_up/MayMeMyMoeMum.mp4",
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":true,
   "exercise_type":null,
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"",
   "Ex_Description":"Watch the video and follow the instructions. Then press Next.",
   "module_name":"MayMeMyMoeMum",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":1
}

{
   "Video_Address":"/video_instructions/breathing/Breathing exercise with counting.mp4",
   "Video_Instructions":true,
   "capture_audio":false,
   "instruct":"Watch the video and follow the instructions. Then press Next.",
   "name":"Breathing Exercise Instruction",
   "text":"",
   "plan_name":"Breathing",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Breathing//Exercises/General/Video_Instructions/BreathingInstruction",
   "ex_path":"Breathing//Exercises/General/Video_Instructions/BreathingInstruction",
   "today":"2023-07-11 16:49",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":"/video_instructions/breathing/Breathing exercise with counting.mp4",
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":true,
   "exercise_type":null,
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"",
   "Ex_Description":"Watch the video and follow the instructions. Then press Next.",
   "module_name":"Breathing",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":2
}


{
   "Video_Address":"/video_instructions/loudness/pitch/Pitch_exercise.mp4",
   "Video_Instructions":true,
   "capture_audio":false,
   "instruct":"Watch the video instructions, then press Next.",
   "name":"Pitch instructions",
   "text":"",
   "plan_name":"Pitch variation",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Pitch variation/Exercises/General/PD_Intensive_EN/Video_Instructions/Sounds/Pitch_instructions/",
   "ex_path":"Pitch variation/Exercises/General/PD_Intensive_EN/Video_Instructions/Sounds/Pitch_instructions/",
   "today":"2023-07-11 16:50",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":"/video_instructions/loudness/pitch/Pitch_exercise.mp4",
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":true,
   "exercise_type":null,
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"",
   "Ex_Description":"Watch the video instructions, then press Next.",
   "module_name":"Pitch variation",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

{
   "Video_Address":"/video_instructions/loudness/ah/Ah_full.mp4",
   "Video_Instructions":true,
   "capture_audio":false,
   "instruct":"Watch the video and follow the instructions. Then press Next.",
   "name":"Ah full exercise instructions",
   "text":"",
   "plan_name":"Phonation time",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Phonation time//Exercises/General/PD_Intensive_EN/Video_Instructions/Sounds/Ah_full",
   "ex_path":"Phonation time//Exercises/General/PD_Intensive_EN/Video_Instructions/Sounds/Ah_full",
   "today":"2023-07-11 16:51",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":"/video_instructions/loudness/ah/Ah_full.mp4",
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":true,
   "exercise_type":null,
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"",
   "Ex_Description":"Watch the video and follow the instructions. Then press Next.",
   "module_name":"Phonation time",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

{
   "name":"Toad/Thai/Prevalence/Governed/Poo/-0",
   "instruct":"Press Start. Read the following word with a loud and clear voice and speech. Press Next.",
   "text":"Toad",
   "assist":[
      "spm_live"
   ],
   "voicetype":[
      "spm"
   ],
   "speechtype":[
      "intelligibility"
   ],
   "module_name":"Words",
   "curr_exercise_path":"word_list/",
   "assessment_type":"SLP_TRAINING",
   "today":"2023-07-11 16:52",
   "plan_name":"Words",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

{
   "Description":"It claimed it been transferred, and asked me to set up a direct debit. ",
   "Title":"HSBC set off a scam alert over my John Lewis Partnership credit card",
   "Type":"article",
   "Url":"https://www.theguardian.com/money/2023/jul/11/hsbc-set-off-a-scam-alert-over-my-john-lewis-partnership-credit-card",
   "Urltoimage":"https://i.guim.co.uk/img/media/059533f0d0c63b4a52745fe4d004aee6cdf77e06/0_280_6048_3628/master/6048.jpg?width=465&dpr=1&s=none",
   "assist":[
      "spm_live"
   ],
   "exercise_type":"basic_module",
   "instruct":"Press Start. Read the following news with a loud and clear voice and  speech. Press Next.",
   "name":"HSBC set off a scam alert over my John Lewis Partnership credit card",
   "speechtype":[
      "sentencegibility"
   ],
   "text":"HSBC set off a scam alert over my John Lewis Partnership credit card. \n It claimed it been transferred, and asked me to set up a direct debit. ",
   "plan_name":"News (~30 words)",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"News (~30 words)/9",
   "ex_path":"News (~30 words)/9",
   "today":"2023-07-11 16:56",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"basic_module",
   "url":"https://www.theguardian.com/money/2023/jul/11/hsbc-set-off-a-scam-alert-over-my-john-lewis-partnership-credit-card",
   "url2image":"https://i.guim.co.uk/img/media/059533f0d0c63b4a52745fe4d004aee6cdf77e06/0_280_6048_3628/master/6048.jpg?width=465&dpr=1&s=none",
   "audioIns":null,
   "Ex_Text":"HSBC set off a scam alert over my John Lewis Partnership credit card. \n It claimed it been transferred, and asked me to set up a direct debit. ",
   "Ex_Description":"Press Start. Read the following news with a loud and clear voice and  speech. Press Next.",
   "module_name":"News (~30 words)",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

{
   "name":" Dogs can understand about 165 words and gestures./ Dogs have a sense of time, expecting walks and events at certain times./ Dogs sweat through their paws, which helps them stay cool in hot weather./ Dogs can read human facial expressions./ Dogs have three eyelids, one in the corner of each eye and a third one that protects the eyes from debris./-0",
   "instruct":"Press Start. Read the following sentence loudly and clearly. Press Stop.",
   "text":" Dogs can understand about 165 words and gestures.",
   "assist":[
      "spm_live"
   ],
   "voicetype":[
      "spm"
   ],
   "speechtype":[
      "sentencegibility"
   ],
   "module_name":"Fun Facts",
   "curr_exercise_path":"word_list/",
   "assessment_type":"SLP_TRAINING",
   "today":"2023-07-11 16:57",
   "plan_name":"Fun Facts",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}


{
   "name":"beatles-0",
   "instruct":"Press Start. Read the following paragraph with a loud and clear voice and speech. Press Next.",
   "text":"The Beatles are a British rock band formed in Liverpool in 1960. Considered by many to be the most influential and, in the opinion of some critics, the greatest band in the history of pop culture, the Beatles revolutionized pop music and changed the face of modern music. From their early days, the Beatles created a sound by combining elements of existing musical genres, including rock and roll, blues, country and folk. The band's lyrics often explored contemporary issues and were often full of wit and artistry.",
   "assist":[
      "spm_live"
   ],
   "voicetype":[
      "spm"
   ],
   "speechtype":[
      "sentencegibility"
   ],
   "module_name":"Short Paragraph",
   "curr_exercise_path":"short_paragraph/",
   "assessment_type":"SLP_TRAINING",
   "today":"2023-07-11 16:58",
   "plan_name":"Short Paragraph",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":3
}

{
   "assist":[
      "spm_live"
   ],
   "exercise_type":"basic_module",
   "instruct":"Press Start. Read the following tongue twister with a loud and clear voice and speech. Press Next.",
   "speechtype":[
      "sentencegibility"
   ],
   "text":"Rory the warrior and Roger the worrier were reared wrongly in a rural brewery.",
   "plan_name":"Tongue twisters",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Tongue twisters/brewery",
   "ex_path":"Tongue twisters/brewery",
   "today":"2023-07-11 16:58",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"basic_module",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"Rory the warrior and Roger the worrier were reared wrongly in a rural brewery.",
   "Ex_Description":"Press Start. Read the following tongue twister with a loud and clear voice and speech. Press Next.",
   "module_name":"Tongue twisters",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

{
   "assist":[
      "spm_live"
   ],
   "exercise_type":"basic_module",
   "instruct":"Press Start. Read the following joke with a loud and clear voice and  speech. Press Next.",
   "speechtype":[
      "sentencegibility"
   ],
   "text":"Why do elephants never use computers?\n\n They are afraid of the mouse.\n\n\n",
   "plan_name":"Jokes",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Jokes/joke_37",
   "ex_path":"Jokes/joke_37",
   "today":"2023-07-11 16:59",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"basic_module",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"Why do elephants never use computers?\n\n They are afraid of the mouse.\n\n\n",
   "Ex_Description":"Press Start. Read the following joke with a loud and clear voice and  speech. Press Next.",
   "module_name":"Jokes",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}


{
   "Video_Address":"/video_instructions/articulation_words/iy/peas.mp4",
   "Video_Instructions":true,
   "instruct":"Watch the video instructions. Press Start. Say the following word. Press Next",
   "name":"peas",
   "speechtype":[
      "intelligibility"
   ],
   "text":"peas",
   "plan_name":"Phonemes",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Phonemes/5",
   "ex_path":"Phonemes/5",
   "today":"2023-07-11 17:00",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":"/video_instructions/articulation_words/iy/peas.mp4",
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":true,
   "exercise_type":null,
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"peas",
   "Ex_Description":"Watch the video instructions. Press Start. Say the following word. Press Next",
   "module_name":"Phonemes",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
  export default {
      name: 'multi_word',
      props: {
        exForm: {
          type: Object,
        },
      },
      components: { Layout, Sidebar },
      data() {
        return {
          stop_sign_text:'Stop if your voice is strained',
          video_url:null,
          video_player:null,
          showPhonation:false,
          phonationOn:false,
          show_break_page:false,
          demo_video_pool:[],
          demo_video_index:0,
          speak_numbers_data:{'level':1,'streak':0,'max_level':10,'streak_target':7,'base_level_address':'/Levels/SpeakNumbers'},
          memeImg:false,
          background_noise_checked:true,
          playback_audio:false,
          freq_range:0,
          max_freq:0,
          min_freq:0,
          hasTimer: false,
          seconds_passed: 0,
          showTimer:false,
          frequency_data:[],
          progress: 0,
          maxdBValue:80,
          mindBValue:40,
          hasVideoInstructions: false,
          hasAudioInstructions: false,
          video_instructions_address: '',
          audio_instructions_address: '',
          audio_player:null,
          audio_player_text:'',
          audio_player_status:false,
          background_audio_location: null,
          background_audio_url:null,
          audioImg: audioImg,
          big_text: true,
          sound_l:'',
          sound_l2:'',
          chart_data: null,
          chart_data_spect: null,
          smoothie_spect: null,
          smoothie: null,
          Ex_Text: '',    
          Ex_Description: [],
          avg_loudness: 0,
          max_loudness:0,
          avg_loudness_show: 0,
          hasSPLMeter: false,
          last_loudness: 0,
          total_loudness: 0,
          counter_loudness: 0,
          audioCaptureOn: true,
          hasSpectrogram: false,
          chart_not_created: true,
          chart_not_created_spect: true,
          canvasContext: null,
          tts_enabled: true,
          play_status: false,
          ground_truth:undefined,
          current_exercise_index: 0,
          total_exercises: 1,
          modules_results_path: null,
          resultsTableBack: [],
          has_checkbox: false,
          checkbox_done: false,
          is_recording: false,
          url:'',
          url2image:'',
          userCategory: 'patient',
          client_number: null,
          uid: localStorage.getItem('uid'),
          loud_target:65,

          timerID: 0,
          bv_tts: useTTS().tts_engine,
          bv_points: new BV_Points(),
          region: localStorage.getItem('region'),
          loudness_graph_img:loudness_graph_img,
          spectrogram_graph_img:spectrogram_graph_img,
          graph_type:'loudness',
        }
      },
      created() {
        let _this = this;
        
        checkPermissionsMedia(); 
        _this.getExercise();
        
        logActivity( 'accessed', 'multi_word')
        console.debug('_this.exForm = '  + _this.exForm)
  
        _this.userCategory = localStorage.getItem('userCategory');
  
        if (this.userCategory=='slp') {
          let client_number = localStorage.getItem('client_uid');

          if (client_number != 'undefined' && client_number != null && client_number != '') {
            _this.client_number = client_number; 
          }
        }
          
      },
      computed: {
        Ex_Description_Split: function () {
          return this.Ex_Description.toString().split('\n')
        },
        Ex_Text_Split: function() {
    
          console.debug('this.Ex_Text = ' + this.Ex_Text)
    
          let split_text =  this.Ex_Text.toString().split('\n')
      
          console.debug('split_text = ' + split_text)
      
          console.debug('split_text.length = ' + split_text.length)
          
          if(split_text.length<=1) {
            this.big_text = false
          } else {
            this.big_text = true
          }
    
          console.debug('this.big_text =  ' + this.big_text)
    
          return split_text
        },
    },
    beforeUnmount() {
        
      const _this = this

      this.bv_tts.cancel()

      clearInterval(window.refreshIntervalId)

      if(_this.is_recording) {
        _this.endRecordingAudio(true);
      } else {
        _this.endRecordingAudio(false);
      }

      deleteSoundMeter()

      if (_this.audio_player != undefined && _this.audio_player != null) {
        if (!_this.audio_player.paused) {
          _this.audio_player.pause();
        }
      }

      if(_this.video_player != undefined && _this.video_player != null) {
        if(!_this.video_player.paused)
        {

          _this.video_player.pause();
        }
      }
      
      const backdrop = document.querySelector('.modal-backdrop')
      if (backdrop) {
        backdrop.remove();
      }

    },
    mounted() {
      
      let loudness_target = localStorage.getItem('loudness_target')

      if (loudness_target != undefined && loudness_target != null) {
        loudness_target = parseInt(loudness_target)

        if (!isNaN(loudness_target)) {
          this.loud_target = loudness_target
        }
      }

      this.renderGraphs()
      
      if ('background_audio_location' in this.exForm) {

        let temp_background_noise_checked = sessionStorage.getItem("in_noise_background_noise")

        if (temp_background_noise_checked!=undefined && temp_background_noise_checked!=null) {
          if (temp_background_noise_checked==='false') {
            this.background_noise_checked = false
          } else {
            this.background_noise_checked = true
          }

        } else {
          this.background_noise_checked = true
        }

        let background_audio_location_array = this.exForm['background_audio_location']
        this.background_audio_location = background_audio_location_array[[Math.floor(background_audio_location_array.length * Math.random())]]

        this.loadBackgroundAudio()        
      }

    },
    methods: 
    {
      graph_instruction(type)
      {
        if(type == 'loudness') {
          this.graph_type = 'loudness'
        } else if(type == 'pitch') {
          this.graph_type = 'pitch'
        } else {
          console.log('unknown graph type = '+type)
        }
        let graph_pop = new Modal(document.getElementById('graph_pop'));
        graph_pop.show()
      },
      gotoVideoDemo()
      {
        const _this = this

        let demo_video_pop = new Modal(document.getElementById('demo_video_pop'));
        demo_video_pop.show()

        if(_this.audio_player!=undefined && _this.audio_player!=null) {
          if(!_this.audio_player.paused) {
            _this.audio_player.pause();
          }
        }

        this.demo_video_index = 0

        this.playDemoVideoInstructions()

        //to be programmed - for showing demo examples 1,2,3
      },
      nextDemoVideo()
      {
        this.demo_video_index = this.demo_video_index+1

        this.playDemoVideoInstructions()
      },
      playDemoVideoInstructions()
      {
        const _this = this

        let curr_video = this.demo_video_pool[this.demo_video_index]


        let demo_video_instructions_address = curr_video["Video_Address"]

        let storage = _this.$firebase.storage();

        storage.ref(demo_video_instructions_address).getDownloadURL()
          .then((video_url) => {
            document.getElementById('video_demo_placeholder').innerHTML='<video  id="movie_demo" src="'+video_url+'" height="380px" width="600px" controls autoplay> </video>';
            _this.video_player =document.getElementById('movie_demo');
            _this.video_player.load();
            

            console.debug('here1')

            document.getElementById('demo_video_pop').addEventListener('hidden.bs.modal', function (event) {
              
              console.debug('here2')

              if(_this.video_player!=undefined && _this.video_player!=null)
              {
                if(!_this.video_player.paused)
                {
                  _this.video_player.pause();
                }
              }  
                // do something...
            })

          })
          .catch((error) => {
            // Handle any errors
          });   
      },
      getBufferCallback(  ) {

        this.playback_audio = true

        let blob = get_last_recording()

        this.audio_player = new Audio(URL.createObjectURL(blob));

        this.audio_player.play()        
      },
      renderGraphs() {
        const _this = this
            
        if (_this.hasSPLMeter) {
          document.getElementById("SPL-div").hidden = false
          
          _this.createChart()
        } else {
          document.getElementById("SPL-div").hidden = true
        }

        if (_this.hasSpectrogram) {
          document.getElementById("Spect-div").hidden = false
          
          _this.createChartSpect()
        } else {
          document.getElementById("Spect-div").hidden = true
        }
      },
      getExercise() {
        const _this = this;
        
        if (_this.is_recording) {
          // _this.endRecordingAudio(true)
        }

        _this.pause();

        // this.exForm.exercise_type='speak_numbers'

        if(this.exForm.exercise_type==='speak_numbers') {
          _this.loadSingleExercise()
          _this.loadSpeakNumberExercise()
        } else {
          _this.loadSingleExercise()
        }

      },
      async loadSpeakNumberExercise() {

        let base_level_address = this.speak_numbers_data.base_level_address

        let levels_output = await getDifficulty(base_level_address)

        this.speak_numbers_data.level = levels_output[0]
        this.speak_numbers_data.streak = levels_output[1]

        let level_properties = {
            1:{'level':1,'n_digits':1, 'round_number':-1, 'numeral':false},

            2:{'level':2,'n_digits':2, 'round_number':1,'numeral':false},
            3:{'level':3,'n_digits':2, 'round_number':-1,'numeral':false},

            4:{'level':4,'n_digits':1, 'round_number':-1,'numeral':true},
            
            5:{'level':5,'n_digits':2, 'round_number':1, 'numeral':true},
            6:{'level':6,'n_digits':2, 'round_number':-1, 'numeral':true},

            7:{'level':7,'n_digits':3,'round_number':2, 'numeral':false},
            8:{'level':8,'n_digits':3,'round_number':1, 'numeral':false},
            9:{'level':9,'n_digits':3,'round_number':-1, 'numeral':false},


            10:{'level':10,'n_digits':4,'round_number':3, 'numeral':false},
            11:{'level':11,'n_digits':4,'round_number':2, 'numeral':false},
            12:{'level':12,'n_digits':4,'round_number':1, 'numeral':false},
            13:{'level':13,'n_digits':4,'round_number':-1, 'numeral':false},
          
            14:{'level':14,'n_digits':3,'round_number':2, 'numeral':true},
            15:{'level':15,'n_digits':3,'round_number':1, 'numeral':true},
            16:{'level':16,'n_digits':3,'round_number':-1, 'numeral':true},


            17:{'level':17,'n_digits':4,'round_number':3, 'numeral':true},
            18:{'level':18,'n_digits':4,'round_number':2, 'numeral':true},
            19:{'level':19,'n_digits':4,'round_number':1, 'numeral':true},
            20:{'level':20,'n_digits':4,'round_number':-1, 'numeral':true},
          }


        this.speak_numbers_data['level_properies'] = level_properties
        this.speak_numbers_data['max_level'] = Object.keys(level_properties).length


        if (this.speak_numbers_data.level<1) {
          this.speak_numbers_data.level = 1
        }

        if (this.speak_numbers_data.level>Object.keys(level_properties).length) {
          this.speak_numbers_data.level = Object.keys(level_properties).length
        }

        let n_digits = level_properties[this.speak_numbers_data.level]['n_digits']
        
        let numeral = level_properties[this.speak_numbers_data.level]['numeral']

        let round_number = level_properties[this.speak_numbers_data.level]['round_number']

        this.createSpeakNumberExercise(n_digits,numeral,round_number)
      },
      createSpeakNumberExercise(n_digits,numeral,round_number)
      {
        let number = ''

        console.debug('n_digits = ' + n_digits)
        console.debug('round_number = ' + round_number)

        if (n_digits>1) {
          let random_easier = Math.floor(Math.random() * 10);

          if (random_easier>=5) {
            n_digits = n_digits-1
            round_number = round_number-1 
          }
        }

      
        for(let k=0;k<n_digits;++k) {

          let random_number = 0

          if (round_number <= -1 || n_digits-k-1>=round_number) {
            random_number = Math.floor(Math.random() * 10);
            if (k==0 && random_number==0) {
              random_number = 1
            }
          }
          number = number+random_number 
        }

        console.debug('number = ' + number)

        if (numeral) {
          this.Ex_Text = number
          this.Ex_Text_Split = number      

          this.exForm.name = number

        } else {
          this.Ex_Text = toWords(number)
          this.Ex_Text_Split = toWords(number)
        }

        this.ground_truth = toWords(number)

        if (this.ground_truth.split(' ').length>1) {
          this.exForm.speechtype[0] = 'sentencegibility'
        }

        console.debug('this.Ex_Text = ' + this.Ex_Text)
      },
      repeatExerciseModal() {
        const _this = this

        _this.show_break_page = false
        let avg_loudness_array = []

        this.playback_audio = false

        if (_this.audio_player != undefined && _this.audio_player != null) {
          if (!_this.audio_player.paused) {
            _this.audio_player.pause();
          }
        }

        if (_this.video_player != undefined && _this.video_player != null) {
          if (!_this.video_player.paused) {
            _this.video_player.pause();
          }
        }

        if (_this.avg_loudness > 0) {
          avg_loudness_array.push(_this.avg_loudness)
        }

        if (_this.background_audio_url != null) {
          _this.playBackgroundAudio()
        }

        _this.stopTimer()

        _this.avg_loudness = 0;
        _this.total_loudness = 0;
        _this.counter_loudness = 0;
        _this.max_loudness = 0

        _this.play_status = false
      },
      stopTimer() {
        let _this = this

        _this.hasTimer = false
        _this.seconds_passed = 0
        _this.progress = 0

        _this.avg_loudness = 0;
        _this.total_loudness = 0;
        _this.counter_loudness = 0;

        if (_this.is_recording) {
          _this.is_recording = false
          _this.endRecordingAudio(true)
        }

        _this.max_loudness = 0

        clearInterval(window.refreshIntervalId)
        clearInterval(_this.timerID)
      }, 
      loadSingleExercise() {          
        const _this = this

        _this.demo_video_pool = _this.exForm.demo_video_pool

        console.debug('therapist_demo here')

        if ('therapist_demo' in _this.exForm) {
          console.debug('therapist_demo found')

          _this.demo_video_pool = _this.exForm.therapist_demo

          console.debug('_this.demo_video_pool = ' + _this.demo_video_pool)
        }

        _this.Ex_Text = _this.exForm.text; 
      
        _this.tts_enabled = true

        let voicetype = _this.exForm.voicetype

        if (voicetype != undefined && voicetype != null) {
          if (Array.isArray(voicetype) && voicetype.includes('max_phonation')) {
            _this.showPhonation = true
            _this.showTimer = true
          }
        }

        if (_this.Ex_Text==undefined || _this.Ex_Text==null) {
          _this.tts_enabled = false
        } else {
          let list_tts_fail_words = ['phoneme','/ah/','/eh/','/ih/','/oh/','/uh/']

          let ex_text_lower = _this.Ex_Text
          ex_text_lower = ex_text_lower.toLowerCase()

          for (let i in list_tts_fail_words) {
            if (ex_text_lower.includes(list_tts_fail_words[i])) {
              _this.tts_enabled = false
              break
            }
          }
        }

        if (_this.exForm.image_address!=undefined && _this.exForm.image_address!=null && _this.exForm.image_address!='') {
          _this.showimage(_this.exForm.image_address)
        }

        _this.Ex_Description = _this.exForm.instruct;

        _this.current_exercise_index = _this.exForm.current_exercise_index + 1
        _this.total_exercises = _this.exForm.total_all_type_exercises

        let url = _this.exForm.url
        let url2image = _this.exForm.url2image

        if (url!=undefined && url!=null) {
          _this.url = url
        }

        if (url2image!=undefined && url2image!=null) {
          _this.url2image = url2image
        }

        _this.Ex_Description = removeWeirdCharactersFromString(_this.Ex_Description)
        
        _this.Ex_Text = removeWeirdCharactersFromString(_this.Ex_Text)


        if (_this.exForm.search_img!=undefined && _this.exForm.search_img!=null && _this.exForm.search_img!='') {
          _this.imgSearchWord(_this.Ex_Text)
        }

        if (_this.exForm.sound_autoplay!=undefined && _this.exForm.sound_autoplay!=null && _this.exForm.sound_autoplay==true) {
          setTimeout(() => {
            _this.readContentAloud()
          }, 750)
        }
        
        let captureAudio = _this.exForm.capture_audio
        if (captureAudio != undefined && captureAudio != null) {
          _this.audioCaptureOn = captureAudio
        }

        console.debug('_this.exForm.stop_sign_text = ' + _this.exForm.stop_sign_text)

        if (_this.exForm.stop_sign_text) {
          _this.stop_sign_text = _this.exForm.stop_sign_text
          console.debug('_this.stop_sign_text = ' + _this.stop_sign_text)
        } else {
          if (!_this.audioCaptureOn) {
            _this.stop_sign_text = 'Stop if your muscles are strained'
          }
        }

        let has_checkbox = _this.exForm.checkbox

        if (has_checkbox != undefined && has_checkbox != null) {
          _this.has_checkbox = has_checkbox
        }

        if (_this.Ex_Text == undefined || _this.Ex_Text == null ) {
          _this.Ex_Text = ""
        }
        if (_this.Ex_Description == undefined || _this.Ex_Description == null ) {
          _this.Ex_Description = ""
        }

        _this.checkAudioVideoInstructions()
        _this.checkAssistance()
      },
      readContentAloud() {
        const _this = this

        if (_this.Ex_Text.length>200) {
          let array_text = _this.Ex_Text.split('.')
          _this.bv_tts.say_sentences(array_text, 75)
        } else {
          _this.bv_tts.say(_this.Ex_Text)
        }
      },
      imgSearchWord(word)
      {
        const _this = this
      
        let CSE_ID = '0a00ce12c9207f97c'
        let API_KEY = 'AIzaSyAyaTunYyJEdu_QbzvBN_xMJYmRDMWB_sc'

        const Http = new XMLHttpRequest();
        const url='https://customsearch.googleapis.com/customsearch/v1?cx='+CSE_ID+'&key='+API_KEY+'&q='+word+'&searchType=image&imgType=clipart&page=1&num=1&fields=items(image/thumbnailLink)'

        console.debug(url)
        Http.open("GET", url);
        Http.send();

        Http.onreadystatechange = (e) => {

          let json_response = JSON.parse(Http.response)

          if(json_response['items'].length>0)
          {
            _this.url2image = json_response['items'][0]['image']['thumbnailLink']
          }
        }

        // const imageSearch = require('image-search-google');

        // const client = new imageSearch(CSE_ID, API_KEY);

        // window.setImmediate = window.setTimeout
        // const options = {page:1,num:1};


        // client.search(word, options).then(images => {

        //   if(images!=undefined && images!=null && images.length>0)
        //   {
        //     _this.url2image = images[0]['thumbnail']
        //   }


        //   /*
        //   [{
        //       'url': item.link,
        //       'thumbnail':item.image.thumbnailLink,
        //       'snippet':item.title,
        //       'context': item.image.contextLink
        //   }]
        //   */
        // }).catch(error => console.debug('error = ' + error));

      },
      checkAudioVideoInstructions()
      {
        const _this = this

        console.debug('_this.exForm.videoIns = ' + _this.exForm.videoIns)

        let hasVideoInstructions = _this.exForm.videoIns;

        if(hasVideoInstructions!=undefined && hasVideoInstructions!=null) {
          _this.hasVideoInstructions = hasVideoInstructions;
        } else {
          _this.hasVideoInstructions = false;

          if (_this.exForm.Video_Address!=undefined && _this.exForm.Video_Address!=null) {
            _this.hasVideoInstructions = true
            _this.exForm.videoAddress = _this.exForm.Video_Address
          }
        }

        let audioIns = _this.exForm.audioIns

        if(audioIns!=undefined & audioIns!=null) {
          _this.hasAudioInstructions = true;
        } else {
          _this.hasAudioInstructions = false;
        }

        if(_this.hasVideoInstructions) {
          _this.video_instructions_address = _this.exForm.videoAddress;

          console.debug('_this.video_instructions_address = ' + _this.video_instructions_address)
          _this.watchVideoInstructions()
        } else if(_this.hasAudioInstructions) {
          _this.audio_instructions_address = audioIns
          _this.watchVideoInstructions()
        }

      },
      watchVideoInstructions()
      {
        const _this = this;

        if (_this.is_recording) {
          _this.is_recording = false
          _this.endRecordingAudio(false);
        }
        
        if (_this.hasVideoInstructions) {
          _this.is_recording = false;
      
          var storage = _this.$firebase.storage();

          storage.ref(_this.video_instructions_address).getDownloadURL()
            .then((url) => {
              _this.video_url = url
              _this.playVideoInstructions(url);
            })
            .catch((error) => {
              // Handle any errors
            });
        } else if(_this.hasAudioInstructions) {

          // document.getElementById('ex_div').hidden = true;

          // document.getElementById('mic_dist_div').hidden = true;

          // document.getElementById('video_instructions_div').hidden = false;

          stopRecordingMedia(null,null,null);
          
          clearInterval(window.refreshIntervalId);
  
          _this.drawLoop(0);

          _this.is_recording = false;
      
          let storage = _this.$firebase.storage();

          storage.ref(_this.audio_instructions_address).getDownloadURL()
            .then((url) => {
              _this.playAudioInstructions(url);
            })
            .catch((error) => {
              // Handle any errors
            });
        }
      },
      playVideoInstructions(url) {      
        console.debug('url = ' + url)
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="380px" width="600px" controls autoplay> </video>';
        this.video_player = document.getElementById('movie');
        this.video_player.load();
      },
      checkAssistance() {
        const _this = this

        let assistance = _this.exForm.assist

        _this.hasSPLMeter = false;

        if (assistance!=null) {
          console.debug('assistance = ' + assistance)
          
          if(Array.isArray(assistance) && assistance.includes('spm_live')) {
            _this.hasSPLMeter = true;
          } else if('spm_live' in assistance) {
            _this.hasSPLMeter = true;
          }

          if(Array.isArray(assistance) && assistance.includes('spl_live')) {
            _this.hasSPLMeter = true;
          } else if('spl_live' in assistance) {
            _this.hasSPLMeter = true;
          }

          if(Array.isArray(assistance) && assistance.includes('spectrogram')) {
            _this.hasSpectrogram = true;
          } else if('spl_live' in assistance) {
            _this.hasSpectrogram = true;
          }
        }

        // _this.hasSpectrogram = true
      },
      runTimer() {
        let interval_time = 250;

        const _this = this;

        clearInterval(window.refreshIntervalId)
        
        window.refreshIntervalId = setInterval(function () {
        
        if (soundMeter != null) {

          // if(element!=null)
          {

            // var sound_l = soundMeter.instant.toFixed(2) ;
            var sound_l = soundMeter.db.toFixed(2);
            _this.sound_l2 = soundMeter.db2.toFixed(2);
            var max_freq_index = soundMeter.max_freq_index

            if(max_freq_index==undefined || max_freq_index==null)
            {
              max_freq_index = 0
            }

            if(_this.hasTimer==false && sound_l>55)
            {
              _this.hasTimer = true;
              _this.seconds_passed = 1;

              //timer indicator
              _this.timerID = setInterval(function () {
                if(_this.phonationOn)
                {
                  _this.seconds_passed = _this.seconds_passed + 1
                  if (_this.progress <= 99 ) {
                    _this.progress = _this.progress + 5;
                  }


                }
              }, 1000);
            }
            
            max_freq_index = max_freq_index.toFixed(2);


            // let sound_calibration = 20;
            let sound_threshold = 52;
            

            console.debug('sound_l = ' + sound_l)
            if(sound_l>sound_threshold)
            {
            
              _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
              
              _this.avg_loudness_show = _this.roundNumber(_this.avg_loudness,0)

              console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)

              _this.max_loudness = Math.max(_this.max_loudness,sound_l)

              _this.counter_loudness += 1;
            }

            const min_spect_loudness = 50

            if(sound_l>min_spect_loudness ) {

              if(_this.hasSpectrogram )
              {
                _this.addDataChartSpect(max_freq_index)

                console.debug('frequency_data_add = ' + max_freq_index)
              }
              
              _this.frequency_data.push(Number(max_freq_index))
            }

            if(!(_this.hasSPLMeter||_this.hasSpectrogram))
            {
              let element = document.getElementById("meter")

              _this.canvasContext = element.getContext("2d");
              _this.drawLoop(sound_l);

            }

            if(_this.hasSPLMeter)
            {

            // _this.addDataChart(max_freq_index)
            _this.addDataChart(sound_l)
            }
          }
        }

        }, interval_time);
      },
      roundNumber(number,number_of_decimals)
      {   
        let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)
        return rounded_number;
      },
      showimage(img_address) {
        const _this = this
        var storageRef = _this.$firebase.storage().ref();
        storageRef.child(img_address).getDownloadURL().then(function(url) {
            _this.url2image = url
        }).catch(function(error) {

        });
      },
      skipExercise()
      {
        const _this = this
        _this.$emit('nextExercise');
      },
      noAudioCaptureNext()
      {
        let _this = this;

        _this.is_recording = false

        let avg_loudness_array = []

        let basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}

        _this.$emit('logExerciseResults', basicData);
        
        _this.$emit('nextExercise');
      },  
      goToResults()
      {
        const _this = this

        if (_this.play_status) {
          _this.last_loudness = this.roundNumber(_this.avg_loudness,2)

          let avg_loudness_array = []

          if (_this.avg_loudness>0) {
            avg_loudness_array.push(_this.avg_loudness)
          }

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;

          if (_this.is_recording) {
            _this.is_recording = false

            _this.endRecordingAudio(true)
          }

          _this.max_loudness = 0


          var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}
          _this.$emit('logExerciseResults', basicData);
        }

        _this.$emit('goToResults', true);

      },
      start_stop() {
          
        const _this = this;

        if (_this.audio_player!=undefined && _this.audio_player!=null) {
          if (!_this.audio_player.paused) {
            _this.audio_player.pause();
          }
        }

        if (_this.video_player!=undefined && _this.video_player!=null) {
          if (!_this.video_player.paused) {
            _this.video_player.pause();
          }
        }

        if (_this.play_status) {
          _this.last_loudness = this.roundNumber(_this.avg_loudness,2)

          let avg_loudness_array = []

          if(_this.avg_loudness>0) {
            avg_loudness_array.push(_this.avg_loudness)
          }

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;

          if (_this.is_recording) {
            _this.is_recording = false          
            _this.endRecordingAudio(true)
          }

          _this.max_loudness = 0

          if (_this.resultsTableBack.length > 0) {
            let basicData = {resultsTableBack: [_this.resultsTableBack[this.resultsTableBack.length-1]], avg_loudness_array: avg_loudness_array}
            _this.$emit('logExerciseResults', basicData);
          } else {
            let basicData = {resultsTableBack: null, avg_loudness_array: avg_loudness_array}
            _this.$emit('logExerciseResults', basicData);
          }

          clearInterval(_this.timerID)
          // _this.hasTimer = false
          _this.phonationOn = false


          let rand_pic_id = Math.floor(Math.random() * constants.num_meme_imgs)
          _this.memeImg = new URL(`../../../../../assets/images/well_done/well_done${rand_pic_id}.jpeg`, import.meta.url)

          this.play_status = false

          if (this.audio_player!=null) {
            this.audio_player_status = false
            this.audio_player.pause();
          }
          if (this.exForm.assessment_type && this.exForm.assessment_type.includes('ASSESSMENT')) {
            this.nextExercise()
          } else {
            this.show_break_page = true
          }
          
        } else {
          _this.play_status = true;
          _this.phonationOn = true

          _this.resumeRecording()
          _this.runTimer()
        }

      },
      nextExercise() {
        this.$emit('nextExercise');
      },
      resumeRecording() {
        let _this = this;

        if (!_this.is_recording) {
          recordAudioMedia()
          _this.is_recording = true;
        }
      },
      loadBackgroundAudio()
      {
        let _this = this;

        let storage = _this.$firebase.storage();

        storage.ref(_this.background_audio_location).getDownloadURL()
        .then((url) => {
          console.debug('loaded background audio')
          _this.background_audio_url = url
          _this.playBackgroundAudio()
          // _this.play(url);

        })
        .catch((error) => {
        });
      
      },
      stopBackgroundNoise() {
        if (this.audio_player.paused) {
          this.background_noise_checked = true
          let checkbox = document.getElementById("flexCheckChecked");

          checkbox.checked = this.background_noise_checked

          sessionStorage.setItem("in_noise_background_noise", this.background_noise_checked)

          this.playBackgroundAudio()

        } else {
          this.audio_player_status = false
          this.audio_player.pause();

          this.background_noise_checked = false

          var checkbox = document.getElementById("flexCheckChecked");

          checkbox.checked = this.background_noise_checked

          sessionStorage.setItem("in_noise_background_noise", this.background_noise_checked)

          this.audio_player_text = 'Resume background noise'
        }

      },
      playBackgroundAudio() {
        const _this = this


        if (_this.audio_player == null) {
          _this.audio_player = new Audio(this.background_audio_url);


          let checkbox = document.getElementById("flexCheckChecked");

          checkbox.checked = _this.background_noise_checked

          sessionStorage.setItem("in_noise_background_noise", this.background_noise_checked)

          if (_this.background_noise_checked) {
            _this.audio_player.play();

            this.audio_player_text = 'Stop background noise'
            this.audio_player_status = true
          }


        } else if(_this.background_noise_checked) {

          if (!_this.audio_player.paused) {
            _this.audio_player.pause();
          }
          _this.audio_player = new Audio(this.background_audio_url);
          this.audio_player_status = true
          this.audio_player_text = 'Stop background noise'

          let checkbox = document.getElementById("flexCheckChecked");
          _this.background_noise_checked = true
          checkbox.checked = _this.background_noise_checked

          sessionStorage.setItem("in_noise_background_noise", this.background_noise_checked)

          _this.audio_player.play();
        }
      },
      endRecordingAudio(save_audio) {
        const _this = this;

        if (save_audio) {

          console.debug('curr_exercise_path = ' + _this.exForm.curr_exercise_path)

          let results_addresses = generate_results_path(this.exForm)

          let random_identifier = results_addresses['random_identifier']

          let exercises_results_path = results_addresses['exercises_results_path']
          let modules_results_path = results_addresses['modules_results_path']
          console.log(results_addresses)

          console.log(_this.exForm.curr_exercise_path)
          let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;
          
          if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
            audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
          }
          
          var audio_address = 'audios/' + audio_folder + '.mp3';

          audio_address = audio_address.replaceAll('//','/')

          console.debug("Saving " + audio_address);

          
          let today = _this.exForm.today
          
          let voicetype = _this.exForm.voicetype
          let speechtype = _this.exForm.speechtype

          console.debug('exercises_results_path = ' + exercises_results_path)
          console.debug('assessment_type = ' + _this.exForm.assessment_type)
          
          updateDataDirectory(modules_results_path, {plan_name: _this.exForm.module_name, assessment_type: _this.exForm.assessment_type})

          let name = _this.exForm.name
          if(name == undefined || name == null || name == '') {
            name = _this.Ex_Text
          }
          
          let push_structure = {
            'date': today, 
            'name': name, 
            'path': exercises_results_path, 
            'audio_address': audio_address, 
            'uid': _this.uid, 
            'loudness': _this.last_loudness,
            'localisation': _this.region
          }


          let ground_truth = _this.Ex_Text

          if(this.ground_truth != undefined) {
            ground_truth = this.ground_truth
          }

          if('ground_truth' in _this.exForm && _this.exForm.ground_truth!=undefined && _this.exForm.ground_truth!=null) {
            ground_truth = _this.exForm['ground_truth']
          }

          if(this.exForm.exercise_type==='speak_numbers') {
            let output = updateLevel(this.speak_numbers_data.base_level_address,this.speak_numbers_data.level,this.speak_numbers_data.streak+1,this.speak_numbers_data.max_level,this.speak_numbers_data.streak_target)

            this.speak_numbers_data.level = output[0]
            this.speak_numbers_data.streak = output[1]
          }

          console.log('ground_truth = ' + ground_truth)

          if (ground_truth!=undefined && ground_truth!=null) {
            ground_truth = removeQuotesAndSimilarForAnalysis(ground_truth)
          } else{
            ground_truth = ''
          }

          let has_analysis = false

          if(voicetype != null && voicetype != undefined) {
              has_analysis = true               

            if(Array.isArray(voicetype) && voicetype.includes('intelligibility')) {
              push_structure['intelligibility'] = ground_truth
            }
            else if('Intelligibility' in voicetype) {
              push_structure['intelligibility'] = ground_truth
            }

            if(Array.isArray(voicetype) && voicetype.includes('sentencegibility')) {
              push_structure['sentencegibility'] = ground_truth
            } else if('sentencegibility' in voicetype) {
              push_structure['sentencegibility'] = ground_truth
            }


            if(Array.isArray(voicetype) && voicetype.includes('max_phonation')) {
              push_structure['max_phonation'] = '1'
            }
            else if('max_phonation' in voicetype) {
              push_structure['max_phonation'] = '1'
            }

            console.log('check pitch_range')

            if(Array.isArray(voicetype) && voicetype.includes('speech_rate')) {
              console.debug('speech_rate')
            push_structure['speech_rate'] = '1'
            } else if('speech_rate' in voicetype) {
              push_structure['speech_rate'] = '1'
            }

            if(Array.isArray(voicetype) && voicetype.includes('jitter_shimmer')) {
              push_structure['jitter_shimmer'] = '1'
            } else if('jitter_shimmer' in voicetype) {
              push_structure['jitter_shimmer'] = '1'
            }


            if(Array.isArray(voicetype) && voicetype.includes('f0')) {
              push_structure['f0'] = '1'
            } else if('f0' in voicetype) {
              push_structure['f0'] = '1'
            }
          }

          if (speechtype!=null && speechtype!=undefined) {
            has_analysis = true

            if (Array.isArray(speechtype) && speechtype.includes('ddk_rate')) {
              push_structure['ddk_rate'] = '1'
            }

            if(Array.isArray(speechtype) && speechtype.includes('unscripted_speech_recognition')) {
              push_structure['unscripted_speech_recognition'] = '1'
            }

            if(Array.isArray(speechtype) && speechtype.includes('intelligibility')) {
              push_structure['intelligibility'] = ground_truth
            } else if('Intelligibility' in speechtype) {
              push_structure['intelligibility'] = ground_truth
            }


            if(Array.isArray(speechtype) && speechtype.includes('sentencegibility')) {
              push_structure['sentencegibility'] = ground_truth
            } else if('sentencegibility' in speechtype) {
              push_structure['sentencegibility'] = ground_truth
            }

            if(Array.isArray(speechtype) && speechtype.includes('speech_rate')) {
              push_structure['speech_rate'] = '1'
            } else if('speech_rate' in speechtype) {
              push_structure['speech_rate'] = '1'
            }

          }


          if (Array.isArray(voicetype) && voicetype.includes('pitch_range')) {

            if (this.frequency_data.length>0) {
              
              let max_freq = 0
              let min_freq = 1e6
              let mean_freq = 0

              for(let i in this.frequency_data) {

                let curr_freq = this.frequency_data[i]
                mean_freq = mean_freq+curr_freq

                if(curr_freq>max_freq) {
                  max_freq = curr_freq
                }                   
                
                if(min_freq>curr_freq) {
                  min_freq = curr_freq
                }
              }

              mean_freq = mean_freq/this.frequency_data.length

              min_freq = mean_freq+mean_freq-max_freq

              let freq_range = max_freq-min_freq

              this.freq_range = this.roundNumber(freq_range,0)
              this.max_freq = this.roundNumber(max_freq,0)
              this.min_freq = this.roundNumber(min_freq,0)

              if (isNaN(this.freq_range)) {
                this.freq_range = 0
              }

              if (isNaN(this.max_freq)) {
                this.max_freq = 0
              }

              if (isNaN(this.min_freq)) {
                this.min_freq = 0
              }

              let freq_results = {freq_range:freq_range,mean_freq:mean_freq,max_freq:max_freq,min_freq:min_freq,loudness:_this.last_loudness}

              let resultsTablePre = {exercise_type: 'Pitch Range', exercise: _this.exForm.name, results: freq_results, audio_address:audio_address}

              var pitch_range_data = {resultsTablePre: resultsTablePre}

              save_pre_computed_results(_this.exForm, pitch_range_data)

              this.$emit('logExerciseResults', pitch_range_data);
            }
              
          }

          console.log(push_structure)

          if (has_analysis) {
            _this.resultsTableBack.push(exercises_results_path)
            _this.modules_results_path = modules_results_path
            // console.debug('path = ' + modules_results_path)
            // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
            stopRecordingMedia(audio_address, push_structure, _this);
          } else if('capture_audio' in _this.exForm && _this.exForm.capture_audio) {
            push_structure['real_life_exercise'] = this.Ex_Text

            _this.resultsTableBack.push(exercises_results_path)
            _this.modules_results_path = modules_results_path
            // console.debug('path = ' + modules_results_path)
            // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
            stopRecordingMedia(audio_address, push_structure, _this);
          } else {
            stopRecordingMedia(audio_address, null,null);
          }
            
        } else {
          stopRecordingMedia(null,null,null);
        }            


        this.is_recording = false;

      }, 
      createChart() {
        const _this = this
        
        if(_this.chart_not_created) {
          _this.chart_not_created = false
          console.debug('creating chart')
          console.debug('this.loud_target = ' + this.loud_target)
          _this.smoothie = new smoothie.SmoothieChart({millisPerPixel:11,grid:{fillStyle:'#dfd7d7',verticalSections:2},labels:{fillStyle:'#000000',fontSize:15},horizontalLines:[{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}],maxValue:_this.maxdBValue,minValue:_this.mindBValue})
          var canvas = document.getElementById("loud_canvas")
          _this.chart_data = new smoothie.TimeSeries();
          _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
          // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
          _this.smoothie.streamTo(canvas, 500);
  
        }
      },
      updateChart() {
        const _this = this

        localStorage.setItem('loudness_target',_this.loud_target)

        let uid = localStorage.getItem('uid');
        let ref_path = 'Users/' + uid + '/Settings/'

        updateDataDirectory(ref_path, {'loudness_target':String(_this.loud_target)})        

        _this.smoothie.stop()

        _this.smoothie.options.horizontalLines = [{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}]
        
        _this.smoothie.start()
        // var canvas = document.getElementById("loud_canvas")
        // _this.chart_data = new smoothie.TimeSeries();
        // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
        // // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
        // _this.smoothie.streamTo(canvas, 500);

      },
      createChartSpect() {
        const _this = this
        
        if (_this.chart_not_created_spect) {
          _this.chart_not_created_spect = false
          console.debug('creating spec chart')
  
  
          _this.smoothie_spect = new smoothie.SmoothieChart({millisPerPixel:11,labels:{fillStyle:'#000000',  precision:0},grid:{fillStyle:'rgba(40,40,154,0.60)',strokeStyle:'#ffffff', verticalSections:4},maxValue:500,minValue:50})
          var canvas = document.getElementById("spect_canvas")
          _this.chart_data_spect = new smoothie.TimeSeries();
  
          _this.smoothie_spect.addTimeSeries(this.chart_data_spect, {lineWidth:3.3,strokeStyle:'#00ff00'});
          _this.smoothie_spect.streamTo(canvas, 500);
        }  
      },
      addDataChart(in_data) {
        const _this = this
        if(_this.smoothie!=undefined && _this.smoothie!= null) {
          _this.chart_data.append(new Date().getTime(),in_data)
        }
      },
      addDataChartSpect(in_data) {
        const _this = this
        if (_this.smoothie_spect!=undefined && _this.smoothie_spect!= null) {
          _this.chart_data_spect.append(new Date().getTime(),in_data)
        }
      },
      pause() {
        const _this = this;
        _this.play_status = false;

        clearInterval(window.refreshIntervalId)
      },
      drawLoop(sound_l) {
        // clear the background
  
        var _this = this;
  
  
        var WIDTH = 400;//document.getElementById('meter').clientWidth;
        var HEIGHT = 30;//document.getElementById('meter').clientHeight;;
  
        var green_threshold = 50;
  
        // console.debug("Width = " + WIDTH);
        
        // console.debug("HEIGHT = " + HEIGHT);
  
        _this.sound_l = sound_l;
  
  
  
        if(_this.canvasContext != undefined && _this.canvasContext !=null) {
          _this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
        
          if (sound_l > green_threshold) {
            _this.canvasContext.fillStyle = "green";
          }
          else {
  
            // _this.silence_flag = true;
  
            _this.canvasContext.fillStyle = "red";
          }
          _this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
          // _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);
        }
  
        // draw a bar based on the current volume
  
  
      },
      playAudioInstructions(url)
      {
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="50px" width="400px" controls autoplay> </video>';
        this.video_player =document.getElementById('movie');
        this.video_player.load();
      },
    }
  }
</script>
  
<style scoped>

    .backimg {
      position: relative;
      top: 0;
      left: 0;
      /* border: 1px red solid; */
    }

    .text {
      font-size: 14px;
    }
  
    .text_item {
      /* margin-bottom: 18px; */
      font-size: 30px;
      /* vertical-align: middle; */
      padding: 40px 0;
      /* height:80px; */
    }
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }
    .clearfix:after {
      clear: both
    }
  
    .box-card {
      width: 60%;
      margin:auto;
    }
  
    .demo-input-label {
      display: inline-block;
      width: 130px;
    }

    .legend-custom-style {
      font-size: 20px;
    }
</style>

<!-- Result Description
{
  "<plan_name?>":{
    "<key>": {
      "audio_address": "<storage_audio_address>",
      "loudness": {
        "loudness": "<int>"
      },
      "name": "<exercise_name>", // Back end analysis results (if they are requested):
      "sentencegibility": "<sentencegibility_dict_see_below>", // daily news, pub quiz, jokes, tongue twisters, fun facts, sentence intelligibility and speech rate (Assessments)
      "intelligibility": "<intelligibility_dict_see_below>", //practice mouth movement
      "max_phonation_dictionary":"<max_phonation_dict_see_below>", //say ahhh
      "jitter_shimmer_dictionary":"<jitter_shimmer_dict_see_below>", //say ahhh
      "speechrate_dictionary":"<speech_rate_dict_see_below>", //sentence intelligibility and speech rate (Assessments)
      "f0_dictionary":"<f0_dict_see_below>", // pitch variation
      "resultsTablePre":"<pre_computed_results>"// pitch variation

       

    },
    "assessment_type": "<USER_TRAINING/SLP_TRAINING/SLP_ASSESSMENT>",
    "plan_name": "<plan_name>"
  }
}

{
  "resultsTablePre":
    {
    "audio_address": "<audio_address>",
    "exercise": "<ex_name>",
    "exercise_type": "Pitch Range",
    "results": {
      "freq_range": <float>,
      "max_freq": <float>,
      "mean_freq": <float>,
      "min_freq": <float>
    }
  }
}
{
  "<sentencegibility_dict>":
  {
    "accuracy_score": "<int>",
    "audio_duration": "<float in sec>",
    "fluency_score": "<int>",
    "reference_text": "<target_text>",

    "Words":[
      // For each word recognised
      {
        "word":"<ground truth word>",
        "accuracy_score": "<int>",
        "error_type": "<string>",
        "phonemes": [
          // For each phoneme in the word
          {
            "accuracy_score":"<int>",
            "phoneme":"<using iso text>"
          }
        ]
      }

    ]

  }
}

{
  "jitter_shimmer_dictionary":
  {
    "apq11Shimmer": "<float>",
    "apq3Shimmer": "<float>",
    "aqpq5Shimmer": "<float>",
    "audio_duration": "<float>",
    "ddaShimmer": "<float>",
    "ddpJitter": "<float>",
    "hnr": "<float>",
    "localJitter": "<float>",
    "localShimmer": "<float>",
    "localabsoluteJitter": "<float>",
    "localdbShimmer": "<float>",
    "meanF0": "<float>",
    "ppq5Jitter": "<float>",
    "rapJitter": "<float>",
    "stdevF0": "<float>"
  }
}
{
  "max_phonation_dictionary":
  {
    "audio_duration": "<float>",
    "max_phonation": "<float>"
  }
}

{
  "f0_dictionary":{
    "apq11Shimmer": "<float>",
    "apq3Shimmer": "<float>",
    "aqpq5Shimmer": "<float>",
    "audio_duration": "<float>",
    "ddaShimmer": "<float>",
    "ddpJitter": "<float>",
    "hnr": "<float>",
    "localJitter": "<float>",
    "localShimmer": "<float>",
    "localabsoluteJitter": "<float>",
    "localdbShimmer": "<float>",
    "meanF0": "<float>",
    "ppq5Jitter": "<float>",
    "rapJitter": "<float>",
    "stdevF0": "<float>"
  
  }
}
{
  "intelligibility":{
    "Error": "<string>",
    "Phonemes": [
      // For each phoneme in word
      {
        "Duration": "<int in microseconds>",
        "Offset": "<int in microseconds>",
        "Phoneme": "<string in iso>",
        "PronunciationAssessment": {
          "AccuracyScore": "<int>"
        }
      }
    ],
    "accuracy_score": "<int>",
    "audio_duration": "<float>",
    "completeness_score": "<int>",
    "fluency_score": "<int>",
    "pronunciation_score": "<float>",
    "reference_text": "<target_text"
  }
}


{
  "speechrate_dictionary":{
    "ASD(speakingtime by nsyll)": "<float>",
    "articulation rate(nsyll by phonationtime)": "<float>",
    "audio_duration": "<float>",
    "dur(s)": "<float>",
    "npause": "<int>",
    "nsyll": "<int>",
    "phonationtime(s)": "<float>",
    "speechrate(nsyll by dur)": "<float>"
  }
}
 -->