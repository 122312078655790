<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>
    
    <div class="container">
      <h1 class="display-5">Goals</h1>
      <Goals class="mt-4" />
    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>        
</template>


<script>
import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'
import Goals from '../../components/goals.vue'

export default {
  name: 'goals_page',
  components: {Goals, Footerbar, Navbar},
  data() {
    return {
    }
  },
  created() {
  
  },
  methods:
  {
    
  }
}
</script>
<style scoped>

</style>





