<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row">
          <div class="card card-body">
            <h2>
              {{ curr_question}}
              <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(curr_question)">
            </h2>
          </div>
        </div>
        <div class="row" style="height: 15mm;">
          <span :style="interaction_message_color"> {{  interaction_message}}</span> 
        </div>
        <div class="row" v-if="displayOptNum === 3">
          <div class="col-md-4">
            <div v-if="dict_choices[0]" :class="dict_choices[0].class" @click="checkSelection(0)">
              <!-- <h2> -->
                <!-- {{ dict_choices[0].display}} -->
                <img :src="dict_choices[0].img_url"  style="object-fit: contain">
              <!-- </h2> -->
              
            </div>
          </div>
          <div class="col-md-4">
            <div v-if="dict_choices[1]" :class="dict_choices[1].class" @click="checkSelection(1)">
              <!-- <h2> -->
                <!-- {{ dict_choices[1].display}} -->
                <img :src="dict_choices[1].img_url" style="object-fit: contain">
              <!-- </h2> -->
              
            </div>
          </div>
          <div class="col-md-4">
            <div v-if="dict_choices[2]" :class="dict_choices[2].class" @click="checkSelection(2)">
              <!-- <h2> -->
                <!-- {{ dict_choices[2].display}} -->
                <img :src="dict_choices[2].img_url" style="object-fit: contain">
              <!-- </h2> -->
              
            </div>
          </div>
        </div>
        <div class="row" v-if="displayOptNum === 4">
          <div class="col-md-3">
            <div v-if="dict_choices[0]" :class="dict_choices[0].class" @click="checkSelection(0)">
              <!-- <h2> -->
                <!-- {{ dict_choices[0].display}} -->
                <img :src="dict_choices[0].img_url"  style="object-fit: contain">
              <!-- </h2> -->
              
            </div>
          </div>
          <div class="col-md-3">
            <div v-if="dict_choices[1]" :class="dict_choices[1].class" @click="checkSelection(1)">
              <!-- <h2> -->
                <!-- {{ dict_choices[1].display}} -->
                <img :src="dict_choices[1].img_url" style="object-fit: contain">
              <!-- </h2> -->
              
            </div>
          </div>
          <div class="col-md-3">
            <div v-if="dict_choices[2]" :class="dict_choices[2].class" @click="checkSelection(2)">
              <!-- <h2> -->
                <!-- {{ dict_choices[2].display}} -->
                <img :src="dict_choices[2].img_url"  style="object-fit: contain">
              <!-- </h2> -->
              
            </div>
          </div>
          <div class="col-md-3">
            <div v-if="dict_choices[3]" :class="dict_choices[3].class" @click="checkSelection(3)">
              <!-- <h2> -->
                <!-- {{ dict_choices[2].display}} -->
                <img :src="dict_choices[3].img_url"  style="object-fit: contain">
              <!-- </h2> -->
              
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"

          :has_skip="true"
          @skip="skipExercise()"

          :has_show_results="has_results"
          @show_results="goToResults()"   
          >
        </Sidebar>
      </template>
    </Layout>
    <div class="modal fade" id="level_up_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Level up</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="m-3">
              <div class="row">
                <h4 >{{ level_up_message }}</h4>
              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import { Modal } from 'bootstrap'

import {getData,updateDataDirectory} from '@/firebase/index'
import audioImg from '@/assets/images/audio_logo.png'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import {LevenshteinDistance} from '@/utils/text_distance_metrics'
import { useTTS } from '@/stores/tts'

  // SLT\Exercises\Receptive Language\reversible passives.docx
  export default {
    name: 'money_recognition',
    props: 
    {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data(){
      return {
        base_level_address:'/Levels/Money_recognition',
        level_address:'',
        level_up_message:'',
        has_results:false,
        userCategory:'',
        region:'',
        current_exercise_index: 0,
        total_exercises: 0,
        total_number_levels:1,
        max_level:false,
        audioImg:audioImg,
        nextExerciseTimeout: null,
        target_words:null,
        is_recording:false,
        results:{correct:0,incorrect:0,total:0},
        first_attempt:true,
        current_level:1,
        current_setup:{},
        current_question_id:0,
        total_question_num:1,
        answer_type:'',
        total_q_a:[],
        if_recorder:false,
        btn_class: [],
        interaction_message_color:'',
        interaction_message:'',
        level_up_counter:0,
        level_up_target:3,
        btn_move_counter:0,
        selectNum:1,
        displayOptNum:3,
        bv_tts: useTTS().tts_engine,
        curr_question:'',
        ex_level:'',
        ex_format:'',
        num_choices:3,
        dict_choices:[],
        all_correct_selections:[],
        
        target_selection:null,
      }
    },
    created() {
  
      const _this = this
  
      this.userCategory = localStorage.getItem('userCategory');
      this.region = localStorage.getItem('region');
      // this.loadImages();
  

      _this.firsthas_results_exercise = _this.exForm.has_results

      logActivity( 'accessed', 'reversible_passive')
      
      _this.current_exercise_index = _this.exForm.current_exercise_index + 1
      _this.total_exercises = _this.exForm.total_all_type_exercises

      this.loadDifficulty();
    },
    beforeUnmount()
    {
      this.bv_tts.cancel()

            
      if (this.nextExerciseTimeout) {
          window.clearTimeout(this.nextExerciseTimeout); // will do nothing if no timeout with id is present
      }


    },
    mounted()
    {

    },
    methods: {
        skipExercise()
        {
          this.nextExercise()
        },
        foundAllWords()
        {
          this.interaction_message = 'Well done, you did it!'
          this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
          startConfetti(this)

          this.if_recorder = false

          this.results['correct'] = 1
          this.results['total'] = 1


          setTimeout(() => {
              this.nextExercise()
          }, 1200);
        },
        
        nextExercise()
        {
          const _this = this;

          _this.saveResults()


 
          
          _this.$emit('nextExercise');
            
        },
        goToResults()
        {
          const _this = this
          _this.saveResults()
          _this.$emit('goToResults', !this.first_attempt);
        },
        saveResults()
        {
          const _this = this

          if(!this.first_attempt)
          {

            let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect'],Total:_this.results['total'],Level:_this.current_level}
          
            let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect']}
            let results = {all_results:all_results, plot_results:plot_results}
            
            var moneyRecognitionData = {resultsTablePre: {exercise_type: 'Money Recognition', exercise: 'Money Recognition', results: results, audio_address:''}}
            
            save_pre_computed_results(_this.exForm, moneyRecognitionData)
            
            
            _this.$emit('logExerciseResults', moneyRecognitionData);

          }
          
        },
        loadDifficulty()
        {

            let _this = this

            let userType = localStorage.getItem('userCategory');


            let uid = localStorage.getItem('uid');

            this.level_address = '/Users/' + uid + this.base_level_address


            if(userType!=undefined || userType==='slp')
            {
                let client_number = localStorage.getItem('client_uid');

                if(client_number!='undefined' && client_number!=null && client_number!='')
                {

                    this.level_address = '/Users/'+ client_number + this.base_level_address

                }
            }


            

            let do_once = true

            getData(_this.level_address, function (err, result) {

                if(do_once)
                {
                  do_once = false
                  
                  let current_level = result.child('current_level').val()
                  let lvl_up_counter = result.child('level_up_counter').val()



                  if(current_level==undefined || lvl_up_counter==undefined)
                  {
                    current_level = 1
                    lvl_up_counter = 0
                    updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter})
                  }


                  _this.current_level = current_level
                  // _this.current_level = 7;//for debugging
                
                  _this.level_up_counter = lvl_up_counter
                  _this.loadSettings()
                }

            })
        },
        loadSettings()
        {
            let _this = this
            // this.current_question_id = 0;
            let diff_ref = _this.$firebase.database().ref('Exercises').child('Settings').child('Money_Recognition')
            var do_once = true;
            getData(diff_ref , function (err, result) {

                if (do_once) 
                {

                    let settings = result.val()
                    // console.debug('start')
                    
                    // console.debug('current level= '+_this.current_level)

                    _this.total_number_levels = Object.keys(settings).length + 1


                    console.debug('_this.total_number_levels = ' + _this.total_number_levels)

                    if(_this.current_level==_this.total_number_levels)
                    {
                      _this.max_level = true

                      _this.current_level = Math.ceil(Math.random()*(_this.total_number_levels-1))

                    }

                    console.log('_this.max_level = ' + _this.max_level)






                    let level_key = 'level'+_this.current_level

          
                    if(!(level_key in settings))
                    {
                      _this.current_level = 1
                      level_key = 'level'+_this.current_level

                    }
                    let temp_ex_level = settings[level_key]
                    _this.ex_level = _this.findResources(temp_ex_level)

                    console.debug('diff_level = '+_this.ex_level)
                    // console.debug(' !diff_level = '+_this.current_setup.word_diff)

                    // console.debug('keys = ' + Object.keys(_this.current_setup))


                    
                    do_once = false;
                    
                    _this.initialiser()
                }

            });
        },
        findResources(keywords)
        {
          let _this = this
          let output = ''
          if(keywords == 'notes' || keywords == 'coins')
          {
            output = keywords
            _this.ex_format = 'match'
            _this.displayOptNum = 3
          }
          else if(keywords == 'notes_compare')
          {
            output = 'notes'
            _this.ex_format = 'compare'
            _this.displayOptNum = 3
          }
          else if(keywords == 'coins_compare')
          {
            output = 'coins'
            _this.ex_format = 'compare'
            _this.displayOptNum = 3
          }
          else if(keywords == 'mixed_2')
          {
            output = 'mixed'
            _this.selectNum = 2
            _this.ex_format = 'match'
            _this.displayOptNum = 4
          }
          else if(keywords == 'mixed_3')
          {
            output = 'mixed'
            _this.selectNum = 3
            _this.ex_format = 'match'
            _this.displayOptNum = 4
          }
          else if(keywords == 'notes_cover')
          {
            output = 'notes'
            _this.num_choices = 3
            _this.ex_format = 'match'
            _this.displayOptNum = 3
          }
          else
          {
            console.log('unknow settings = '+keywords)
          }
          return output
        },
      initialiser()
      {
        let _this = this

        console.debug('_this.ex_level = ' + _this.ex_level)

        if(_this.ex_level != 'mixed')
        {
          let data_ref = _this.$firebase.database().ref('Exercises').child('Resources').child('Money').child(_this.ex_level)
          var do_once = true;
          getData(data_ref , function (err, result) {

          if (do_once) 
          {
            let all_options = result.val()
            let all_options_names = Object.keys(all_options)

            let selected_options = _this.randomSelection(all_options_names,_this.num_choices)
            _this.dict_choices = []
            for(let n in selected_options)
            {
              _this.dict_choices.push(all_options[selected_options[n]])
            }

            for(let nn in _this.dict_choices)
            {
              _this.getImages(nn)
            }
            
            if(_this.ex_format == 'match')
            {
              let target_key_name = _this.randomSelection(selected_options,1)
              _this.target_selection = all_options[target_key_name]
              _this.target_selection['display'] = _this.rightAnswer(_this.target_selection.name)

              console.debug('_this.target_selection.display = ' + _this.target_selection.display)
              if(_this.current_level<5)
              {
                _this.curr_question = 'Click on the money showing '+_this.target_selection.display
              }
              else if(_this.current_level == 7)
              {
                let rand_cost = Math.floor(Math.random()*4)
                let purchase_item = ['book', 'water bottle', 'wallet', 'burger meal', 'sweater', 'cat toy']
                let rand_item = Math.floor(Math.random()*purchase_item.length)
                _this.curr_question = 'You want to buy a '+ purchase_item[rand_item] +' that costs ' +(_this.target_selection.value-rand_cost) +' pounds. Click on the money that you will give to the salesperson'
              }
              _this.bv_tts.say(_this.curr_question)
              //_this.$forceUpdate()
            }
            else if(_this.ex_format == 'compare')
            {
              let compare_value_arr = ['lowest','highest']
              let compare_target = _this.randomSelection(compare_value_arr,1)
              let sorted_dict = _this.sortDictionary(_this.dict_choices,false)
              if(compare_target == 'lowest')
              {
                _this.target_selection = sorted_dict[0]
                
              }
              else if(compare_target == 'highest')
              {
                _this.target_selection = sorted_dict[sorted_dict.length-1]
              }
              _this.target_selection['display'] = _this.rightAnswer(_this.target_selection.name)


              _this.curr_question = 'Click on the money showing the '+compare_target+' value'



              console.debug('_this.curr_question = ' + _this.curr_question)

              _this.bv_tts.say(_this.curr_question)
              //_this.$forceUpdate()
            }
            
            do_once = false;
              
          }

          });
        }
        else
        {
          let data_ref = _this.$firebase.database().ref('Exercises').child('Resources').child('Money')
          var do_once = true;
          getData(data_ref , function (err, result) {

          if (do_once) 
          {
            let all_options = result.val()
            let all_notes_names = Object.keys(all_options.notes)
            let selected_notes = _this.randomSelection(all_notes_names,2)

            let all_coins_names = Object.keys(all_options.coins)
            let selected_coins = _this.randomSelection(all_coins_names,2)

            _this.dict_choices = []
            for(let n in selected_notes)
            {
              _this.dict_choices.push(all_options.notes[selected_notes[n]])
            }
            for(let n in selected_coins)
            {
              _this.dict_choices.push(all_options.coins[selected_coins[n]])
            }

            for(let nn in _this.dict_choices)
            {
              _this.getImages(nn)
            }
            
            if(_this.ex_format == 'match')
            {
              let target_answers = _this.randomSelection(_this.dict_choices,_this.selectNum)
              let total_val = 0;
              for(let n=0;n<_this.selectNum;n++)
              {
                total_val = total_val + target_answers[n].value
              }
              let displayArr = _this.num2Display(total_val)
              console.log('display arr = '+displayArr)
              if(displayArr[0] == 0) //pence only
              {
                _this.curr_question = 'Click on the moneys that add up to '+displayArr[1] + (displayArr[1] == 1 ? ' penny':' pence')
              }
              else if(displayArr[1] == 0) //pounds only
              {
                _this.curr_question = 'Click on the moneys that add up to '+displayArr[0] + (displayArr[0] == 1 ? ' pound':' pounds')
              }
              else
              {
                _this.curr_question = 'Click on the moneys that add up to '+displayArr[0]+ (displayArr[0] == 1 ? ' pound':' pounds')+' and '+displayArr[1] + (displayArr[1] == 1 ? ' penny':' pence')
              }
              _this.target_selection = target_answers
              // _this.target_selection['display'] = _this.rightAnswer(_this.target_selection.name)

              // console.debug('_this.target_selection.display = ' + _this.target_selection.display)
              // _this.curr_question = 'Click on the money showing '+total_val
              _this.bv_tts.say(_this.curr_question)
              //_this.$forceUpdate()
            }

              do_once = false;
              
          }

          });
        }

        
      },
      randomSelection(arr,num_select)
      {
        let output = []
        let temp_arr = [...arr]
        for(let i = 0; i<num_select;i++)
        {
          let rand_ids = Math.floor(Math.random()*temp_arr.length)
          output.push(temp_arr[rand_ids])
          temp_arr.splice(rand_ids,1)
        }
        return output
      },
      sortDictionary(dict,ifReverse)
      {
        const sort_by = (field, reverse, primer) => {

          const key = primer ?
            function(x) {
              return primer(x[field])
            } :
            function(x) {
              return x[field]
            };

          reverse = !reverse ? 1 : -1;

          return function(a, b) {
            return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
          }
        }

        let temp_dict = [...dict]
        let sorted_dict = temp_dict.sort(sort_by('value', ifReverse, false ))

        return sorted_dict
      },
      getImages(id)
      {
        let _this = this
        // console.log('dict_choices '+id+' = '+_this.dict_choices[id])
        let img_address = _this.dict_choices[id].img_storage_address
        var storageRef = _this.$firebase.storage().ref();
        storageRef.child(img_address).getDownloadURL().then(function(url) {
          _this.dict_choices[id]['img_url'] = url;
          _this.dict_choices[id]['display'] = _this.rightAnswer(_this.dict_choices[id].name)
          _this.dict_choices[id]['class'] = "card card-body h-100"
          //_this.$forceUpdate()
          // document.getElementById(_this.img_idx[x]).src = url;
        }).catch(function(error) {
        });
      },
      rightAnswer(text)
      {
        let target_words = text.split(';')
        console.debug('right answer = '+target_words)
        let first_answer = target_words[0]
        return first_answer
      },
      num2Display(num)
      {
        // console.log('orig num = '+num)
        
        let intg = Math.floor(num)
        let decm = Math.round((num - intg)*100)
        // console.log('numArr = '+[intg,decm])
        return [intg,decm]
      },
      checkSelection(id)
      {
        let selection = this.dict_choices[id].display

        let has_levelled_up = false

        if(!Array.isArray(this.target_selection))
        {
          this.resetAllSelections()

          if(selection == this.target_selection.display)
          {
            this.interaction_message = 'Well done, you did it!'
            this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
            this.bv_tts.say(this.interaction_message)
            this.dict_choices[id].class = "card card-body border-success border-5 h-100"
            startConfetti(this)
            //this.$forceUpdate()

            if(this.first_attempt)
            {
              this.results.correct = this.results.correct + 1
              this.results.total = this.results.total + 1
              has_levelled_up = this.result_response(1)


            }

            this.first_attempt = false

            if(!has_levelled_up)
            {
              this.nextExerciseTimeout = setTimeout(() => {
                  this.nextExercise()
              }, 2600);
            }

          }
          else
          {
            this.interaction_message = 'Oops.. not correct this time. Try again!'
            this.interaction_message_color = 'fontSize:24px;color:red;white-space: pre-wrap'
            this.bv_tts.say(this.interaction_message)
            this.dict_choices[id].class = "card card-body border-danger border-5 h-100"
            //this.$forceUpdate()
            if(this.first_attempt)
            {
              this.results.incorrect = this.results.incorrect + 1
              this.results.total = this.results.total + 1
              this.result_response(-1)


            }

            this.first_attempt = false
          }
        }
        else //multiple selctions
        {
          let no_correct_selection = true
          for(let i in this.target_selection)
          {
            if(selection == this.target_selection[i].display)
            {
              this.dict_choices[id].class = "card card-body border-success border-5 h-100"
              //this.$forceUpdate()
              no_correct_selection = false

              // this checks if the current correct selection has already been selected before
              
              if(!this.all_correct_selections.includes(id))
              {
                // if it is a new correct selection (some money that wasnt selected before), it adds to the list of correct selections
                this.all_correct_selections.push(id)

                if(this.all_correct_selections.length==this.target_selection.length)
                {
                  // if all target selections have been chosen it congratulates them
                  this.interaction_message = 'Well done, you did it!'
                  this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
                  this.bv_tts.say(this.interaction_message)
                  startConfetti(this)
                  //this.$forceUpdate()

                  if(this.first_attempt)
                  {
                    this.results.correct = this.results.correct + 1
                    this.results.total = this.results.total + 1
                    this.result_response(1)

                  }

                  this.nextExerciseTimeout = setTimeout(() => {
                      this.nextExercise()
                  }, 2600);
                  this.first_attempt = false
                }
                else
                {
                  let remaining_selections = this.target_selection.length - this.all_correct_selections.length
                  this.interaction_message = 'Good job, you still need to find ' +  remaining_selections +' more'+ (remaining_selections==1 ? ' money':' moneys') +' to get to the full value.'
                  this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
                  this.bv_tts.say(this.interaction_message)
                  //this.$forceUpdate()

                }
              }


            }
          }

          // This goes here if none of the correct options was selected
          if(no_correct_selection)
          {

            this.interaction_message = 'Oops.. not correct this time. Try again!'
            this.interaction_message_color = 'fontSize:24px;color:red;white-space: pre-wrap'
            this.bv_tts.say(this.interaction_message)
            this.dict_choices[id].class = "card card-body border-danger border-5 h-100"
            //this.$forceUpdate()
            if(this.first_attempt)
            {
              this.results.incorrect = this.results.incorrect + 1
              this.results.total = this.results.total + 1
              this.result_response(-1)


            }

            this.first_attempt = false


          }
        }
        
      },
      resetAllSelections()
      {
        for(let i in this.dict_choices)
        {
          this.dict_choices[i].class = "card card-body h-100"
        }
      },
      result_response(increment)
      {
        let has_levelled_up = false
        let level_down_target = 0-this.level_up_target
        this.level_up_counter = this.level_up_counter + increment

        if(this.max_level)
        {
          this.current_level = this.total_number_levels
        }

        let new_level = this.current_level


        if(this.level_up_counter >= this.level_up_target && this.current_level<this.total_number_levels)
        {
            this.level_up_counter = 0
            new_level = new_level + 1

            if (this.nextExerciseTimeout) {
                window.clearTimeout(this.nextExerciseTimeout); // will do nothing if no timeout with id is present
            }

            this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
            let level_up_pop = new Modal(document.getElementById('level_up_pop'));
            level_up_pop.show()


            has_levelled_up = true



          
        }
        else if(this.level_up_counter <= level_down_target)
        {
          this.level_up_counter = 0
          new_level = new_level -1
          if(new_level<1)
          {
            new_level = 1
          }
        }

        
        updateDataDirectory(this.level_address,{current_level:new_level,level_up_counter:this.level_up_counter})
        
        return has_levelled_up


        
      },
      nextQuestion()
      {
        this.current_question_id++
        this.interaction_message = ''
        this.bv_tts.say(this.total_q_a[this.current_question_id].qs)
        this.initialiseBtns()
        //this.$forceUpdate()
      },
      
    },
  }
  </script>
  <style scoped>
  
  .custom-text{
      font-size: 20px;
      font-family: Arial;
  }

  .basic-button
  {
    color: black;
    background-color: lightgrey;
    border-color: black;
  }

  .basic-wide-button
  {
    color: blueviolet;
    background-color: lightgray;
    border-color: black;
    width: 45mm;
  }
  .incorrect-wide-button
  {
    color: white;
    background-color: red;
    border-color: red;
    width: 45mm;
  }

  .correct-button
  {
    color:white;
    background-color: green;
    border-color: green;
  }
  .incorrect-button
  {
    color:white;
    background-color: red;
    border-color: red;
  }
  
  </style>

  <!-- Results Description
  {
  "Reversible Passive": {
    "Exercises": { //Need to remove this layer
      "General": { //Need to remove this layer
        "Aphasia": { //Need to remove this layer
          "ReversiblePassive": { //Need to remove this layer
            "UserLevel": { //Need to remove this layer
              "<key>": {
                "audio_address": "<empty>",
                "resultsTablePre": {
                  "audio_address": "<empty>",
                  "exercise": "<int>",
                  "exercise_type": "Reversible Passive",
                  "results": {
                    "all_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>",
                      "Total": "<int>"
                    },
                    "plot_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>"
                    }
                  }
                },
                "results_type": "pre_computed"
              }
            }
          }
        }
      }
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Reversible Passive"
  }
} -->