<template>
  <div>
    <div class="card card-body">


      <div >
        <div class="card card-body mt-2">
          <div class="row">
            <span style="font-size:24px;color: #0000FF;">
              You are doing well!
              <img :src="thumbupImg" style="width:35px;">
            </span>
          </div>
          <div class="row">
            <span style="font-size:24px;white-space: pre-wrap;color: #0000FF;">{{title_message}}</span>
          </div>
          <div class="row">
            <h5 v-if="more_exercises_available">
              There are more exercises to go. Drink some water before doing more.
            </h5>
            <h5 v-else>
              If you want to explore more exercises, do not forget to drink some water first.
            </h5>
          </div>
          <div class="row mt-2 justify-content-center">
            <img :src="waterImg" class="img-thumbnail" style="width:220px;">
          </div>
          <div class="row mt-2 justify-content-center">
            <button class="btn btn-primary btn-lg w-25" @click="skip()">{{emitClose?"Go back": "Main menu"}}</button>
            <button v-if="more_exercises_available" class="btn btn-success btn-lg ms-2 w-25" @click="moreExercises()">Do more exercises</button>
          </div>
        </div>

        <div v-show="waiting_to_load" class=" mt-2">
          <p>Loading results, please wait...</p>
          <img id= "img_naming" v-bind:src="imgSrc" width="350" height="350" > 
        </div>
        <div v-show="!waiting_to_load">
          <Result_Sections v-if="hasResults" :results="results"></Result_Sections>
        </div>


        <div class="row mt-2 justify-content-center">
          <button v-if="hasResults && !more_exercises_available && userCategory == 'slp' && view_mode != 'demo'" 
            class="btn btn-danger btn-lg ms-2 w-25" 
            @click="discardResults()">
            Discard results
          </button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Footerbar from '@/views/Footerbar.vue'

import Result_Sections from '@/views/session/Result/result.vue'

import loadingSVG from '@/assets/images/loader.svg'

import { getDataDirectoryCallback } from '@/firebase/index'

import waterImg from '@/assets/images/instructions/water2.png'
import thumbupImg from '@/assets/images/icon-thumb_up.png'

import { useTTS } from '@/stores/tts'
import { parseProccesedAudioExerciseData, process_resultsTablePre} from '@/utils/results/common'

export default {
  components: { 
    Footerbar,
    Result_Sections,
  },
  name: 'results',
  props: {
    resultsTable: {},
    emitClose: false,
    more_exercises_available: true,
  },
  emits: [ 
    "nextExercise", 
    "emitClose", 
    "discardResults" 
  ],
  data() {
    return {
      
      imgSrc: loadingSVG,
      waterImg: waterImg,
      thumbupImg: thumbupImg,

      userCategory: localStorage.getItem("userCategory"),
      view_mode: sessionStorage.getItem('view_mode'),

      hasResults: false,

      title_message: '',
      
      results: {},

      waiting_to_load: true,

      bv_tts: useTTS().tts_engine,
    }
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()
  },
  async created(){
    let _this = this;

    if(_this.resultsTable) { //this should always be true

      if('back' in _this.resultsTable && _this.resultsTable['back'].length > 0) {
        _this.title_message = 'Your results are being analysed. They will appear shortly...'
        let localResults = await _this.getBackEndExercisesResultsData(_this.resultsTable['back'], _this.results)
        _this.results = Object.assign({}, _this.results, localResults) 
        _this.hasResults = true
      }


      if('pre' in _this.resultsTable && _this.resultsTable['pre'].length > 0) {
        let accumulatedResults = {}; 

        for (let i in _this.resultsTable['pre']) {
          let exercise_data = _this.resultsTable['pre'][i];
          let localResults = await process_resultsTablePre(exercise_data, _this.results);

          accumulatedResults = Object.assign({}, accumulatedResults, localResults);
        }

        _this.results = Object.assign({}, _this.results, accumulatedResults);

        _this.hasResults = true
      }

      _this.waiting_to_load = false

      if(Object.keys(_this.results).length > 0) {        
        _this.title_message = 'Please see your results below.';
      } 
    }

  },
  updated() {
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    skip() {
      this.userCategory = localStorage.getItem("userCategory")
      if (this.userCategory === 'slp') {
        if (sessionStorage.getItem("view_mode") === "demo") {
          //sessionStorage.getItem("view_mode", ) should set this to null

          //go back to patient page
          //this.$router.push({name: "client_demo"})
          this.$router.back();
        }
        else if (sessionStorage.getItem("view_mode") === "assessment" || sessionStorage.getItem('view_mode') === "training") {
          //sessionStorage.getItem("view_mode", ) should set this to null

          //go back to patient page
          let clientUID = localStorage.getItem("client_uid")
          this.$router.push({ name: 'client_interface', query: {uid: clientUID} });
        } else {
          //should not reach here -- but it will be reached when view as patient is implemented -- not implemented yet
          this.$router.push({ name: 'home' });
        }
      } else {
        
        if (this.emitClose) {
          this.$emit('emitClose')
          return
        }
        this.$router.back();
      }
    },
    moreExercises() {
      this.$emit('nextExercise');
    },
    async getBackEndExercisesResultsData(backExercises, results) {
      let _this = this

      let localResults = Object.assign({}, results)

      let promiseArray = []

      //console.error(backExercises)
      for(let n=0; n<backExercises.length; ++n)
      {
        let promise = new Promise ((resolve, reject) => {
          
          getDataDirectoryCallback(backExercises[n], function (err, result) {

            let exercise_data = result.val();
            //console.error(backExercises[n])
            //console.error(exercise_data)
            console.debug(exercise_data)

            if (exercise_data && exercise_data['processing_status']) {

              //console.error(backExercises[n])
              //console.error(exercise_data)

              if (exercise_data['processing_status'] === "finished") {
                let result = parseProccesedAudioExerciseData(exercise_data, localResults, backExercises[n])
                resolve(result)
              } else if (exercise_data['processing_status'] === "processing" || 
                         exercise_data['processing_status'] === "started") {
                
                //console.error("processing - so set listener f")

                if (exercise_data['processing_status'] === 'processing' && 'error' in exercise_data) {
                  //console.error(exercise_data['error'])
                  resolve({})
                }
                // to only processing or to be processed results

                _this.$firebase.database().ref(backExercises[n]).on('child_changed', 
                  function(new_child){

                    getDataDirectoryCallback(backExercises[n], function (err, result) {
                      let exercise_data = result.val();
                      console.log(exercise_data)
                      if (exercise_data) {
                        if (exercise_data['processing_status'] === 'finished') {
                          let _result = parseProccesedAudioExerciseData(exercise_data, localResults, backExercises[n])
                          resolve(_result)
                        } else if (exercise_data['processing_status'] === 'error') {
                          resolve({})
                        }
                      } else {
                        resolve({})
                      }
                    });
                  }
                )

                //detect error -- this should be replace with processing_status = 'error'
                _this.$firebase.database().ref(backExercises[n]).on('child_added', 
                  function(new_child){
                    getDataDirectoryCallback(backExercises[n], function (err, result) {
                      let exercise_data = result.val();
                      if (exercise_data) {
                        if (exercise_data['processing_status'] === 'processing' && 'error' in exercise_data) {
                          //console.error(exercise_data)
                          resolve({})
                        }
                      }
                    });
                  }
                )
                /////
              } else {
                console.error("[DEV] Processing status by API indicates process failure!")
                resolve({})
              }
            } else {
              console.warn("[DEV] Exercise path with processing status not created by client side - check API pre-call section! || exercise doesn't create those")
              resolve({})
            }
          });

        })
        promiseArray.push(promise)
      }

      let values = await Promise.all(promiseArray)

      for (let value of values) {
        localResults = Object.assign({}, localResults, value)
      }

      return localResults
    },
    discardResults() {
      this.$emit("discardResults")
    },
  },
}
</script>
<style scoped>
  .custom-text{
    font-size: 22px;
  }
  .custom-text20{
    font-size: 20px;
  }
  .title_txt {
    float: left;
    line-height: 13mm;
    margin-left: 2%;
    font-size: 30px;
  }
  canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  #chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #chartjs-radar{
    width : 60%;
    height: 60%;
  }
  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
</style>