<template>
    <div>
      <div class="card m-2">
        <div class="card-body">
          <h5 class="mb-4">Communication difficulties</h5>
          <div class="row justify-content-between">
            <div v-for="(issue, index) in issueLib" :key="index" class="col-lg-3">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input mb-2"
                  type="checkbox"
                  :id="(index + 1)"
                  v-model="diseaseType"
                  @change="updateUserInfo('diseaseType')"
                  :disabled="diseaseType.length === 1 && diseaseType.includes(issue)"
                  :value="issue"
                />
                <label class="form-check-label" :for="'d' + (index + 1)">{{ issue }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card m-2" v-if="diseaseType.includes('Hoarse/breathy voice')">
        <div class="card-body">
          <div class="row justify-content-between">
            <div class="col-lg-5 mb-4">
              <h5 class="mb-4">Loudness target</h5>
              <label class="form-label">Loudness target in exercises</label>
              <select 
                class="form-select" 
                v-model="loudness_target" 
                placeholder="Select" 
                @change="updateUserInfo('loudness_target')">
                <!-- <option key="1" label="55 dB - Low voice" value="55"> </option> -->
                <option key="2" label="60 dB - Quiet conversation" value="60"> </option>
                <option key="3" label="65 dB - Normal conversation" value="65"> </option>
                <option key="4" label="70 dB - Loud conversation" value="70"> </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card m-2" v-if="diseaseType.includes('Hoarse/breathy voice') && calibrate_mic">
        <div class="card-body">
          <h5 class="mb-4">Calibrate your microphone</h5>
          <div class="row justify-content-between">
            <div class="col-lg-10">        
              <label class="form-label">Calibrating your microphone will make your loudness readings more accurate. Contact contact@beautifulvoice.co.uk for help.</label>
            </div>
            <div class="col-lg-2">        
              <button class="btn btn-primary" @click="goToCalibrate()">Calibrate</button>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </template>
<script>
  import { ref, reactive, onMounted } from 'vue';
  import { getDataDirectoryCallback, updateDataDirectory } from '@/firebase/index';
  import { useToast } from 'vue-toastification';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'CommunicationDifficulties',
    props: {
      uid: String,
      calibrate_mic: {
        type: Boolean,
        default: true
      }
    },
    setup(props, {emit}) {
      const toast = useToast();
      const diseaseType = ref([]);
      const issueLib = [
        'Slurred/choppy speech',
        'Difficulty in moving lips, jaw or tongue',
        'Hoarse/breathy voice',
        'Dysphasia/aphasia',
      ];
      const issueFlag = reactive([false, false, false, false]);
      const loudness_target = ref(null);
      const router = useRouter();
      
      const settingsRefPath = 'Users/' + props.uid + '/Settings/';

      const loadUserInfo = async () => {
        await new Promise((resolve) => {
          getDataDirectoryCallback(settingsRefPath, (err, result) => {
            console.log(result.child('Qs_diseaseType').val())
            diseaseType.value = result.child('Qs_diseaseType').val() || [];
            loudness_target.value = parseInt(result.child('loudness_target').val()) || null;
  
            localStorage.setItem('loudness_target',loudness_target.value )
  
            resolve();
          });
        });
      };
  
      const updateUserInfo = (inp) => {
  
        if (inp === 'diseaseType') {
          updateDataDirectory(settingsRefPath, { Qs_diseaseType: diseaseType.value });
          for (let i = 0; i < issueFlag.length; i++) {
            issueFlag[i] = diseaseType.value.includes(issueLib[i]);
          }
          localStorage.setItem('issue_flag', JSON.stringify(issueFlag));

          toast.info('Your information has been updated.');
        } else if(inp == 'loudness_target') {
          updateDataDirectory(settingsRefPath, {'loudness_target': loudness_target.value})
          
          toast.info('Your information has been updated.');
        }

        emit('changedSetting')
      };

      const goToCalibrate = () => {
        router.push({name: "microphone_calibration"})
      }
  
      onMounted(() => {
        loadUserInfo();
      });
  
      return {
        diseaseType,
        issueLib,
        loudness_target,
        updateUserInfo,
        goToCalibrate,
      };
    },
  };
  </script>
  