<template>
  <div> 
    <div class="container">
      <FormKit type="form" :actions="false" classs="mt-2" id="form" @submit="submit()">      
        <div class="card card-body">
          <div class="row mb-2">
            <p class="text-start" for="exercise_name">Module name <span style="color:red">*</span></p>
            <div class="row">
              <VTooltip>
                <FormKit input-class="form-control" id="exercise_name" validation-name="Module name" name="name" type="text" v-model="module_name" 
                :sections-schema="{
                  messages: { $el: 'div' },
                  message: { $el: 'span' },
                  options: { $el: 'div' },
                  option: { $el: 'div' },
                }"
                messages-class="text-danger"
                validation="required"/>
                <template #popper>
                  Enter the name of your training module
                </template>
              </VTooltip>
            </div>
          </div>
          <div class="row mb-2">
            <p class="text-start" for="instructions">Instruction to patients <span style="color:red">*</span></p>
            <div class="row">
              <VTooltip>
                <FormKit input-class="form-control" id="instructions" validation-name="Instruction" name="text" 
                :sections-schema="{
                  messages: { $el: 'div' },
                  message: { $el: 'span' },
                  options: { $el: 'div' },
                  option: { $el: 'div' },
                }"
                messages-class="text-danger"
                placeholder='E.g., Call a take-away and order the food for the house tonight.' 
                v-model="exercise_meta_data.text" 
                validation="required"/>
                <template #popper>
                  Call a take-away and order the food for the house tonight.
                </template>
              </VTooltip>
            </div>
            <div class="row mt-2">
              <div class="col-3">
                <button v-if="(exercise_meta_data.Audio_Address==null)||(exercise_meta_data.Video_Address==null)" class="btn btn-primary w-100 me-2" type="button" data-bs-toggle="modal" data-bs-target="#record_instructions_modal" @click="addVideo()"> Add video</button>
                <button v-if="exercise_meta_data.Video_Address" class="btn btn-warning w-100 mt-1 me-2" type="button" data-bs-toggle="modal" data-bs-target="#watch_instructions_modal" @click="watchVideo()"> Review video</button>
                <button v-if="exercise_meta_data.Video_Address" class="btn btn-danger w-100 mt-1 me-2" type="button" @click="deleteVideo()"> Delete video</button>
              </div>
            </div>
          </div>
          <div class="row mt-4 justify-content-center text-center">
            <div class="col-12">
              <div class="input-group">
                <div class="form-check form-check-inline">
                  <label class="form-check-label" for="checkbox">Checkbox for the client to tick when done</label>
                  <input class="form-check-input" type="checkbox" v-model="exercise_meta_data.checkbox" name="checkbox" id="checkbox" value="checkbox">
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label" for="capture_audio">Audio recording of the exercise</label>
                  <input class="form-check-input" type="checkbox" v-model="exercise_meta_data.capture_audio" name="capture_audio" id="capture_audio" value="capture_audio">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-6">
            <p>Save to: </p>
            <select class="form-select" v-model="module_location">
              <option v-if="!module_folder_locations.includes('General')" value="General">General</option>
              <option v-for="ml in module_folder_locations" v-bind:key="ml" :value="ml">{{ ml }}</option>
              <option value="__other__">Other Location</option>
            </select>
          </div>
        </div>
        <div class="row justify-content-center mt-2" v-if="module_location === '__other__'">
          <div class="col-6">
            <input type="text" class="form-control" v-model="custom_location" placeholder="Name of location"/>
          </div>
        </div>
        <div class="row mt-4 mb-4 justify-content-center">
          <button class="btn btn-secondary w-25 me-2" type="button" @click="cancel_submit">Cancel</button>
          <button class="btn btn-success w-25" type="submit" @click="add_homework=false">Save</button>
          <button class="btn btn-outline-success w-25 ms-2" type="submit" @click="add_homework=true">Add to homework list</button>
        </div>
      </FormKit>

      <div class="modal fade" id="record_instructions_modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="video_type" class="modal-title">
                Record video
              </h5> 
              <h5 v-else class="modal-title">
                Record audio
              </h5> 
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <Instructions_recording :video_type="video_type" @audioClosed="audioClosed($event)" @videoClosed="videoClosed($event)"/>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="watch_instructions_modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Watch video
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <Watch_recording ref="Watch_recording" @close="watch_instructions=false"/>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  </template>
  <script>
  
  import {updateData, getDataDirectoryCallback} from '@/firebase/index'
  import Instructions_recording from '../components/instructions_recording.vue'
  import Watch_recording from '../components/watch_recording.vue'
  import { useToast } from "vue-toastification";

  export default {
    name: 'activity_custom_module_setup',
    components: {
      Instructions_recording, 
      Watch_recording
    },
    props: {
      edit_meta_module_pointer: null,
      module_folder_locations: []
    },
    data(){
      return {
        module_location: 'General',
        custom_location: '',
        record_instructions: false,
        watch_instructions:false,
        audio_address:'',
        curr_item_index: 0,
        video_type: true,
        module_name: '',
        exercise_meta_data: {
          text : '',
          instruct: '',

          Audio_Instructions : '',
          Video_Instructions : '',
          Video_Address : '',
          Audio_Address : '',

          capture_audio : '',
          checkbox : ''
        },
        custom_module: null,
        add_homework: false,
        client_uid: localStorage.getItem('client_uid')
      }
    },
    created() {
      let _this = this
      
      if (this.edit_meta_module_pointer) {

        let module_data = this.edit_meta_module_pointer.module_handle.val()

        _this.module_name = _this.edit_meta_module_pointer.module_name
        _this.module_location = _this.edit_meta_module_pointer.location

        for (let i=0; i<module_data['exercises'].length; i++) {

          let exercise_address = module_data['exercises'][i]['address']

          getDataDirectoryCallback(exercise_address, (err, exercise_data) => {
            let exercise_data_ = exercise_data.val()

            _this.exercise_meta_data['text'] = exercise_data_['text'],
            _this.exercise_meta_data['instruct'] = exercise_data_['instruct'],

            _this.exercise_meta_data['Audio_Instructions'] = exercise_data_['Audio_Instructions'] ? exercise_data_['Audio_Instructions'] : null
            _this.exercise_meta_data['Video_Instructions'] = exercise_data_['Video_Instructions'] ? exercise_data_['Video_Instructions'] : null
            _this.exercise_meta_data['Video_Address'] = exercise_data_['Video_Address'] ? exercise_data_['Video_Address'] : null
            _this.exercise_meta_data['Audio_Address'] = exercise_data_['Audio_Address'] ? exercise_data_['Audio_Address'] : null

            _this.exercise_meta_data['capture_audio'] = exercise_data_['capture_audio'] ? exercise_data_['capture_audio'] : null
            _this.exercise_meta_data['checkbox'] = exercise_data_['checkbox'] ? exercise_data_['checkbox'] : null
          });
        }


      }
    },
    methods: {
      submit() {
        let uid = localStorage.getItem('uid');

        if (this.module_location === '__other__') {
          if (this.custom_location === '') {
            this.module_location = 'General'
          } else {
            this.module_location = this.custom_location
          }
        }

        let module_page_ref = this.$firebase.database().ref('/Custom_Modules').child(uid).child(this.module_location).child('ActivityModules');

        let module_info = {
          mod_name: this.module_name
        }
        
        updateData(module_page_ref.child(this.module_name), module_info);

        let exercise_address = 'Custom_Exercises/' + uid + '/' + this.module_location + '/ActivityModules/'

        let exercise_page_ref = this.$firebase.database().ref(exercise_address);
        let push_key = exercise_page_ref.push().key;

        let exercise_data = {
          text: this.exercise_meta_data.text,
          instruct: this.exercise_meta_data.instruct,
          
          Audio_Instructions: this.exercise_meta_data.Audio_Instructions,
          Video_Instructions: this.exercise_meta_data.Video_Instructions,
          Audio_Address: this.exercise_meta_data.Audio_Address,
          Video_Address: this.exercise_meta_data.Video_Address,

          capture_audio: this.exercise_meta_data.capture_audio,
          checkbox: this.exercise_meta_data.checkbox
        }

        console.log(exercise_data)
        updateData(exercise_page_ref.child(0), exercise_data)

        let module_exercise_info = {
          address: exercise_address + '/0',
          exercise_range:'single_exercise'
        }

        updateData(module_page_ref.child(this.module_name).child('exercises').child(0), module_exercise_info);

        this.custom_module = module_page_ref.child(this.module_name)

        if (this.add_homework) {
          this.addToHomeworkList()
        } else {
          const toast = useToast()
          toast.success("Module saved!")
          this.module_saved()
        }
      
      },
      async addToHomeworkList() {

        let pro = await this.custom_module.get()

        this.$emit('add_to_assignment_list', pro)
      },
      deleteVideo() {
        
        this.exercise_meta_data.Audio_Instructions = null
        this.exercise_meta_data.Audio_Address = null

        this.exercise_meta_data.Video_Instructions = null
        this.exercise_meta_data.Video_Address = null
      },
      watchVideo() {
        const _this = this

        let curr_video_address = this.exercise_meta_data.Video_Address
        this.watch_instructions = true

        setTimeout(() => {
          _this.$refs.Watch_recording.watchVideoInstructions(curr_video_address)
        }, 100);

      },
      addVideo() {
        this.record_instructions = true
        this.video_type = true
      },
      addAudio() {
        this.record_instructions = true
        this.video_type = false
      },
      audioClosed(audio_address) {
  
        this.record_instructions = false

        if (audio_address!=null) {
          this.exercise_meta_data.Audio_Instructions = audio_address
          this.exercise_meta_data.Audio_Address = audio_address
    
          this.exercise_meta_data.Video_Instructions = null
          this.exercise_meta_data.Video_Address = null
        }
      },
      videoClosed(video_address) {
        this.record_instructions = false

        if (video_address != null) {
  
          this.exercise_meta_data.Video_Instructions = video_address
          this.exercise_meta_data.Video_Address = video_address

          this.exercise_meta_data.Audio_Instructions = null
          this.exercise_meta_data.Audio_Address = null
        }  
      },
      cancel_submit () {
        this.$emit('cancel_submit')
      },
      module_saved () {
        this.$emit('module_saved', this.module_location)
      }
    },
  }
  </script>
  
  <style scoped>
  </style>