<template>
  <div>
    <div class="card card-body mb-2">  
      <p class="lead text-center">
        Generate case note for the session conducted on:
      </p>


      <div class="row justify-content-center">
        <div class="col-3">
          <div class="form-floating">
          <select class="form-select text-center" id="floatingSelect" v-model="session_date" @change="generateReport()" aria-label="Floating label select example">  
            <option v-for="date in dates" :key="date" :label="date" :value="date" />
          </select>
          <label for="floatingSelect">Select date <i class="bi bi-calendar-day"></i></label>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-4 mb-2" v-if="session_date!=null">
        <div class="col">
          <button class="btn btn-primary w-25" @click="generatePDFReport()"><i class="bi bi-download"></i> Download Case Note</button>
        </div>
      </div>
    </div> 
    <vue3-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="2200"
      :filename=clientUID
      :pdf-quality="2"
      :manual-pagination="false"
      :margin="[10, 10]"
      @hasDownloaded="hide_hidden_element=true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"

      ref="html2Pdf"
      > 
      <template  v-slot:pdf-content>
        <div class="card card-body" v-show="session_date!=null">
          <div class="container">
            <section class="pdf-item">
              <div class="row justify-content-center text-center mb-4">
                <div class="col">
                  <img src="@/assets/images/logo/logo_white.svg" alt="" class="d-inline-block align-text-top">
                </div>
              </div>
              <div class="row text-center mb-4">
                <h5>
                  <b>
                    Speech and Language Therapy Session Report


                  </b>

                </h5>
              </div>
              <div v-if="found_results_for_date" class="row mt-4">
                <div class="row">
                  <div class="col">
                    <div  class="text-start" ref="editableDiv">
                      <p class="mb-0">SLT name: {{slt_name}}</p>
                      <p class="mb-0">Patient name: {{client_name}}</p>
                      <p class="mb-0">Date: {{session_date}}</p>
                      <p>Patient consented to the session</p>
                      <p><b>Subjective: </b></p>
                      <p v-show="!hide_hidden_element">{{report_notes['objective']}}</p>
                      <textarea v-show="hide_hidden_element" class="textarea w-100 form-control" type="text" style="height:250px; width: 100px;"
                        v-model="report_notes['subjective']" @keyup="saveReportNote('subjective')" />
                      <br/>
                      <br/>
                      <p><b>Objective: </b></p>
                      <p v-show="!hide_hidden_element">{{report_notes['objective']}}</p>
                      <textarea v-show="hide_hidden_element" class="textarea w-100 form-control" style="height:250px; width: 100px;" type="text" 
                        v-model="report_notes['objective']" @keyup="saveReportNote('objective')" />
                      <br/>
                      <div v-if="Object.keys(assignment_history).length > 0">
                        <b>Actions: </b>
                        <p v-show="!hide_hidden_element">{{report_notes['actions']}}</p>
                        <textarea v-show="hide_hidden_element" class="textarea w-100 form-control" type="text" style="height: 300px; width: 100px;" 
                          v-model="report_notes['actions']" @keyup="saveReportNote('actions')" />
                      </div>
                      <br/>
                      <p><b>Plan: </b></p>
                      <p v-show="!hide_hidden_element">{{report_notes['plan']}}</p>
                      <textarea v-show="hide_hidden_element" class="textarea w-100" type="text" v-model="report_notes['plan']" @keyup="saveReportNote('plan')" />
                      <br/>
                      <br/>
                      <div v-if="report_notes.hasOwnProperty('notes') && Object.keys(report_notes['notes']).length > 0">
                        <div v-for="note_index of Object.keys(report_notes['notes'])">
                          <p><b>Note {{ Number(note_index) + 1 }}:</b></p>
                          <p v-show="!hide_hidden_element">{{report_notes['notes']}}</p>
                          <textarea v-show="hide_hidden_element" class="textarea w-100" type="text" v-model="report_notes['notes'][String(note_index)]" @keyup="saveReportNote('notes')" />
                          <br/>
                          <br/>
                        </div>
                      </div>
                      <button v-if="hide_hidden_element" class="btn btn-outline-primary" @click="add_report_note_section"><i class="bi bi-plus-circle-dotted"></i> Add note</button>
                      <br/>
                      <br/>
                    </div>
                  </div>
                  <div v-if="hide_hidden_element" class="col-1">
                    <button @click="copyToClipBoard()" :class="['btn btn-sm', {'btn-outline-secondary':!copied}, {'btn-success':copied}]" type="button" style="width: 70px">
                      <span v-if="!copied">
                        <i class="bi bi-clipboard fs-4"></i>
                        copy
                      </span>
                      <span v-else>
                        <i class="bi bi-clipboard-check fs-4 w-25"></i>
                        copied
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <div class="container">

              <section class="pdf-item" v-if="!found_results_for_date">
                <div class="row text-center">
                  <div class="card card-body" >
                    <p> Unfortunately we don't yet support reports for the exercises/assessments conducted on this date.</p>
                    <p>This will be included in the near future</p>
                  </div>
                </div>
              </section>
              
              <Token_assessment v-if="all_results_tables.tokenAssessmentTable.length > 0"
                :tokenAssessmentOverview="tokenAssessmentOverview"
                :show_collapse=!hide_hidden_element>
              </Token_assessment>

     

      
              <Memory_image v-if="all_results_tables.memoryImageTable.length > 0"
                :memoryImageOverview="memoryImageOverview">
              </Memory_image>

              <Picture_naming v-if="all_results_tables.pictureNamingTable.length > 0"
                :pictureNamingOverview="pictureNamingOverview"
                :table="all_results_tables.pictureNamingTable"
                :show_collapse=!hide_hidden_element>
              </Picture_naming>

              <Cookie_theft v-if="all_results_tables.cookie_theft.length > 0"
                  :cookieTheftOverview="cookie_theftOverview"
                 :table="all_results_tables.cookie_theft"
                 :show_collapse=!hide_hidden_element>
              </Cookie_theft> 


              <Phonation v-if="all_results_tables.phonationTable.length > 0"
                :phonationOverview="phonationOverview"
                :table="all_results_tables.phonationTable"
                :show_collapse=!hide_hidden_element>
              </Phonation>
              
              <Word_finding v-if="all_results_tables.word_findingTable.length>0"
                :word_findingOverview="word_findingOverview"
                :table="all_results_tables.word_findingTable"
                :show_collapse=!hide_hidden_element>
              </Word_finding>

              <Ddk v-if="all_results_tables.ddkTable.length>0"
                :ddk_interp="ddk_interp"
                :table="all_results_tables.ddkTable"
                :show_collapse=!hide_hidden_element>
              </Ddk>

              <Pitch_variation v-if="all_results_tables.pitchVariationTable.length>0"
                :table="all_results_tables['pitchVariationTable']"
                :show_collapse=!hide_hidden_element>
              </Pitch_variation>

              <Questionnaire v-if="all_results_tables['questionnaires'] && all_results_tables['questionnaires'].length>0"
                :table="all_results_tables['questionnaires']"
                :show_collapse=!hide_hidden_element>
              </Questionnaire>

              <Swal_qol v-if="all_results_tables['swal_qol'] && all_results_tables['swal_qol'].length>0"
                :table="all_results_tables['swal_qol']"
                :show_collapse=!hide_hidden_element>
              </Swal_qol>

              
              <FDA v-if="all_results_tables['fda'] && all_results_tables['fda'].length>0"
                :table="all_results_tables['fda']"
                :show_collapse=!hide_hidden_element>
              </FDA>


              <Semantic_memory v-if="all_results_tables['semantic_memory'] && all_results_tables.semantic_memory.length>0"
                :table="all_results_tables['semantic_memory']"
                :show_collapse=!hide_hidden_element>
              </Semantic_memory>

              <Recognition_memory v-if="all_results_tables['recognition_memory'] && all_results_tables['recognition_memory'].length>0"
                :table="all_results_tables['recognition_memory']"
                :show_collapse=!hide_hidden_element>
              </Recognition_memory>
              
              <Written_words_comprehension v-if="all_results_tables['written_words_comprehension'] && all_results_tables['written_words_comprehension'].length>0"
                :table="all_results_tables['written_words_comprehension']"
                :show_collapse=!hide_hidden_element>
              </Written_words_comprehension>

              <Spoken_words_comprehension v-if="all_results_tables['spoken_words_comprehension'] && all_results_tables['spoken_words_comprehension'].length>0"
                :table="all_results_tables['spoken_words_comprehension']"
                :show_collapse=!hide_hidden_element>
              </Spoken_words_comprehension>

              <Intelligibility v-if="all_results_tables.intelligibilityTable.length>0"
                :intel_words="intel_words"
                :intelligibilityOverview="intelligibilityOverview"
                :table="all_results_tables.intelligibilityTable"
                :show_collapse=!hide_hidden_element>
              </Intelligibility>
              
              <Sentence_intelligibility v-if="all_results_tables.sentence_intelligibilityTable.length>0"
                :sentence_intelligibilityOverview="sentence_intelligibilityOverview"
                :table="all_results_tables.sentence_intelligibilityTable"
                :show_collapse=!hide_hidden_element>
              </Sentence_intelligibility>

            </div>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="row justify-content-center mt-4" v-if="session_date!=null">
      <div class="col">
        <button class="btn btn-primary w-25" @click="generatePDFReport()"><i class="bi bi-download"></i> Download Report</button>
      </div>
    </div>
  </div>
</template>


<script>
import backImg from '@/assets/images/back.png'
import planImg from '@/assets/images/plan.png'
import warnImg from '@/assets/images/warn.png'
import addImg from '@/assets/images/add.png'

import crossImg from '@/assets/images/wrong_cross.png'
import tickImg from '@/assets/images/Tick.png'

import { generateReport, getDataAndDates } from '@/utils/report_generation'
import { roundNumber } from "@/utils/utils"

import jsPDF from 'jspdf'
import Vue3Html2pdf from 'vue3-html2pdf'
import { useToast } from "vue-toastification";

import Token_assessment  from "./components/token_assessment/token_assessment.vue"
import Memory_image  from "./components/memory_image/memory_image.vue"
import Picture_naming  from "./components/picture_naming/picture_naming.vue"
import Cookie_theft  from "./components/cookie_theft/cookie_theft.vue"

import Phonation from "./components/phonation/phonation.vue"
import Word_finding from "./components/word_finding/word_finding.vue"
import Ddk from "./components/ddk/ddk.vue"
import Pitch_variation from "./components/pitch_variation/pitch_variation.vue"
import Questionnaire from "./components/questionnaire/questionnaire.vue"
import Swal_qol from "./components/questionnaire/swal_qol.vue"
import FDA from "./components/fda/fda.vue"

import Semantic_memory from "./components/semantic_memory/semantic_memory.vue"
import Recognition_memory from "./components/recognition_memory/recognition_memory.vue"
import Written_words_comprehension from "./components/written_words_comprehension/written_words_comprehension.vue"
import Spoken_words_comprehension from "./components/spoken_words_comprehension/spoken_words_comprehension.vue"
import Intelligibility from "./components/intelligibility/intelligibility.vue"
import Sentence_intelligibility from "./components/sentence_intelligibility/sentence_intelligibility.vue"

export default {
  name: 'Report',
  props:{

  },
  components: {
    Vue3Html2pdf,
    Token_assessment,
    Memory_image,
    Picture_naming,
    Phonation,
    Word_finding,
    Swal_qol,
    FDA,
    Ddk,
    Cookie_theft,
    Pitch_variation,
    Questionnaire,
    Semantic_memory,
    Recognition_memory,
    Written_words_comprehension,
    Spoken_words_comprehension,
    Intelligibility,
    Sentence_intelligibility,
  },
  data() {
      return {
        copied: false,
        client_name: '',
        slt_name: '',
        data_assessment: [],
        data_training: [],

        dates: [],

        session_date: null,
        
        planImg: planImg,
        warnImg: warnImg,
        addImg: addImg,
        backImg: backImg,
        tickImg: tickImg,
        crossImg: crossImg,

        found_results_for_date:true,
        hide_hidden_element: true,
        all_results_tables: { 
          phonationTable:[],
          word_findingTable:[],
          sentence_intelligibilityTable:[],
          intelligibilityTable:[],
          pictureNamingTable:[],
          tokenAssessmentTable:[],
          memoryImageTable:[],
          ddkTable:[],
          pitchVariationTable:[],
          semantic_memory:[],
          recognition_memory:[],
          written_words_comprehension:[],
          spoken_words_comprehension:[],
          cookie_theft:[]
        },

        word_findingOverview:{},
        phonationOverview:{},
        intelligibilityOverview:{},
        sentence_intelligibilityOverview:{},
        pitchVariationOverview:{},
        cookie_theftOverview:{},

        ddkOverview:{},
        pictureNamingOverview:{},
        tokenAssessmentOverview:{},
        memoryImageOverview:{},
        wwCAOverview:{},
        swCAOverview:{},
        semantic_memoryOverview:{},
        recognition_memoryOverview:{},

        selected_session: '',
        ddk_interp: {
          single: 'NA',
          double: 'NA',
          triple: 'NA'
        },
        intel_words: [ '', '', '' ],
        clientUID: this.$route.query.uid,
        assignment_history: {},
        report_notes: {'subjective': '', 'plan': ''}
      }
  },

  created() {

    const _this = this

    _this.client_name = localStorage.getItem('clientName');
    _this.slt_name = localStorage.getItem('slt_name');

    _this.getDataAndDates()
  },
  mounted: function()
  {
  },
  methods:
  {
    add_report_note_section() {
      if (!this.report_notes.hasOwnProperty('notes')) {
        this.report_notes['notes'] = {}
      }
      
      let length = Object.keys(this.report_notes['notes']).length
      this.report_notes['notes'][String(length)] = ''
    },
    isDateSame(selected_session, date) {
      return (
        date.getFullYear() === selected_session.getFullYear() &&
        date.getMonth() === selected_session.getMonth() &&
        date.getDate() === selected_session.getDate()
      );
    },
    saveReportNote(section) {

      let timestamp = new Date(this.session_date).getTime()

      let uid = localStorage.getItem('uid')
      let client_number = localStorage.getItem('client_uid')

      let report_notes_path = '/Users/' + uid + '/Clients/' + client_number + '/Report_Notes/'
      //console.error({[section]: this.report_notes[section]})
      this.$firebase.database().ref(report_notes_path).child(timestamp).update({[section]: this.report_notes[section]})
    },
    generatePDFReport () {
      this.hide_hidden_element = false

      this.$refs.html2Pdf.generatePdf()
    },
    copyToClipBoard() {
      console.error(this.report_notes)
      this.copied = true
      let text = this.$refs.editableDiv.innerText;
      text = text.replace("Subjective:", "Subjective: \n\n" + this.report_notes['subjective'])
      text = text.replace("Plan:", "Plan: \n\n" + this.report_notes['plan'])
      navigator.clipboard.writeText(text);
      const toast = useToast()
      toast.success("Copied!")

      setTimeout(()=>{
        this.copied = false
      }, 3000);

    },
    async getDataAndDates()
    {
      const _this = this

      let uid = localStorage.getItem('uid');

      let client_number = localStorage.getItem('client_uid');

      let training_results_address = 'Users/'+ client_number +'/Results/clinic_training/general/'
      let assessment_results_address = 'Users/'+ client_number +'/Results/clinic_assessment/general/'

      console.debug('training_results_address = ' + training_results_address)
      console.debug('assessment_results_address = ' + assessment_results_address)

      let promise_data_dates = [getDataAndDates(training_results_address), getDataAndDates(assessment_results_address)]

      const resolved_promise = await Promise.all(promise_data_dates)

      let data_dates_training = resolved_promise[0]
      let data_dates_assessment = resolved_promise[1]

      let all_dates_training = data_dates_training.all_dates
      let all_dates_assessment = data_dates_assessment.all_dates

      let data_training = data_dates_training.data
      let data_assessment = data_dates_assessment.data
      _this.dates = all_dates_training

      for(let i in all_dates_assessment)
      {
        if(!(_this.dates.includes(all_dates_assessment[i])))
        {
          _this.dates.push(all_dates_assessment[i])
        }
      }

      _this.dates = _this.dates.sort(function (a, b) {
        if (a < b) {
          return 1;
        }
        if (a > b) {
          return -1;
        }
        return 0;
      });

      _this.data_training = data_training
      _this.data_assessment = data_assessment    

    },
    async generateReport()
    {

      const _this = this

      let curr_report = await generateReport(_this.data_training,_this.data_assessment,_this.session_date)

      _this.found_results_for_date = _this.populate_with_report_data(curr_report)

      ///////////fill sent assignments for that date

      let uid = localStorage.getItem('uid');
      let client_number = localStorage.getItem('client_uid');

      let selected_session = new Date(this.session_date)
      

      let directory = '/Users/' + uid + '/Clients/' + client_number + '/Assignment_History/'

      console.debug('directory = ' + directory)
      let assignment_promise = await this.$firebase.database().ref(directory).get()
      let whole_assignment_history = assignment_promise.val()
     

      console.debug('whole_assignment_history = ' + whole_assignment_history)

      this.assignment_history = {}
      if (whole_assignment_history!= undefined && whole_assignment_history!= null && 'assessment' in whole_assignment_history) {

        console.debug('assessment')

        for (const [key, data] of Object.entries(whole_assignment_history['assessment'])) {
          if (this.isDateSame(selected_session, new Date(Number(key)))) {
            this.assignment_history['assessment'] = data
          }
        }

        if (!this.assignment_history.hasOwnProperty('assessment')) {
          const keys = Object.keys(whole_assignment_history['assessment']);
          const lastKey = keys[keys.length - 1];

          this.assignment_history['assessment'] = whole_assignment_history['assessment'][lastKey];
        }
      }

      if (whole_assignment_history!= undefined && whole_assignment_history!= null &&  'training' in whole_assignment_history) {
        for (const [key, data] of Object.entries(whole_assignment_history['training'])) {
          if (this.isDateSame(selected_session, new Date(Number(key)))) {
            this.assignment_history['training'] = data
          }
        }

        if (!this.assignment_history.hasOwnProperty('training')) {
          const keys = Object.keys(whole_assignment_history['training']);
          const lastKey = keys[keys.length - 1];

          this.assignment_history['training'] = whole_assignment_history['training'][lastKey];
        }
      }
      ///////////////////////////////////////////////////

      this.report_notes = {}
      let report_notes_path = '/Users/' + uid + '/Clients/' + client_number + '/Report_Notes/'
      let report_notes_promise = await this.$firebase.database().ref(report_notes_path).get()
      let report_notes_data = report_notes_promise.val()

      
      if (report_notes_data) {
        for (const [key, data] of Object.entries(report_notes_data)) {
          if (this.isDateSame(selected_session, new Date(Number(key)))) {
            this.report_notes = data
          }
        }
      }

      if (!this.report_notes.hasOwnProperty('subjective')) 
      {
        this.report_notes['subjective'] = ''
      }

      if (!this.report_notes.hasOwnProperty('plan')) 
      {
        this.report_notes['plan'] = ''
      }

      if (!this.report_notes.hasOwnProperty('objective')) 
      {
        this.report_notes['objective'] = ''


        if (this.all_results_tables.tokenAssessmentTable.length>0) {
          this.report_notes['objective'] += "Token test:" + this.tokenAssessmentOverview['overview_text'] + "\n\n"
        }
        if (this.all_results_tables.pictureNamingTable.length>0) {
          this.report_notes['objective'] += "Picture naming: " + this.pictureNamingOverview['overview_text'] + "\n\n"
        }
        if (this.all_results_tables.intelligibilityTable.length>0) {
          this.report_notes['objective'] += "Word intelligibility: " + this.intelligibilityOverview['overview_text'] + "\n\n"
        }
        if (this.all_results_tables.cookie_theft.length>0) {
          this.report_notes['objective'] += "Cookie theft: " + this.cookie_theftOverview['overview_text'] + "\n\n"
        }
        if (this.all_results_tables.memoryImageTable.length>0) {
          this.report_notes['objective'] += "Memory image: " + this.memoryImageOverview['overview_text'] + "\n\n"
        }
        if (this.all_results_tables.phonationTable.length>0) {
          this.report_notes['objective'] += "Sound phonation: " + this.phonationOverview['overview_text'] + "\n\n"
        }
        if (this.all_results_tables.word_findingTable.length>0) {
          this.report_notes['objective'] += "Semantic fluency: " + this.word_findingOverview['overview_text'] + "\n\n"
        }
        
        if (this.all_results_tables.ddkTable.length>0) {
          this.report_notes['objective'] += "DDK rate: " + this.ddkOverview['overview_text'] + "\n\n"
        }
        if (this.all_results_tables.pitchVariationTable.length>0) {
          this.report_notes['objective'] += "Pitch range: " + this.pitchVariationOverview['overview_text'] + "\n\n"
        }

        if('swal_qol' in curr_report)
        {

          

          _this.all_results_tables.swal_qol = curr_report.swal_qol

          for(let k in this.all_results_tables['swal_qol'])
          {
      
  
            this.report_notes['objective'] += "Swal-Qol: The total score is " + this.all_results_tables['swal_qol'][k]['Score']['Overall'].toFixed(0) + '/100.'

            if('severity_level' in this.all_results_tables['swal_qol'][k]['Score'])
            {
              this.report_notes['objective'] += "   This indicates that the patient's swallowing has "+this.all_results_tables['swal_qol'][k]['Score']['severity_level']+" impact on their daily life."

            }
            this.report_notes['objective'] += " The score of each subset is: \n"

            for(let n in this.all_results_tables['swal_qol'][k]['Score']['Domains'])
            {
              this.report_notes['objective'] += ' - ' + this.all_results_tables['swal_qol'][k]['Score']['Domains'][n]['Name'] + ' : ' + this.all_results_tables['swal_qol'][k]['Score']['Domains'][n]['score'] + '/10\n'
            }

            this.report_notes['objective'] += '\n'

            
            console.debug(this.report_notes['objective'])
          }
      }

      if('fda' in curr_report)
        {

          

          _this.all_results_tables.fda = curr_report.fda


          for(let k in this.all_results_tables['fda'])
          {
      
  
            console.debug(this.all_results_tables['fda'][k])
            this.report_notes['objective'] += "FDA scoring: The total score is " + this.all_results_tables['fda'][k]['Score']['Total']['score'].toFixed(0) + '/' + this.all_results_tables['fda'][k]['Score']['Total']['total'].toFixed(0) + '\n'



            // if('severity_level' in this.all_results_tables['swal_qol'][k]['Score'])
            // {
            //   this.report_notes['objective'] += "   This indicates that the patient's swallowing has "+this.all_results_tables['swal_qol'][k]['Score']['severity_level']+" impact on their daily life."

            // }
            this.report_notes['objective'] += " The score of each subset is: \n"

            let subsets = this.all_results_tables['fda'][k]['Score']['Domains']

 
          
           


            for(let n in subsets)
            {
              this.report_notes['objective'] += ' - ' + subsets[n]['Domain'] + ' : ' + subsets[n]['score'] + '/'+subsets[n]['total'] + '\n'
            }

            this.report_notes['objective'] += '\n'
            
            
          }
      }

      
        if (this.all_results_tables['questionnaires'] && this.all_results_tables['questionnaires'].length>0) {

          for(let k in this.all_results_tables['questionnaires'])
          {
            if('VHI' in this.all_results_tables['questionnaires'][k])
            {
              this.report_notes['objective'] += "Questionnaire: Conducted VHI-"+this.all_results_tables['questionnaires'][k]['VHI']['total_questions']+ " questionnaire. The total score is " + this.all_results_tables['questionnaires'][k]['VHI']['total_score'] + ". This indicates that the patient's voice has "+this.all_results_tables['questionnaires'][k]['VHI']['severity_level']+" impact on their daily life. \n\n"
            }

            if('la_trobe_self' in this.all_results_tables['questionnaires'][k])
            {
              this.report_notes['objective'] += "LCQ: Conducted the La Trobe Communication Questionnaire. The score is " + this.all_results_tables['questionnaires'][k]['la_trobe_self']['total_score'].toFixed(0) + ". This indicates that the patient has "
              
              let score = this.all_results_tables['questionnaires'][k]['la_trobe_self']['total_score'].toFixed(0)
              let impact = ''
              
              if(score<=40)
              {
                impact = 'no communication dificulties.'
                
              }
              else if(score<=60)
              {
                impact = 'mild communication dificulties.'
                
              }
              else if(score<=80)
              {
                impact = 'moderate communication dificulties.'
                
              }
              else if(score<=100)
              {
                impact = 'significant communication dificulties.'
                
              }
              else
              {
                impact = 'severe communication dificulties.'
                
              }

              this.report_notes['objective'] += impact + '\n\n'

            }
          }
        }

        if (this.all_results_tables.sentence_intelligibilityTable.length>0) {
          this.report_notes['objective'] += "Sentence intelligibility: " + this.sentence_intelligibilityOverview['overview_text'] + "\n\n"
        } 

    

        
        if (this.all_results_tables.semantic_memory.length>0) {

          this.report_notes['objective'] += "Semantic Memory Assessment: " + this.semantic_memoryOverview['overview_text'] + "\n\n"
        } 
        
        if (this.all_results_tables.recognition_memory.length>0) {
          this.report_notes['objective'] += "Recognition Memory Assessment: " + this.recognition_memoryOverview['overview_text'] + "\n\n"
        } 


        if (this.all_results_tables.written_words_comprehension.length>0) {
          this.report_notes['objective'] += "Written Words Comprehension Assessment: " + this.wwCAOverview['overview_text'] + "\n\n"
        } 

        if (this.all_results_tables.spoken_words_comprehension.length>0) {
          this.report_notes['objective'] += "Spoken Words Comprehension Assessment: " + this.swCAOverview['overview_text'] + "\n\n"
        } 
      }

      if (!this.report_notes.hasOwnProperty('actions') && Object.keys(this.assignment_history).length > 0) {
        
        this.report_notes['actions'] = ''

        if (this.assignment_history['assessment']) {
          this.report_notes['actions'] += "Assessment assignments sent: \n\n"
          for (const [index, value] of this.assignment_history['assessment'].entries()) {
            if (value.hasOwnProperty('name')) {
              this.report_notes['actions'] += String(index+1) + ". " + value.name + "\n"
            } else {
              this.report_notes['actions'] += String(index+1) + ". " + value + "\n"
            }

          }
          this.report_notes['actions'] += "\n\n"
        }
        
        if (this.assignment_history['training']) {
          this.report_notes['actions'] += "Training assignments sent: \n\n"
          for (const [index, value] of this.assignment_history['training'].entries()) {
            if (value.hasOwnProperty('name')) {
              this.report_notes['actions'] += String(index+1) + ". " + value.name + "\n"
            } else {
              this.report_notes['actions'] += String(index+1) + ". " + value + "\n"
            }
          }
          this.report_notes['actions'] += "\n\n"
        }
      }

    },
    populate_with_report_data(curr_report)
    {

      console.debug('populate_with_report_data!')
      const _this = this

      console.debug(Object.keys(curr_report))

      if('overview' in curr_report)
      {
        _this.session_date = curr_report.overview.date
      }


      
      if('picture_naming' in curr_report)
      {
        _this.pictureNamingOverview = curr_report.picture_naming.overview
        _this.all_results_tables.pictureNamingTable = curr_report.picture_naming.individual_outputs
        let total_hints = 0;
        let total_options = 0;
        for(let i in _this.all_results_tables.pictureNamingTable) {
          // console.debug('pic table = '+  _this.all_results_tables.pictureNamingTable[i].correct);
          if(_this.all_results_tables.pictureNamingTable[i].sound_hints) {
            total_hints = total_hints +1;
          }
          
          if(_this.all_results_tables.pictureNamingTable[i].show_options) {
            total_options = total_options + 1;
          }
        }
        _this.pictureNamingOverview['percentage'] = Math.ceil(_this.pictureNamingOverview.correct/_this.pictureNamingOverview.total *100)
        _this.pictureNamingOverview['total_hints'] = total_hints;
        _this.pictureNamingOverview['total_options'] = total_options;
        _this.pictureNamingOverview['hint_percentage'] = Math.ceil(total_hints/_this.pictureNamingOverview.total *100)
        _this.pictureNamingOverview['option_percentage'] = Math.ceil(total_options/_this.pictureNamingOverview.total *100)

        let overview_text = 'The client performed ' + _this.all_results_tables.pictureNamingTable.length + ' picture naming exercises. They got ' + _this.pictureNamingOverview.correct + ' of them correct.'

        this.pictureNamingOverview['overview_text'] = overview_text
      }

      if('questionnaires' in curr_report)
      {
        _this.all_results_tables['questionnaires'] = curr_report.questionnaires
      }

      if('swal_qol' in curr_report)
      {
        _this.all_results_tables['swal_qol'] = curr_report.swal_qol
      }

      
      if('fda' in curr_report)
      {
        _this.all_results_tables['fda'] = curr_report.fda
      }


      if('phonation' in curr_report)
      {
        _this.phonationOverview = curr_report.phonation.overview
        _this.all_results_tables.phonationTable = curr_report.phonation.individual_outputs
        // console.debug('avg loudness = ' + _this.phonationOverview.average_loudness + ' avg duration= '+_this.phonationOverview.average_duration)
        let overview_text = 'The client did ' + _this.all_results_tables.phonationTable.length + ' phonation exercises.'


        let mean_db = [0,0]

        for(let n in _this.all_results_tables.phonationTable)
        {

          if(_this.all_results_tables.phonationTable[n].loudness > 0)
          {
            mean_db[0] = mean_db[0] + _this.all_results_tables.phonationTable[n].mean_db
            mean_db[1] = mean_db[1] + 1
          }

        }

        if(_this.phonationOverview.average_duration>0)
        {
          overview_text+= ' The average phonation duration was '+ _this.phonationOverview.average_duration + 's'
          
          if(mean_db[0]>0)
          {
            overview_text+= ' with mean ' + roundNumber(mean_db[0]/mean_db[1],0)+ 'dB.'
          }
          else
          {
            overview_text+='.'
          }

        }


        if(_this.phonationOverview.average_max_loudness>0)
        {
          overview_text+= ' The average max loudness was '+ _this.phonationOverview.average_max_loudness + 'db.'
        }
        
        this.phonationOverview['overview_text'] = overview_text      
      }


      if('token_assessment' in curr_report)
      {
        _this.tokenAssessmentOverview = curr_report.token_assessment.overview
        _this.all_results_tables.tokenAssessmentTable = curr_report.token_assessment.individual_outputs
        _this.tokenAssessmentOverview['percentage'] = Math.ceil(_this.tokenAssessmentOverview.correct/_this.tokenAssessmentOverview.total *100)
        

        let overview_text = 'The client did ' + _this.all_results_tables.tokenAssessmentTable.length + ' token assessment exercises. They got '+ _this.tokenAssessmentOverview['percentage'] + '% of the tasks correct.'
        this.tokenAssessmentOverview['overview_text'] = overview_text
      }

      if('memory_image' in curr_report && curr_report.memory_image.individual_outputs.length>0)
      {
        _this.memoryImageOverview = curr_report.memory_image.overview
        _this.all_results_tables.memoryImageTable = curr_report.memory_image.individual_outputs
        _this.memoryImageOverview['percentage'] = Math.ceil(_this.memoryImageOverview.correct/_this.memoryImageOverview.total *100)

        let overview_text = 'The client did ' + _this.all_results_tables.memoryImageTable.length + ' memory exercises. They got '+ _this.memoryImageOverview['percentage'] + '% of the tasks correct.'
        this.memoryImageOverview['overview_text'] = overview_text
      }


      if('semantic_memory' in curr_report && curr_report.semantic_memory.individual_outputs.length>0)
      {
        _this.semantic_memoryOverview = curr_report.semantic_memory.overview
        _this.all_results_tables.semantic_memory = curr_report.semantic_memory.individual_outputs

      }
      else
      {
        _this.all_results_tables.semantic_memory = []
      }

      if('recognition_memory' in curr_report && curr_report.recognition_memory.individual_outputs.length>0)
      {
        _this.recognition_memoryOverview = curr_report.recognition_memory.overview
        _this.all_results_tables.recognition_memory = curr_report.recognition_memory.individual_outputs

      }
      else
      {
        _this.all_results_tables.recognition_memory = []
      }


      if('written_words_comprehension' in curr_report && curr_report.written_words_comprehension.individual_outputs.length>0)
      {
        _this.wwCAOverview = curr_report.written_words_comprehension.overview
        _this.all_results_tables.written_words_comprehension = curr_report.written_words_comprehension.individual_outputs

      }
      else
      {
        _this.all_results_tables.written_words_comprehension = []
      }



      if('spoken_words_comprehension' in curr_report && curr_report.spoken_words_comprehension.individual_outputs.length>0)
      {
        _this.swCAOverview = curr_report.spoken_words_comprehension.overview
        _this.all_results_tables.spoken_words_comprehension = curr_report.spoken_words_comprehension.individual_outputs

     
      }
      else
      {
        _this.all_results_tables.spoken_words_comprehension = []
      }

      
      if('cookie_theft' in curr_report && curr_report.cookie_theft.individual_outputs.length>0)
      {
        _this.cookie_theftOverview = curr_report.cookie_theft.overview

        console.debug('_this.cookie_theftOverview')
        console.debug(curr_report.cookie_theft)
        
        console.debug(_this.cookie_theftOverview)
        _this.all_results_tables.cookie_theft = curr_report.cookie_theft.individual_outputs

     
      }
      else
      {
        _this.all_results_tables.spoken_words_comprehension = []
      }

      if('word_finding' in curr_report && curr_report.word_finding.individual_outputs.length>0)
      {
        _this.word_findingOverview = curr_report.word_finding.overview
        _this.all_results_tables.word_findingTable = curr_report.word_finding.individual_outputs
        let sortable = _this.all_results_tables.word_findingTable.toSorted((a, b) => {
            return a.Number_Words - b.Number_Words;
        });
        let leastTopic = [];
        let total_hint = 0;

        let all_topics = []

        for(let n in _this.all_results_tables.word_findingTable)
        {
          all_topics.push(_this.all_results_tables.word_findingTable[n].Topic)
        }



        function onlyUnique(value, index, array) {
          return array.indexOf(value) === index;
        }

        var unique_topics = all_topics.filter(onlyUnique);


        for(let count_hint in sortable)
        {
          if(count_hint <3)
          {
            if(sortable[count_hint].Number_Words < 8)
            {
              leastTopic.push({topic: sortable[count_hint].Topic,num_words : sortable[count_hint].Number_Words})
            }
            else
            {
              leastTopic[0] = {topic: sortable[0].Topic,num_words : sortable[0].Number_Words}
            }
          }
          if(sortable[count_hint].Number_Hints>0)
          {
            total_hint = total_hint + 1;
          }
        }

        let overview_text = 'The client performed the following semantic fluency tasks: '

        for(let n in unique_topics)
        {
          if(unique_topics.length==1)
          {
            overview_text = 'The client performed the following semantic fluency task: ' + unique_topics[n]
          }
          else if(n<(unique_topics.length-1))
          {
            overview_text+= unique_topics[n] + ', '
          }
          else
          {
            overview_text+= 'and ' + unique_topics[n]
          }
        }


        
        _this.word_findingOverview['leastTopic'] = leastTopic
        _this.word_findingOverview['total_hint'] = total_hint
        _this.word_findingOverview['total'] = _this.all_results_tables.word_findingTable.length
        _this.word_findingOverview['hint_percentage'] = Math.ceil(total_hint/_this.all_results_tables.word_findingTable.length *100)

        overview_text+='. The client said on average ' + this.word_findingOverview.average_words + ' words per topic.'
        
        if(leastTopic.length>0)
        {
          overview_text+= ' The most challenging topic was ' + leastTopic[0].topic + '.' 

        }

        overview_text+= ' The best one was ' + sortable[0].Topic + '.'


        _this.word_findingOverview['overview_text'] = overview_text


      }

      if('sentence_intelligibility' in curr_report)
      {
          _this.sentence_intelligibilityOverview = curr_report.sentence_intelligibility.overview
          _this.all_results_tables.sentence_intelligibilityTable = curr_report.sentence_intelligibility.individual_outputs

          let mean_db = [0,0]
          let max_db = [0,0]
          let mean_intelligibility = [0,0]


          for(let n in _this.all_results_tables.sentence_intelligibilityTable)
          {

            //mean loudness
            if(_this.all_results_tables.sentence_intelligibilityTable[n].loudness>0)
            {
              mean_db[0] += _this.all_results_tables.sentence_intelligibilityTable[n].loudness
              mean_db[1] += 1
            }

            //max loudness
            if(_this.all_results_tables.sentence_intelligibilityTable[n].hasOwnProperty('max_loudness') && 
               _this.all_results_tables.sentence_intelligibilityTable[n].max_loudness > 0)
            {
              max_db[0] += _this.all_results_tables.sentence_intelligibilityTable[n].max_loudness
              max_db[1] += 1
            }

            if(_this.all_results_tables.sentence_intelligibilityTable[n].accuracy>0)
            {
              mean_intelligibility[0] += _this.all_results_tables.sentence_intelligibilityTable[n].accuracy
              mean_intelligibility[1] += 1
            }

          }


          let overview_text = 'The client did ' + _this.all_results_tables.sentence_intelligibilityTable.length + ' sentence reading exercises.'

          if(mean_db[0]>0 && mean_intelligibility[0]>0)
          {
            overview_text+= ' It had an average intelligibility of ' + roundNumber(mean_intelligibility[0]/mean_intelligibility[1],0) + '% and mean loudness of ' + roundNumber(mean_db[0]/mean_db[1],0) + 'dB.'

          }
          else if(mean_db[0]>0)
          {
            overview_text+= ' It had a mean loudness of ' + roundNumber(mean_db[0]/mean_db[1],0) + 'dB.'
          }
          else if(mean_intelligibility[0]>0)
          {
            overview_text+= ' It had an average intellibility of ' + roundNumber(mean_intelligibility[0]/mean_intelligibility[1],0) + '%.'
          }
          
          if(max_db[0] > 0) {
            overview_text+= ' It had a mean max loudness of ' + roundNumber(max_db[0]/max_db[1],0) + 'dB.'
          }

          this.sentence_intelligibilityOverview['overview_text'] = overview_text
      }

      if('intelligibility' in curr_report)
      {
        _this.intelligibilityOverview = curr_report.intelligibility.overview
        _this.all_results_tables.intelligibilityTable = curr_report.intelligibility.individual_outputs
        let intel_acc = [];
        let newArr = []
        for(let n=0; n<_this.all_results_tables.intelligibilityTable.length;n++)
        {
          intel_acc.push(_this.all_results_tables.intelligibilityTable[n]['accuracy']);
        }
        newArr = intel_acc.map(function(o, i){return {idx: i, obj: o}; }).sort(function(a, b) {
            return a.obj - b.obj;
        });
        if(newArr.length>0)
        {
          for(let k=0;k<Math.min(newArr.length,3);k++)
          {
            let id = newArr[k].idx;
            _this.intel_words[k] = _this.all_results_tables.intelligibilityTable[id]['name'] +' ('+_this.all_results_tables.intelligibilityTable[id]['accuracy']+'%)';
          }

        }

        
        let mean_db = [0,0]
        let max_db = [0,0]
        let mean_intelligibility = [0,0]


        for(let n in _this.all_results_tables.intelligibilityTable)
        {

          if(_this.all_results_tables.intelligibilityTable[n].loudness>0)
          {
            mean_db[0] += _this.all_results_tables.intelligibilityTable[n].loudness
            mean_db[1] += 1
          }

          if(_this.all_results_tables.intelligibilityTable[n].nax_loudness>0)
          {
            max_db[0] += _this.all_results_tables.intelligibilityTable[n].nax_loudness
            max_db[1] += 1
          }

          if(_this.all_results_tables.intelligibilityTable[n].accuracy>0)
          {
            mean_intelligibility[0] += _this.all_results_tables.intelligibilityTable[n].accuracy
            mean_intelligibility[1] += 1
          }

        }


        let overview_text = 'The client did ' + _this.all_results_tables.intelligibilityTable.length + ' word reading exercises.'

        if(mean_db[0]>0 && mean_intelligibility[0]>0)
        {
          overview_text+= ' It had an average intelligibility of ' + roundNumber(mean_intelligibility[0]/mean_intelligibility[1],0) + '% and mean loudness of ' + roundNumber(mean_db[0]/mean_db[1],0) + 'dB.'

        }
        else if(mean_db[0]>0)
        {
          overview_text+= ' It had a mean loudness of ' + roundNumber(mean_db[0]/mean_db[1],0) + 'dB.'

        }
        else if(mean_intelligibility[0]>0)
        {
          overview_text+= ' It had an average intellibility of ' + roundNumber(mean_intelligibility[0]/mean_intelligibility[1],0) + '%.'
        }

        if(max_db[0]>0)
        {
          overview_text+= ' It had a mean max loudness of ' + roundNumber(max_db[0]/max_db[1],0) + 'dB.'
        }

        this.intelligibilityOverview['overview_text'] = overview_text
      }

      if('pitch_variation' in curr_report)
      {

        _this.pitchVariationOverview = curr_report.pitch_variation.overview
        _this.all_results_tables.pitchVariationTable = curr_report.pitch_variation.individual_outputs

        let mean_pitch = [0,0]
        let max_freq = [0,0]
        let min_freq = [0,0]
        for(let n in _this.all_results_tables.pitchVariationTable)
        {

          if(_this.all_results_tables.pitchVariationTable[n]['max_freq']>0)
          {
            max_freq[0] = max_freq[0]+_this.all_results_tables.pitchVariationTable[n]['max_freq']
            max_freq[1] = max_freq[1]+1

          }

          if(_this.all_results_tables.pitchVariationTable[n]['min_freq']>0)
          {
            min_freq[0] = min_freq[0]+_this.all_results_tables.pitchVariationTable[n]['min_freq']
            min_freq[1] = min_freq[1]+1

          }
          
          if(_this.all_results_tables.pitchVariationTable[n]['mean_freq']>0)
          {
            mean_pitch[0] = mean_pitch[0]+_this.all_results_tables.pitchVariationTable[n]['mean_freq']
            mean_pitch[1] = mean_pitch[1]+1

          }
          
        }

        let overview_text = 'The client performed ' + _this.all_results_tables.pitchVariationTable.length + ' pitch exercises.'

        if(max_freq[0]>0 && min_freq[0]>0)
        {
          overview_text+= ' The average maximum frequency was ' + roundNumber(max_freq[0]/max_freq[1], 0) 
          overview_text+= ' and the average minimum frequency was ' + roundNumber(min_freq[0]/min_freq[1], 0) + '.' 
        }

        this.pitchVariationOverview['overview_text'] = overview_text



      }

      if('ddk_rate' in curr_report && curr_report.ddk_rate.individual_outputs.length>0)
      {
          _this.ddkOverview = curr_report.ddk_rate.overview
          _this.all_results_tables.ddkTable = curr_report.ddk_rate.individual_outputs
          let avg_single_count = 0;
          let single_num = 0;
          let avg_double_count = 0;
          let double_num = 0;
          let avg_triple_count = 0;
          let triple_num = 0;

          
          for(let i=0; i<_this.all_results_tables.ddkTable.length;i++)
          {
            if((_this.all_results_tables.ddkTable[i]['name'] == 'Pa') || (_this.all_results_tables.ddkTable[i]['name'] == 'Ta') || (_this.all_results_tables.ddkTable[i]['name'] == 'Ka'))
            {
              avg_single_count = avg_single_count + _this.all_results_tables.ddkTable[i]['number_repetitions'];
              single_num = single_num + 1;
              _this.all_results_tables.ddkTable[i]['interp'] = _this.compare_ddkCounts(_this.all_results_tables.ddkTable[i]['number_repetitions'],'single',true);
            }
            else if(_this.all_results_tables.ddkTable[i]['name'] == 'Ka La')
            {
              avg_double_count = avg_double_count + _this.all_results_tables.ddkTable[i]['number_repetitions'];
              double_num = double_num + 1;
              _this.all_results_tables.ddkTable[i]['interp'] = _this.compare_ddkCounts(_this.all_results_tables.ddkTable[i]['number_repetitions'],'double',true);
            }
            else if(_this.all_results_tables.ddkTable[i]['name'] == 'Pa Ta Ka')
            {
              avg_triple_count = avg_triple_count + _this.all_results_tables.ddkTable[i]['number_repetitions'];
              triple_num = triple_num + 1;
              _this.all_results_tables.ddkTable[i]['interp'] = _this.compare_ddkCounts(_this.all_results_tables.ddkTable[i]['number_repetitions'],'triple',true);
            }
          }
          if(avg_single_count != 0)
          {
            let single_score = avg_single_count/single_num;
            single_score = roundNumber(single_score,0)
            _this.ddk_interp.single = single_score 
            _this.ddk_interp['single_rating'] = _this.compare_ddkCounts(single_score,'single',false)
          }
          if(avg_double_count != 0)
          {
            let double_score = avg_double_count/double_num;
            double_score = roundNumber(double_score,0)
            _this.ddk_interp.double = double_score 
            _this.ddk_interp['double_rating'] = _this.compare_ddkCounts(double_score,'double',false)
          }
          if(avg_triple_count != 0)
          {
            let triple_score = avg_triple_count/triple_num;
            triple_score = roundNumber(triple_score,0)
            _this.ddk_interp.triple = triple_score 
            _this.ddk_interp['triple_rating'] = _this.compare_ddkCounts(triple_score,'triple',false)
          }

          if(_this.all_results_tables.ddkTable.length>0)
          {
            _this.ddk_interp['duration'] = _this.all_results_tables.ddkTable[0]['test_duration'] 

          }

          let overview_text = 'The client performed ' + _this.all_results_tables.ddkTable.length + ' assessments.'

          if(avg_single_count != 0)
          {
            overview_text+= ' For single syllables, the average number of repetitions was ' + _this.ddk_interp.single  + ' in ' + this.ddk_interp.duration + 's.'

          }

          if(avg_double_count != 0)
          {
            overview_text+= ' For double syllables, the average number of repetitions was ' + _this.ddk_interp.double  + ' in ' + this.ddk_interp.duration + 's.'

          }

          if(avg_triple_count != 0)
          {
            overview_text+= ' For triple syllables, the average number of repetitions was ' + _this.ddk_interp.triple  + ' in ' + this.ddk_interp.duration + 's.'

          }


          this.ddkOverview['overview_text'] = overview_text

      }

      let results_to_display_for_date = false

      for(let i in _this.all_results_tables)
      {

        if(_this.all_results_tables[i].length>0)
        {
          results_to_display_for_date = true

          break
        }

      }

      return results_to_display_for_date


    },
    compare_ddkCounts(counts, type, metadata)
    {
      if(type == 'single')
      {
        if(counts >=20)
        {
          let op_str = 'Normal'
          if(metadata)
          {
            op_str = op_str + ' (20-30)';
          }
          
          return op_str
        }
        else if((counts>=15) && (counts<20))
        {
          let op_str = 'Good'
          if(metadata)
          {
            op_str = op_str + ' (15-19)';
          }
          
          return op_str
        }
        else if((counts>=8) && (counts<15))
        {
          let op_str = 'Fair'
          if(metadata)
          {
            op_str = op_str + ' (8-14)';
          }
          
          return op_str
        }
        else
        {
          let op_str = 'Poor'
          if(metadata)
          {
            op_str = op_str + ' (1-7)';
          }
          
          return op_str
        }
      }
      else if(type == 'double')
      {
        if(counts >=15)
        {
          let op_str = 'Normal'
          if(metadata)
          {
            op_str = op_str + ' (15-20)';
          }
          
          return op_str
        }
        else if((counts>=10) && (counts<15))
        {
          let op_str = 'Good'
          if(metadata)
          {
            op_str = op_str + ' (10-14)';
          }
          
          return op_str
        }
        else if((counts>=5) && (counts<10))
        {
          let op_str = 'Fair'
          if(metadata)
          {
            op_str = op_str + ' (5-9)';
          }
          
          return op_str
        }
        else
        {
          let op_str = 'Poor'
          if(metadata)
          {
            op_str = op_str + ' (1-4)';
          }
          
          return op_str
        }
      }
      else if(type == 'triple')
      {
        if(counts >=12)
        {
          let op_str = 'Normal'
          if(metadata)
          {
            op_str = op_str + ' (12-15)';
          }
          
          return op_str
        }
        else if((counts>=8) && (counts<12))
        {
          let op_str = 'Good'
          if(metadata)
          {
            op_str = op_str + ' (8-11)';
          }
          
          return op_str
        }
        else if((counts>=4) && (counts<8))
        {
          let op_str = 'Fair'
          if(metadata)
          {
            op_str = op_str + ' (4-7)';
          }
          
          return op_str
        }
        else
        {
          let op_str = 'Poor'
          if(metadata)
          {
            op_str = op_str + ' (1-3)';
          }
          
          return op_str
        }
      }
    },
  }
}
</script>
<style scoped>
.textarea {
  white-space: pre-wrap; /* Ensures line breaks are respected */
  word-wrap: break-word; /* Ensures long words wrap */
}

.accordion-button {
    background: lavender;
}


li:hover {
background-color: #e0e0e2;
color: white;
}
</style>





