<template>
  <div class=""> 
    <CommunicationDifficulties :uid="uid" @changedSetting="emitChangedComm"/>
    <LanguageRegion :uid="uid"/>
    
    <div class="card m-2">
      <div class="card-body">
        <h5>Topics of interest</h5>
        <p class="lead">Select topics of interest to personalise your exercises</p>
        <div class="row" v-for="i in allInterests.length" :key="i">
          <div class="col text-center d-flex justify-content-center" v-for="j in allInterests[i-1].length" :key="j"> 
            <a @click="checkbutton(i-1, j-1)" > 
              <div v-if="allInterests[i-1][j-1].checked" class="interestButtonChecked" style="position:relative">
                <img class="interestThumbnail" :src="allInterests[i-1][j-1].bg"  style="opacity: 80%"/>
              </div>
              <div v-else class="interestButtonUnChecked" style="position:relative">
                <img class="interestThumbnail" :src="allInterests[i-1][j-1].bg" style="opacity: 40%"/>
                <div class="bi bi-plus-lg" style="height:100%; margin-left: auto; margin-right: auto; left: 0; right: 0; top:18px; text-align: center; font-size: 60px; color:gray; font-weight: 1000; position:absolute;"/>
              </div>
              <p class="text-overlay">{{allInterests[i-1][j-1].name}} <i v-if="allInterests[i-1][j-1].checked" class="bi bi-check" style="font-size: 24px; color: rgb(7, 241, 77); font-weight: 1000;"/></p> 
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Account information</h5>
        <div class="row justify-content-between">
          <div class="col-md-5">
            <p class="lead mt-3">{{ name }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label class="form-label">Email Address</label>
            <input class="form-control mb-3" v-model="email" autocomplete="off" disabled />

            <label class="form-label">Display name</label>
            <input class="form-control mb-3" v-model="userName" autocomplete="off" />

            <label class="form-label">Reminder frequency</label>
            <select class="form-select mb-3" v-model="freq" placeholder="Select">
              <option key="1" label="Every day of inactivity" value="1"> </option>
              <option key="2" label="Every 2 days of inactivity" value="2"> </option>
              <option key="3" label="Every 3 days of inactivity" value="3"> </option>
              <option key="4" label="Every 4 days of inactivity" value="4"> </option>
              <option key="5" label="Every 5 days of inactivity" value="5"> </option>
              <option key="6" label="Every 6 days of inactivity" value="6"> </option>
              <option key="7" label="Every 7 days of inactivity" value="7"> </option>
              <option key="8" label="Opt out" value="0"> </option>
            </select>
            <button class="btn btn-outline-primary float-end" @click="updateUserInfo('userName_notification_freq')">Update</button>
          </div>
          <div class="col-lg-6" v-if="tier_name && next_payment_due">
            <div class="card h-100">
              <div class="card-body">
                <p><strong>Subscription plan:</strong> <i>{{ tier_name }}</i></p>
                <p v-if="!tier.includes('F') && expiry_date != ''" ><strong>Expiry date:</strong> <i>{{ expiry_date }}</i></p>
                <p v-if="!tier.includes('F') && !tier.includes('Trial')" ><strong>Next payment due:</strong> <i>{{ next_payment_due }}</i></p>

                <button v-if="tier.includes('F') || tier == 'T1' || tier.includes('Trial')" class="btn btn-primary float-start" @click="goToSubscriptionPage()">Upgrade Subscription</button>
                <button v-if="!tier.includes('Trial') && !tier.includes('F')" class="btn btn-outline-success float-end" @click="goToCustomerPortal()">Payment details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { getUserDataDir, getData, updateDataDirectory } from '@/firebase/index'
import CommunicationDifficulties from '@/views/common/settings/Communication_difficulties.vue'
import LanguageRegion from '@/views/common/settings/LanguageRegion.vue'
import { useToast } from "vue-toastification";
import { useTTS } from '@/stores/tts'

export default {
  components: { CommunicationDifficulties, LanguageRegion },
  name: 'Patient_setting',
  props: {
    tier_name: {
      type:String,
      required: true,
    }, 
    next_payment_due: {
      type:String,
      required: true,
    }, 
  },
  emits: ['changedSetting'],
  data(){
    return {
      userCategory: localStorage.getItem('userCategory'),
      region: localStorage.getItem('region'),
      language: localStorage.getItem('language'),
      tier: sessionStorage.getItem("tier"),
      license_status: sessionStorage.getItem('license_status'),

      email: '',
      userName: '',
      freq: '3',
      
      colNum: 5,
      allInterests: [],

      expiry_date: '',

      bv_tts: useTTS().tts_engine,
      voices: [],
      preferred_voice: null,

      tts_enabled: false,
      uid: localStorage.getItem('uid')
    }
  },
  created() {
    this.loadUserInfo()
    this.loadEmail()
    this.fetchInterestList()
  },
  async mounted() {
    this.matchAgainstUserInterestList()
  },
  watch: {
    next_payment_due(new_expiry) {
      this.expiry_date = new_expiry
    }
  },
  methods: {
    update_auto_tts() {
      let ref_path = 'Users/' + this.uid + '/Settings'
      updateDataDirectory(ref_path, {'auto_tts': this.tts_enabled})
    },
    test_voice() {
      this.bv_tts.say("Hello! Let's practice our communication today!")
    },
    goToSubscriptionPage() {
      this.$router.push({name: "subscribe"})
    },
    goToCustomerPortal() {
      window.location.href = import.meta.env.VITE_CUSTOMER_PORT_LINK + this.email
    },
    loadEmail() {
      let _this = this;
      getUserDataDir('Data/' , function (err, result) {
        _this.email = result.val()['Email'];
      });
    },
    async loadUserInfo() {
      let _this = this;

      await new Promise(resolve => {

        getUserDataDir('Data/' , async function (err, result) {
          
          _this.name =  result.child('Firstname').val().charAt(0).toUpperCase() + result.child('Firstname').val().slice(1) + ' ' +
                        result.child('Surname').val().charAt(0).toUpperCase() + result.child('Surname').val().slice(1)
          
          resolve()
        });
      }) 

      await new Promise(resolve => {
        getUserDataDir('Settings/', async function (err, result) {

          let userName = result.child('userName').val()

          if(userName == undefined || userName == null) {
            _this.userName = _this.firstname
          } else {
            _this.userName = userName;
          }

          let notification_frequency = result.child('notification_frequency').val()

          if(notification_frequency == undefined || notification_frequency == null) {
            _this.freq = "3";
          } else {
            _this.freq = notification_frequency;
          }

          let user_interests = result.child('interests').val()
          localStorage.setItem("interests_array", JSON.stringify(user_interests))

          resolve()
        })
      })

    },
    emitChangedComm() {
      this.$emit('changedSetting')
    },
    updateUserInfo(inp)
    {
      let _this = this;

      let settings_ref_path = 'Users/' + this.uid + '/Settings/'
      
      let info_updated = false
      
      if(inp == 'userName_notification_freq') {
        updateDataDirectory(settings_ref_path, {'notification_frequency': _this.freq, 'userName': _this.userName});

        info_updated = true
      } else if(inp == 'interests') {
        let interests_array = [];
        for(let k=0;k<_this.allInterests.length;k++) {

          for(let j=0; j<_this.allInterests[k].length; j++) {
            if(_this.allInterests[k][j].checked == true) {
              let interest_name = _this.allInterests[k][j].name;
              interests_array.push(interest_name);
            }
          }
        }

        localStorage.setItem('interests_array', JSON.stringify(interests_array))
        //console.debug('set interest array = ' + interests_array)

        updateDataDirectory(settings_ref_path, {'interests':interests_array});

        info_updated = true
      }
       if (info_updated) {
        const toast = useToast()
        toast.info("Your information has been updated.")
      }
      _this.$emit('changedSetting')
    },
    checkbutton(i, j)
    {
      if(this.allInterests[i][j].checked == true) {
        this.allInterests[i][j].checked = false;
      } else {
        this.allInterests[i][j].checked = true;
      }

      this.updateUserInfo('interests')
    },
    fetchInterestList()
    {
      let _this = this;
      let user_ref = _this.$firebase.database().ref('Exercises').child('General').child('News').child('UK').child('Standard').child('Short');

      var do_once = true;
      _this.allInterests = [];
      getData(user_ref , function (err, result) {

        if (do_once) {

          do_once = false;

          result.forEach(function(childSnapshot) {
            let interest_temp = {name:childSnapshot.key, bg:'', checked:''};
            let image_file = childSnapshot.key.replaceAll(' ','')
            let bg_img = new URL(`../../../assets/images/interests/${image_file}.png`, import.meta.url)

            interest_temp.bg = bg_img

            _this.allInterests.push(interest_temp);
          });

          _this.matchAgainstUserInterestList();
        }
      });

    },
    matchAgainstUserInterestList()
    {
      const _this = this

      let cached_interests_array = localStorage.getItem('interests_array');

      if(cached_interests_array!=undefined && cached_interests_array!=null) {
        cached_interests_array = JSON.parse(cached_interests_array)
      } else {
        cached_interests_array = []
      }

      //console.debug('interests localstorage = ' + cached_interests_array)

      for(let n in cached_interests_array) {
        for(let i in _this.allInterests) {
          if(cached_interests_array[n]==_this.allInterests[i].name) {
            //console.debug('found interest')
            _this.allInterests[i].checked = true
            break;
          }
        }
      }

      //convert to 2d
      let tmp = []
      let index = 0
      for(let i=0; i<_this.allInterests.length; i=i+this.colNum) {


        tmp.push([])

        for(let j=0; j<this.colNum; j++) {
          let defined = this.allInterests[i+j]
          if (defined) { //null check
            tmp[index].push(defined)
          }
        }

        index++;
      }

      this.allInterests = tmp;
    },

  }
}
</script>
<style scoped>

.interestThumbnail {
  width: 120px;
  height: 120px;
  position: relative;
}

.text-overlay {
  /* margin-top:5%; */

  color:rgb(0, 0, 0);

  font-size: 16px;
}

.interestButtonChecked {
  width: 120px;
  height: 120px;
  overflow: hidden;

  cursor:pointer;

  border: 5px solid rgb(7, 241, 77);

  background-color: #555555;
}

.interestButtonUnChecked {
  width: 120px;
  height: 120px;
  overflow: hidden;

  cursor:pointer;
  
  border: 5px solid rgb(128, 127, 127);
}


</style>