<template>
  <div>
    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body"  style="border-color: blueviolet;">
          <div class="accordion" id="accordionPhonation">
            <div class="accordion-item mb-3">
              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#phonation_collapse" aria-expanded="false" aria-controls="phonation_collapse"><p class="text-start" >
                  <b>Sound phonation</b></p></button>
              </div>
              <div :class="['accordion-collapse', 'collapse', show_collapse ? 'show': '']" id="phonation_collapse" data-bs-parent="#accordionPhonation">

                <div class="row m-2 mt-4">
                  <div class="col-6 text-start">
                    <p>
                      Avg. phonation duration:
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p> {{phonationOverview.average_duration}}s </p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>
                      Longest phonation:
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p> {{phonationOverview.highest_duration}}s </p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>
                      Shortest phonation:
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{phonationOverview.lowest_duration}}s </p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>
                      Average loudness:
                    </p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{phonationOverview.average_loudness}}dB </p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <!-- <span>
                  Result summary: <br>Average phonation duration: {{phonationOverview.average_duration}}s. <br> 
                  Longest phonation: {{phonationOverview.highest_duration}}s. Shortest phonation: {{phonationOverview.lowest_duration}}s.<br>  Total Exercises: {{phonationOverview.total_exercises}}
                </span> -->
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr class="text-center">
                          <th>Exercise</th>
                          <th>Loudness (dB)</th>

                          <th>Duration (s)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center" v-for="(item, index) in table" :key="index" >
                          <td>{{ item.name }}</td>
                          <td>{{ item.loudness }}</td>

                          <td>{{ item.max_phonation }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'Phonation',
  props: ['phonationOverview', 'table', 'show_collapse'],
  setup(props, { emit }) {

    return {
      
    }

  }
}

</script>
<style scoped>


</style>





