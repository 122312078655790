<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>
    <div class="container-fluid">
      <h1 class="display-5" v-if="list_type=='clinic'">Clinic training history</h1>
      <h1 class="display-5" v-if="list_type=='home'">Home training & assignment history</h1>
      <h1 class="display-5" v-if="list_type=='assessment'">Assessment history</h1>

      <div class="card card-body p-4">
        <table class="table table-hover overflow-auto" style="height:100px">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row, index in historyDataView" v-bind:key="index">
              <td>{{ row.date }}</td>
              <td>{{ row.module_name }}</td>
              <td><button class="btn btn-primary" @click="goResultsPage(row)">View results</button></td>
              <td><button class="btn btn-outline-danger" @click="delete_session_results(row)"><i class="bi bi-x-lg"></i></button></td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>
</template>

<script>
import Navbar from '@/views/Navbar.vue'
import Footerbar from '@/views/Footerbar.vue'
import { getData } from '@/firebase/index'
import { useToast } from "vue-toastification";

export default {
    name: 'results_list',
    components: {Footerbar, Navbar},
    data() {
      return {
        client_uid: localStorage.getItem('client_uid'),
        userCategory: localStorage.getItem("userCategory"),
        uid: localStorage.getItem('uid'),
        list_type: this.$route.query.list_type,

        start_date: this.$route.query.start_date,
        end_date: this.$route.query.end_date,
        
        requested_module: this.$route.query.requested_module,

        historyDataView: [],

        historyData:[]
      }
    },
    created() {

    },
    mounted() {
      this.get_pointers()
    },
    methods: 
    {
      get_pointers() {
        let _this  = this;
        
        let results_root_paths = []

        if (this.list_type === "clinic") {
          let clinic_training_dir = '/Users/' + this.client_uid + '/Results/clinic_training/general/' 

          results_root_paths.push(clinic_training_dir)

        } else if (this.list_type === "assessment") {

          let results_dir = 'Users/'+ this.client_uid +  '/Results/clinic_assessment/packages/'
          results_root_paths.push(results_dir)

          results_dir = 'Users/'+ this.client_uid +  '/Results/clinic_assessment/general/' 
          results_root_paths.push(results_dir)

          results_dir = 'Users/' + this.client_uid + '/Results/assignment_assessment/general/'
          results_root_paths.push(results_dir)

          ////////////////
          results_dir = 'Users/' + this.client_uid + '/Results/remote_assessment/general/'
          results_root_paths.push(results_dir)
          ////////////////

          results_dir = 'Users/' + this.client_uid + '/Results/assignment_assessment/packages/'
          results_root_paths.push(results_dir)
          
        } else if (this.list_type === "home") {

          let home_training_dir = '/Users/' + this.client_uid + '/Results/training/general/'
          
          results_root_paths.push(home_training_dir)

          home_training_dir = '/Users/' + this.client_uid + '/Results/assignment_training/general/'
          
          results_root_paths.push(home_training_dir)
 
        }
             
        _this.historyData = []

        console.debug('results_root_paths = ' + results_root_paths)

        for(let n in results_root_paths) {
          let result_address = results_root_paths[n]
          if(result_address.includes('packages')) {
            this.load_packages_based_session_results_pointers(result_address)
          } else {
            this.load_modules_based_session_results_pointers(result_address)
          }
        }
      },
      load_modules_based_session_results_pointers(result_address) {
        const _this = this
        
        getData(result_address, function (err, result) {
          result.forEach(function(childSnapshot) {
            _this.load_results_between_dates(childSnapshot)
          });
          _this.update_list(_this.historyData)
        });
      },
      load_results_between_dates(childSnapshot) {
        let _this = this

        let module_date = childSnapshot.key
        
        let log_date = new Date(module_date).getTime()

        if(log_date >= _this.start_date && log_date <= _this.end_date) {
          
          childSnapshot.forEach(function(assessmentSnapshot) {
            let module_name = assessmentSnapshot.key

            let temp_module_name = assessmentSnapshot.child('plan_name').val()

            if(temp_module_name != null && temp_module_name != '') {
              module_name = temp_module_name
            }
            
            if(module_name !=null && assessmentSnapshot.hasChildren()) {
              if(_this.requested_module != null )  ///specific module requested
              {
                let root_path = decodeURIComponent(childSnapshot.ref.toString().substring(childSnapshot.ref.root.toString().length));
                if (module_name === _this.requested_module ) {
                  _this.historyData.push({date:module_date, module_name:module_name, assessmentSnapshot:assessmentSnapshot, root_path:root_path});
                } else if ('All modules' === _this.requested_module) {
                  _this.historyData.push({date:module_date, module_name:module_name, assessmentSnapshot:assessmentSnapshot, root_path:root_path});
                }
              }
            }
          });
        }
      },
      load_packages_based_session_results_pointers(result_address) {
        const _this = this

        getData(result_address, function (err, result) {
          result.forEach(function(packageSnapshot) {
            let exercisesSnapshot = packageSnapshot.child('exercises')

            exercisesSnapshot.forEach(function(childSnapshot) {
              _this.load_results_between_dates(childSnapshot)
            });
          });
          _this.update_list(_this.historyData)
        });
      },
      update_list(historyData) {
        const _this = this

        historyData = historyData.sort(function(a, b) {
          if (a.date > b.date) {
            return -1
          } else if (a.date < b.date) {
            return 1
          }
          return 0
        });
        _this.historyDataView = [...historyData]
      },
      delete_session_results(row) {
        let _this = this;

        let core_directory_handle = row['assessmentSnapshot'].ref

        core_directory_handle.remove().then(function() {
          const toast = useToast()
          toast.info("Exercise deleted!")
          _this.getResults();
        })
        .catch(function(error) {
          console.debug("Remove failed: " + error.message)
        });

      },
      findKey(firebaseHandle, target_key, dir_root) {
        let _this = this
        if(firebaseHandle.key != target_key) {
          if(firebaseHandle.hasChildren()) {

            let all_results = []

            firebaseHandle.forEach(function (childSnapshot) {
              let c_snapshot = childSnapshot
              
              let new_dir = dir_root + '/' + firebaseHandle.key

              let curr_result = _this.findKey(c_snapshot, target_key, new_dir, _this)

              if(curr_result != null)
              {
                all_results =  all_results.concat(curr_result)
              }
            });

            if(all_results.length>0) {
              return all_results
            }
          }
        }
        else{
          return [dir_root + '/'] //this directory should contain audio address (audio address is where data is stored)
        }

        return null
      },
      goResultsPage(row) {
        const _this = this

        let core_directory_handle = row['assessmentSnapshot']
        let path = row['root_path']
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///This need to change and we need to have a exercise folder to hold all exercises instead of using audio_address as indicator
        
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        let audio_address_exercises_paths = _this.findKey(core_directory_handle, 'audio_address', path)

        _this.$router.push({ name: 'session_results', 
          query: {
            root_path: JSON.stringify(path), 
            audio_address_exercises_paths: JSON.stringify(audio_address_exercises_paths),
            module_name: core_directory_handle.key
          }
        });
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      },
    }
  }

</script>

<style scoped>
  
</style>