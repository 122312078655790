<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar :currentIndex="userCategory == 'patient' ? '' : 'subscription_page'" :enableBack="false" ></Navbar>
    <div class="container-fluid mt-4">

      <Patient v-if="userCategory == 'patient'"></Patient>
      <SLT v-else></SLT>
      
      
    </div>
    <Footerbar class="mt-auto"></Footerbar>
  </div>
</template>

<script>
import Navbar from '@/views/Navbar.vue'
import Patient from './patient/patient.vue'
import SLT from './slt/slt.vue'

export default {
  props: {},
  components: {
    Navbar,
    Patient,
    SLT
  },
  data() {
    
    return {
      userCategory: localStorage.getItem('userCategory')
    };
  },
  created() {

  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>