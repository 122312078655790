<template>
  <div class="d-flex flex-column min-vh-100">
  <Navbar  :enableBack="true"></Navbar>
  <div class="container-fluid">
    <h1 class="display-5">Reading comprehension practice</h1>
    <div class="text-center">
      <div class="text-center card card-body">
        <div class="row mt-4 justify-content-center">
          <h2> What topic do you want to learn about?</h2>
        </div>
        <div class="row mt-4 justify-content-center">
          <input v-model="reading_comprehension_form.topic" :placeholder="placeholder_text" class="form-control" style="fontSize:20px;width:40%" />
        </div>

      

        <div class="row mt-2 justify-content-center">
          <button v-if="!generating" class="btn btn-primary btn-lg mt-2 w-25" @click="generate_reading_comprehension(reading_comprehension_form.topic)">
            Start
          </button>
          <button v-else class="btn btn-primary btn-lg mt-2 w-25" disabled>
            <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
          </button>
        </div>

        <div style="margin-top: 2%;"></div>
        <div class="row mt-2 justify-content-center">
          <div style="width:40%">
            <h4> Pick a difficulty level</h4>

            <FormKit
                v-model="diffLevel"
                :options="{'0':'Easy', '1':'Medium', '2':'Hard'}"
                wrapper-class= "form-check-inline ms-4"
            input-class= "form-check-input ms-2"
            option-class= "list-group"
            options-class= "list-group list-group-horizontal justify-content-center mt-4"
                type="radio"
              />

          </div>
          </div>
          <br/>


        <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
        <h4> Out of ideas? Try one of these by clicking here:</h4>
        <div class="mt-2">
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_reading_comprehension('The Beatles')" style="margin-left:5mm">
            The Beatles
          </button>
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_reading_comprehension('Ancient Greece')" style="margin-left:5mm">
            Ancient Greece
          </button>
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_reading_comprehension('Liverpool FC in the 80s')" style="margin-left:5mm">
            Liverpool FC in the 80s
          </button>
        </div>
        <div class="mt-2">
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_reading_comprehension('Leonardo Da Vinci')" style="margin-left:5mm">
            Leonardo Da Vinci
          </button>
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_reading_comprehension('The Olympic Games')" style="margin-left:5mm">
            The Olympic Games
          </button>
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_reading_comprehension('Thailand')" style="margin-left:5mm">
            Thailand
          </button>
        </div>
      </div>
    </div>
  </div>
  <Footerbar class="mt-auto"></Footerbar>
</div>
</template>
<script>
  
  
  
  import {reading_comprehension} from '@/utils/interface_ai'
  import Footerbar from '@/views/Footerbar.vue'
  import Navbar from '@/views/Navbar.vue'

  export default {
    name: 'reading_comprehension_setup',
    components: {Navbar, Footerbar},
    data() {
      return {
        userCategory: '',
        results_data: '',
        generating: false,
        difficulties:{'Easy':6,'Medium':10,'Hard':15},
        diffMarks:{
        0:'Easy',
        1:'Medium',
        2:'Hard'},
        diffLevel:0,
        placeholder_text: 'British TV programmes from the 80s',
        reading_comprehension_form:
        {
          topic:'',
          instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',
          n_item:3,
          text: [],
        },
      }
    },
    created() 
    {
      const _this = this
  
      _this.userCategory = localStorage.getItem("userCategory")

    },
  
    methods: 
    {
      generate_reading_comprehension(topic)
      {
          const _this = this

          let n_item = _this.reading_comprehension_form.n_item

          _this.generating = true


          if(topic==='')
          {
              topic = _this.placeholder_text
          }

          console.debug('this.diffLevel = ' + this.diffLevel)

          let age_diff = this.difficulties[this.diffMarks[this.diffLevel]];
          console.debug(age_diff)


          let list_quiz = reading_comprehension(_this,topic,age_diff)

          list_quiz.then(function(result) {

                console.debug('list_quiz = ' + result)

                for(let i in result)
                {
                  for(let k in result[i])
                  {
                    console.debug('result['+i+']['+k+'] = ' + result[i][k])

                  }
                }

                let string_result = JSON.stringify(result)


                localStorage.setItem('list_reading_comprehension',string_result)

                _this.$router.push({ name: 'reading_comprehension_interest' } );


            })
          

      },
      updateDifficulty()
      {
      }

    }
  }
  </script>
  <style scoped>
  
  </style>