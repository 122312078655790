<template>
<div>
  <div class="vh-100" style="background-color: #195e52; background-size:cover;" >
    <div id="signIn_page" class="mb-4">
      <h1 class="display-1 pt-5 pb-4" style="text-align: center; color:#FFFFFF;">BeautifulVoice</h1>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="card card-body rounded shadow-lg border" style="background-color: #c8e3cf;">
              <div class="container px-4s text-center">
                <div class="row gx-5">
                  <div class="col-md-8 mt-4 mb-4 border-end border-secondary" style="">
                    <div class="row mt-1 mb-2">
                      <h2 class="display-6"> Sign In </h2>
                    </div>
                    <div class="row">
                      <FormKit type="form" style="text-align: left" id="form" :actions="false" @submit="signIn()">
                        <div class="mb-4">
                          <label for="email" class="form-label">Email address / account ID <span style="color:red">*</span></label>
                          <FormKit input-class="form-control" id="email" type="text" name="Email address" v-model="email" 
                            :sections-schema="{
                              messages: { $el: 'div' },
                              message: { $el: 'span' },
                              options: { $el: 'div' },
                              option: { $el: 'div' },
                            }"
                            messages-class="text-danger"
                          placeholder="Enter your account ID" />
                        </div>
                        <div class="mt-2 mb-1">
                          <label for="password" class="form-label">Password <span style="color:red">*</span></label>
                          <FormKit input-class="form-control" id="password" name="password" type="password" v-model="password" 
                            :sections-schema="{
                              messages: { $el: 'div' },
                              message: { $el: 'span' },
                              options: { $el: 'div' },
                              option: { $el: 'div' },
                            }"
                            messages-class="text-danger"
                          placeholder="Enter your password" validation="required" />
                        </div>
                      </FormKit>
                      <div class="mt-2">
                        <button type="button" class="btn btn-link float-end fs-6" @click="forgotPassword()"><i>Forgot my password</i></button>
                      </div>
                      <div class="mt-2">
                        <button v-if="!loading" form="form" type="submit" class="btn btn-primary" style="width:100%" >Sign In</button>
                        <button v-else class="btn btn-primary" type="button" style="width:100%" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Signing in...
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="mt-4">
                      <p>Don't have an account?</p>
                      <button class="btn btn-success mb-4" style="margin-top:6px;" @click="signUp_details()">Register here</button>        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footerbar style="position: fixed; bottom:0px; width: 100%"></Footerbar>
  </div>

</div>
</template>

<script>
import { getUserDataDir, emailLogin, signInWithCustomToken } from '@/firebase/index'
import Footerbar from '@/views/Footerbar.vue'
import { useToast } from "vue-toastification";

export default {
  name: 'signIn',
  components: { Footerbar },
  data() {
    return {
      email: '',
      password: '',
      loading: false
    }
  },
  created()
  {
    const _this = this;
    let email = _this.$route.params.email

    if(email != undefined && email != null) {
      _this.email = email
    }
   
  },
  methods: {

    get_browser() {
      var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
      if(/trident/i.test(M[1])){
          tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
          return {name:'IE',version:(tem[1]||'')};
          }   
      if(M[1]==='Chrome'){
          tem=ua.match(/\bOPR|Edge\/(\d+)/)
          if(tem!=null)   {return {name:'Opera', version:tem[1]};}
          }   
      M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
        
      return {
        name: M[0],
        version: M[1]
      };
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    signIn() {
      let _this = this
      
      _this.loading = true;

      let browser_version = _this.get_browser()
          
      if (browser_version != undefined && browser_version != null) {
        const userAgent = navigator.userAgent;
        
        if (/Safari/.test(userAgent) && !/CriOS/.test(userAgent) && !/Windows/.test(userAgent) && !/Chrome/.test(userAgent)) {
          alert("We recommend using Chrome browser. Using Safari may lead to some product features not functioning as expected.") 
        }
      }

      if (this.isValidEmail(this.email)) {

        emailLogin(this.email, this.password, function (err) {

          if (err == null) {
            _this.authenticated()
          } else {
            const toast = useToast();

            toast.error("Invalid Credentials. If you forgot your password please click: 'Forgot my password'.");
            _this.loading = false;
          }
        })
      } else {
        let credentials = {'account_id': this.email, 'password': this.password}

        this.axios.post(import.meta.env.VITE_FAST_API + '/login', credentials, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
            const customToken = response.data;

            signInWithCustomToken(customToken, function (err) {

            if (err == null) {
              _this.authenticated()
            } else {
              const toast = useToast();

              toast.error("Invalid Credentials. If you forgot your password please click: 'Forgot my password'.");
              _this.loading = false;
            }})
        })
        .catch((error) => {
            console.error("Error generating custom token:", error);
            const toast = useToast();
            toast.error("Invalid Credentials. If you forgot your password please click: 'Forgot my password'.");

            _this.loading = false
        });
      }
    },
    forgotPassword() {
      this.$router.push({ name: 'forgot_password' });
    },
    signUp_details() {
      this.$router.push({ name: 'register' });
    },
    authenticated() {
      let _this = this
      let uid = _this.$firebase.auth().currentUser.uid;
      localStorage.setItem('uid', uid);

      getUserDataDir('Data', function (err, result) {
        let data = result.val()

        let userCategory = data['category']
        localStorage.setItem('userCategory', userCategory)
        
        if (data.hasOwnProperty('onboarding') ) {

          if (!data['onboarding'].hasOwnProperty('finished')) {
            _this.$router.push({ name: 'register'})                
          } else {
            const redirectAfterLogin = localStorage.getItem('redirectAfterLogin')
            localStorage.removeItem('redirectAfterLogin')
            _this.$router.push(redirectAfterLogin || { name: 'home' })
          }

        } else {
          const redirectAfterLogin = localStorage.getItem('redirectAfterLogin')
          localStorage.removeItem('redirectAfterLogin')
          _this.$router.push(redirectAfterLogin || { name: 'home' })
        }

      });
    }
  },
}
</script>

<style scoped>
#signIn_page {
  background-image: url('@/assets/images/beautiful-voice-merge.svg');
  background-color: #195e52;
  background-size: contain;
  background-repeat: no-repeat;

  background-position: 100% 50%;
}
.enterBtn,
.enterBtn:focus,
.enterBtn:hover {
  background-color: #7cc543;
  font-size: 20px;
  border-radius: 10px;
  border-color: #7cc543;
  /* margin-top: 8px; */
}

</style>
