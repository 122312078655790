<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row">
          <p class="lead" style="font-size: xx-large;"> {{instruction_text}}</p>
        </div>
        <button  v-for="num in btn_setting.length" :key="num" :style="btn_setting[num-1]" style="fontSize:30px" :class="['btn', btn_setting[num-1].checked]" @click="checkSequence(num-1)">{{btn_setting[num-1].numShown}}</button>
        
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
          @next="nextExercise()"
          >
        </Sidebar>
      </template>
    </Layout>
    <div class="modal fade" id="nothing_heard_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Level up</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container text-center">
              <div class="row">
                <div class="row">
                  <span style="font-size: x-large;"> {{ alert_msg }}</span>
                </div>
                <div class="row mt-2 justify-content-center">
                  <button class="btn btn-success w-100"   data-bs-dismiss="modal" >Let's go</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import {toWords} from '@/utils/utils'
import { Modal } from 'bootstrap'
import {getData, updateDataDirectory} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'
import { BV_Points } from '@/utils/bv_points'
import {startConfetti} from '@/common_js/confetti'

export default {
  name: 'Module',
  props: 
  {
    exForm: {
      type: Object,
    },
  },
  emits: [
    'logExerciseResults',
    'goToResults',
    'nextExercise',
  ],
  components: {Layout, Sidebar},
  data(){
    return {
      level_address:'',
      base_level_address:'/Levels/Number Sequence',
      correct_counter:0,
      incorrect_counter:0,
      level_up_target:3,
      total_counter: 0,
      today:null,
      week_info: null,
      correct_streak: 0,
      current_exercise_index: 0,
      failed_already: false,
      total_exercises:10,
      userCategory:'',
      region:'',
      num_btn:3,
      min_index: 0,
      btn_setting:[],
      diff_level:'Lvl0',
      level_properties:{
        1:{difficulty:1,num_btn:3,max_num:10,add_words:false,sum:false,minus:false,multiplication:false,num_operations:0},
        2:{difficulty:2,num_btn:3,max_num:50,add_words:false,sum:false,minus:false,multiplication:false,num_operations:0},
        3:{difficulty:3,num_btn:4,max_num:100,add_words:true,sum:false,minus:false,multiplication:false,num_operations:0},
        4:{difficulty:4,num_btn:4,max_num:20,add_words:true,sum:false,minus:false,multiplication:false,num_operations:0},
        5:{difficulty:5,num_btn:5,max_num:20,add_words:false,sum:true,minus:false,multiplication:false,num_operations:1},
        6:{difficulty:6,num_btn:5,max_num:20,add_words:false,sum:false,minus:true,multiplication:false,num_operations:1},
        7:{difficulty:7,num_btn:5,max_num:50,add_words:true,sum:false,minus:false,multiplication:false,num_operations:0},
        8:{difficulty:8,num_btn:5,max_num:50,add_words:false,sum:true,minus:true,multiplication:false,num_operations:1},
        9:{difficulty:9,num_btn:5,max_num:100,add_words:true,sum:false,minus:false,multiplication:false,num_operations:0},
        10:{difficulty:10,num_btn:5,max_num:50,add_words:false,sum:true,minus:true,multiplication:true,num_operations:1},
        11:{difficulty:11,num_btn:5,max_num:100,add_words:true,sum:true,minus:true,multiplication:true,num_operations:1},
        12:{difficulty:12,num_btn:6,max_num:100,add_words:true,sum:true,minus:true,multiplication:true,num_operations:2},
        13:{difficulty:13,num_btn:6,max_num:100,add_words:true,sum:true,minus:true,multiplication:true,num_operations:3}

      },
      sum:false,
      minus:false,
      multiplication:false,
      curr_level_properties:null,
      all_correct: true,
      instruction_text:'Click on the following numbers in an increasing order',
      max_num:10,
      add_words:false,
      op_num1:0,
      op_num2:0,
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points(),
      alert_msg:''
    }
  },
  created() {

    const _this = this

    _this.userCategory = localStorage.getItem('userCategory');
    _this.region = localStorage.getItem('region');

    logActivity( 'accessed', 'number_sequence')

    _this.get_difficulty();

    _this.current_exercise_index = _this.exForm.current_exercise_index
    _this.total_exercises = _this.exForm.total_all_type_exercises


  },
  beforeUnmount()
  {
    this.bv_tts.cancel()

  },
  methods: {
    get_difficulty()
    {
      const _this = this

      let do_once = true

      let userType = localStorage.getItem('userCategory');


      let uid = localStorage.getItem('uid');

      this.level_address = '/Users/' + uid + this.base_level_address


      if(userType!=undefined || userType==='slp')
      {
          let client_number = localStorage.getItem('client_uid');

          if(client_number!='undefined' && client_number!=null && client_number!='')
          {

              this.level_address = '/Users/'+ client_number + this.base_level_address

          }
      }


      getData(this.level_address, function (err, result) {

        if(do_once)
        {
          do_once = false

          let diff_level = result.child('Level').val()
          let correct_streak = result.child('Streak').val()

          if(diff_level!=undefined && diff_level!=null)
          {
            if (typeof diff_level === 'string' || diff_level instanceof String)
            {

              if(diff_level.length>3)
              {
                console.debug('diff_level = ' + diff_level)
                let number_part = diff_level.substring(3,5)
                console.debug('number_part = ' + number_part)

                number_part = parseInt(number_part)

                diff_level = number_part + 1
                
              }
              
            }

            
          }




          if(diff_level==undefined || diff_level==null)
          {
            diff_level = 1
            correct_streak = 0;

            updateDataDirectory(_this.level_address,{Level:diff_level,Streak:correct_streak})
            console.debug('creating level')
          }

          if(diff_level<1 || diff_level>Object.keys(_this.level_properties).length)
          {
            diff_level=1
          }


          _this.diff_level = diff_level

          _this.curr_level_properties = _this.level_properties[_this.diff_level] 

          console.debug('_this.level_properties = ' + _this.level_properties)
          console.debug('_this.current_level = ' + _this.current_level)

          console.debug('_this.curr_level_properties = ' + _this.curr_level_properties)



          // _this.diff_level = 'Lvl9'
          _this.correct_streak = correct_streak

          _this.DiffiConfig()

          _this.randPositions();
        }
      })
    },
    nextExercise()
    {
      const _this = this;

      window.speechSynthesis.cancel()
      
      if(_this.correct_counter>0 || this.incorrect_counter>0) { 
        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}

        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter, Difficulty: _this.diff_level, Total:_this.total_counter}

        let results = {all_results:all_results, plot_results:plot_results}

        var number_sequence_data = {resultsTablePre: {exercise_type: 'Life Skills', exercise: 'Number Sequence', results: results, audio_address:''}}

        _this.saveResults(number_sequence_data)

        _this.$emit('logExerciseResults', number_sequence_data);

      }

      _this.$emit('nextExercise');
    },
    goToResults()
    {
      const _this = this;
    
      var goToResultsData = {curr_ex_done:false}

      _this.$emit('goToResults', goToResultsData);

    },
    DifficultyUpdate()
    {
      const _this = this

      // if((_this.diff_level == 'Lvl0' || _this.diff_level == 'Lvl1') && _this.correct_streak>=2)
      // {
      //   _this.$alert('You have been doing really well! We will increase the difficulty level a little for you.')
      //   let curr_diff = _this.diff_level;
      //   let curr_diff_num = curr_diff.slice(-1);
      //   _this.diff_level = 'Lvl'+curr_diff_num+1;
      //   console.debug('new diff = '+_this.diff_level);
      //   _this.correct_streak = 0

      // }
      // else if((_this.diff_level !='Lvl0' && _this.diff_level !='Lvl1') && _this.correct_streak>=3)
      if(_this.correct_streak>=_this.level_up_target)
      {


        if(this.diff_level>=Object.keys(this.level_properties).length)
        {
          _this.alert_msg = 'You have finished all difficulty levels. Well done!'
          let nothing_heard_pop = new Modal(document.getElementById('nothing_heard_pop'));
          nothing_heard_pop.show()
          _this.correct_streak = 0


          
        }
        else
        {
          _this.diff_level = _this.diff_level+1

          _this.alert_msg = "You have been doing really well! Let’s try something harder."
          let nothing_heard_pop = new Modal(document.getElementById('nothing_heard_pop'));
          nothing_heard_pop.show()
          _this.correct_streak = 0

          // _this.$alert('You have been doing really well! We will increase the difficulty level a little for you.')
        }
      

      }

      updateDataDirectory(this.level_address,{Level:_this.diff_level,Streak:_this.correct_streak})
    },
    DiffiConfig()
    {
      const _this = this

      console.debug(this.curr_level_properties)


      this.num_btn = this.curr_level_properties['num_btn']
      this.max_num = this.curr_level_properties['max_num']
      this.add_words = this.curr_level_properties['add_words']
      this.sum = this.curr_level_properties['sum']
      this.minus = this.curr_level_properties['minus']
      this.multiplication = this.curr_level_properties['multiplication']
      this.num_operations = this.curr_level_properties['num_operations']


      this.operation_id = []

      let all_btns = []
      for(let n=0;n<this.num_btn;++n)
      {
        all_btns.push(n)
        
      }

      function shuffle(o) {
          for(var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
          return o;
      };

      var random = shuffle(all_btns);

      for(let n=0;n<this.num_operations;++n)
      {
        this.operation_id.push(random[n])

      }

      console.debug('this.operation_id = ' + this.operation_id)
      


    },
    randPositions()
    {
        const _this = this
        _this.min_index = 0
        _this.current_exercise_index = _this.current_exercise_index + 1
        _this.all_correct = true
        if(_this.current_exercise_index>_this.total_exercises)
        {
          _this.endExercise()
          return
        }

        this.btn_setting = [];
        let existing_num = [];

        let all_random_positions = []

        let grid_size = 4

        for(let i=0;i<(grid_size);++i)
        {
          for(let n=0;n<(grid_size);++n)
          {
            all_random_positions.push({left:i,top:n})
          }
        }

        for(let i=0; i<this.num_btn;++i)
        {
          let number_positions = all_random_positions.length

          let random_index = Math.floor(Math.random()*number_positions)

          let random_position = all_random_positions[random_index]

          let left_val = random_position.left*20+1
          let top_val = random_position.top*15+15

          all_random_positions.splice(random_index,1)
          let rand_num = 0;
          let numDisp = '';
          
          if((this.sum || this.minus || this.multiplication) && (this.operation_id.includes(i)))
          {
          
            [rand_num,numDisp] = this.numOperations();
          }
          else if((this.add_words) && (this.operation_id.includes(i)))
          {
            rand_num = (Math.floor(Math.random() * this.max_num));
            numDisp = toWords(rand_num);
          }
          else
          {
            rand_num = (Math.floor(Math.random() * this.max_num));
            numDisp = rand_num.toString();
          }

          if(!existing_num.includes(rand_num))
          {
              existing_num.push(rand_num);
          }
          else
          {
              while(existing_num.includes(rand_num))
              {
                if((this.sum || this.minus || this.multiplication) && (this.operation_id.includes(i)))
                {

                  [rand_num,numDisp] = this.numOperations();
                }
                else if((this.add_words) && (this.operation_id.includes(i)))
                {
                  rand_num = (Math.floor(Math.random() * this.max_num));
                  numDisp = toWords(rand_num);
                }
                else
                {
                  rand_num = (Math.floor(Math.random() * this.max_num));
                  numDisp = rand_num.toString();
                }              
              }
              existing_num.push(rand_num);
          }

          let obj = {margin:'auto',position:'absolute',left:left_val+'%',top:top_val+'%',numShown:numDisp, numVal:rand_num, clicked: false,sequence:0,checked:'btn-primary'}
          this.btn_setting.push(obj);

        }

        let sequenced_arr = existing_num.map((x) => x);

        sequenced_arr.sort(function(a, b){return a-b});

        for(let j=0;j<sequenced_arr.length;j++)
        {
          let before_sort_id = existing_num.indexOf(sequenced_arr[j]);
          this.btn_setting[before_sort_id].sequence = j;
        }
        
    },
    numOperations()
    {
      let rand_num = 0;
      let rand_num1 = 0;
      let rand_num2 = 0;
      let disp_operation = ''

      if(this.sum && !this.minus && !this.multiplication)
      {
        let lower_bound = Math.floor(this.max_num/2);

        rand_num1 = (Math.floor(Math.random() * lower_bound));
        rand_num2 = (Math.floor(Math.random() * lower_bound));
        rand_num = rand_num1+rand_num2;
        disp_operation = '+'
      }
      else if(!this.sum && this.minus && !this.multiplication)
      {
        let lower_bound = Math.floor(this.max_num/2);

        rand_num1 = (Math.floor(Math.random() * lower_bound))+lower_bound;
        rand_num2 = (Math.floor(Math.random() * lower_bound));
        rand_num = rand_num1 - rand_num2;
        disp_operation = '-'
      }
      else if(this.sum && this.minus && !this.multiplication)
      {
        let rand_operation = (Math.floor(Math.random() * 2));
        let lower_bound = Math.floor(this.max_num/2);

        if(rand_operation == 0)
        {
          rand_num1 = (Math.floor(Math.random() * lower_bound));
          rand_num2 = (Math.floor(Math.random() * lower_bound));
          rand_num = rand_num1+rand_num2;
          disp_operation = '+'
        }
        else
        {
          rand_num1 = (Math.floor(Math.random() * lower_bound))+lower_bound;
          rand_num2 = (Math.floor(Math.random() * lower_bound));
          rand_num = rand_num1 - rand_num2;
          disp_operation = '-'
        }  
      }
      else
      {
        let rand_operation = (Math.floor(Math.random() * 3));
        let lower_bound = Math.floor(this.max_num/2);

        if(rand_operation == 0)
        {
          rand_num1 = (Math.floor(Math.random() * lower_bound));
          rand_num2 = (Math.floor(Math.random() * lower_bound));
          rand_num = rand_num1+rand_num2;
          disp_operation = '+'
        }
        else if(rand_operation == 1)
        {
          rand_num1 = (Math.floor(Math.random() * lower_bound))+lower_bound;
          rand_num2 = (Math.floor(Math.random() * lower_bound));
          rand_num = rand_num1 - rand_num2;
          disp_operation = '-'
        } 
        else
        {
          rand_num1 = (Math.floor(Math.random() * 9));
          rand_num2 = (Math.floor(Math.random() * 9));
          rand_num = rand_num1*rand_num2;
          disp_operation = 'x'
        }
      }
      
      let disp = rand_num1.toString() + ' '+ disp_operation +' '+rand_num2.toString();
      return [rand_num,disp];
      
    },
    checkSequence(index)
    {

      const _this = this
      

      if(!_this.btn_setting[index].clicked)
      {
        
        let curr_sequence_number = _this.btn_setting[index].sequence

        if(curr_sequence_number==_this.min_index)
        {
          _this.btn_setting[index].checked = 'btn-success'

          _this.min_index +=1 

          _this.btn_setting[index].clicked = true

          console.debug('_this.min_index = ' + _this.min_index)
          console.debug('_this.btn_setting.length-1 = ' + (_this.btn_setting.length-1))

          _this.bv_tts.say(String(_this.btn_setting[index].numVal));
          if(_this.min_index==(_this.btn_setting.length))
          {
            startConfetti(this)

          }


          if(_this.all_correct && _this.min_index==(_this.btn_setting.length))
          {
            _this.correct_counter = _this.correct_counter + 1
            _this.correct_streak = _this.correct_streak + 1
            _this.total_counter = 1


            _this.DifficultyUpdate()
          }

          

        }
        else
        {
          if(_this.all_correct)
          {
            _this.incorrect_counter = _this.incorrect_counter+1
            _this.correct_streak = _this.correct_streak - 1
            _this.total_counter = 1
            _this.DifficultyUpdate()

            _this.bv_tts.say(String(_this.btn_setting[index].numVal) + ' is not correct!');


          }
          else
          {
            _this.bv_tts.say(String(_this.btn_setting[index].numVal));

          }
          _this.all_correct = false
          _this.btn_setting[index].checked = 'btn-danger'

        }
      }
    },
    saveResults()
    {
      const _this = this

      if(this.correct_counter!=0 || this.incorrect_counter!=0)
      {
        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter, Difficulty: _this.diff_level, Total:_this.total_counter}

        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}
        
        let results = {all_results:all_results, plot_results:plot_results}

        var number_sequence_data = {resultsTablePre: {exercise_type: 'Life Skills', exercise: 'Number Sequence', results: results, audio_address:''}}
        
        save_pre_computed_results(_this.exForm,  number_sequence_data)

        _this.bv_points.add(10)
      }
    },
    
  },
}
</script>
<style scoped>


</style>